// src/components/Layout.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MainHeader from './MainHeader';
import Footer from './Footer';
import '../styles/ComponentsStyles/Layout.css';
import { updates } from '../data/updatesData';

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const [showUpdatesModal, setShowUpdatesModal] = useState(false);

  useEffect(() => {
    const lastSeenVersion = localStorage.getItem('lastSeenVersion');
    if (lastSeenVersion !== updates.currentVersion) {
      setShowUpdatesModal(true);
    }

    // Ajout d'un écouteur pour fermer la modale avec la touche ESC
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        handleCloseModal();
      }
    };
    window.addEventListener('keydown', handleKeyDown);

    // Nettoyage de l'écouteur lors du démontage
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  
  const handleCloseModal = () => {
    localStorage.setItem('lastSeenVersion', updates.currentVersion);
    setShowUpdatesModal(false);
  };

  const handleViewUpdates = () => {
    navigate('/updates');
    handleCloseModal();
  };

  return (
    <div className="layout-container">
      <MainHeader />
      {/* Modal de mise à jour avec affichage des nouveautés */}
      {showUpdatesModal && (
        <div className="updates-modal" onClick={handleCloseModal}>
        <div
          className="updates-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
            <h2>Nouveautés - Version {updates.currentVersion}</h2>
            <p style={{ textAlign: 'center' }}>Mixify est en constante évolution !</p>
            <ul>
              {updates.versionNotes[0].notes.map((note, index) => (
                <li key={index}>{note}</li>
              ))}
            </ul>
            <p style={{ fontSize: '1.5rem', marginBottom: '1rem' }}>À venir :</p>
            <ul>
              {updates.upcoming.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
            <button onClick={handleViewUpdates}>Voir tous les détails</button>
            <button onClick={handleCloseModal}>Fermer</button>
          </div>
        </div>
      )}
      <main className="main-content">
        {children}
      </main>
      <Footer />
    </div>
  );
};


export default Layout;