// src/utils/passwordUtils.js
export const validatePassword = (password) => {
    const minLength = 8;
    const uppercase = /[A-Z]/;
    const lowercase = /[a-z]/;
    const number = /[0-9]/;
    const specialChar = /[!@#$%^&*(),.?":{}|<>_\-~]/;
  
    const criteria = {
      length: password.length >= minLength,
      uppercase: uppercase.test(password),
      lowercase: lowercase.test(password),
      number: number.test(password),
      specialChar: specialChar.test(password),
    };
  
    const isValid = Object.values(criteria).every(Boolean);
    return { isValid, criteria };
  };