import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/ComponentsStyles/Footer.css'; // Vos styles spécifiques au footer

const Footer = () => {
    return (
      <footer className="home-footer">
        {/* === Zone Liens et Informations === */}
        <div className="footer-links">
          {/* -- COLONNE : Contact -- */}
          <div className="footer-column">
            <h4 className="footer-col-title">Contact</h4>
            <a href="mailto:contact@mixify.com" className="footer-link">
              contact@mixify.com
            </a>
            <a
              href="https://www.instagram.com/mixify_fr/"
              target="_blank"
              rel="noreferrer"
              className="footer-link footer-icon-link"
            >
              <i className="fab fa-instagram footer-icon"></i>
              Instagram 
            </a>
          </div>
  
          {/* -- COLONNE : Liens (liens du header) -- */}
          <div className="footer-column">
            <h4 className="footer-col-title">Liens</h4>
            <Link to="/" className="footer-link">Accueil</Link>
            <Link to="/features" className="footer-link">Fonctionnalités</Link>
            <Link to="/subscription" className="footer-link">Tarifs</Link>
            <Link to="/charts" className="footer-link">Charts</Link>
            <Link to="/about" className="footer-link">À Propos</Link>
            
          </div>
  
          {/* -- COLONNE : Aide -- */}
          <div className="footer-column">
            <h4 className="footer-col-title">Aide</h4>
            <Link to="/faq" className="footer-link">FAQ</Link>
            <Link to="/documentation" className="footer-link">Documentation</Link>
            <Link to="/privacy-policy" className="footer-link">Politique de Confidentialité</Link>
            <Link to="/terms-of-service" className="footer-link">Conditions d'Utilisation</Link>
            <Link to="/mentions-legales" className="footer-link">Mentions Légales</Link>
            <Link to="/spotify-api" className="footer-link">Spotify Api</Link>
          </div>
  
          {/* -- COLONNE : Compte -- */}
          <div className="footer-column">
            <h4 className="footer-col-title">Compte</h4>
            <Link to="/signup" className="footer-link">Inscription</Link>
            <Link to="/login" className="footer-link">Connexion</Link>
            <Link to="/subscription" className="footer-link">Abonnement</Link>
          </div>
        </div>
  
        {/* === Mention Spotify === */}
        <div className="footer-powered-by">
          Base de données musicale propulsée par l’API Web de Spotify.
        </div>
  
        {/* === Copyright === */}
        <div className="footer-copy">
          <p>© {new Date().getFullYear()} Launchmachine Music. Tous droits réservés.</p>
        </div>
      </footer>
    );
  };
  

export default Footer;