import { supabase } from './supabaseClient';

export const getSetting = async (eventId, key) => {
  console.log(`Fetching setting "${key}" for event "${eventId}"`);
  try {
    const { data, error } = await supabase
      .from('evenements')
      .select(key)
      .eq('id', eventId)

    if (error) {
      console.error(`Erreur lors de la récupération du paramètre ${key} pour l'événement ${eventId} :`, error);
      return null;
    }

    if (!data || data.length === 0) {
      console.error(`Aucune entrée trouvée pour la clé "${key}" et l'événement ${eventId}.`);
      return null;
    }

    return data[0]?.[key] || null; // Retourne la première valeur trouvée
  } catch (err) {
    console.error(`Erreur lors de la récupération du paramètre ${key} :`, err);
    return null;
  }
};

export const updateSetting = async (eventId, key, value) => {
  try {
    const { data, error } = await supabase
      .from('evenements')
      .update({ [key]: value }) // Met à jour la colonne spécifiée
      .eq('id', eventId);

    if (error) {
      console.error(`Erreur lors de la mise à jour du paramètre ${key} pour l'événement ${eventId} :`, error);
      return null;
    }

    console.log(`Mise à jour réussie pour ${key} de l'événement ${eventId} :`, data);
    return data;
  } catch (err) {
    console.error(`Erreur lors de la mise à jour du paramètre ${key} :`, err);
    return null;
  }
};

// settings.js
export const manageCooldownDuration = async (eventId, setCooldownDuration) => {
    const cooldown = await getSetting(eventId,'cooldown');
    if (cooldown) {
      setCooldownDuration(Number(cooldown));
      console.log(`Délai chargé depuis Supabase pour l'événement ${eventId} :`, cooldown);
    }
  };

  // Fonction pour initialiser les motifs de refus par défaut
export const initializeEventSettings = async (eventId) => {
  const defaultRefusalReasons = [
    { keyword: 'default', label: 'Désolé, ce morceau n’est pas adapté à la dynamique actuelle de la soirée.' },
    { keyword: 'Déjà Passé', label: 'Malheureusement, ce morceau est déjà passé plus tôt dans la soirée.' },
    { keyword: 'Copyright', label: 'Désolé, notre DJ n’a pas pu se procurer votre musique.' },
  ];

  try {
    const { data, error } = await supabase
      .from('evenements')
      .select('refused_text_reason')
      .eq('id', eventId);

    if (error) {
      console.error(`Erreur lors de la récupération des paramètres pour l'événement ${eventId} :`, error);
      return;
    }

    if (!data || !data[0]?.refused_text_reason) {
      console.log('Aucun motif de refus trouvé. Initialisation...');
      await updateSetting(eventId, 'refused_text_reason', JSON.stringify(defaultRefusalReasons));
    }
  } catch (err) {
    console.error('Erreur lors de l\'initialisation des paramètres :', err);
  }
};