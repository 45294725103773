// src/components/SubscriptionBanner.js
import React, { useEffect, useState } from 'react';
import { supabase } from '../utils/supabaseClient';

function SubscriptionBanner() {
  const [subscription, setSubscription] = useState(null);
  const [loadingSub, setLoadingSub] = useState(true);
  const [formattedCancelDate, setFormattedCancelDate] = useState('');

  useEffect(() => {
    supabase.auth.getSession().then(({ data, error }) => {
      if (error) {
        console.error('Erreur session:', error);
        setLoadingSub(false);
        return;
      }
      const userId = data?.session?.user?.id;
      if (!userId) {
        // Pas connecté => pas d’abonnement => pas de bandeau
        setLoadingSub(false);
        return;
      }
      fetchSubscription(userId);
    });

    async function fetchSubscription(userId) {
      try {
        const { data: sub, error: subError } = await supabase
          .from('subscriptions')
          .select('*')
          .eq('user_id', userId)
          .maybeSingle();

        if (subError) {
          console.error('Erreur lecture subscription:', subError);
          setLoadingSub(false);
          return;
        }

        if (sub) {
          setSubscription(sub);
          if (sub.cancel_at) {
            const dateObj = new Date(sub.cancel_at * 1000);
            const dateString = dateObj.toLocaleDateString('fr-FR', {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            });
            setFormattedCancelDate(dateString);
          }
        }
      } catch (err) {
        console.error('Erreur fetchSubscription:', err);
      } finally {
        setLoadingSub(false);
      }
    }
  }, []);

  if (loadingSub) {
    return null; 
  }

  if (!subscription) {
    return null;
  }

  const isActiveAndCancel = (
    subscription.status === 'active' &&
    subscription.cancel_at_period_end === true
  );

  if (!isActiveAndCancel) {
    return null;
  }

  // ---------- STYLES DU BANDEAU ----------
  // position: 'fixed' => reste en haut (ou bas) de l'écran
  // top: 0 => tout en haut (si tu préfères en bas, utilise bottom: 0)
  // width: '100%' => toute la largeur
  // zIndex: 9999 => par-dessus le contenu
  // backdropFilter => crée l'effet de flou sur l'arrière-plan
  // backgroundColor => tu mets de la transparence (RGBA)

  return (
    <div
      style={{
        position: 'fixed',
        bottom: 0,              // ou bottom: 0 si tu préfères le bas
        left: 0,
        width: '100%',
        zIndex: 9999,
        // Couleur orange avec alpha pour semi-transparence
        backgroundColor: 'rgba(255, 165, 0, 0.3)', 
        // Le blur
        backdropFilter: 'blur(6px)',
        // Hauteur/padding plus petit => "petit en hauteur"
        padding: '0.5rem 1rem',
        textAlign: 'center',
        color: '#fff',
        fontWeight: 'bold',
        // Si tu veux un style un peu arrondi sur le bas, c'est optionnel
        borderBottomRightRadius: '8px',
        borderBottomLeftRadius: '8px',
        // Petite ombre en bas pour bien séparer du contenu
        boxShadow: '0 2px 6px rgba(0,0,0,0.3)',
      }}
    >
      Votre abonnement prendra fin le {formattedCancelDate}
    </div>
  );
}

export default SubscriptionBanner;