import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { supabase } from '../utils/supabaseClient';
import '../styles/LandingPage.css'; // On peut réutiliser le même CSS
import '../styles/spinner.css';

function LandingNoAuthPage() {
  const { eventId } = useParams();
  const navigate = useNavigate();

  // Au clic sur "Proposer un titre", on génère un userId (si pas déjà fait),
  // puis on va sur /search.
  const handleProposer = async () => {
    let userId = localStorage.getItem('userId');
    if (!userId) {
      userId = uuidv4();
      localStorage.setItem('userId', userId);
      console.log('LandingNoAuthPage: userId généré =', userId);

     // (1) On insère/upsert dans la table "users"
     try {
       const { data, error } = await supabase
         .from('users')
         .upsert({
           id: userId,
           event_id: eventId, // si vous voulez lier user->event
           created_at: new Date().toISOString()
         }, {
           onConflict: 'id'  // on se base sur la PK ou unique(id)
         });
       if (error) {
         console.error("Erreur lors de l'upsert user anonyme:", error);
       } else {
         console.log("User anonyme créé/upsert avec succès:", data);
       }
     } catch (err) {
       console.error("Erreur inattendue insert user:", err);
     }
    }
    // On redirige vers la page /search
    navigate(`/search/${eventId}`);
  };

  return (
    <div className="landing-page-container">
      <Helmet>
        <title>Mixify</title>
        <meta name="description" content="Scannez, Proposez, Vibrez." />
      </Helmet>

      {/* Section Supérieure : Titre et Sous-Titre */}
      <div className="top-section">
        <h1 className="landing-title">Mixify</h1>
        <h2 className="landing-subtitle">Scannez, Proposez, Vibrez</h2>
      </div>

      {/* Section Centrale : on retire le code input, on met un seul bouton */}
      <div className="middle-section">
        <p className="landingnoauth-description">
          Propose un morceau, suis sa progression et soit notifié lorsque il est joué.
        </p>

        <button className="access-button" onClick={handleProposer}>
          Proposer un titre
        </button>

        <div className="progress-bar"></div>
      </div>
    </div>
  );
}

export default LandingNoAuthPage;