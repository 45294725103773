// src/utils/AuthContext.js
import React, { createContext, useState, useEffect, useRef } from "react";
import { supabase } from "./supabaseClient";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const userRef = useRef(null);

  // Met à jour la référence dès que "user" change
  useEffect(() => {
    userRef.current = user;
  }, [user]);

  useEffect(() => {
    
    // -----------------
    // 2) onAuthStateChange
    // -----------------
    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        try {
          console.log("[AuthContext] onAuthStateChange => event =", event);
    
          // Vérifier si la session a réellement changé :
          if (!session?.user || !userRef.current || session.user.id !== userRef.current.id) {
            setLoading(true);
          }
          
          if (session?.user) {
            console.log("[AuthContext] => session.user existe, je vais fetchUserProfile...");
            const userFetched = await fetchUserProfile(session.user);
            console.log("[AuthContext] => fetchUserProfile terminé, userFetched =", userFetched);
            setUser(userFetched);
    
            if (event === "SIGNED_IN") {
              console.log("[AuthContext] => event = SIGNED_IN => je check le profil...");
              try {
                const { data: existingProfile, error } = await supabase
                  .from("profiles")
                  .select("*")
                  .eq("user_id", session.user.id)
                  .maybeSingle();
    
                if (error) {
                  console.error("Erreur en vérifiant l'existence du profil:", error);
                }
    
                if (!existingProfile) {
                  console.log("[AuthContext] => pas de profil => je le crée");
                  const fallbackUsername =
                    session.user.user_metadata?.full_name || session.user.email;
    
                  await supabase.from("profiles").insert({
                    user_id: session.user.id,
                    email: session.user.email,
                    username: fallbackUsername,
                  });
    
                  try {
                    await fetch("/api/notify", {
                      method: "POST",
                      headers: { "Content-Type": "application/json" },
                      body: JSON.stringify({
                        subject: "Nouvel utilisateur inscrit",
                        userEmail: session.user.email,
                        username: fallbackUsername,
                      }),
                    });
                  } catch (notifErr) {
                    console.error("Erreur lors de l'envoi du mail admin:", notifErr);
                  }
                  console.log("[AuthContext] => profil créé");
                }
              } catch (profileErr) {
                console.log("[AuthContext] => Erreur creation profil (catch):", profileErr);
              }
              console.log("[AuthContext] => fin du check profil");
            }
          } else {
            // Pas de session => l'utilisateur est déconnecté
            setUser(null);
          }
        } catch (err) {
          console.error("[AuthContext] => onAuthStateChange => erreur: ", err);
          setUser(null);
        } finally {
          // Toujours terminer en mettant loading à false
          setLoading(false);
          console.log("[AuthContext] => loading est false => fin callback");
        }
      }
    );


    return () => {
            authListener.subscription.unsubscribe();
          };
        }, []);
      
        // -------------------------------
        // fetchUserProfile (inchangé)
        // -------------------------------
        async function fetchUserProfile(baseUser) {
          console.log("[fetchUserProfile] => Début, baseUser.id =", baseUser.id);
          try {
            console.log("[fetchUserProfile] => avant supabase.from('profiles').select() ...");
            const { data: profile, error } = await supabase
              .from("profiles")
              .select("username, avatar_url")
              .eq("user_id", baseUser.id)
              .maybeSingle();
            console.log("[fetchUserProfile] => Requête finie. profile =", profile, " error =", error);
        
            if (error) {
              console.error("[fetchUserProfile] => error renvoyée par Supabase:", error);
              return baseUser; // ou throw error
            }
        
            if (!profile) {
              console.log("[fetchUserProfile] => pas de profil => on renvoie baseUser");
              return baseUser;
            }
        
            console.log("[fetchUserProfile] => success => on fusionne baseUser + profile");
            return { ...baseUser, ...profile };
        
          } catch (err) {
            console.error("[fetchUserProfile] -> Exception:", err);
            return baseUser;
          }
        }
        

  /**
   * Inscription par email/mot de passe
   * @param {string} email
   * @param {string} password
   * @param {object} [options] - options Supabase (ex: { emailRedirectTo: '...' })
   * @returns {Promise<{ data, error }>}
   */
  const signUpWithEmail = async (email, password, options = {}) => {
    console.log("[AuthContext] signUpWithEmail -> email:", email);
    setLoading(true);
    const { data, error } = await supabase.auth.signUp(
      { email, password },
      options
    );
    if (error) {
      console.error("[AuthContext] signUpWithEmail -> ERREUR:", error);
    } else {
      console.log("[AuthContext] signUpWithEmail -> data:", data);
    }

    if (data.session) {
      localStorage.setItem("hasLoggedIn", "true");
    }
    setLoading(false);
    return { data, error };
  };

  /**
   * Connexion par email/mot de passe
   * @param {string} email
   * @param {string} password
   * @returns {Promise<{ data, error }>}
   */
  const signInWithEmail = async (email, password) => {
    console.log("[AuthContext] signInWithEmail -> email:", email);
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    if (error) {
      console.error("[AuthContext] signInWithEmail -> ERREUR:", error);
    } else {
      console.log("[AuthContext] signInWithEmail -> data:", data);
    }

    if (data.session) {
      localStorage.setItem("hasLoggedIn", "true");
    }
    return { data, error };
  };

  /**
   * Connexion (ou inscription) via Google OAuth
   * @param {string} redirectTo - URL où renvoyer après connexion
   * @returns {Promise<{ data, error }>}
   */
  const signInWithGoogle = async (redirectTo = "") => {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: "google",
      options: { redirectTo },
    });
    return { data, error };
  };

  /**
   * Déconnexion
   */
  const logout = async () => {
    await supabase.auth.signOut();
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        signUpWithEmail,
        signInWithEmail,
        signInWithGoogle,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
