// src/components/EventCreationModal.js
import React, { useState } from 'react';
import '../styles/ComponentsStyles/EventCreationModal.css';

/**
 * Ce composant affiche une modale pour créer un nouvel évènement.
 * Il reçoit trois props :
 * - isOpen : booléen indiquant si la modale doit être affichée.
 * - onClose : fonction à appeler pour fermer la modale.
 * - onCreate : fonction à appeler lors de la création (reçoit nom, type et l'état de l'authentification).
 */
function EventCreationModal({ isOpen, onClose, onCreate }) {
  // États locaux pour gérer les champs de la modale
  const [newEventName, setNewEventName] = useState('');
  // On initialise le type par défaut (par exemple "Soirée privée")
  const [eventType, setEventType] = useState('Soirée privée');
  // État pour le toggle d'authentification (par défaut désactivé)
  const [authenticationEnabled, setAuthenticationEnabled] = useState(false);

  const handleSubmit = () => {
    if (newEventName.trim() === '' || eventType.trim() === '') {
      alert("Veuillez remplir tous les champs.");
      return;
    }
    // Appel de la fonction de création avec les valeurs renseignées
    onCreate(newEventName, eventType, authenticationEnabled);
    // Réinitialiser les champs
    setNewEventName('');
    setEventType('Soirée privée');
    setAuthenticationEnabled(false);
  };

  // Si isOpen est false, on ne rend rien (la modale n'est pas affichée)
  if (!isOpen) return null;

  return (
    // L'overlay permet de couvrir tout l'écran
    <div className="modal-overlay" onClick={onClose}>
      {/* On arrête la propagation du clic pour éviter de fermer la modale quand on clique à l'intérieur */}
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2>Créer un nouvel évènement</h2>
        <div className="modal-form-group">
          <label>Nom de l'évènement</label>
          <input
            type="text"
            value={newEventName}
            onChange={(e) => setNewEventName(e.target.value)}
            placeholder="Entrez le nom de l'évènement"
            maxLength={41}
          />
        </div>
        <div className="modal-form-group">
          <label>Type d'évènement</label>
          <select value={eventType} onChange={(e) => setEventType(e.target.value)}>
            <option value="Soirée privée">Soirée privée (mariages, anniversaires, etc)</option>
            <option value="Clubs, bars dansants">Clubs, bars dansants</option>
            <option value="restaurant, lounge">restaurant, lounge</option>
          </select>
        </div>
        {/* Nouvel élément pour le toggle d'authentification */}
        <div className="modal-form-group">
          <label>
            <input
              type="checkbox"
              checked={authenticationEnabled}
              onChange={() => setAuthenticationEnabled(!authenticationEnabled)}
              className="ui-checkbox"
            />{' '}
            Activer l'authentification
          </label>
        </div>
        <div className="modal-buttons">
          <button className="btn btn-primary" onClick={handleSubmit}>Créer</button>
          <button className="btn btn-secondary" onClick={onClose}>Annuler</button>
        </div>
      </div>
    </div>
  );
}

export default EventCreationModal;