// src/pages/ForgotPasswordPage.js
import React, { useState } from 'react'
import { supabase } from '../utils/supabaseClient'
import '../styles/Auth.css'
import Input from '../components/Input';
import Layout from '../components/Layout';
import { Link } from 'react-router-dom';

function ForgotPasswordPage() {
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const handleResetPassword = async (e) => {
    e.preventDefault()
    setMessage('')
    setErrorMessage('')

    if (!email) {
      setErrorMessage('Veuillez saisir votre adresse email.')
      return
    }

    try {
      // Supabase envoie un email avec un lien de réinitialisation
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/update-password`, 
        // => page où l’utilisateur sera redirigé après clic dans l’e-mail
      })

      if (error) {
        setErrorMessage(error.message)
        return
      }

      setMessage('Un email de réinitialisation vous a été envoyé.')
    } catch (err) {
      setErrorMessage(err.message)
    }
  }

  return (
    <Layout>
    <div className="auth-page">
    <div className="auth-container">
      <h2>Mot de passe oublié</h2>
      <form onSubmit={handleResetPassword}>
        <Input
  id="email"
  type="email"
  label="Adresse email"
  placeholder="ex: dj@mail.com"
  value={email}
  onChange={(e) => setEmail(e.target.value)}
  required
/>

        <button type="submit">Réinitialiser</button>
      </form>

      {errorMessage && <div className="auth-message error">{errorMessage}</div>}
      {message && <div className="auth-message success">{message}</div>}

      <div className="switch-auth-links">
      <p>
            Vous n’avez pas de compte ? <br/><Link to="/signup">S'inscrire</Link>
          </p>
          <p>
            Vous avez déjà un compte ? <br/><Link to="/login">Se connecter</Link>
          </p>
        </div>
    </div>
    </div>
    </Layout>
  )
}

export default ForgotPasswordPage