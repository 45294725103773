// src/components/UserMenu.js
import React, { useContext, useState, useRef, useEffect } from 'react';
import { AuthContext } from '../utils/AuthContext';
import { useNavigate } from 'react-router-dom';

import '../styles/ComponentsStyles/UserMenu.css'; // <-- On importe le fichier de styles

function UserMenu() {
  const navigate = useNavigate();
  const { user, logout } = useContext(AuthContext);
  const [menuOpen, setMenuOpen] = useState(false); // Indique si le menu est affiché ou non
  const menuRef = useRef(null);

  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    if (menuOpen) {
      console.log('[UserMenu] menuOpen = true => on écoute le clic extérieur');
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpen]);

  // Déconnexion
  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };



  // 2) Sinon => on a user.username
  const username = user.username;
  const userAvatarUrl = user.avatar_url;

   return (
    <div className="user-menu-container" ref={menuRef}>
      <div
        className="user-menu-circle"
        title="Cliquez pour afficher le menu"
        onClick={() => setMenuOpen(!menuOpen)}
        style={{
          backgroundImage: userAvatarUrl ? `url(${userAvatarUrl})` : 'none',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {/* Comme avant : si pas d'avatar => initiales */}
        {!userAvatarUrl && username.slice(0, 2).toUpperCase()}
      </div>

      <div className={`user-menu-dropdown ${menuOpen ? 'show' : ''}`} ref={menuRef}>
        <div className="user-menu-username">{username}</div>

        <button
          className="menu-button"
          onClick={() => {
            setMenuOpen(false);
            navigate('/profile');
          }}
        >
          Mon compte
        </button>

        <button className="menu-button" onClick={() => navigate('/events-manager')}>
          Mes évènements
        </button>

        <button className="menu-button" onClick={() => navigate('/subscription')}>
          Abonnement
        </button>

        <button className="logout-button" onClick={handleLogout}>
          Se déconnecter
        </button>
      </div>
    </div>
  );
}

export default UserMenu;