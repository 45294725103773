// src/components/AvatarUploader.js

import React, { useState, useRef, useCallback } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import imageCompression from 'browser-image-compression';
import { supabase } from '../utils/supabaseClient';

function AvatarUploader({ onUploadSuccess, username }) {
  // States
  const [imgSrc, setImgSrc] = useState('');
  const [selectedFileName, setSelectedFileName] = useState('');
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState(null);

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  /**
   * Au changement de fichier, on lit le fichier localement pour l'afficher
   * et on stocke le nom du fichier pour l'afficher en dessous du bouton
   */
  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setSelectedFileName(file.name); // <-- on mémorise le nom
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImgSrc(reader.result.toString() || '');
      });
      reader.readAsDataURL(file);
    }
  };

  /**
   * Recadrage centré au chargement
   */
  const onImageLoad = useCallback((e) => {
    const { width, height } = e.currentTarget;
    const initialCrop = centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 50,
        },
        1, // ratio 1:1 => carré
        width,
        height
      ),
      width,
      height
    );
    setCrop(initialCrop);
  }, []);

  const onCompleteCrop = (c) => {
    setCompletedCrop(c);
  };

  /**
   * Génére le blob à partir du canvas recadré
   */
  const getCroppedImgBlob = async () => {
    return new Promise((resolve, reject) => {
      if (!previewCanvasRef.current) {
        return reject('Canvas non défini.');
      }
      previewCanvasRef.current.toBlob((blob) => {
        if (!blob) {
          return reject('Erreur lors de la génération du blob.');
        }
        resolve(blob);
      }, 'image/jpeg');
    });
  };

  /**
   * Gère l'upload final vers Supabase
   */
  const handleUpload = async () => {
    try {
      const croppedBlob = await getCroppedImgBlob();

      // Compression
      const compressedFile = await imageCompression(croppedBlob, {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 800,
        useWebWorker: true,
      });

      // Upload vers le bucket "avatars"
      const fileExt = 'jpg';
      const fileName = `avatar-${username}-${Date.now()}.${fileExt}`;
      const filePath = `${fileName}`;

      const { error } = await supabase.storage
        .from('avatars')
        .upload(filePath, compressedFile, {
          cacheControl: '3600',
          upsert: false,
        });

      if (error) {
        throw error;
      }

      // Récupérer l'URL publique
      const { data: publicData } = supabase.storage
        .from('avatars')
        .getPublicUrl(filePath);

      const publicUrl = publicData?.publicUrl;
      if (!publicUrl) {
        throw new Error("Impossible d'obtenir l'URL publique du fichier.");
      }

      onUploadSuccess(publicUrl);
    } catch (err) {
      console.error('handleUpload =>', err);
      alert("Erreur lors de l'upload de l'avatar. " + err.message);
    }
  };

  /**
   * On dessine l'image recadrée dans un canvas invisible
   */
  React.useEffect(() => {
    if (!completedCrop || !imgRef.current || !previewCanvasRef.current) {
      return;
    }
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * scaleX * pixelRatio;
    canvas.height = crop.height * scaleY * pixelRatio;

    const ctx = canvas.getContext('2d');
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
  }, [completedCrop]);

  return (
    <div style={{ marginTop: '20px' }}>
      

      {/* (1) Input caché et Label custom pour le bouton */}
      <div style={{ marginBottom: '10px' }}>
        <label 
          htmlFor="file-upload" 
          style={{
            display: 'inline-block',
            padding: '8px 16px',
            backgroundColor: '#4caf50',
            color: '#fff',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          Choisir un fichier
        </label>
        <input
          id="file-upload"
          type="file"
          accept="image/*"
          onChange={onSelectFile}
          style={{ display: 'none' }} // on masque l'input natif
        />
      </div>

      {/* (2) Affichage du nom de fichier (ou "Aucun fichier sélectionné") en dessous du bouton */}
      <p style={{ margin: 0 }}>
        {selectedFileName ? selectedFileName : 'Aucun fichier sélectionné'}
      </p>

      {/* (3) Zone de recadrage si un fichier est sélectionné */}
      {imgSrc && (
        <div style={{ marginTop: '20px' }}>
          <ReactCrop
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={onCompleteCrop}
            aspect={1}
          >
            <img
              ref={imgRef}
              alt="Source"
              src={imgSrc}
              onLoad={onImageLoad}
              style={{ maxWidth: '100%', maxHeight: '300px' }}
            />
          </ReactCrop>
        </div>
      )}

      {/* Canvas invisible pour générer le blob recadré */}
      <canvas ref={previewCanvasRef} style={{ display: 'none' }} />

      {/* (4) Bouton pour finaliser l'upload, visible uniquement si un fichier est sélectionné */}
      {imgSrc && (
        <button onClick={handleUpload} style={{ marginTop: '10px' }}>
          Enregistrer mon avatar
        </button>
      )}
    </div>
  );
}

export default AvatarUploader;