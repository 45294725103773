// Radio.js
import React from 'react';
import styled from 'styled-components';

const Radio = ({ options, selected, onChange }) => {
  return (
    <StyledWrapper>
      <div className="radio-inputs">
        {options.map((option) => (
          <label key={option.key} className="radio">
            <input
              type="radio"
              name="eventStatusRadio"
              checked={selected === option.key}
              onChange={() => onChange(option.key)}
            />
            <span
              className="name"
              style={{
                backgroundColor: selected === option.key ? option.color : '#333',
                color: selected === option.key ? '#fff' : option.color,
              }}
            >
              {option.label}
            </span>
          </label>
        ))}
      </div>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  .radio-inputs {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    border-radius: 0.5rem;
    background-color: #333;
    box-sizing: border-box;
    box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.06);
    padding: 0.25rem;
    width: 300px;
    font-size: 14px;
  }

  .radio-inputs .radio {
    flex: 1 1 auto;
    text-align: center;
  }

  .radio-inputs .radio input {
    display: none;
  }

  .radio-inputs .radio .name {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    border: none;
    padding: 0.5rem 0;
    transition: all 0.15s ease-in-out;
  }

  .radio-inputs .radio input:checked + .name {
    font-weight: 600;
  }
`;

export default Radio;