// src/components/ProtectedRoute.js
import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../utils/AuthContext";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { supabase } from "../utils/supabaseClient";
import { getSetting } from "../utils/settings.js";
import LandingNoAuthPage from "../pages/LandingNoAuthPage";
import "../styles/spinner.css";

function LoadingIndicator() {
  return (
    <div className="spinner-container">
      <div className="spinner"></div>
    </div>
  );
}

/**
 * @param {boolean} loginRequired => Exige-t-on une session Supabase pour y accéder ?
 * @param {boolean} ignoreCodeCheck => Ignore-t-on la vérification de code d'accès (même si authentication_enabled) ?
 */
function ProtectedRoute({
  children,
  loginRequired = true,
  ignoreCodeCheck = false,
  subscriptionRequired = true,
  noAuthPage = null,
  skipWaitingRedirect = false,
  skipEndingRedirect = false,
}) {
  console.log('[ProtectedRoute] RENDER START -->', {
       loginRequired,
       ignoreCodeCheck,
       subscriptionRequired,
       noAuthPage,
       skipWaitingRedirect,
       skipEndingRedirect,
     });

     const contextData = useContext(AuthContext);
     console.log("[ProtectedRoute] => contextData =", contextData);
     const { user, loading: loadingAuth } = contextData;


  const { eventId } = useParams();
  const navigate = useNavigate();

  // -- ÉTAT "authentication_enabled"
  const [authenticationEnabled, setAuthenticationEnabled] = useState(null);
  console.log('[ProtectedRoute] initialState => authenticationEnabled=', authenticationEnabled);

  // -- Indicateur qu’on est en train de vérifier (code d’accès)
  const [isCheckingCode, setIsCheckingCode] = useState(false);

  // -- ÉTAT "allowed" => indique si l’utilisateur peut accéder (abonnement ok)
  const [allowed, setAllowed] = useState(null);
  const [checkingSubscription, setCheckingSubscription] = useState(false);
  const [eventStatus, setEventStatus] = useState(null);

  // --------------------------------------------------
  // 2) RÉCUPÉRER « authentication_enabled » depuis la table evenements
  // --------------------------------------------------
  useEffect(() => {
    console.log('[ProtectedRoute] (2) useEffect => fetchAuthSetting, eventId=', eventId);
    if (!eventId) {
      console.log('[ProtectedRoute] (2) => eventId est vide. On disable "authenticationEnabled".');
      setAuthenticationEnabled(false);
      return;
    }

    async function fetchAuthSetting() {
      try {
        const authEnabled = await getSetting(eventId, "authentication_enabled");
        console.log("[ProtectedRoute] getSetting(authentication_enabled) =>", authEnabled);
        const isAuthEnabled = authEnabled === "true" || authEnabled === true;
        console.log('[ProtectedRoute] => isAuthEnabled =', isAuthEnabled);
        setAuthenticationEnabled(isAuthEnabled);
      } catch (err) {
        console.error("[ProtectedRoute] fetchAuthSetting -> Exception:", err);
        setAuthenticationEnabled(false);
      }
    }
    fetchAuthSetting();
  }, [eventId]);

  // --------------------------------------------------
  // 3) VÉRIFICATION « CODE » si l’authentification est activée
  // --------------------------------------------------
  useEffect(() => {
    console.log('[ProtectedRoute] (3) => Vérification code (localStorage) => authenticationEnabled=', authenticationEnabled);
    if (authenticationEnabled === null) return; // encore inconnu
    console.log('[ProtectedRoute] => Auth enable == null => on attend (return)');
    if (!authenticationEnabled) {
      console.log('[ProtectedRoute] => Auth pas activée => skip code check');
      return;
    }

    if (ignoreCodeCheck) {
      console.log('[ProtectedRoute] => ignoreCodeCheck = true => skip');
      return;
    }

    // Si loginRequired && user => pas besoin de code localStorage
    if (loginRequired && user) {
      console.log('[ProtectedRoute] => loginRequired & user => skip code check');
      return;
    }

    // Sinon => on vérifie localStorage
    setIsCheckingCode(true);

    (async () => {
      console.log('[ProtectedRoute] => Checking localStorage for "authenticated" and "accessCode"...');
      const isAuthenticated = localStorage.getItem("authenticated") === "true";
      const accessCode = localStorage.getItem("accessCode");

      if (!isAuthenticated || !accessCode) {
        console.log("[ProtectedRoute] Pas de code => redirect /access");
        localStorage.removeItem("accessCode");
        localStorage.removeItem("authenticated");
        navigate(`/access/${eventId}`);
        return;
      }

      // Vérif en DB
      const { data, error } = await supabase
        .from("access_codes")
        .select("id_used, type")
        .eq("code", accessCode)
        .eq("event_id", eventId)
        .maybeSingle();

      if (error || !data) {
        console.log("[ProtectedRoute] Code introuvable => redirect");
        localStorage.removeItem("accessCode");
        localStorage.removeItem("authenticated");
        navigate(`/access/${eventId}`);
        return;
      }

      if (data.type === "user" && !data.id_used) {
        console.log("[ProtectedRoute] Code type=user non validé => redirect");
        localStorage.removeItem("accessCode");
        localStorage.removeItem("authenticated");
        navigate(`/access/${eventId}`);
        return;
      }

      console.log('[ProtectedRoute] => Check code: done. isAuthenticated=', isAuthenticated, 'accessCode=', accessCode);
      setIsCheckingCode(false);
    })();
  }, [
    authenticationEnabled,
    user,
    eventId,
    loginRequired,
    ignoreCodeCheck,
    navigate,
  ]);

  // --------------------------------------------------
  // 4) STATUS de l’événement (waiting, ended, etc.)
  // --------------------------------------------------

  useEffect(() => {
    console.log("[ProtectedRoute] (4) fetchEventStatus -> eventId=", eventId);
    if (!eventId) return;
    (async () => {
      const statusVal = await getSetting(eventId, "event_status");
      if (statusVal) {
        console.log(
          "[ProtectedRoute] fetchEventStatus -> statusVal=",
          statusVal
        );
        setEventStatus(statusVal);
      }
    })();
  }, [eventId]);

  useEffect(() => {
    if (!eventId) return;
    const channel = supabase
      .channel(`event_status_realtime_${eventId}`)
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "evenements",
          filter: `id=eq.${eventId}`,
        },
        (payload) => {
          const newVal = payload.new?.event_status;
          if (typeof newVal !== "undefined") {
            setEventStatus(newVal);
            console.log("[ProtectedRoute] Realtime event_status =>", newVal);
          }
        }
      )
      .subscribe();
    return () => {
      supabase.removeChannel(channel);
    };
  }, [eventId]);

  // --------------------------------------------------
  // 5) VÉRIFIER L'ABONNEMENT (si besoin)
  // --------------------------------------------------
  useEffect(() => {
    console.log(
      "[ProtectedRoute] Vérif abo => subscriptionRequired=",
      subscriptionRequired
    );
    if (!subscriptionRequired) {
      setAllowed(true);
      return;
    }

    if (!loginRequired) {
      setAllowed(true);
      return;
    }

    // Tant qu'on charge l'auth => on attend
    if (loadingAuth) return;

    // Si pas de user => on n'a pas encore la suite
    if (!user) return;

    // On va checker la table "subscriptions"
    setCheckingSubscription(true);

    (async () => {
      const { data: sub, error } = await supabase
        .from("subscriptions")
        .select("status")
        .eq("user_id", user.id)
        .maybeSingle();

      if (error) {
        console.error("[ProtectedRoute] subscriptions => error:", error);
        setAllowed(false);
        setCheckingSubscription(false);
        return;
      }

      if (!sub || sub.status !== "active") {
        setAllowed(false);
      } else {
        setAllowed(true);
      }
      setCheckingSubscription(false);
    })();
  }, [subscriptionRequired, loginRequired, loadingAuth, user]);

  // --------------------------------------------------
  // RENDER final selon l’état
  // --------------------------------------------------
  console.log('[ProtectedRoute] => Avt. RENDER => final checks =>', {
       loadingAuth,
       authenticationEnabled,
       isCheckingCode,
       user,
       subscriptionRequired,
       allowed,
       checkingSubscription,
       eventStatus
     });
  if (loadingAuth) {
    console.log("ProtectedRoute => Auth encore loading => on attend, affichage Chargement de la session...");
    return <div>Chargement de la session...</div>;
  }

// 2) Si `authenticationEnabled` est null => on attend => AFFICHER un vrai spinner
//    => Mais seulement si eventId existe vraiment
if (authenticationEnabled === null && eventId) {
  return <LoadingIndicator />;
}

  // 3) Si `authenticationEnabled === false` et on a un `noAuthPage`, on l’affiche si pas déjà visité
  if (!authenticationEnabled && noAuthPage) {
    const hasVisitedNoAuth = localStorage.getItem(
      `hasVisitedNoAuth_${eventId}`
    );
    if (!hasVisitedNoAuth) {
      localStorage.setItem(`hasVisitedNoAuth_${eventId}`, "true");
      return <LandingNoAuthPage />;
    }
  }

  // 4) Si l’auth est activée et qu’on est en train de vérifier le code => loading
  if (authenticationEnabled && isCheckingCode) {
    console.log("[ProtectedRoute] => isCheckingCode => <LoadingIndicator />");
    return <LoadingIndicator />;
  }

  // 5) Si loginRequired et pas de user => go /login
  if (loginRequired && !user) {
    console.log(
      "[ProtectedRoute] => loginRequired & no user => redirect /login"
    );
    return <Navigate to="/login" replace />;
  }

  // 6) Si on est encore en train de checker l’abo => loading
  if (checkingSubscription) {
    console.log(
      "[ProtectedRoute] => checkingSubscription => <LoadingIndicator />"
    );
    return <LoadingIndicator />;
  }

  // 7) Si allowed === false => redirect /subscription
  if (allowed === false) {
    console.log("[ProtectedRoute] => !allowed => /subscription");
    return <Navigate to="/subscription" replace />;
  }

  // 8) Vérifier l’eventStatus
  if (eventStatus === "ended" && !skipEndingRedirect) {
    console.log("[ProtectedRoute] => event ended => /end-of-event");
    return <Navigate to={`/end-of-event/${eventId}`} replace />;
  }
  if (eventStatus === "waiting" && !skipWaitingRedirect) {
    console.log("[ProtectedRoute] => event waiting => /waiting");
    return <Navigate to={`/waiting/${eventId}`} replace />;
  }

  // 9) Sinon => tout OK
  console.log("[ProtectedRoute] => Tout OK => render children");
  return children;
}

export default ProtectedRoute;
