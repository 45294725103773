// src/components/FixedEventButton.js
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { supabase } from '../utils/supabaseClient';
import '../styles/ComponentsStyles/FixedEventButton.css'; // Assurez-vous que les styles du bouton sont importés

function FixedEventButton() {
  const [session, setSession] = useState(null);

  useEffect(() => {
    const fetchSession = async () => {
      const { data } = await supabase.auth.getSession();
      setSession(data.session);
    };
    fetchSession();
  }, []);

  return (
    <>
      {session && (
        <NavLink to="/events-manager" className="fixed-event-button">
          Accéder à mon espace évènements
        </NavLink>
      )}
    </>
  );
}

export default FixedEventButton;