// App.js
import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import SearchPage from "./pages/SearchPage";
import Dashboard from "./pages/Dashboard"; // Import de la page Dashboard
import DJView from "./pages/DJView";
import CodeManager from "./pages/CodeManager";
import ProtectedRoute from "./components/ProtectedRoute";
import LandingNoAuthPage from "./pages/LandingNoAuthPage";
import EvenementsManager from "./pages/EvenementsManager";
import { v4 as uuidv4 } from "uuid"; // Import de uuid pour générer des userId
import HomePage from "./pages/HomePage";
import SignupPage from "./pages/SignupPage"; // Importer la page Signup
import LoginPage from "./pages/LoginPage"; // Importer la page Login
import SubscriptionPage from "./pages/SubscriptionPage";
import SubscriptionSuccessPage from "./pages/SubscriptionSuccessPage";
import SubscriptionCancelPage from "./pages/SubscriptionCancelPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ProfilePage from "./pages/ProfilePage";
import CompleteProfilePage from "./pages/CompleteProfilePage";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TermsOfServicePage from "./pages/TermsOfServicePage";
import MentionsLegales from "./pages/MentionsLegales";
import PolitiqueCookies from "./pages/PolitiquesCookies";
import GoodbyePage from "./pages/GoodbyePage";
import SendMessagePage from "./pages/SendMessagePage";
import DisplayMessagesPage from "./pages/DisplayMessagesPage";
import PaymentSuccessMessagePage from "./pages/PaymentSuccessMessagePage";
import { Analytics } from "@vercel/analytics/react";
import EndOfEventPage from "./pages/EndOfEventPage";
import ChartsPage from "./pages/ChartsPage";
import AboutPage from "./pages/AboutPage";
import FeaturesPage from "./pages/FeaturesPage";
import ConfirmationPage from "./pages/ConfirmationPage";
import WaitingPage from "./pages/WaitingPage";
import SpotifyApi from "./pages/SpotifyApi";
import UpdatesPage from "./pages/UpdatesPage";
import SpotifyDashboard from "./pages/SpotifyDashboard";

function App() {
  console.log("[App] -> Rendering <App /> at all?");
  useEffect(() => {
    // Vérifie si un userId existe déjà, sinon en génère un
    let userId = localStorage.getItem("userId");
    if (!userId) {
      userId = uuidv4();
      console.log("[App] => userId non trouvé => on génère", userId);
      localStorage.setItem("userId", userId);
    } else {
      console.log("[App] => userId déjà existant:", userId);
    }
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/confirmation" element={<ConfirmationPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/terms-of-service" element={<TermsOfServicePage />} />
          <Route path="/mentions-legales" element={<MentionsLegales />} />
          <Route path="/politique-cookies" element={<PolitiqueCookies />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/goodbye" element={<GoodbyePage />} />
          <Route path="/waiting/:eventId" element={<WaitingPage />} />
          <Route path="/send-message/:eventId" element={<SendMessagePage />} />
          <Route path="/display/:eventId" element={<DisplayMessagesPage />} />
          <Route
            path="/payment-success"
            element={<PaymentSuccessMessagePage />}
          />
          <Route path="/end-of-event/:eventId" element={<EndOfEventPage />} />
          <Route path="/charts" element={<ChartsPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/features" element={<FeaturesPage />} />
          <Route path="/spotify-api" element={<SpotifyApi />} />
          <Route path="/updates" element={<UpdatesPage />} />

          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/change-password" element={<ChangePasswordPage />} />
          <Route path="/access/:eventId" element={<LandingPage />} />
          <Route path="/noauth/:eventId" element={<LandingNoAuthPage />} />

          <Route
            path="/search/:eventId"
            element={
              <ProtectedRoute
                loginRequired={false}
                ignoreCodeCheck={false}
                subscriptionRequired={false}
                noAuthPage={<LandingNoAuthPage />}
              >
                <SearchPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/dashboard/:eventId"
            element={
              <ProtectedRoute
                loginRequired={true}
                ignoreCodeCheck={true}
                subscriptionRequired={true}
                skipWaitingRedirect={true}
                skipEndingRedirect={true}
              >
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/spotify-dashboard/:eventId"
            element={
              <ProtectedRoute
                loginRequired={true}
                ignoreCodeCheck={true}
                subscriptionRequired={true}
                skipWaitingRedirect={true}
                skipEndingRedirect={true}
              >
                <SpotifyDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dj-view/:eventId"
            element={
              <ProtectedRoute
                loginRequired={true}
                ignoreCodeCheck={true}
                subscriptionRequired={true}
                skipWaitingRedirect={true}
                skipEndingRedirect={true}
              >
                <DJView />
              </ProtectedRoute>
            }
          />
          <Route
            path="/code-manager/:eventId"
            element={
              <ProtectedRoute
                loginRequired={true}
                ignoreCodeCheck={true}
                subscriptionRequired={true}
                skipWaitingRedirect={true}
                skipEndingRedirect={true}
              >
                <CodeManager />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute
                loginRequired={true}
                ignoreCodeCheck={true}
                subscriptionRequired={false}
                skipWaitingRedirect={true}
                skipEndingRedirect={true}
              >
                <ProfilePage />
              </ProtectedRoute>
            }
          />
          <Route path="/subscription" element={<SubscriptionPage />} />
          <Route
            path="/subscription-success"
            element={<SubscriptionSuccessPage />}
          />
          <Route
            path="/subscription-cancel"
            element={<SubscriptionCancelPage />}
          />
          <Route
            path="/complete-profile"
            element={
              <ProtectedRoute
                loginRequired={true}
                ignoreCodeCheck={true}
                subscriptionRequired={false}
              >
                <CompleteProfilePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/events-manager"
            element={
              <ProtectedRoute
                ignoreCodeCheck={true}
                requiredRole={["admin", "dj", "establishment"]}
                loginRequired={true}
                subscriptionRequired={true}
                skipWaitingRedirect={true}
                skipEndingRedirect={true}
              >
                <EvenementsManager />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
      <Analytics />
    </>
  );
}

export default App;
