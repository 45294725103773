// Mixify/pages/SubscriptionPages.js

import React, { useEffect, useState } from 'react'
import { supabase } from '../utils/supabaseClient'
import Layout from '../components/Layout';
import '../styles/SubscriptionPage.css'
import SubscriptionBanner from '../components/SubscriptionBanner';
import FixedEventButton from '../components/FixedEventButton';

function SubscriptionPage() {
  const [userId, setUserId] = useState(null)

  // NOUVEAU : on stocke la subscription (ex: { status: 'active', ... } )
  const [subscription, setSubscription] = useState(null)
  const [loadingSub, setLoadingSub] = useState(false)
  const [errorSub, setErrorSub] = useState('')

  useEffect(() => {
    // Récupérer l'utilisateur actuel (s'il est connecté)
    supabase.auth.getSession().then(({ data, error }) => {
      if (data?.session?.user) {
        setUserId(data.session.user.id)
      }
    })
  }, [])

  // Quand userId est défini, on va chercher l’abonnement
  useEffect(() => {
    if (!userId) return // si pas loggé, on ne fait rien
    const fetchSubscription = async () => {
      setLoadingSub(true)
      setErrorSub('')
      const { data: sub, error } = await supabase
        .from('subscriptions')
        .select('*') // si vous avez d’autres champs
        .eq('user_id', userId)
        .maybeSingle()

      if (error) {
        setErrorSub(error.message)
      } else {
        setSubscription(sub) // sub = { status: 'active' } ou null
      }
      setLoadingSub(false)
    }
    fetchSubscription()
  }, [userId])

  const handleSubscribe = async (priceId) => {
    // (1) Si l’utilisateur n’est pas connecté => redirection vers login
    if (!userId) {
      alert('Vous devez être connecté pour souscrire à un abonnement.');
      // Redirection vers /login
      window.location.href = '/login';
      return;
    }
  
    // (2) Sinon, on continue le process habituel
    try {
      const response = await fetch('/api/create-checkout-session', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ priceId, userId }),
      });
      const data = await response.json()

      if (data.url) {
        // Rediriger l'utilisateur vers Stripe Checkout
        window.location.href = data.url
      } else {
        alert('Erreur: impossible de créer la session de paiement.')
      }
    } catch (err) {
      console.error(err)
      alert('Une erreur est survenue. Veuillez réessayer plus tard.')
    }
  }

  // NOUVEAU : Fonction pour démarrer l'essai gratuit (ici, on utilise le plan DJ par défaut)
  const handleFreeTrial = async () => {
    if (!userId) {
      alert('Vous devez être connecté pour démarrer l\'essai gratuit.');
      window.location.href = '/login';
      return;
    }
    try {
      const response = await fetch('/api/create-checkout-session', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        // On envoie le priceId du plan DJ ; côté backend, on doit s'assurer d'ajouter trial_period_days: 7
        body: JSON.stringify({ priceId: 'price_1QjSWiGRn8HctkO3lvR7RuyX', userId }),
      });
      const data = await response.json();

      if (data.url) {
        window.location.href = data.url;
      } else {
        alert('Erreur: impossible de démarrer l\'essai gratuit.');
      }
    } catch (err) {
      console.error(err);
      alert('Une erreur est survenue. Veuillez réessayer plus tard.');
    }
  };

  return (
    <div>
      <Layout>
        {/* (2) Le contenu de la page conditionnel */}
        <div className="subscription-container">
          {/* On affiche toujours les tarifs */}
          <h2 className="subscription-title">Choisissez votre abonnement</h2>

          {/* Si on est en train de charger l'abo */}
          {loadingSub && (
            <div>Chargement de l'abonnement...</div>
          )}

          {/* S’il y a une erreur (lecture subscription) */}
          {errorSub && (
            <div>Erreur de lecture abonnement: {errorSub}</div>
          )}

          {subscription && subscription.status === 'trialing' && (
            <div className="trial-notice">
              Vous êtes actuellement en période d’essai gratuit ! 
              {/* Par exemple, "Profitez-en jusqu’au 12/03/2025" */}
            </div>
          )}

          <div className="subscription-plans">
            {/* Plan DJ */}
            <div className="subscription-plan">
              <h3>DJ</h3>
              <p className="plan-description">Idéal pour les DJ indépendants</p>
              <div className="price">10€ / mois</div>

              {/* Liste des fonctionnalités */}
              <ul className="plan-features slide-in">
                <li><span className="feature-icon">✔ </span>Accès complet à Mixify Charts</li>
                <li><span className="feature-icon">✔ </span>3 évènements max</li>
                <li><span className="feature-icon">✔ </span>Personnalisation des motifs de refus</li>
                <li><span className="feature-icon">✖ </span>Gestion de l'ordre des morceaux dans les files d'attente/playlists (drag&drop)</li>
                <li><span className="feature-icon">✖ </span>Personnalisation de la page de "recherche"</li>
              </ul>

              <button
                className="btn-subscribe"
                onClick={() => handleSubscribe('price_1QjSWiGRn8HctkO3lvR7RuyX')}
                disabled={
                  subscription &&
                  subscription.status === 'active' &&
                  subscription.price_id === 'price_1QjSWiGRn8HctkO3lvR7RuyX'
                }
              >
                {
                  subscription && subscription.status === 'active'
                    ? (
                      subscription.price_id === 'price_1QjSWiGRn8HctkO3lvR7RuyX'
                        ? 'Déjà abonné'
                        : 'Mettre à niveau'
                    )
                    : "S'abonner"
                }
              </button>
            </div>

            {/* Plan DJ+ */}
            <div className="subscription-plan">
              <div className="best-value-ribbon">RECOMMANDÉ</div>
              <h3>DJ+</h3>
              <p className="plan-description">L'abonnement renforcé pour DJ professionnels</p>
              <div className="price">15€ / mois</div>
              <ul className="plan-features slide-in">
                <li><span className="feature-icon">✔ </span>Accès complet à Mixify Charts</li>
                <li><span className="feature-icon">✔</span>Nombre d'évènements illimités</li>
                <li><span className="feature-icon">✔</span>Personnalisation des motifs de refus</li>
                <li><span className="feature-icon">✔</span>Gestion de l'ordre des morceaux dans les files d'attente/playlists (drag&drop)</li>
                <li><span className="feature-icon">✔</span>Personnalisation de la page de "recherche"</li>
                <li><span className="feature-icon">✔</span>Création de QR Codes personnalisés</li>
                <li><span className="feature-icon">✔ </span> Priorité de support technique</li>
              </ul>

              <button
                className="btn-subscribe"
                onClick={() => handleSubscribe('price_1QhUsjGRn8HctkO3hg5iacZX')}
                disabled={
                  subscription &&
                  subscription.status === 'active' &&
                  subscription.price_id === 'price_1QhUsjGRn8HctkO3hg5iacZX'
                }
              >
                {
                  subscription && subscription.status === 'active'
                    ? (
                      subscription.price_id === 'price_1QhUsjGRn8HctkO3hg5iacZX'
                        ? 'Déjà abonné'
                        : 'Mettre à niveau'
                    )
                    : "S'abonner"
                }
              </button>
            </div>

            {/* Plan Établissement */}
            <div className="subscription-plan">
              <h3>Établissement</h3>
              <p className="plan-description">Parfait pour les bars / clubs</p>
              <div className="price">40€ / mois</div>

              <ul className="plan-features slide-in">
                <li><span className="feature-icon">✔ </span>Tout le contenu du plan DJ+</li>
                <li><span className="feature-icon">✔ </span>Analyse avancée & Statistiques</li>
                <li><span className="feature-icon">✔ </span>Priorité de support technique</li>
                <li><span className="feature-icon">✔ </span>Personnalisation de l'interface</li>
                <li><span className="feature-icon">✔ </span>Diffusion de contenu personnalisable (pubs, etc)</li>
              </ul>

              <button
                className="btn-subscribe"
                onClick={() => handleSubscribe('price_1QhUu3GRn8HctkO3RQzRx01K')}
                disabled={
                  subscription &&
                  subscription.status === 'active' &&
                  subscription.price_id === 'price_1QhUu3GRn8HctkO3RQzRx01K'
                }
              >
                {
                  subscription && subscription.status === 'active'
                    ? (
                      subscription.price_id === 'price_1QhUu3GRn8HctkO3RQzRx01K'
                        ? 'Déjà abonné'
                        : 'Mettre à niveau'
                    )
                    : "S'abonner"
                }
              </button>
            </div>


          {/* NOUVEAU : Bouton pour démarrer l'essai gratuit */}
          { !subscription && (
            <div className="trial-button-container">
              <button className="btn-trial" onClick={handleFreeTrial}>
                Démarrer votre essai gratuit de 7 jours
              </button>
            </div>
          )}

            {/* Comparatif des fonctionnalités */}
            <div className="comparison-container">
              <h3 className="comparison-title">Comparatif des fonctionnalités</h3>

              <table className="comparison-table">
                <thead>
                  <tr>
                    <th>Fonctionnalités</th>
                    <th>DJ</th>
                    <th>DJ+</th>
                    <th>Établissement</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-label="Fonctionnalités">Accès complet à Mixify Charts</td>
                    <td data-label="DJ">✔</td>
                    <td data-label="DJ+">✔</td>
                    <td data-label="Établissement">✔</td>
                  </tr>
                  <tr>
                    <td data-label="Fonctionnalités">Création d'évènements</td>
                    <td data-label="DJ">3 max</td>
                    <td data-label="DJ+">illimités</td>
                    <td data-label="Établissement">illimités</td>
                  </tr>
                  <tr>
                    <td data-label="Fonctionnalités">Personnalisation des motifs de refus</td>
                    <td data-label="DJ">✔</td>
                    <td data-label="DJ+">✔</td>
                    <td data-label="Établissement">✔</td>
                  </tr>
                  <tr>
                    <td data-label="Fonctionnalités">Création de QR Codes personnalisés</td>
                    <td data-label="DJ">✖</td>
                    <td data-label="DJ+">✔</td>
                    <td data-label="Établissement">✔</td>
                  </tr>
                  <tr>
                    <td data-label="Fonctionnalités">Gestion de l'ordre des morceaux (Drag&Drop)</td>
                    <td data-label="DJ">✖</td>
                    <td data-label="DJ+">✔</td>
                    <td data-label="Établissement">✔</td>
                  </tr>
                  <tr>
                    <td data-label="Fonctionnalités">Personnalisation de l'interface</td>
                    <td data-label="DJ">✖</td>
                    <td data-label="DJ+">✔</td>
                    <td data-label="Établissement">✔</td>
                  </tr>
                  <tr>
                    <td data-label="Fonctionnalités">Analyse avancée</td>
                    <td data-label="DJ">✖</td>
                    <td data-label="DJ+">✔</td>
                    <td data-label="Établissement">✔ (complète)</td>
                  </tr>
                  <tr>
                    <td data-label="Fonctionnalités">Support prioritaire</td>
                    <td data-label="DJ">✖</td>
                    <td data-label="DJ+">✔</td>
                    <td data-label="Établissement">✔</td>
                  </tr>
                  <tr>
                    <td data-label="Fonctionnalités">Tarif</td>
                    <td data-label="DJ">10€ / mois</td>
                    <td data-label="DJ+">15€ / mois</td>
                    <td data-label="Établissement">40€ / mois</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>


          <SubscriptionBanner />
        </div>
        <FixedEventButton />
      </Layout>
    </div>
  );
}

export default SubscriptionPage;