import React, { useState, useEffect } from 'react';
import { supabase } from '../utils/supabaseClient';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import UserMenu from '../components/UserMenu';

function CodeManager() {
  const { eventId } = useParams();
  const [codes, setCodes] = useState([]);
  const newCodeType = 'user';
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(null); // 'user' ou 'admin'
  const [numCodes, setNumCodes] = useState(1); // Par défaut, 1 code à générer
  

  // Charger les codes existants
  useEffect(() => {
    const fetchCodes = async () => {
      const { data, error } = await supabase
        .from('access_codes')
        .select('*')
        .eq('event_id', eventId)
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Erreur lors du chargement des codes:', error);
      } else {
        setCodes(data || []);
      }
    };

    fetchCodes();

    const subscription = supabase
      .channel('access_codes_changes')
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'access_codes', filter: `event_id=eq.${eventId}` },
        
        (payload) => {
          if (payload.eventType === 'INSERT') {
            setCodes((prev) => [payload.new, ...prev]);
          } else if (payload.eventType === 'UPDATE') {
            setCodes((prev) =>
              prev.map((code) => (code.id === payload.new.id ? payload.new : code))
            );
          }
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(subscription);
    };
  }, [eventId]);

  // Générer un nouveau code
  const handleGenerateCode = async () => {
    try {
      const existingCodes = codes.map((code) => code.code);
      const newCodes = [];
  
      for (let i = 0; i < numCodes; i++) {
        let uniqueCode;
  
        // Générer un code unique
        do {
          uniqueCode = Math.random().toString(36).substring(2, 10).toUpperCase();
        } while (existingCodes.includes(uniqueCode));
  
        newCodes.push({
          code: uniqueCode,
          type: newCodeType,
          id_used: false,
          event_id: eventId,
        });
      }
  
      const { error } = await supabase.from('access_codes').insert(newCodes);
  
      if (error) throw new Error('Erreur lors de la génération des codes.');
  
      setSuccessMessage(`${numCodes} code(s) généré(s) avec succès !`);
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (err) {
      console.error(err.message);
      setErrorMessage('Une erreur est survenue. Veuillez réessayer.');
    }
  };

  const handleDeleteCode = async (codeId) => {
    const { error } = await supabase
    .from('access_codes')
    .delete()
    .eq('event_id', eventId) 
    .eq('id', codeId);
  
    if (error) {
      console.error('Erreur lors de la suppression du code:', error);
    } else {
      // Mettre à jour la liste des codes localement
      setCodes((prev) => prev.filter((code) => code.id !== codeId));
      setSuccessMessage('Code supprimé avec succès.');
      setTimeout(() => setSuccessMessage(''), 3000); // Efface le message après 3 secondes
    }
  };

  // Réinitialiser les codes utilisateurs ou administrateurs
  const handleResetCodes = async () => {
    const { error } = await supabase
      .from('access_codes')
      .delete()
      .eq('event_id', eventId)
      .eq('type', 'user')
      .neq('code', 'SUPERUSER'); // Exclut le SUPERADMIN

       if (!error) {
        // Pas d'erreur => on retire localement les codes user 
        setCodes((prev) =>
          prev.filter(
            (code) =>
              code.event_id !== eventId ||
              code.type !== 'user' ||
              code.code === 'SUPERUSER'
          )
        );
        setSuccessMessage('Les codes utilisateurs ont été réinitialisés.');
        setTimeout(() => setSuccessMessage(''), 3000);
        setShowConfirmation(null); // Ferme la confirmation
     } else {
       // En cas d'erreur, on affiche un message ou on met un errorMessage
       setErrorMessage("Impossible de réinitialiser les codes.");
       setTimeout(() => setErrorMessage(''), 3000);
    }
  };

  const userCodes = codes.filter((code) => code.type === 'user');

  return (
    <div
      style={{
        padding: '20px',
        backgroundColor: '#1e1e2c',
        color: '#fff',
        minHeight: '100vh',
        fontFamily: 'Poppins, sans-serif',
        position: 'relative',
      }}
    >
       <Helmet>
              <title>Mixify - Code Manager</title>
              <meta name="description" content="Gérez les codes d'accès à votre application." />
            </Helmet>
            <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
              <UserMenu />
            </div>
      <h1 style={{ textAlign: 'center', marginBottom: '20px', fontSize: '2rem' }}>Gestion des Codes</h1>

      {/* Message de succès ou d'erreur */}
      {successMessage && (
        <div
          style={{
            backgroundColor: '#1E5128',
            padding: '10px',
            marginBottom: '20px',
            borderRadius: '5px',
            textAlign: 'center',
          }}
        >
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div
          style={{
            backgroundColor: '#B71C1C',
            padding: '10px',
            marginBottom: '20px',
            borderRadius: '5px',
            textAlign: 'center',
          }}
        >
          {errorMessage}
        </div>
      )}

<div style={{ marginBottom: '30px', textAlign: 'center' }}>
  <label style={{ marginRight: '10px', fontSize: '1rem' }}>Nombre de codes :</label>
  <input
    type="number"
    min="1"
    max="10"
    value={numCodes}
    onChange={(e) => setNumCodes(Math.min(Math.max(parseInt(e.target.value, 10), 1), 10))}
    style={{
      width: '60px',
      padding: '8px',
      fontSize: '1rem',
      marginRight: '10px',
      borderRadius: '5px',
      border: '1px solid #444',
      backgroundColor: '#333',
      color: '#fff',
      textAlign: 'center',
    }}
  />

  <button
    onClick={handleGenerateCode}
    style={{
      padding: '8px 15px',
      fontSize: '1rem',
      backgroundColor: '#1E88E5',
      color: '#fff',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
    }}
    onMouseOver={(e) => (e.target.style.backgroundColor = '#1565C0')}
    onMouseOut={(e) => (e.target.style.backgroundColor = '#1E88E5')}
  >
    Générer le(s) Code(s)
  </button>
</div>

      {/* Boutons de réinitialisation */}
      <div style={{ textAlign: 'center', marginBottom: '30px' }}>
        <button
          onClick={() => setShowConfirmation('user')}
          style={{
            padding: '8px 15px',
            fontSize: '1rem',
            backgroundColor: '#dc3545',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            marginRight: '10px',
          }}
        >
          Réinitialiser les Codes Utilisateurs
        </button>
      </div>

      {/* Confirmation */}
      {showConfirmation && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#1e1e2c',
            padding: '20px',
            borderRadius: '10px',
            zIndex: 1000,
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          }}
        >
          <h3 style={{ textAlign: 'center', marginBottom: '20px', color: '#fff' }}>
            Êtes-vous sûr de vouloir réinitialiser les codes{' '}
          </h3>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
            <button
              onClick={() => handleResetCodes()}
              style={{
                padding: '10px 20px',
                backgroundColor: '#28a745',
                color: '#fff',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              Oui
            </button>
            <button
              onClick={() => setShowConfirmation(null)}
              style={{
                padding: '10px 20px',
                backgroundColor: '#6c757d',
                color: '#fff',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              Non
            </button>
          </div>
        </div>
      )}

      {/* Tableau des utilisateurs */}
      <div>
        <h2 style={{ textAlign: 'center', marginBottom: '10px' }}>Codes Utilisateurs</h2>
        <table
          style={{
            width: '100%',
            borderCollapse: 'collapse',
            textAlign: 'left',
          }}
        >
          <thead>
            <tr style={{ backgroundColor: '#333', color: '#fff' }}>
              <th style={{ padding: '10px', borderBottom: '1px solid #444' }}>Créé le</th>
              <th style={{ padding: '10px', borderBottom: '1px solid #444' }}>Code</th>
              <th style={{ padding: '10px', borderBottom: '1px solid #444' }}>Utilisé</th>
              <th style={{ padding: '10px', borderBottom: '1px solid #444' }}>Supprimer</th>
            </tr>
          </thead>
          <tbody>
              {userCodes.map((code) => (
    <tr
      key={code.id}
      style={{
        backgroundColor: code.id_used ? '#1E5128' : 'transparent',
        color: '#fff',
        transition: 'background-color 0.3s',
      }}
    >
      <td>{new Date(code.created_at).toLocaleString()}</td>
      <td>{code.code}</td>
      <td>{code.id_used ? 'Oui' : 'Non'}</td>
      <td
  style={{
    textAlign: 'center',
    verticalAlign: 'middle',
    padding: '10px', // Ajustez si nécessaire
  }}
>
  <button
    onClick={() => handleDeleteCode(code.id)}
    style={{
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '1rem', // Taille de l'icône ajustable
    }}
  >
    🗑️
  </button>
</td>
    </tr>
  ))}
</tbody>
        </table>
      </div>
    </div>
  );
}

export default CodeManager;