// src/components/PasswordCriteria.js
import React from 'react';
import PropTypes from 'prop-types';
import '../styles/ComponentsStyles/PasswordCriteria.css'; // Assurez-vous que ce fichier existe et contient les styles nécessaires

function PasswordCriteria({ criteria }) {
  return (
    <div className="password-criteria">
      <p>Votre mot de passe doit contenir au moins :</p>
      <ul>
        <li className={criteria.length ? 'met' : 'unmet'}>8 caractères</li>
        <li className={criteria.uppercase ? 'met' : 'unmet'}>Une lettre majuscule</li>
        <li className={criteria.lowercase ? 'met' : 'unmet'}>Une lettre minuscule</li>
        <li className={criteria.number ? 'met' : 'unmet'}>Un chiffre</li>
        <li className={criteria.specialChar ? 'met' : 'unmet'}>Un caractère spécial</li>
      </ul>
    </div>
  );
}

PasswordCriteria.propTypes = {
  criteria: PropTypes.shape({
    length: PropTypes.bool.isRequired,
    uppercase: PropTypes.bool.isRequired,
    lowercase: PropTypes.bool.isRequired,
    number: PropTypes.bool.isRequired,
    specialChar: PropTypes.bool.isRequired,
  }).isRequired,
};

export default PasswordCriteria;