import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/TermsOfServicePage.css'; // Assurez-vous de créer ce fichier CSS pour le style
import Layout from '../components/Layout';

function CGV() {
  return (
    <Layout>
    <div className="cgv-container">
      <Helmet>
        <title>Mixify - Conditions Générales de Vente</title>
        <meta name="description" content="Conditions Générales de Vente de Mixify." />
      </Helmet>

      <h1>Conditions Générales de Vente (CGV)</h1>

      <section>
        <h2>1. Objet</h2>
        <p>
          Les présentes Conditions Générales de Vente (CGV) régissent les relations contractuelles entre Mixify (ci-après dénommé "le Prestataire") et toute personne physique ou morale souhaitant effectuer un achat via le site internet Mixify (ci-après dénommé "le Client").
        </p>
      </section>

      <section>
        <h2>2. Produits et Services</h2>
        <p>
          Mixify propose divers abonnements permettant aux utilisateurs de proposer et gérer des morceaux lors d'événements musicaux. Les détails des offres sont disponibles sur la page dédiée aux abonnements.
        </p>
      </section>

      <section>
        <h2>3. Prix</h2>
        <p>
          Les prix des abonnements sont indiqués en euros (€) toutes taxes comprises (TTC). Mixify se réserve le droit de modifier ses prix à tout moment, mais les abonnements seront facturés sur la base des tarifs en vigueur au moment de la validation de la commande.
        </p>
      </section>

      <section>
        <h2>4. Commande</h2>
        <p>
          La commande est effectuée en ligne via le site Mixify. Le Client doit créer un compte et fournir les informations nécessaires pour finaliser sa commande.
        </p>
      </section>

      <section>
        <h2>5. Paiement</h2>
        <p>
          Le paiement des abonnements s'effectue en ligne via des moyens de paiement sécurisés proposés par Mixify. Le Client garantit qu'il dispose des autorisations nécessaires pour utiliser le mode de paiement choisi.
        </p>
      </section>

      <section>
        <h2>6. Droit de Rétractation</h2>
        <p>
          Conformément à la législation en vigueur, le Client dispose d'un délai de 14 jours à compter de la date de souscription pour exercer son droit de rétractation sans avoir à justifier de motifs ni à payer de pénalités.
        </p>
      </section>

      <section>
        <h2>7. Résiliation</h2>
        <p>
          Le Client peut résilier son abonnement à tout moment via son espace personnel. La résiliation prendra effet à la fin de la période d'abonnement en cours.
        </p>
      </section>

      <section>
        <h2>8. Responsabilité</h2>
        <p>
          Mixify ne pourra être tenu responsable des dommages indirects résultant de l'utilisation du site ou des services proposés. La responsabilité de Mixify se limite au montant de la commande passée par le Client.
        </p>
      </section>

      <section>
        <h2>9. Propriété Intellectuelle</h2>
        <p>
          Tous les éléments du site Mixify sont et restent la propriété intellectuelle exclusive de Mixify. Toute reproduction totale ou partielle est strictement interdite sans autorisation préalable.
        </p>
      </section>

      <section>
        <h2>10. Données Personnelles</h2>
        <p>
          Les informations recueillies par Mixify font l'objet d'un traitement informatique destiné à la gestion des abonnements et des services proposés. Conformément au RGPD, le Client dispose d'un droit d'accès, de rectification et de suppression des données le concernant.
        </p>
      </section>

      <section>
        <h2>11. Loi Applicable et Juridiction</h2>
        <p>
          Les présentes CGV sont régies par la loi française. En cas de litige, les tribunaux compétents seront ceux du ressort du siège social de Mixify.
        </p>
      </section>

      <section>
        <h2>12. Contact</h2>
        <p>
          Pour toute question relative aux présentes CGV, veuillez nous contacter à l'adresse suivante : contact@mixify.fr.
        </p>
      </section>
    </div>
    </ Layout>
  );
}

export default CGV;