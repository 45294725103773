// src/components/Checkbox.js
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Checkbox = ({ id, checked, onChange, label }) => {
  return (
    <StyledWrapper>
      <label className="container" htmlFor={id}>
        <input
          className="checkbox"
          type="checkbox"
          id={id}
          checked={checked}
          onChange={onChange}
        />
        <span className="checkbox-label">
          <div className="checkbox-container">
            <div className="box" />
            <div className="checkmark">
              <div className="tick-container">
                <svg
                  className="tickmark"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 52 52"
                >
                  <path
                    className="tickmark-check"
                    fill="none"
                    d="M14 27l8 8 16-16"
                  />
                </svg>
              </div>
            </div>
          </div>
          {label && <span className="checkbox-text">{label}</span>}
        </span>
      </label>
    </StyledWrapper>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.node, // Changement de string à node
};

Checkbox.defaultProps = {
  label: '',
};

const StyledWrapper = styled.div`
  width: 100%; /* Assure que le wrapper occupe toute la largeur */
  
  .container {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    width: 100%; /* Occupation complète de la largeur */
    --checkbox-size: 25px;
    --checkbox-width: 3px;
    --checkbox-border-radius: 4px;
    --tickmark-width: 5px;
    --checkmark-box-color: #1d8beb;
    --checkmark-color: #fff;
    /* Supprimer margin-right pour utiliser flex */
    /* margin-right: 8px; */
  }
  
  .checkbox {
    display: none;
  }

  .checkbox-label {
    display: flex;
    align-items: center;
    width: 100%; /* Occupation complète de la largeur */
  }

  .checkbox-container {
    flex-shrink: 0; /* Empêche la réduction de la checkbox */
    width: var(--checkbox-size);
    height: var(--checkbox-size);
    position: relative;
    aspect-ratio: 1;
    margin-right: 10px; /* Espace entre la checkbox et le texte */
  }

  .checkbox-container .box {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: var(--checkbox-width) solid rgba(255, 255, 255, 1);
    border-radius: var(--checkbox-border-radius);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }

  .checkbox-container:hover .box {
    border-color: var(--checkmark-box-color);
    box-shadow: 0 0 5px var(--checkmark-box-color);
  }

  .checkbox-container .checkmark::before,
  .checkbox-container .checkmark::after {
    content: "";
    background: var(--checkmark-box-color);
    width: 0%;
    height: 0%;
    position: absolute;
    top: 50%;
    transition: all 0.3s ease;
    z-index: -1;
  }

  .checkbox-container .checkmark::before {
    left: 0;
    border-top-left-radius: var(--checkbox-border-radius);
    border-bottom-left-radius: var(--checkbox-border-radius);
  }

  .checkbox-container .checkmark::after {
    right: 0;
    border-top-right-radius: var(--checkbox-border-radius);
    border-bottom-right-radius: var(--checkbox-border-radius);
  }

  .checkbox-container .checkmark {
    z-index: 2;
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .checkbox:checked + .checkbox-label .checkmark::before,
  .checkbox:checked + .checkbox-label .checkmark::after {
    width: 50%;
    height: 100%;
    top: 0;
  }

  .tick-container {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .checkbox:checked + .checkbox-label .tickmark {
    position: absolute;
    top: 0;
    width: 100%;
    stroke: var(--checkmark-color);
    stroke-width: var(--tickmark-width);
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .checkbox:checked + .checkbox-label .tickmark-check {
    stroke-dasharray: 36; /* Total length of the tick path */
    stroke-dashoffset: 36; /* Initially hidden */
    animation: draw-check 0.6s ease-out forwards;
  }

  @keyframes draw-check {
    to {
      stroke-dashoffset: 0;
    }
  }

  .checkbox-text {
    font-size: 14px;
    color: #fff;
    /* margin-left: 10px; Déplacé vers le container */
    flex-grow: 1; /* Permet au texte de prendre tout l'espace restant */
  }
`;

export default Checkbox;