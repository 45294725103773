// /Users/launchmachine/PROGRAMMATION/Projets/Mixify/src/pages/WaitingPage.js
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { getSetting } from "../utils/settings";
import { supabase } from "../utils/supabaseClient";
import "../styles/WaitingPage.css";
import Logo from "../components/Logo";
import { Music, Users } from "lucide-react";

function WaitingPage() {
  const { eventId } = useParams();
  const navigate = useNavigate();

  const [waitingMessage, setWaitingMessage] = useState("");
  const [eventStatus, setEventStatus] = useState("waiting");
  const [loading, setLoading] = useState(true);
  const [eventStartTime, setEventStartTime] = useState(null);
  const [autoStartEnabled, setAutoStartEnabled] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [musicStyle, setMusicStyle] = useState("Global Music");

  useEffect(() => {
    const fetchWaitingData = async () => {
      try {
        const msg = await getSetting(eventId, "waiting_message");
        if (msg) {
          setWaitingMessage(msg);
        }
        const statusVal = await getSetting(eventId, "event_status");
        setEventStatus(statusVal);

        const asEnabled = await getSetting(eventId, "auto_start_enabled");
        setAutoStartEnabled(asEnabled === true || asEnabled === "true");

        const startTime = await getSetting(eventId, "event_start_time");
        if (startTime) {
          setEventStartTime(startTime);
        }
        // Nouvel ajout : récupération du style musical
        const style = await getSetting(eventId, "music_style");
        if (style) {
          setMusicStyle(style);
        }
        setLoading(false);
      } catch (error) {
        console.error("Erreur lors de la récupération des paramètres :", error);
        setLoading(false);
      }
    };
    fetchWaitingData();
  }, [eventId]);

  useEffect(() => {
    if (!eventId) return;
    const channel = supabase
      .channel(`event_status_waiting_realtime_${eventId}`)
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "evenements",
          filter: `id=eq.${eventId}`,
        },
        (payload) => {
          if (typeof payload.new?.event_start_time !== "undefined") {
            setEventStartTime(payload.new.event_start_time);
          }
          const newStatus = payload.new?.event_status;
          if (!newStatus) return;
          setEventStatus(newStatus);
          if (newStatus === "ongoing") {
            navigate(`/search/${eventId}`);
          } else if (newStatus === "ended") {
            navigate(`/end-of-event/${eventId}`);
          }
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [eventId, navigate]);

  useEffect(() => {
    if (!eventStartTime) return;
    const targetDate = new Date(eventStartTime);
    function updateCountdown() {
      const now = new Date();
      const diffMs = targetDate - now;
      if (diffMs <= 0) {
        setTimeRemaining(0);
        return;
      }
      setTimeRemaining(Math.floor(diffMs / 1000));
    }
    updateCountdown();
    const intervalId = setInterval(updateCountdown, 1000);
    return () => clearInterval(intervalId);
  }, [eventStartTime]);

  function formatTimeRemaining(seconds) {
    if (seconds <= 0) return "00:00:00";
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(secs).padStart(2, "0")}`;
  }

  if (loading) {
    return (
      <div className="waiting-container">
        <div className="loading-message">Chargement...</div>
      </div>
    );
  }

  if (eventStatus === "ended") {
    navigate(`/end-of-event/${eventId}`);
    return null;
  }
  if (eventStatus === "ongoing") {
    navigate(`/search/${eventId}`);
    return null;
  }

  return (
    <div className="waiting-container">
      <Helmet>
        <title>Page d'attente - Événement {eventId}</title>
      </Helmet>

      <div className="logo-container">
        <Logo />
      </div>

      <h1 className="waiting-title">L'événement va bientôt commencer !</h1>

      {timeRemaining !== null && autoStartEnabled && (
        <div className="countdown">
          <strong>{formatTimeRemaining(timeRemaining)}</strong>
        </div>
      )}

      {waitingMessage ? (
        <p className="waiting-message">{waitingMessage}</p>
      ) : (
        <p className="waiting-message"></p>
      )}

      {/* Section des cards avec icônes */}
      <div className="cards-container">
        <div className="card">
          <div className="card-row">
            <Music className="card-icon" />
            <div>
              <h3>Style Musical</h3>
              <p>{musicStyle}</p>
              <p>Animé par DJ REVR</p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-row">
            <Users className="card-icon" />
            <div>
              <h3>Préparez-vous</h3>
              <p>Réfléchissez aux morceaux que vous aimeriez entendre</p>
              <p>Soyez prêt à participer !</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WaitingPage;
