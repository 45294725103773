// /src/components/SpotifyConnectButton.jsx
import React from "react";

const SpotifyConnectButton = ({ eventId }) => {
  const handleConnect = () => {
    const clientId = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
    const redirectUri = encodeURIComponent(
      "https://www.mixify.fr/api/spotify-callback"
    );
    const scopes = encodeURIComponent(
      "playlist-modify-public playlist-modify-private playlist-read-private user-read-playback-state user-modify-playback-state ugc-image-upload"
    );

    console.log("=== SpotifyConnectButton: eventId =", eventId);
    if (!eventId) {
      alert(
        "Impossible de se connecter à Spotify : eventId est vide/indéfini !"
      );
      return;
    }

    const authUrl = `https://accounts.spotify.com/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&scope=${scopes}&state=${eventId}&show_dialog=true`;
    window.location.href = authUrl;
  };

  return (
    <button onClick={handleConnect} className="btn btn-primary">
      Connectez-vous à Spotify
    </button>
  );
};

export default SpotifyConnectButton;
