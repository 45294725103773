// LockedFeatureBadge.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/ComponentsStyles/LockedFeatureBadge.css'; // le style avec .djplus-badge

function LockedFeatureBadge({
  text = "Passer à DJ+",
  to = "/subscription" // la route de destination
}) {
  return (
    <Link to={to} className="djplus-badge">
      {text}
    </Link>
  );
}

export default LockedFeatureBadge;