// /src/pages/SpotifyDashboard.js
import React, { useState, useEffect, useCallback } from "react";
import { supabase } from "../utils/supabaseClient";
import { updateSuggestionStatus } from "../utils/suggestions";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import {
  addTrackToPlaylist,
  getSpotifyUserProfile,
  createPlaylist,
  updatePlaylistDetails,
  refreshSpotifyAccessToken,
  getPlaylistTracks,
  getCurrentlyPlayingTrack,
  updatePlaylistCoverImage,
  removeTrackFromPlaylist,
  addTrackToQueue,
} from "../utils/spotifyPlaylist";
import SpotifyConnectButton from "../components/SpotifyConnectButton";
import { generateCoverImageWithText } from "../utils/canvasHelpers";
import { FaSpotify } from "react-icons/fa";
import { DEFAULT_COVER_BASE64 } from "../assets/defaultCover";
import "../styles/SpotifyDashboard.css";

// Fonction pour extraire l'ID d'une playlist à partir d'une URL Spotify ou d'une chaîne simple
function extractPlaylistId(input) {
  // Si la chaîne commence par "http", on considère que c'est une URL complète
  if (input.startsWith("http")) {
    // On découpe la chaîne à partir de "/playlist/"
    const parts = input.split("/playlist/");
    if (parts.length > 1) {
      let idPart = parts[1];
      // Si l'ID est suivi de paramètres (commençant par "?"), on les retire
      const queryIndex = idPart.indexOf("?");
      if (queryIndex !== -1) {
        idPart = idPart.substring(0, queryIndex);
      }
      return idPart;
    }
  }
  // Sinon, on renvoie directement l'input
  return input;
}

function SpotifyDashboard() {
  const { eventId } = useParams();
  const [suggestions, setSuggestions] = useState([]);
  const [acceptedSuggestions, setAcceptedSuggestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [existingPlaylistId, setExistingPlaylistId] = useState("");
  const [spotifyConfig, setSpotifyConfig] = useState({
    playlistId: null,
    accessToken: null,
    refreshToken: null,
  });
  const [isPlaylistModalOpen, setIsPlaylistModalOpen] = useState(false);
  const [showLogout, setShowLogout] = useState(false);

  const handleLogout = async () => {
    // Réinitialisation de l'état local
    setSpotifyConfig({
      playlistId: null,
      accessToken: null,
      refreshToken: null,
    });

    // Supprimer la ligne dans la table "spotify_tokens" pour cet eventId
    const { error } = await supabase
      .from("spotify_tokens")
      .delete()
      .eq("event_id", eventId);

    if (error) {
      console.error("Erreur lors de la suppression des tokens :", error);
    } else {
      console.log("Tokens supprimés avec succès");
    }
  };

  // const [coverFile, setCoverFile] = useState(null);
  const [eventName, setEventName] = useState("");
  const [playlistDetails, setPlaylistDetails] = useState({
    name: `Mixify - ${eventId}`,
    description: `Playlist générée automatiquement par Mixify pour l'événement ${eventId}`,
  });
  const [newName, setNewName] = useState(playlistDetails.name);
  const [newDescription, setNewDescription] = useState(
    playlistDetails.description
  );
  const handleAddToQueue = async (track) => {
    try {
      const trackUri = `spotify:track:${track.id}`;
      await addTrackToQueue(trackUri, spotifyConfig.accessToken);
      alert(`Le morceau "${track.name}" a été ajouté à la file d'attente !`);

      // Récupérer le morceau actuellement joué
      const currentTrack = await getCurrentlyPlayingTrack(
        spotifyConfig.accessToken
      );
      let newOrder = 1;
      if (currentTrack && currentTrack.id) {
        // Recherche du morceau actuellement joué dans les suggestions acceptées
        const currentSuggestion = acceptedSuggestions.find(
          (s) => s.id === currentTrack.id
        );
        if (currentSuggestion && currentSuggestion.order) {
          newOrder = currentSuggestion.order + 1;
        }
      }
      // Met à jour le morceau en passant explicitement l'ordre
      await updateSuggestionStatus(
        track.id,
        "Accepté",
        eventId,
        null,
        newOrder
      );
      alert(
        `Le morceau "${track.name}" sera joué juste après le morceau en cours.`
      );
      loadSuggestions();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert(
          "Le morceau a peut-être été ajouté, mais assurez-vous d'avoir un appareil actif sur Spotify pour que la file d'attente fonctionne correctement."
        );
      } else {
        console.error("Erreur lors de l'ajout à la file d'attente :", error);
        alert(`Erreur lors de l'ajout à la file d'attente : ${error.message}`);
      }
    }
  };

  useEffect(() => {
    // Si eventName est connu (≠ Nom inconnu)
    // et si la playlist existe déjà (spotifyConfig.playlistId est défini),
    // et si localement on a encore "Mixify - <UUID>" dans playlistDetails.name
    if (
      eventName &&
      eventName !== "Nom inconnu" &&
      spotifyConfig.playlistId &&
      playlistDetails.name === `Mixify - ${eventId}`
    ) {
      // On met à jour la playlist côté Spotify
      const newName = `Mixify - ${eventName}`;
      const newDesc = `Playlist générée automatiquement par Mixify pour l'événement ${eventName}`;

      updatePlaylistDetails(
        spotifyConfig.playlistId,
        newName,
        newDesc,
        spotifyConfig.accessToken
      )
        .then(() => {
          // On met aussi à jour l'état local, pour la modale
          setPlaylistDetails({ name: newName, description: newDesc });
          console.log("Playlist renommée avec succès !");
        })
        .catch((err) => {
          console.error("Erreur updatePlaylistDetails:", err);
        });
    }
  }, [
    eventName,
    eventId,
    spotifyConfig.playlistId,
    playlistDetails.name,
    spotifyConfig.accessToken,
  ]);

  const [eventStatus, setEventStatus] = useState("waiting");

  useEffect(() => {
    setNewName(playlistDetails.name);
    setNewDescription(playlistDetails.description);
  }, [playlistDetails]);
  // -----------------------------
  // ↓↓↓ Partie upload couverture ↓↓↓
  /*
  const handleCoverChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Vérifier le type MIME si besoin

    const reader = new FileReader();
    reader.onload = function (evt) {
      if (evt.target.readyState !== 2) return;
      if (evt.target.error) {
        alert("Erreur lors de la lecture du fichier !");
        return;
      }

      const base64String = evt.target.result;
      const base64WithoutPrefix = base64String.split(",")[1];
      setCoverFile(base64WithoutPrefix);
    };
    reader.readAsDataURL(file);
  };

  const handleUpdateCover = async () => {
    if (!coverFile) {
      alert("Aucune image sélectionnée !");
      return;
    }
    if (!spotifyConfig.playlistId || !spotifyConfig.accessToken) {
      alert("Aucune playlist ou token Spotify introuvable !");
      return;
    }
    try {
      await updatePlaylistCoverImage(
        spotifyConfig.playlistId,
        DEFAULT_COVER_BASE64, // la chaîne base64 (sans préfixe)
        spotifyConfig.accessToken
      );
      alert("Couverture mise à jour avec succès !");
    } catch (err) {
      console.error("Erreur lors de la mise à jour de la couverture :", err);
      alert("Échec de la mise à jour de la couverture !");
    }
  };
  */
  // ↑↑↑ Partie upload couverture ↑↑↑
  // -----------------------------

  // Fonction pour retourner l'apparence du statut
  function getEventStatusAppearance(status) {
    switch (status) {
      case "waiting":
        return { label: "En attente", color: "#FFC107" };
      case "ongoing":
        return { label: "En cours", color: "#2196F3" };
      case "ended":
        return { label: "Terminé", color: "#4CAF50" };
      default:
        return { label: status || "Inconnu", color: "#9E9E9E" };
    }
  }
  const { label: statusLabel, color: statusColor } =
    getEventStatusAppearance(eventStatus);

  // Fonction pour tenter d'ouvrir la playlist dans l'app Spotify avec fallback
  function openSpotifyPlaylistWithFallback(playlistId) {
    const spotifyUrl = `spotify:playlist:${playlistId}`;
    const webUrl = `https://open.spotify.com/playlist/${playlistId}`;
    const timeout = setTimeout(() => {
      window.location.href = webUrl;
    }, 1000);

    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = spotifyUrl;
    document.body.appendChild(iframe);

    setTimeout(() => {
      clearTimeout(timeout);
      document.body.removeChild(iframe);
    }, 1500);
  }

  // Récupérer le nom et le statut depuis la table 'evenements'
  useEffect(() => {
    const fetchEventData = async () => {
      if (!eventId) return;
      try {
        const { data, error } = await supabase
          .from("evenements")
          .select("name, event_status")
          .eq("id", eventId)
          .single();
        if (error) {
          console.error(
            "Erreur lors de la récupération de l'événement :",
            error
          );
        } else {
          setEventName(data.name || "Nom inconnu");
          setEventStatus(data.event_status || "waiting");
        }
      } catch (err) {
        console.error("Erreur lors de la récupération de l'événement :", err);
      }
    };
    fetchEventData();
  }, [eventId]);

  // Charge la configuration Spotify depuis la BDD
  useEffect(() => {
    async function loadSpotifyConfig() {
      console.log("loadSpotifyConfig => eventId=", eventId);
      if (!eventId) {
        console.warn("Pas d'eventId => on ne charge pas la config Spotify.");
        return;
      }
      const { data, error } = await supabase
        .from("spotify_tokens")
        .select("*")
        .eq("event_id", eventId)
        .single();
      if (error) {
        // On vérifie le code d'erreur
        if (error.code === "PGRST116") {
          // "Row not found"
          console.log(
            "Pas de token spotify_tokens trouvé pour eventId=",
            eventId
          );
        } else {
          console.error(
            "Erreur lors du chargement de la config Spotify :",
            error
          );
        }
        return;
      }
      if (!data) {
        console.log("Aucune donnée de tokens pour cet eventId, table vide?");
        return;
      }
      setSpotifyConfig({
        playlistId: data.spotify_playlist_id,
        accessToken: data.access_token,
        refreshToken: data.refresh_token,
      });
    }
    loadSpotifyConfig();
  }, [eventId]);

  // Si aucun playlistId n'est défini et que le token est disponible,
  // créer automatiquement la playlist
  /*
useEffect(() => {
  async function setupPlaylist() {
    if (
      spotifyConfig.accessToken &&
      !spotifyConfig.playlistId &&
      eventName &&
      eventName !== "Nom inconnu"
    ) {
      try {
        // 1) Créer la playlist
        const profile = await getSpotifyUserProfile(spotifyConfig.accessToken);
        const userId = profile.id;
        const playlistName = `Mixify - ${eventName}`;
        const playlistData = await createPlaylist(
          userId,
          playlistName,
          spotifyConfig.accessToken,
          eventName
        );

        const playlistId = playlistData.id;
        await supabase
          .from("spotify_tokens")
          .update({ spotify_playlist_id: playlistId })
          .eq("event_id", eventId);

        setSpotifyConfig((prev) => ({ ...prev, playlistId }));
        setPlaylistDetails({
          name: playlistName,
          description: `Playlist générée automatiquement par Mixify pour l'événement ${eventName}`,
        });

        // 2) Générer la couverture avec le nom de l'événement
        const customCoverBase64 = await generateCoverImageWithText(
          DEFAULT_COVER_BASE64,
          eventName
        );

        // 3) Mettre à jour l'image de couverture sur Spotify
        await updatePlaylistCoverImage(
          playlistId,
          customCoverBase64,
          spotifyConfig.accessToken
        );
        console.log("Couverture mise à jour avec texte centré !");
      } catch (error) {
        console.error("Erreur lors de la création playlist + cover custom :", error);
      }
    }
  }

  setupPlaylist();
}, [
  eventId,
  spotifyConfig.accessToken,
  spotifyConfig.playlistId,
  eventName
]);
*/

  const handleCreateNewPlaylist = async () => {
    if (!spotifyConfig.accessToken || eventName === "Nom inconnu") {
      alert("Le token Spotify ou le nom de l'événement n'est pas disponible.");
      return;
    }
    try {
      // Récupérer le profil utilisateur pour obtenir son ID
      const profile = await getSpotifyUserProfile(spotifyConfig.accessToken);
      const userId = profile.id;
      // Définir le nom de la playlist à créer
      const playlistName = `Mixify - ${eventName}`;
      // Créer la playlist via l'API Spotify
      const playlistData = await createPlaylist(
        userId,
        playlistName,
        spotifyConfig.accessToken,
        eventName
      );
      const playlistId = playlistData.id;
      // Mettre à jour la base de données avec le nouvel ID de playlist
      await supabase
        .from("spotify_tokens")
        .update({ spotify_playlist_id: playlistId })
        .eq("event_id", eventId);
      // Mettre à jour l'état local
      setSpotifyConfig((prev) => ({ ...prev, playlistId }));
      setPlaylistDetails({
        name: playlistName,
        description: `Playlist générée automatiquement par Mixify pour l'événement ${eventName}`,
      });
      // Générer une couverture personnalisée
      const customCoverBase64 = await generateCoverImageWithText(
        DEFAULT_COVER_BASE64,
        eventName
      );
      // Mettre à jour l'image de couverture sur Spotify
      await updatePlaylistCoverImage(
        playlistId,
        customCoverBase64,
        spotifyConfig.accessToken
      );
      alert("La nouvelle playlist a été créée et liée à l'événement !");

      // Réinitialiser les suggestions acceptées de l'ancienne playlist
      await supabase
        .from("suggestions")
        .update({ status: "Reset", order: null })
        .eq("event_id", eventId)
        .eq("status", "Accepté");

      // Vider l'état local pour forcer un rafraîchissement
      setAcceptedSuggestions([]);
    } catch (error) {
      console.log(
        "===== Détails de l'erreur createPlaylist:",
        error?.response?.data || error?.message,
        error
      );
      console.error(
        "Erreur lors de la création de la nouvelle playlist :",
        error
      );
      alert("Erreur lors de la création de la playlist.");
    }
  };

  const handleLinkExistingPlaylist = async () => {
    if (!existingPlaylistId) {
      alert("Veuillez saisir l'ID d'une playlist existante.");
      return;
    }
    // Extraction de l'ID uniquement, même si l'utilisateur a collé une URL complète
    const cleanedId = extractPlaylistId(existingPlaylistId);
    try {
      await supabase
        .from("spotify_tokens")
        .update({ spotify_playlist_id: cleanedId })
        .eq("event_id", eventId);
      setSpotifyConfig((prev) => ({ ...prev, playlistId: cleanedId }));
      alert("La playlist existante a été liée à l'événement !");
      // Réinitialiser les suggestions acceptées de l'ancienne playlist
      await supabase
        .from("suggestions")
        .update({ status: "Reset", order: null })
        .eq("event_id", eventId)
        .eq("status", "Accepté");
      setAcceptedSuggestions([]);
    } catch (error) {
      console.error(
        "Erreur lors de la liaison de la playlist existante :",
        error
      );
      alert("Erreur lors de la liaison de la playlist.");
    }
  };

  // Charger les suggestions
  const loadSuggestions = useCallback(async () => {
    console.log("Début du chargement des suggestions pour eventId:", eventId);
    const { data, error } = await supabase
      .from("suggestions")
      .select("*")
      .eq("event_id", eventId)
      .eq("status", "En attente");
    if (error) {
      console.error("Erreur lors du chargement des suggestions :", error);
    } else {
      const formattedData = data.map((track) => {
        let safeArtists = [];
        try {
          const parsed = JSON.parse(track.artists);
          safeArtists = Array.isArray(parsed) ? parsed : parsed.split(",");
        } catch (err) {
          safeArtists = [track.artists];
        }
        return { ...track, artists: safeArtists };
      });
      console.log("Suggestions récupérées:", formattedData);
      setSuggestions(formattedData);
    }
    setLoading(false);
  }, [eventId]);

  const loadAcceptedSuggestions = useCallback(async () => {
    console.log(
      "Début du chargement des suggestions acceptées pour eventId:",
      eventId
    );
    const { data, error } = await supabase
      .from("suggestions")
      .select("*")
      .eq("event_id", eventId)
      .eq("status", "Accepté")
      // Ici, on s'assure de trier par le champ "order" pour conserver l'ordre Spotify
      .order("order", { ascending: true });

    if (error) {
      console.error("Erreur lors du chargement des morceaux acceptés :", error);
    } else {
      // On convertit aussi la chaîne JSON des artistes en tableau,
      // comme dans loadSuggestions.
      const formattedData = data.map((track) => {
        let safeArtists = [];
        try {
          const parsed = JSON.parse(track.artists);
          safeArtists = Array.isArray(parsed) ? parsed : parsed.split(",");
        } catch (err) {
          safeArtists = [track.artists];
        }
        return { ...track, artists: safeArtists };
      });

      console.log("Morceaux acceptés récupérés:", formattedData);
      setAcceptedSuggestions(formattedData);
    }
  }, [eventId]);

  useEffect(() => {
    loadSuggestions();
    loadAcceptedSuggestions();
  }, [eventId, loadSuggestions, loadAcceptedSuggestions]);

  useEffect(() => {
    if (!eventId) return;
    const channel = supabase
      .channel(`spotify_dashboard_suggestions_${eventId}`)
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "suggestions",
          filter: `event_id=eq.${eventId}`,
        },
        (payload) => {
          console.log("Realtime payload dans SpotifyDashboard:", payload);
          loadSuggestions();
        }
      )
      .subscribe();

    console.log("Souscription établie pour l'eventId:", eventId);

    return () => {
      supabase.removeChannel(channel);
    };
  }, [eventId, loadSuggestions]);

  useEffect(() => {
    if (!eventId) return;
    const acceptedChannel = supabase
      .channel(`spotify_dashboard_accepted_${eventId}`)
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "suggestions",
          // On filtre sur l'eventId et le statut "Accepté"
          filter: `event_id=eq.${eventId} and status=eq.Accepté`,
        },
        (payload) => {
          console.log("Realtime update for accepted suggestions:", payload);
          // Rechargez les pistes acceptées pour mettre à jour l'affichage
          loadAcceptedSuggestions();
        }
      )
      .subscribe();

    console.log(
      "Souscription realtime pour les pistes acceptées établie pour l'eventId:",
      eventId
    );

    return () => {
      supabase.removeChannel(acceptedChannel);
    };
  }, [eventId, loadAcceptedSuggestions]);

  // Ajouter un morceau à Spotify
  const handleAddToSpotify = async (track) => {
    try {
      await addTrackToPlaylist(
        track.id,
        spotifyConfig.playlistId,
        spotifyConfig.accessToken
      );
      alert(`Le morceau "${track.name}" a été ajouté à la playlist !`);
      await updateSuggestionStatus(track.id, "Accepté", eventId);
      loadAcceptedSuggestions(); // Recharge immédiatement la colonne des morceaux acceptés
      loadSuggestions();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        try {
          const newAccessToken = await refreshSpotifyAccessToken(
            spotifyConfig.refreshToken
          );
          setSpotifyConfig((prev) => ({
            ...prev,
            accessToken: newAccessToken,
          }));
          await addTrackToPlaylist(
            track.id,
            spotifyConfig.playlistId,
            newAccessToken
          );
          alert(
            `Le morceau "${track.name}" a été ajouté à la playlist après rafraîchissement du token !`
          );
          await updateSuggestionStatus(track.id, "Accepté", eventId);
          loadSuggestions();
        } catch (refreshError) {
          console.error(
            "Erreur lors du rafraîchissement du token ou de l'ajout du morceau :",
            refreshError
          );
          alert(
            "Le token Spotify a expiré et le rafraîchissement a échoué. Veuillez vous reconnecter."
          );
        }
      } else {
        console.error("Erreur lors de l'ajout à Spotify :", error);
        alert(`Erreur lors de l'ajout à Spotify : ${error.message}`);
      }
    }
  };

  // Refuser un morceau
  const handleRefuse = async (track) => {
    const defaultRefusalMessage =
      "Ce morceau ne correspond pas aux critères actuels.";
    await updateSuggestionStatus(
      track.id,
      "Refusé",
      eventId,
      defaultRefusalMessage
    );
    loadSuggestions();
  };

  const handleRemoveFromPlaylist = async (track) => {
    if (!spotifyConfig.playlistId || !spotifyConfig.accessToken) {
      alert("Impossible de retirer ce morceau : token ou playlist manquant.");
      return;
    }
    try {
      // 1) Retirer le morceau de la playlist Spotify
      await removeTrackFromPlaylist(
        spotifyConfig.playlistId,
        track.id,
        spotifyConfig.accessToken
      );
      alert(`Le morceau "${track.name}" a été retiré de la playlist !`);

      // 2) Mettre à jour le statut dans Supabase (ici on le marque comme "Supprimé")
      await updateSuggestionStatus(
        track.id,
        "Supprimé", // Vous pouvez adapter ce statut selon vos besoins
        eventId,
        "Morceau retiré manuellement de la playlist"
      );

      // 3) Recharger la liste des morceaux acceptés
      loadAcceptedSuggestions();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Gestion de l'expiration du token Spotify
        try {
          const newAccessToken = await refreshSpotifyAccessToken(
            spotifyConfig.refreshToken
          );
          setSpotifyConfig((prev) => ({
            ...prev,
            accessToken: newAccessToken,
          }));
          // Réessayer la suppression
          await removeTrackFromPlaylist(
            spotifyConfig.playlistId,
            track.id,
            newAccessToken
          );
          alert(
            `Le morceau "${track.name}" a été retiré de la playlist (après rafraîchissement du token) !`
          );
          await updateSuggestionStatus(
            track.id,
            "Supprimé",
            eventId,
            "Morceau retiré après rafraîchissement du token"
          );
          loadAcceptedSuggestions();
        } catch (refreshError) {
          console.error(
            "Erreur lors du rafraîchissement du token :",
            refreshError
          );
          alert(
            "Le token Spotify a expiré et le rafraîchissement a échoué. Veuillez vous reconnecter."
          );
        }
      } else {
        console.error("Erreur lors du retrait du morceau :", error);
        alert(`Erreur lors du retrait : ${error.message}`);
      }
    }
  };

  // Enregistrer les modifications (nom / description) via la modale
  const handleSavePlaylistDetails = async (newName, newDescription) => {
    try {
      await updatePlaylistDetails(
        spotifyConfig.playlistId,
        newName,
        newDescription,
        spotifyConfig.accessToken
      );
      setPlaylistDetails({ name: newName, description: newDescription });
      alert("La playlist a été mise à jour.");
      setIsPlaylistModalOpen(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        try {
          const newAccessToken = await refreshSpotifyAccessToken(
            spotifyConfig.refreshToken
          );
          setSpotifyConfig((prev) => ({
            ...prev,
            accessToken: newAccessToken,
          }));
          await updatePlaylistDetails(
            spotifyConfig.playlistId,
            newName,
            newDescription,
            newAccessToken
          );
          setPlaylistDetails({ name: newName, description: newDescription });
          alert("La playlist a été mise à jour.");
          setIsPlaylistModalOpen(false);
        } catch (refreshError) {
          console.error(
            "Erreur lors du rafraîchissement du token :",
            refreshError
          );
          alert("Le token Spotify a expiré. Veuillez vous reconnecter.");
        }
      } else {
        console.error("Erreur lors de la mise à jour de la playlist :", error);
        alert(`Erreur lors de la mise à jour : ${error.message}`);
      }
    }
  };

  // --- MODIFIED POLLING CODE ---
  // Ce nouveau useEffect remplace le setInterval par un mécanisme récursif avec setTimeout
  useEffect(() => {
    let timeoutId;
    let lastTrackId = null; // Variable locale pour retenir l'ID de la dernière piste détectée

    async function poll() {
      if (!spotifyConfig.accessToken || !spotifyConfig.playlistId) {
        // Si la configuration Spotify n'est pas encore prête, on réessaie après 20 secondes
        timeoutId = setTimeout(poll, 20_000);
        return;
      }
      try {
        // 1. Synchronisation de la playlist :
        const items = await getPlaylistTracks(
          spotifyConfig.playlistId,
          spotifyConfig.accessToken
        );
        const validTracks = items.filter(
          (it) => it.track && it.track.id && it.track.type === "track"
        );

        for (let i = 0; i < validTracks.length; i++) {
          const trackSpotifyId = validTracks[i].track.id;
          const order = i + 1;

          const { error } = await supabase
            .from("suggestions")
            .update({
              status: "Accepté",
              order,
              updated_at: new Date().toISOString(),
            })
            .eq("id", trackSpotifyId)
            .eq("event_id", eventId);

          if (error) {
            console.error("Erreur mise à jour suggestion:", error);
          }
        }

        // 2. Vérification de la piste actuellement en lecture
        const currentTrack = await getCurrentlyPlayingTrack(
          spotifyConfig.accessToken
        );
        // Par défaut, on réactive le poll dans 20 secondes
        let delay = 20_000;

        if (currentTrack && currentTrack.id) {
          // Si une piste est en cours de lecture et que son ID diffère de la dernière détectée,
          // cela signifie que la piste précédente vient de se terminer.
          if (lastTrackId && lastTrackId !== currentTrack.id) {
            const { error: playedErr } = await supabase
              .from("suggestions")
              .update({
                status: "Joué",
                updated_at: new Date().toISOString(),
              })
              .eq("id", lastTrackId)
              .eq("event_id", eventId);
            if (playedErr) {
              console.error("Erreur mise à jour statut Joué:", playedErr);
            }
          }
          // On enregistre l'ID de la piste en cours
          lastTrackId = currentTrack.id;

          // Si les informations de progression sont disponibles, on calcule le temps restant
          if (
            currentTrack.progress_ms !== undefined &&
            currentTrack.item &&
            currentTrack.item.duration_ms
          ) {
            const remainingTime =
              currentTrack.item.duration_ms - currentTrack.progress_ms;
            // On programme le prochain poll pour qu'il s'exécute juste après la fin de la piste
            delay = remainingTime + 1000; // +1 seconde de tampon
          }
        } else {
          // S'il n'y a aucune piste en lecture et qu'une dernière piste était détectée,
          // on la marque comme "Joué" (si ce n'est pas déjà fait) et on réinitialise lastTrackId.
          if (lastTrackId) {
            const { error: playedErr } = await supabase
              .from("suggestions")
              .update({
                status: "Joué",
                updated_at: new Date().toISOString(),
              })
              .eq("id", lastTrackId)
              .eq("event_id", eventId);
            if (playedErr) {
              console.error("Erreur mise à jour statut Joué:", playedErr);
            }
            lastTrackId = null;
          }
        }

        // 3. Planification du prochain appel de poll() avec le délai calculé
        timeoutId = setTimeout(poll, delay);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.log("Token expiré, tentative de rafraîchissement...");
          try {
            const newAccessToken = await refreshSpotifyAccessToken(
              spotifyConfig.refreshToken
            );
            setSpotifyConfig((prev) => ({
              ...prev,
              accessToken: newAccessToken,
            }));
          } catch (refreshError) {
            console.error("Echec du refresh:", refreshError);
          }
        } else {
          console.error("Erreur dans le polling:", error);
        }
        // En cas d'erreur, on planifie le prochain poll dans 20 secondes par défaut
        timeoutId = setTimeout(poll, 20_000);
      }
    }

    // Démarrage immédiat du polling
    poll();

    // Nettoyage lors du démontage du composant
    return () => clearTimeout(timeoutId);
  }, [
    spotifyConfig.accessToken,
    spotifyConfig.playlistId,
    eventId,
    spotifyConfig.refreshToken,
  ]);

  return (
    <div className="spotify-dashboard">
      <Helmet>
        <title>Mixify - Spotify Dashboard</title>
        <meta
          name="description"
          content="Gérez les suggestions de morceaux et ajoutez les à une playlist Spotify."
        />
      </Helmet>
      {/* En-tête avec navigation et informations sur l'événement */}
      <div
        className="d-flex justify-content-between align-items-center mb-4"
        style={{ flexWrap: "wrap" }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Link to="/events-manager" className="back-to-events">
            ← Évènements
          </Link>
          <h1
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 700,
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {eventName || "Chargement..."}
            <div className="event-status-display">
              <span
                className="event-status-bullet"
                style={{ backgroundColor: statusColor }}
              />
              <span className="event-status-text">{statusLabel}</span>
            </div>
          </h1>
        </div>

        {spotifyConfig.playlistId && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              className="spotify-link"
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  window.open(
                    `https://open.spotify.com/playlist/${spotifyConfig.playlistId}`,
                    "_blank"
                  );
                } else {
                  openSpotifyPlaylistWithFallback(spotifyConfig.playlistId);
                }
              }}
            >
              <FaSpotify size={32} color="#1DB954" />
            </div>
            <button
              className="playlist-edit-button"
              onClick={() => setIsPlaylistModalOpen(true)}
            >
              Modifier la playlist
            </button>
          </div>
        )}
        {/* Affichage du statut "Connecté" si le token existe */}
        {spotifyConfig.accessToken && (
          <div
            onMouseEnter={() => setShowLogout(true)}
            onMouseLeave={() => setShowLogout(false)}
            className="connected-wrapper"
          >
            <span className="connected-text">Connecté</span>
            {showLogout && (
              <button className="spotify-logout-btn" onClick={handleLogout}>
                Déconnexion
              </button>
            )}
          </div>
        )}
      </div>

      {/* Titre de la section spécifique à Spotify */}
      <h2>Gestion de la Playlist Spotify</h2>

      {!spotifyConfig.accessToken ? (
        <div>
          <p>
            Pour accéder à la gestion de votre playlist, connectez-vous à
            Spotify.
          </p>
          <SpotifyConnectButton eventId={eventId} />
        </div>
      ) : !spotifyConfig.playlistId ? (
        <div className="playlist-choice-cards">
          {/* Card pour créer une nouvelle playlist */}
          <div
            style={{
              border: "1px solid #ccc",
              padding: "1rem",
              borderRadius: "8px",
              width: "300px",
              textAlign: "center",
            }}
          >
            <h4>Créer une playlist pour l'évènement</h4>
            <p>
              Une nouvelle playlist sera créée avec le nom de l'évènement et une
              couverture personnalisée.
            </p>
            <button
              className="btn btn-primary"
              onClick={handleCreateNewPlaylist}
            >
              Créer la playlist
            </button>
          </div>

          {/* Card pour lier une playlist existante */}
          <div
            style={{
              border: "1px solid #ccc",
              padding: "1rem",
              borderRadius: "8px",
              width: "300px",
              textAlign: "center",
            }}
          >
            <h4>Lier une playlist existante</h4>
            <p>
              Lier une playlist existante à l'évènement pour y ajouter les
              propositions.
            </p>
            <input
              type="text"
              value={existingPlaylistId}
              onChange={(e) => setExistingPlaylistId(e.target.value)}
              placeholder="Entrez l'ID ou l'URL de la playlist"
              style={{ marginBottom: "1rem", width: "100%" }}
            />
            <button
              className="btn btn-secondary"
              onClick={handleLinkExistingPlaylist}
            >
              Lier la playlist
            </button>
          </div>
        </div>
      ) : (
        <>
          {/* -- Partie upload de l'image de couverture, COMMENTÉE -- */}
          {/*
          <div style={{ margin: "1rem 0" }}>
            <label htmlFor="coverUpload">Sélectionner une image de couverture :</label>
            <input
              id="coverUpload"
              type="file"
              accept="image/*"
              onChange={handleCoverChange}
            />
            <button
              className="btn btn-info"
              onClick={handleUpdateCover}
              style={{ marginLeft: "1rem" }}
            >
              Mettre à jour l'image
            </button>
          </div>
          */}
          {/* Bouton pour ouvrir la modale de modification */}

          <div className="dashboard-columns">
            {/* Colonne de gauche - suggestions en attente */}
            <div className="dashboard-column">
              <h3>Suggestions en attente</h3>
              {loading ? (
                <p>Chargement...</p>
              ) : suggestions.length === 0 ? (
                <p>Aucune suggestion en attente.</p>
              ) : (
                <div className="suggestions-list">
                  {suggestions.map((track) => (
                    <div key={track.id} className="dashboard-card">
                      <div className="dashboard-content">
                        <h5 className="dashboard-title">{track.name}</h5>
                        <p className="dashboard-artist">
                          {Array.isArray(track.artists)
                            ? track.artists.join(", ")
                            : "Artiste inconnu"}
                        </p>
                      </div>
                      <div
                        className="dashboard-actions"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <button
                          className="btn btn-success btn-common"
                          onClick={() => handleAddToSpotify(track)}
                        >
                          Ajouter à la playlist
                        </button>
                        <button
                          className="btn btn-info btn-common"
                          style={{ marginLeft: "10px" }}
                          onClick={() => handleAddToQueue(track)}
                        >
                          Ajouter à la file d'attente
                        </button>
                        <button
                          className="btn btn-danger btn-common"
                          style={{ marginLeft: "10px" }}
                          onClick={() => handleRefuse(track)}
                        >
                          Refuser
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Colonne de droite - morceaux acceptés */}
            <div className="dashboard-column">
              <h3>Playlist</h3>
              {loading ? (
                <p>Chargement...</p>
              ) : acceptedSuggestions.length === 0 ? (
                <p>Aucun morceau accepté pour le moment.</p>
              ) : (
                <div className="suggestions-list">
                  {acceptedSuggestions.map((track) => (
                    <div key={track.id} className="dashboard-card">
                      <div className="dashboard-content">
                        <h5 className="dashboard-title">
                          {track.order ? `${track.order}. ` : ""}
                          {track.name}
                        </h5>
                        <p className="dashboard-artist">
                          {Array.isArray(track.artists)
                            ? track.artists.join(", ")
                            : "Artiste inconnu"}
                        </p>
                      </div>
                      <div
                        className="remove-from-playlist"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          fontSize: "1.2rem",
                          marginRight: "8px",
                        }}
                        onClick={() => handleRemoveFromPlaylist(track)}
                      >
                        &times;
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {isPlaylistModalOpen && (
        <div
          className="modal-overlay"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            className="modal-content"
            style={{
              backgroundColor: "#1b1b1b",
              padding: "1.5rem",
              borderRadius: "8px",
              maxWidth: "500px",
              width: "90%",
            }}
          >
            <h3>Options de playlist</h3>

            {/* Section de mise à jour si une playlist est liée */}
            {spotifyConfig.playlistId ? (
              <div style={{ marginBottom: "1rem" }}>
                <h4>Modifier les paramètres de la playlist</h4>
                <div style={{ marginBottom: "0.5rem" }}>
                  <label>
                    Nouveau nom :
                    <input
                      type="text"
                      value={newName}
                      onChange={(e) => setNewName(e.target.value)}
                      style={{ marginLeft: "0.5rem", width: "100%" }}
                    />
                  </label>
                </div>
                <div style={{ marginBottom: "0.5rem" }}>
                  <label>
                    Nouvelle description :
                    <textarea
                      value={newDescription}
                      onChange={(e) => setNewDescription(e.target.value)}
                      style={{ marginLeft: "0.5rem", width: "100%" }}
                    />
                  </label>
                </div>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    handleSavePlaylistDetails(newName, newDescription);
                    setIsPlaylistModalOpen(false);
                  }}
                >
                  Enregistrer les modifications
                </button>
              </div>
            ) : (
              <p>Aucune playlist n'est liée actuellement.</p>
            )}

            <div className="separator">ou</div>

            {/* Section création / liaison */}
            <div style={{ marginBottom: "1rem" }}>
              <button
                className="btn btn-primary"
                onClick={handleCreateNewPlaylist}
              >
                Créer une nouvelle playlist
              </button>
            </div>
            <div style={{ marginBottom: "1rem" }}>
              <label htmlFor="existingPlaylistModal">
                Utiliser une playlist existante :
              </label>
              <input
                type="text"
                id="existingPlaylistModal"
                value={existingPlaylistId}
                onChange={(e) => setExistingPlaylistId(e.target.value)}
                placeholder="Entrez l'ID de la playlist"
                style={{ marginLeft: "0.5rem", width: "60%" }}
              />
              <button
                className="btn btn-secondary"
                onClick={() => {
                  handleLinkExistingPlaylist();
                  setIsPlaylistModalOpen(false);
                }}
                style={{ marginLeft: "0.5rem" }}
              >
                Lier la playlist
              </button>
            </div>

            <button
              className="btn btn-outline-danger"
              onClick={() => setIsPlaylistModalOpen(false)}
            >
              Fermer
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SpotifyDashboard;
