import React, { useContext, useState, useEffect, useCallback } from 'react';
import { supabase } from '../utils/supabaseClient';
import { AuthContext } from '../utils/AuthContext';
import { useNavigate } from 'react-router-dom';
import '../styles/ChartsPage.css';
import FancyButton from '../components/FancyButton';
import Layout from '../components/Layout';


function ChartsPage() {
  const { user } = useContext(AuthContext); 
  const [topSuggestions, setTopSuggestions] = useState([]);
  
  const [mostPlayed, setMostPlayed] = useState([]);

  // (A) État pour user & subscription
  const [userId, setUserId] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [loadingSub, setLoadingSub] = useState(false);
  const [errorSub, setErrorSub] = useState('');

  const [selectedList, setSelectedList] = useState("suggested");
  const [selectedEventType, setSelectedEventType] = useState("all");
  

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setUserId(user.id);
    } else {
      setUserId(null);
    }
  }, [user]);

  useEffect(() => {
    // 2) Si on a un userId, on va lire la table "subscriptions"
    if (!userId) return;
    const fetchSubscription = async () => {
      setLoadingSub(true);
      setErrorSub('');
      const { data: sub, error } = await supabase
        .from('subscriptions')
        .select('*')
        .eq('user_id', userId)
        .maybeSingle();

      if (error) {
        setErrorSub(error.message);
      } else {
        setSubscription(sub); 
      }
      setLoadingSub(false);
    };
    fetchSubscription();
  }, [userId]);

  // (B) Récupération des suggestions (et groupement)
  const fetchTopSuggestions = useCallback(async () => {
    try {
      const now = new Date();
      const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
  
      let query = supabase
  .from('suggestions')
  // Le "!" signale qu'on veut la jointure (inner ou left)
  // Ici "evenements!inner" suppose que la relation s’appelle "evenements" et qu’on veut un INNER JOIN.
  .select('id, name, artists, created_at, evenements!inner(event_type)')
  .gte('created_at', thirtyDaysAgo.toISOString());

console.log('Filtre event_type sélectionné:', selectedEventType);

if (selectedEventType !== 'all') {
  query = query.eq('evenements.event_type', selectedEventType);
}

const { data, error } = await query;
  
      if (error) {
        console.error('Erreur lors de la récupération des suggestions :', error);
        return;
      }
  
      console.log('Données récupérées :', data);
  
      // Grouper par (name + artists)
      const groupedMap = {};
  
      data.forEach((item) => {
        let artistList;
        if (typeof item.artists === 'string') {
          try {
            artistList = JSON.parse(item.artists);
          } catch {
            artistList = ['Artiste inconnu'];
          }
        } else if (Array.isArray(item.artists)) {
          artistList = item.artists;
        } else {
          artistList = ['Artiste inconnu'];
        }
  
        const name = item.name || 'Titre inconnu';
        const artistsJoined = artistList.join(', ');
        const key = `${name}__${artistsJoined}`;
  
        if (!groupedMap[key]) {
          groupedMap[key] = {
            name,
            artists: artistList,
            count: 0,
          };
        }
        groupedMap[key].count += 1;
      });
  
      // On stocke ce résultat
      setTopSuggestions(
        Object.values(groupedMap).sort((a, b) => b.count - a.count)
      );
    } catch (err) {
      console.error('Erreur fetchTopSuggestions :', err);
    }
  }, [selectedEventType]);

  useEffect(() => {
    // Appel initial et à chaque changement de filtre
    fetchTopSuggestions();
  
    // Mise à jour automatique toutes les 60 secondes
    const intervalId = setInterval(fetchTopSuggestions, 60000);
  
    // Nettoyage de l'intervalle
    return () => clearInterval(intervalId);
  }, [fetchTopSuggestions]);

  const fetchMostPlayed = useCallback(async () => {
    try {
      const now = new Date();
      const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
  
      // Préparer la requête avec jointure sur evenements
      let query = supabase
  .from('suggestions')
  .select('id, name, artists, created_at, status, evenements!inner(event_type)')
  .eq('status', 'Joué')
  .gte('created_at', thirtyDaysAgo.toISOString());

if (selectedEventType !== 'all') {
  query = query.eq('evenements.event_type', selectedEventType);
}

const { data, error } = await query;
  
      if (error) {
        console.error('Erreur lors de la récupération des morceaux joués :', error);
        return;
      }
  
      const groupedMap = {};
      data.forEach((item) => {
        let artistList;
        if (typeof item.artists === 'string') {
          try {
            artistList = JSON.parse(item.artists);
          } catch {
            artistList = ['Artiste inconnu'];
          }
        } else if (Array.isArray(item.artists)) {
          artistList = item.artists;
        } else {
          artistList = ['Artiste inconnu'];
        }
  
        const name = item.name || 'Titre inconnu';
        const artistsJoined = artistList.join(', ');
        const key = `${name}__${artistsJoined}`;
  
        if (!groupedMap[key]) {
          groupedMap[key] = {
            name,
            artists: artistList,
            count: 0,
          };
        }
        groupedMap[key].count += 1;
      });
  
      setMostPlayed(
        Object.values(groupedMap).sort((a, b) => b.count - a.count)
      );
    } catch (err) {
      console.error('Erreur fetchMostPlayed :', err);
    }
  }, [selectedEventType]);

  useEffect(() => {
    // Appel initial et à chaque changement de filtre
    fetchMostPlayed();
  
    // Mise à jour automatique toutes les 60 secondes
    const intervalId = setInterval(fetchMostPlayed, 60000);
  
    // Nettoyage de l'intervalle
    return () => clearInterval(intervalId);
  }, [fetchMostPlayed]);

  // (C) Calcul final : limite = 10 si pas abonné, 100 si abonné
  let limit = 10; // par défaut
  const isLoggedIn = !!userId;
  const isSubscribed = subscription && subscription.status === 'active';

  if (isLoggedIn && isSubscribed) {
    limit = 100;
  }
  const limitedSuggestions = topSuggestions.slice(0, limit);

  // 1) State pour afficher un petit message “Copié !”
  const [copyMessage, setCopyMessage] = useState({
    visible: false,
    text: '',
    x: 0,
    y: 0,
  });

  // 2) Notre fonction de copie
  function copyToClipboard(artist, title, event) {
    // On assemble le texte "Artiste - Titre"
    const formattedText = `${artist} - ${title}`;

    navigator.clipboard
      .writeText(formattedText)
      .then(() => {
        // On positionne et affiche "Copié !"
        setCopyMessage({
          visible: true,
          text: 'Copié !',
          x: event.clientX,
          y: event.clientY - 20,
        });

        // On masque après 2 secondes
        setTimeout(() => {
          setCopyMessage({ visible: false, text: '', x: 0, y: 0 });
        }, 2000);
      })
      .catch((err) => {
        console.error('Erreur lors de la copie : ', err);
      });
  }

  return (
    <div>
      <Layout>
        <div className="charts-page-container">
          <div className="mixify-charts">Mixify Charts</div>

{/* Nouveau bloc pour les filtres */}
<div className="charts-filters">
    <div className="filter-block">
      <label htmlFor="eventTypeSelect" className="filter-label">Type d'événement :</label>
      <select
        id="eventTypeSelect"
        className="charts-select"
        value={selectedEventType}
        onChange={(e) => setSelectedEventType(e.target.value)}
      >
        <option value="all">Tous</option>
        <option value="Soirée privée">Soirée privée (mariages, anniversaires, etc)</option>
        <option value="Clubs, bars dansants">Clubs, bars dansants</option>
        <option value="restaurant, lounge">Restaurant, lounge</option>
      </select>
    </div>

    <div className="filter-block">
      <label htmlFor="listSelect" className="filter-label">Afficher :</label>
      <select
        id="listSelect"
        className="charts-select"
        value={selectedList}
        onChange={(e) => setSelectedList(e.target.value)}
      >
        <option value="suggested">Titres les plus proposés</option>
        <option value="played">Titres les plus joués</option>
      </select>
    </div>
  </div>

{/* Affichage conditionnel */}
{selectedList === "suggested" ? (
  <>
    <h2 className="charts-title">
      {limit === 100
        ? 'Les 100 titres les plus proposés (sur les 30 derniers jours)'
        : 'Le Top 100 des titres les plus proposés (sur les 30 derniers jours)'}
    </h2>
    <div className="charts-list">
      {limitedSuggestions.map((track, index) => (
        <div key={index} className="chart-card">
          <div className="chart-rank">#{index + 1}</div>
          <div className="chart-info">
            <div
              className="chart-name"
              onClick={(e) =>
                copyToClipboard(track.artists.join(', '), track.name, e)
              }
              style={{ cursor: 'pointer' }}
            >
              {track.name}
            </div>
            <div
              className="chart-artists"
              onClick={(e) =>
                copyToClipboard(track.artists.join(', '), track.name, e)
              }
              style={{ cursor: 'pointer' }}
            >
              {track.artists.join(', ')}
            </div>
          </div>
          <div className="chart-count">
            {track.count} propositions
          </div>
        </div>
      ))}
    </div>
  </>
) : (
  <>
    <h2 className="charts-title">
      Les 100 titres les plus joués (sur les 30 derniers jours)
    </h2>
    <div className="charts-list">
    {mostPlayed.slice(0, 100).map((track, index) => (
        <div key={index} className="chart-card">
          <div className="chart-rank">#{index + 1}</div>
          <div className="chart-info">
            <div
              className="chart-name"
              onClick={(e) =>
                copyToClipboard(track.artists.join(', '), track.name, e)
              }
              style={{ cursor: 'pointer' }}
            >
              {track.name}
            </div>
            <div
              className="chart-artists"
              onClick={(e) =>
                copyToClipboard(track.artists.join(', '), track.name, e)
              }
              style={{ cursor: 'pointer' }}
            >
              {track.artists.join(', ')}
            </div>
          </div>
          <div className="chart-count">
            {track.count} fois
          </div>
        </div>
      ))}
    </div>
  </>
)}
  
        </div>
        {copyMessage.visible && (
          <div
            className="copy-message"
            style={{
              position: 'absolute',
              top: copyMessage.y,
              left: copyMessage.x,
              pointerEvents: 'none',
            }}
          >
            {copyMessage.text}
          </div>
        )}
        {/* Si non connecté ou non abonné */}
        {!isLoggedIn || !isSubscribed ? (
            <div style={{ marginTop: '20px', textAlign: 'center' }}>
            <FancyButton className="subscribe-btn" onClick={() => navigate('/subscription')}>
              Connectez-vous et souscrivez un abonnement pour voir le top 100 !
            </FancyButton>
          </div>
          ) : null}
          {loadingSub && <p>Chargement abonnement...</p>}
          {errorSub && <p>Erreur Subscription: {errorSub}</p>}
      </Layout>
    </div>
  );
}

export default ChartsPage;