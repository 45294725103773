// src/components/CollaboratorsModal.js

import React, { useEffect, useState } from "react";
import { supabase } from "../utils/supabaseClient"; 
import "../styles/ComponentsStyles/CollaboratorsModal.css";

function CollaboratorsModal({ 
  isOpen, 
  onClose, 
  eventData 
}) {

  const [inviteInput, setInviteInput] = useState("");
  const [currentCollaborators, setCurrentCollaborators] = useState([]);
  const [loading, setLoading] = useState(false);

  // Phrase d'explication (conseil UX #1)
  const infoText = `
    Invitez des amis ou d’autres DJs à collaborer sur cet événement
    en indiquant leur email ou leur nom d’utilisateur. 
    Vous pouvez leur donner un accès en lecture seule (Consultation) 
    ou un accès en écriture (Edition).
  `;

  useEffect(() => {
    if (!eventData || !isOpen) return;
    fetchCollaborators();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventData, isOpen]);

  // Récupérer la liste des collaborateurs pour l'event
  async function fetchCollaborators() {
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from("event_collaborators")
        .select(`
          id,
          collaborator_id,
          role,
          profiles:collaborator_id!inner (
  username,
  avatar_url
)
        `)
        .eq("event_id", eventData.id);

      if (error) {
        console.error("Erreur chargement collaborators:", error);
      } else {
        setCurrentCollaborators(data);
      }
    } catch (err) {
      console.error("Exception fetchCollaborators:", err);
    } finally {
      setLoading(false);
    }
  }

  // Fonction "inviteCollaborator": on cherche un user par email ou username
  // et on insère dans event_collaborators
  async function inviteCollaborator() {
    if (!inviteInput.trim()) return;
    setLoading(true);

    try {
      // 1) Chercher le user dans "profiles" par email ou username
      const { data: matchedUsers, error: searchError } = await supabase
        .from("profiles")
        .select("*")
        .or(`email.eq.${inviteInput},username.eq.${inviteInput}`);
      
      if (searchError) throw searchError;
      if (!matchedUsers || matchedUsers.length === 0) {
        alert("Utilisateur introuvable. Vérifie l'orthographe ou demande-lui de créer un compte.");
        return;
      }

      const userProfile = matchedUsers[0];

      // 2) Insérer la relation dans "event_collaborators"
      const { data: insertData, error: insertError } = await supabase
        .from("event_collaborators")
        .insert({
          event_id: eventData.id,
          collaborator_id: userProfile.user_id,
          role: "Consultation", // par défaut
        })
        .select("*")
        .single();

      if (insertError) throw insertError;

      // 3) Mise à jour de la liste locale
      setCurrentCollaborators((prev) => [...prev, insertData]);

      // 4) (Optionnel) Envoi d’un email d’invitation
      //    => Nécessite un service d’email. Ou un call webhook.
      //    => Ex: "sendgrid" ou "mailjet" ou "supabase functions" etc.

      // Reset input
      setInviteInput("");
    } catch (err) {
      console.error("Erreur inviteCollaborator:", err);
      alert(err.message);
    } finally {
      setLoading(false);
    }
  }

  // Gestion du changement de rôle (viewer → editor, etc.)
  async function updateRole(collabId, newRole) {
    setLoading(true);
    try {
      const { error } = await supabase
        .from("event_collaborators")
        .update({ role: newRole })
        .eq("id", collabId)
        .select("*")
        .single();

      if (error) throw error;

      // Mettre à jour la liste en local
      setCurrentCollaborators((prev) =>
        prev.map((c) => (c.id === collabId ? { ...c, role: newRole } : c))
      );
    } catch (err) {
      console.error("Erreur updateRole:", err);
    } finally {
      setLoading(false);
    }
  }

  // Suppression d’un collaborateur (conseil UX #3 : confirmation)
  async function removeCollaborator(collab) {
    const confirmRemoval = window.confirm(
      `Êtes-vous sûr de vouloir retirer l'accès à ${collab.profiles?.username || 'cet utilisateur'} ?`
    );
    if (!confirmRemoval) return;

    setLoading(true);
    try {
      const { error } = await supabase
        .from("event_collaborators")
        .delete()
        .eq("id", collab.id);

      if (error) throw error;

      setCurrentCollaborators((prev) => prev.filter((c) => c.id !== collab.id));
    } catch (err) {
      console.error("Erreur removeCollaborator:", err);
    } finally {
      setLoading(false);
    }
  }

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2 className="collaborators-modal-title">Gérer les collaborateurs</h2>
        <p className="collaborators-info-text">{infoText}</p>
 
        <div className="collaborators-invite-section">
          <label className="collaborators-invite-label">
            Inviter par email ou pseudo&nbsp;:
          </label>
          <input
            type="text"
            value={inviteInput}
            onChange={(e) => setInviteInput(e.target.value)}
            className="collaborators-invite-input"
            placeholder="ex: john.doe@email.fr"
          />
          <button
            onClick={inviteCollaborator}
            disabled={loading}
            className="collaborators-invite-button"
          >
            Inviter
          </button>
        </div>
 
        <h3 className="collaborators-list-title">Collaborateurs actuels</h3>
        {loading && <p className="loading-text">Chargement...</p>}
 
        <ul className="collaborators-list">
          {currentCollaborators.map((collab) => {
            const username = collab.profiles?.username || "—";
            const avatarUrl = collab.profiles?.avatar_url || "";
 
            return (
              <li key={collab.id} className="collaborators-list-item">
                {avatarUrl ? (
                  <img
                    src={avatarUrl}
                    alt="avatar"
                    className="avatar-image"
                  />
                ) : (
                  <div className="avatar-initials">
                    {username.slice(0,2).toUpperCase()}
                  </div>
                )}
 
                <span style={{ flex: 1 }}>{username}</span>
 
                <select
                  value={collab.role}
                  onChange={(e) => updateRole(collab.id, e.target.value)}
                  className="collaborators-role-select"
                >
                  <option value="Consultation">Consultation</option>
                  <option value="Edition">Edition</option>
                </select>
 
                <button
                  className="remove-collab-btn"
                  onClick={() => removeCollaborator(collab)}
                >
                  Retirer
                </button>
              </li>
              
            );
          })}
          
        </ul>
 
        <button
          onClick={onClose}
          className="fermer-modal-btn"
        >
          Fermer
        </button>
      </div>
    </div>
  );
}

export default CollaboratorsModal;