// src/components/Input.js
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types'; // Pour la validation des props

const Input = ({ id, type, value, onChange, label, required }) => {
  // Séparer les caractères du label pour l'animation
  const labelChars = label ? label.split('') : [];

  return (
    <StyledWrapper>
      <div className="wave-group">
        <input
          required={required}
          type={type}
          className="input"
          id={id}
          value={value}
          onChange={onChange}
        />
        <span className="bar" />
        <label className="label" htmlFor={id}>
          {labelChars.map((char, index) => {
            if (char === ' ') {
              return <span key={index} className="label-space" />;
            }
            return (
              <span
                key={index}
                className="label-char"
                style={{ '--index': index }}
              >
                {char}
              </span>
            );
          })}
        </label>
      </div>
    </StyledWrapper>
  );
};

Input.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

Input.defaultProps = {
    type: 'text',
    required: false,
    label: 'Label', // Valeur par défaut pour label
  };

const StyledWrapper = styled.div`
  .wave-group {
    position: relative;
  }

  .wave-group .input {
    font-size: 16px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 300px;
    border: none;
    border-bottom: 1px solid #515151;
    background: transparent;
  }

  .wave-group .input:focus {
    outline: none;
  }

  .wave-group .label {
    color: #999;
    font-size: 18px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    display: flex;
  }

  .wave-group .label-char {
    transition: 0.1s ease all; /* Durée de transition réduite */
    transition-delay: calc(var(--index) * 0.03s); /* Délai de transition réduit */
  }

  .wave-group .label-space {
    display: inline-block;
    width: 4px; /* Ajustez la largeur selon vos besoins */
    transition: 0.1s ease all; /* Durée de transition réduite */
    transition-delay: calc(var(--index) * 0.1s); /* Délai de transition réduit */
  }

  .wave-group .input:focus ~ label .label-char,
  .wave-group .input:valid ~ label .label-char,
  .wave-group .input:focus ~ label .label-space,
  .wave-group .input:valid ~ label .label-space {
    transform: translateY(-20px);
    font-size: 14px;
    color: #fff;
  }

  .wave-group .bar {
    position: relative;
    display: block;
    width: 300px;
  }

  .wave-group .bar:before,
  .wave-group .bar:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background:#fff;
    transition: 0.1s ease all; /* Durée de transition réduite */
    -moz-transition: 0.1s ease all;
    -webkit-transition: 0.1s ease all;
  }

  .wave-group .bar:before {
    left: 50%;
  }

  .wave-group .bar:after {
    right: 50%;
  }

  .wave-group .input:focus ~ .bar:before,
  .wave-group .input:focus ~ .bar:after {
    width: 50%;
  }
`;

export default Input;