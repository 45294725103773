import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getSetting } from '../utils/settings';
import '../styles/EndOfEventPage.css'; // <-- importe ton CSS
import Logo from '../components/Logo';
import { supabase } from '../utils/supabaseClient';

function EndOfEventPage() {
  const { eventId } = useParams();

  const navigate = useNavigate();

  const [eventName, setEventName] = useState('');
  const [endMessage, setEndMessage] = useState('');
  const [instagramLink, setInstagramLink] = useState('https://www.instagram.com/mixify_fr/');
  const [websiteLink, setWebsiteLink] = useState('https://www.mixify.fr');
  const [loading, setLoading] = useState(true);
  const [showEndpageStats, setShowEndpageStats] = useState(false);
 const [suggestionsCount, setSuggestionsCount] = useState(0);
 const [acceptedCount, setAcceptedCount] = useState(0);
 const [refusedCount, setRefusedCount] = useState(0);

  const [eventStatus, setEventStatus] = useState('ended'); 

  useEffect(() => {
    async function fetchEndOfEventData() {
      try {
        const message = await getSetting(eventId, 'end_event_message');
        if (message) {
          setEndMessage(message);
        }
        const inst = await getSetting(eventId, 'end_event_instagram');
        if (inst) setInstagramLink(inst);

        const site = await getSetting(eventId, 'end_event_website');
        if (site) setWebsiteLink(site);

        // Lire la valeur show_endpage_stats
     const statsVal = await getSetting(eventId, 'show_endpage_stats');
     if (statsVal === true || statsVal === 'true') {
       setShowEndpageStats(true);
     }


        // NOUVEAU : Récupérer le nom complet depuis la table evenements
     const { data, error } = await supabase
       .from('evenements')
       .select('name')
       .eq('id', eventId)
       .single();
     if (!error && data) {
       setEventName(data.name); // <--- on met à jour le state eventName
     }

       // On va aussi lire la colonne event_status pour savoir
       // si vraiment on est "ended" ou non.
       const statusVal = await getSetting(eventId, 'event_status');
       if (statusVal) {
         setEventStatus(statusVal);
       }

        setLoading(false);
      } catch (error) {
        console.error("Erreur pendant fetchEndOfEventData :", error);
        setLoading(false);
      }
    }
    fetchEndOfEventData();
  }, [eventId]);
  useEffect(() => {
    // Si showEndpageStats = false, on ne fait rien
    if (!showEndpageStats) return;
  
    // 1) Récupérer l'userId (soit localStorage, soit session).
    const userId = localStorage.getItem('userId');
    if (!userId) return; // ou console.error(...) 
    if (!eventId) return;
  
    // 2) Faire la requête
    (async () => {
      // suggestions? Filtrer par (event_id, user_id)
      const { data, error } = await supabase
        .from('suggestions')
        .select('status')
        .eq('event_id', eventId)
        .eq('user_id', userId);
  
      if (error) {
        console.error('[EndOfEventPage] Erreur lecture suggestions:', error);
        return;
      }
  
      // 3) stats
      const total = data.length;
      const accepted = data.filter((s) => s.status === 'Joué').length;
      const refused = data.filter((s) => s.status === 'Refusé').length;
      setSuggestionsCount(total);
      setAcceptedCount(accepted);
      setRefusedCount(refused);
    })();
  }, [showEndpageStats, eventId]);

  // --- DIFF : Abonnement en temps réel à la table "evenements"
 useEffect(() => {
     if (!eventId) return;
   
     // On crée un channel "end_of_event_realtime_{eventId}"
     const channel = supabase
       .channel(`end_of_event_realtime_${eventId}`)
       .on(
         'postgres_changes',
         { event: '*', schema: 'public', table: 'evenements', filter: `id=eq.${eventId}` },
         (payload) => {
           const newStatus = payload.new?.event_status;
           if (!newStatus) return;
   
           setEventStatus(newStatus);
           // Si le statut n’est plus "ended", on redirige :
           if (newStatus === 'waiting') {
             // L’event est repassé en mode attente => on envoie vers waiting
             navigate(`/waiting/${eventId}`);
           } else if (newStatus === 'ongoing') {
             // L’event est redevenu "en cours" => on envoie vers search
             navigate(`/search/${eventId}`);
           }
         }
       )
       .subscribe();
   
     return () => {
       supabase.removeChannel(channel);
     };
   }, [eventId, navigate]);

  if (loading) {
    return (
      <div className="endofevent-container">
        <div className="loading-message">Chargement...</div>
      </div>
    );
  }

  // --- DIFF : Si par malheur on a rechargé la page et qu’entre-temps 
 // event_status n’est plus "ended", on redirige tout de suite :
 if (eventStatus === 'waiting') {
   navigate(`/waiting/${eventId}`);
   return null;
 } else if (eventStatus === 'ongoing') {
   navigate(`/search/${eventId}`);
  return null;
 }

  return (
    <div className="endofevent-container">
       <div className="logo-container">
        <Logo />
        </div> 
        
      <Helmet>
        <title>Fin de la soirée - {eventName}</title>
      </Helmet>

      <h1 className="endofevent-title">Merci pour ta participation !</h1>

      {endMessage ? (
        <p className="endofevent-message">{endMessage}</p>
      ) : (
        <p className="endofevent-message">
          L'évènement "{eventName}" est terminé. <br />
          Vous ne pouvez plus proposer de musique.
        </p>
      )}

   {/* SI "showEndpageStats" est activé, on affiche un bloc */}
   {showEndpageStats && (
     <div className="endofevent-section">
       <h2>Vous avez proposé {suggestionsCount} musique(s) !</h2>
       <p>Parmis elles,  {acceptedCount} ont été acceptées et {refusedCount} ont été refusées.</p>
     </div>
   )}

      <div className="endofevent-section">
        <p className="endofevent-section-title">Retrouvez-nous sur Instagram :</p>
        <a
  href={instagramLink}
  target="_blank"
  rel="noopener noreferrer"
  className="endofevent-link instagram-link" // on peut ajouter une classe supplémentaire
>
  <i className="fab fa-instagram"></i>
</a>
      </div>

      <div className="endofevent-section">
        <p className="endofevent-section-title">Ou visitez notre site web :</p>
        <a
          href={websiteLink}
          target="_blank"
          rel="noopener noreferrer"
          className="endofevent-link"
        >
          {websiteLink}
        </a>
      </div>
    </div>
  );
}

export default EndOfEventPage;