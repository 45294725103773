import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { supabase } from "../utils/supabaseClient";
import "../styles/SendMessagePage.css";
import Logo from "../components/Logo";

// Fonction simple pour générer un userID aléatoire
const generateRandomUserId = () => {
  return "anon_" + Math.random().toString(36).substr(2, 9);
};

function SendMessagePage() {
  const MAX_CHARACTERS = 200;
  const [messageContent, setMessageContent] = useState("");
  const [usernameInput, setUsernameInput] = useState(""); // Champ pour le nom
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(false);
  const { eventId } = useParams();

  useEffect(() => {
    // Vérifier la session utilisateur via Supabase
    supabase.auth.getSession().then(({ data, error }) => {
      let uid;
      if (data?.session?.user) {
        uid = data.session.user.id;
      } else {
        // Aucun utilisateur connecté : on vérifie dans le localStorage
        uid = localStorage.getItem("userId");
        if (!uid) {
          uid = generateRandomUserId();
          localStorage.setItem("userId", uid);
        }
      }
      setUserId(uid);
    });
  }, []);

  const handleSendMessage = async () => {
    if (!messageContent) {
      alert("Veuillez saisir un message.");
      return;
    }
    if (messageContent.length > MAX_CHARACTERS) {
      alert(`Votre message ne doit pas dépasser ${MAX_CHARACTERS} caractères.`);
      return;
    }
    // On n'empêche plus l'envoi pour un utilisateur non connecté,
    // puisque userId est généré/anonyme.
    // (Vous pouvez éventuellement afficher un message d'info pour l'utilisateur)

    // Si l’utilisateur n’a pas saisi de nom, on met "Anonyme"
    const senderName =
      usernameInput.trim() === "" ? "Anonyme" : usernameInput.trim();

    setLoading(true);
    try {
      const response = await fetch("/api/create-message-checkout-session", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ messageContent, userId, senderName, eventId }),
      });
      const data = await response.json();
      if (data.url) {
        window.location.href = data.url; // redirection vers Stripe
      } else {
        alert(data.error || "Erreur inattendue");
      }
    } catch (error) {
      console.error("handleSendMessage error:", error);
      alert("Une erreur est survenue.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="send-message-container">
      <div className="logo-container">
        <Logo />
        <h2 className="send-message-title">Diffuser un message (0,99€)</h2>
        <p style={{ marginTop: "0px", fontSize: "0.9rem", color: "#ccc" }}>
          Vous ne serez prélevé(e) que si votre message est diffusée sur nos
          écrans.
        </p>
        {/* Champ pour le nom d’utilisateur */}
        <input
          type="text"
          placeholder="Votre nom (ou laissez vide pour 'Anonyme')"
          value={usernameInput}
          onChange={(e) => setUsernameInput(e.target.value)}
          style={{
            width: "100%",
            maxWidth: "600px",
            backgroundColor: "#2a2a2a",
            color: "#fff",
            border: "1px solid #444",
            padding: "10px 15px",
            borderRadius: "6px",
            fontSize: "1rem",
            marginBottom: "20px",
          }}
        />
        <textarea
          className="send-message-textarea"
          rows={5}
          cols={40}
          value={messageContent}
          onChange={(e) => setMessageContent(e.target.value)}
          placeholder="Tapez votre message..."
          maxLength={MAX_CHARACTERS}
        />
        <div className="send-message-button-container">
          <button
            className="send-message-button"
            onClick={handleSendMessage}
            disabled={loading}
          >
            {loading ? "Redirection vers Stripe..." : "Payer 0,99€ et envoyer"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default SendMessagePage;
