// ConfirmationModal.js
import React from 'react';
import '../styles/ConfirmationModal.css'; // Assurez-vous que ce fichier existe et est correctement stylisé

function ConfirmationModal({ isOpen, onConfirm, onCancel, message }) {
  if (!isOpen) return null;

  return (
    <div className="confirmation-modal-overlay">
      <div className="confirmation-modal">
        <p>{message}</p>
        <div className="confirmation-modal-actions">
          <button onClick={onConfirm} className="btn btn-danger">
            Confirmer
          </button>
          <button onClick={onCancel} className="btn btn-secondary">
            Annuler
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationModal;