import { supabase } from './supabaseClient';

export const isCooldownActive = async (eventId, cooldownKey) => {
  if (!eventId) {
    console.error("Aucun eventId fourni. Impossible de vérifier le cooldown.");
    return false;
  }

  try {
    const { data, error } = await supabase
      .from('evenements')
      .select('cooldown')
      .eq('id', eventId)
      .single();

    if (error || !data) {
      console.error("Erreur lors de la récupération du cooldown :", error || "Aucune donnée trouvée");
      return false;
    }

    const eventCooldownKey = `${cooldownKey}_${eventId}`;
    const storedCooldownEnd = localStorage.getItem(eventCooldownKey);
    if (!storedCooldownEnd) return false;

    const now = Date.now();
    const endTime = parseInt(storedCooldownEnd, 10);
    return endTime > now;
  } catch (err) {
    console.error("Erreur inattendue lors de la vérification du cooldown :", err);
    return false;
  }
};
  
 // Définit un cooldown pour un événement spécifique
 export const setCooldown = async (eventId, cooldownKey) => {
  if (!eventId) {
    console.error("Aucun eventId fourni. Impossible de définir le cooldown.");
    return;
  }

  try {
    const { data, error } = await supabase
      .from('evenements')
      .select('cooldown')
      .eq('id', eventId)
      .single();

    if (error || !data) {
      console.error("Erreur lors de la récupération du cooldown :", error || "Aucune donnée trouvée");
      return;
    }

    const durationInSeconds = data.cooldown;
    const eventCooldownKey = `${cooldownKey}_${eventId}`;
    const now = Date.now();
    const endTime = now + durationInSeconds * 1000;
    localStorage.setItem(eventCooldownKey, String(endTime));
    console.log(`Cooldown défini pour ${eventCooldownKey} jusqu'à ${new Date(endTime).toLocaleString()}`);
  } catch (err) {
    console.error("Erreur inattendue lors de la définition du cooldown :", err);
  }
};

  // Réinitialise tous les cooldowns pour un événement spécifique
  export const resetAllCooldowns = async (eventId) => {
    if (!eventId) {
      console.error("Aucun eventId fourni. Impossible de réinitialiser les cooldowns.");
      return;
    }
  
    try {
      const cooldownKeys = ['cooldownEnd', 'dashboardCooldownEnd', 'searchCooldownEnd'];
  
      // Supprimer les clés spécifiques à l'eventId du localStorage
      cooldownKeys.forEach((key) => {
        const eventCooldownKey = `${key}_${eventId}`;
        localStorage.removeItem(eventCooldownKey);
      });
  
      console.log(`Tous les cooldowns locaux ont été réinitialisés pour l'événement ${eventId}.`);
  
      // Publier un événement de réinitialisation via Supabase
      const { data, error } = await supabase
      .from('evenements')
      .update({
        cooldown_reset_at: new Date().toISOString()
      })
      .eq('id', eventId);
  
      if (error) {
        console.error("Erreur lors de la réinitialisation des cooldowns dans Supabase :", error);
      } else {
        console.log('Réinitialisation des cooldowns réussie dans Supabase :', data);
      }
    } catch (err) {
      console.error("Erreur inattendue lors de la réinitialisation des cooldowns :", err);
    }
  };

  export async function getCooldownFromDB(userId, eventId) {
       if (!userId || !eventId) return 0;
       try {
         const { data, error } = await supabase
           .from('cooldowns')
           .select('cooldown_end')
           .eq('user_id', userId)
           .eq('event_id', eventId)
           .maybeSingle();
    
         if (error) {
           // Pas trouvé ou autre erreur => on considère pas de cooldown
           console.error('Erreur getCooldownFromDB:', error);
           return 0;
         }
         if (!data || !data.cooldown_end) {
           // Aucune ligne ou champ vide => cooldown inexistant
           return 0;
         }
         // Convertir timestamptz => millisecondes
         const dbDate = new Date(data.cooldown_end).getTime();
         return dbDate;
       } catch (err) {
         console.error('Erreur inattendue getCooldownFromDB:', err);
         return 0;
       }
     }
    
     /**
      * Définit (ou met à jour) la date de fin de cooldown en BDD pour (userId, eventId).
      */
     export async function setCooldownInDB(userId, eventId, newEndTimeMs) {
       if (!userId || !eventId) return;
       try {
         const newEndTimeIso = new Date(newEndTimeMs).toISOString();
         
         // "UPSERT" => Insert ou Update
         // On part du principe que la table "cooldowns" a un unique constraint (user_id, event_id)
         const { data, error } = await supabase
  .from('cooldowns')
  .upsert({
    user_id: userId,
    event_id: eventId,
    cooldown_end: newEndTimeIso,
  }, {
    onConflict: 'user_id,event_id',
  })
           .select(); 
           // Le .select() renverra les données "upsertées"
     
         if (error) {
           console.error('Erreur setCooldownInDB:', error);
         } else {
           console.log('Cooldown mis à jour dans la BDD:', data);
         }
       } catch (err) {
         console.error('Erreur inattendue setCooldownInDB:', err);
       }
     }