// src/pages/LoginPage.js
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../utils/AuthContext'; // <-- Import du contexte

import '../styles/Auth.css';
import Input from '../components/Input';
import Layout from '../components/Layout';
import GoogleButton from '../components/GoogleButton';

function LoginPage() {
  const navigate = useNavigate();
  const { signInWithEmail, signInWithGoogle } = useContext(AuthContext); 
  // Récupère les fonctions du AuthContext

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    // 1) Tentative de connexion (via le context, au lieu de supabase.auth.signInWithPassword)
    const { data, error } = await signInWithEmail(email, password);

    if (error) {
      setErrorMessage(error.message);
      return;
    }

    const user = data.user;
    if (!user) {
      setErrorMessage('Impossible de se connecter. Vérifiez vos identifiants.');
      return;
    }

    setSuccessMessage('Connexion réussie !');
    navigate('/events-manager');
  };

  // Fonction de gestion de l'authentification Google (via le context)
  const handleGoogleSignup = async () => {
    try {
      const { error } = await signInWithGoogle('https://www.mixify.fr/');
      if (error) {
        setErrorMessage(error.message);
      }
      // Supabase redirige automatiquement l'utilisateur vers Google
      // puis, après validation, vers l'URL 'redirectTo'.
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  return (
    <Layout>
      <div className="auth-page">
        <div className="auth-container">
          <h2>Se connecter</h2>
          
          {/* Bouton de connexion avec Google placé en haut */}
          <GoogleButton onClick={handleGoogleSignup} />

          {/* Séparateur visuel avec lignes et texte "ou" */}
          <div className="separator">ou</div>

          {/* Formulaire de connexion par email/mot de passe */}
          <form onSubmit={handleLogin}>
            <Input
              id="email"
              type="email"
              label="Adresse email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <Input
              id="password"
              type="password"
              label="Mot de passe"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />

            <p className="forgot-password">
              Mot de passe oublié ?{' '}
              <span
                className="forgot-password-link"
                onClick={() => (window.location.href = '/forgot-password')}
              >
                Cliquez ici
              </span>
            </p>

            <button type="submit">Connexion</button>
          </form>

          {errorMessage && <div className="auth-message error">{errorMessage}</div>}
          {successMessage && <div className="auth-message success">{successMessage}</div>}

          <div className="switch-auth-link">
            <p>Pas encore de compte ? <a href="/signup">Créer un compte</a></p>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default LoginPage;