// src/pages/GoodbyePage.js

import React from 'react';
import '../styles/GoodbyePage.css'; // Créez ce fichier pour les styles spécifiques

function GoodbyePage() {
  return (
    <div className="goodbye-container">
      <h2>Compte supprimé</h2>
      <p>Votre compte a été supprimé avec succès. Nous sommes désolés de vous voir partir.</p>
      <a href="/">Retour à l'accueil</a>
    </div>
  );
}

export default GoodbyePage;