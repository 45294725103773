import { supabase } from './supabaseClient';
import { getArtistInfo } from './spotifyApi';


const DEFAULT_ARTIST = ['Artiste inconnu'];

// Ajouter un morceau
export const addSuggestion = async (track, eventId) => {
  const userId = localStorage.getItem('userId');
  
  const formattedTrack = {
    id: track.id, // Assurez-vous que `track.id` est bien unique
    name: track.name || 'Titre inconnu',
    artists: JSON.stringify(track.artists || DEFAULT_ARTIST),
    // On initialise styles à une chaîne vide, puis on essaiera de détecter le genre
    styles: JSON.stringify([]),
    status: 'En attente',
    created_at: new Date().toISOString(),
    user_id: userId,
    event_id: eventId,
  };

  // Si le morceau provient de Spotify, ses artistes devraient être un tableau d'objets
  // Nous allons récupérer les genres du premier artiste (si possible)
  let genres = [];
  if (track.artists && track.artists.length > 0 && track.artists[0].id) {
    const artistData = await getArtistInfo(track.artists[0].id);
    if (artistData && artistData.genres) {
      genres = artistData.genres;
    }
  }

  // On met à jour la propriété "styles" avec les genres détectés
  formattedTrack.styles = JSON.stringify(genres);

  const { data, error } = await supabase
    .from('suggestions')
    .insert([formattedTrack]);

  if (error) {
    console.error('Erreur lors de l\'insertion dans Supabase :', error);
    return null;
  }
  
  console.log('Insertion réussie dans Supabase :', data);
  return data;
};


// Récupérer les morceaux proposés
export const getSuggestions = async (eventId) => {
  const { data, error } = await supabase
    .from('suggestions')
    .select('*')
    .eq('event_id', eventId);

  if (error) {
    console.error("Erreur lors de la récupération des suggestions :", error);
    return [];
  }

  const formattedData = data.map((track) => {
    let safeArtists;
    let safeStyles = [];

    // Si track.artists est une chaîne JSON, on essaie de la parser
    if (typeof track.artists === 'string') {
      try {
        const parsed = JSON.parse(track.artists);
        if (Array.isArray(parsed) && parsed.length > 0) {
          safeArtists = parsed;
        } else {
          safeArtists = DEFAULT_ARTIST;
        }
      } catch (err) {
        safeArtists = DEFAULT_ARTIST;
      }
    }
        // Si track.artists est déjà un tableau
        else if (Array.isArray(track.artists) && track.artists.length > 0) {
          safeArtists = track.artists;
        }
        else {
          safeArtists = DEFAULT_ARTIST;
        }
        
    if (typeof track.styles === 'string') {
      try {
        const parsedStyles = JSON.parse(track.styles);
        if (Array.isArray(parsedStyles)) {
          safeStyles = parsedStyles;
        } else {
          safeStyles = [];
        }
      } catch {
        safeStyles = [];
      }
    } else if (Array.isArray(track.styles)) {
      safeStyles = track.styles;
    } else {
      safeStyles = [];
    }



    return {
      ...track,
      artists: safeArtists,
      styles: safeStyles,
    };
  });

  return formattedData;
};

// Supprimer toutes les suggestions
export const clearSuggestions = async (eventId) => {
  const { data, error } = await supabase
    .from('suggestions')
    .delete()
    .neq('id', '')
    .eq('event_id', eventId);

  
 if (error) {
      console.error("Erreur lors de la suppression de toutes les suggestions :", error);
      return;
    }
    
    console.log("Toutes les suggestions ont été supprimées de Supabase :", data);
  };
  
  export const updateTracksOrder = async (updatedTracks, eventId) => {
    // Prépare un tableau d'objets { id, order }
    const updates = updatedTracks.map((track, index) => ({
      id: track.id,
      order: index + 1, // 1-based index
    }));
  
    // Met à jour chaque enregistrement en boucle
    for (const update of updates) {
      const { error } = await supabase
        .from('suggestions')
        .update({ order: update.order })
        .eq('id', update.id)
        .eq('event_id', eventId);
  
      if (error) {
        console.error(`Erreur lors de la mise à jour de l'ordre pour l'ID ${update.id} :`, error);
        return null;
      }
    }
  
    console.log('Ordre mis à jour avec succès.');
    return true;
  };

// Mettre à jour le statut d'un morceau
export const updateSuggestionStatus = async (trackId, newStatus, eventId, reason = null, order = null) => {
  const updatedFields = {
    status: newStatus,
    updated_at: new Date().toISOString(),
    event_id: eventId,
  };

  if (reason) {
    updatedFields.refusal_reason = reason;
  }

  // Si un ordre est fourni, on l'utilise
  if (order !== null) {
    updatedFields.order = order;
  } else if (newStatus === 'Accepté') {
    // Sinon, si le statut est "Accepté", on calcule l'ordre par défaut
    const { data: rows, error: err } = await supabase
      .from('suggestions')
      .select('order')
      .eq('status', 'Accepté')
      .eq('event_id', eventId)
      .order('order', { ascending: false })
      .limit(1);

    let maxOrder = 0;
    if (!err && rows && rows.length > 0) {
      maxOrder = rows[0].order ?? 0;
    }
    updatedFields.order = maxOrder + 1;
  }

  const { data, error } = await supabase
    .from('suggestions')
    .update(updatedFields)
    .eq('id', trackId)
    .eq('event_id', eventId);

  if (error) {
    console.error(`Erreur lors de la mise à jour du statut pour ${trackId} :`, error);
    return null;
  }

  console.log("Mise à jour réussie dans Supabase :", data);
  return data;
};

export const updateTrackStyles = async (trackId,eventId, newStyles) => {
  // on va stocker newStyles en JSON
  const updatedFields = {
    styles: JSON.stringify(newStyles) 
    // ou : newStyles.toString() si tu veux juste une chaîne, mais JSON est mieux
  };

  const { data, error } = await supabase
    .from('suggestions')
    .update(updatedFields)
    .eq('id', trackId)
    .eq('event_id', eventId);

  if (error) {
    console.error(`Erreur lors de la mise à jour des styles pour ${trackId}:`, error);
    return null;
  }

  console.log("Mise à jour des styles réussie pour", trackId, data);
  return data;
};

/**
 * Récupère tous les morceaux en statut "Joué" pour un eventId donné
 * @param {string} eventId - l'ID de l'événement
 * @returns {Promise<Array>} - Liste des suggestions jouées
 */
export const getPlayedSuggestions = async (eventId) => {
  const { data, error } = await supabase
    .from("suggestions")
    .select("*")
    .eq("event_id", eventId)
    .eq("status", "Joué");  // ou un autre statut si besoin

  if (error) {
    console.error("Erreur lors de la récupération des morceaux joués :", error);
    return [];
  }

  // Idem que dans getSuggestions : parser track.artists (JSON) pour en faire un tableau
  const formattedData = data.map((track) => {
    let safeArtists;
    try {
      safeArtists = JSON.parse(track.artists);
    } catch (err) {
      safeArtists = track.artists; // fallback
    }
    return {
      ...track,
      artists: Array.isArray(safeArtists) ? safeArtists : [safeArtists],
    };
  });

  return formattedData;
};