import React, { useRef, useCallback, useState, useEffect } from "react";
import { supabase } from "../utils/supabaseClient";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import LockedFeatureBadge from "../components/LockedFeatureBadge";
import UserMenu from "../components/UserMenu";
import SettingsModal from "../components/SettingsModal";

import {
  getSuggestions,
  clearSuggestions,
  updateSuggestionStatus,
  updateTrackStyles,
  updateTracksOrder,
} from "../utils/suggestions";
import {
  getSetting,
  updateSetting,
  initializeEventSettings,
} from "../utils/settings.js";

import { resetAllCooldowns } from "../utils/cooldownUtils.js";

import "../styles/Dashboard.css";

/* -------------------------------------------------------------------------
 * Helper : Détermine la couleur + label d'un évènement selon son statut
 * ------------------------------------------------------------------------- */
function getEventStatusAppearance(status) {
  switch (status) {
    case "waiting":
      return { label: "En attente", color: "#FFC107" }; // Jaune
    case "ongoing":
      return { label: "En cours", color: "#2196F3" }; // Bleu
    case "ended":
      return { label: "Terminé", color: "#4CAF50" }; // Vert
    default:
      return { label: status ?? "Inconnu", color: "#9E9E9E" };
  }
}

/* -------------------------------------------------------------------------
 * GenreSelector : Composant pour définir les styles/genres d'un morceau
 * ------------------------------------------------------------------------- */
function GenreSelector({ track, onUpdateStyles }) {
  const [selected, setSelected] = useState(track.styles || []);

  const allGenres = [
    "Latino",
    "Afro",
    "Shatta",
    "House",
    "Techno",
    "Rap",
    "Old",
  ];

  // Couleurs utilisées lorsqu'un genre est sélectionné / non sélectionné
  const colorMap = {
    House: "#1E90FF",
    Shatta: "#FFA500",
    Latino: "#FFFF00",
    Techno: "#8A2BE2",
    Rap: "#333333",
    Old: "#008000",
    Afro: "#FF0000",
  };

  const darkColorMap = {
    House: "#0A4B80",
    Shatta: "#7F5200",
    Latino: "#7F7F00",
    Techno: "#4A1769",
    Rap: "#5E3510",
    Old: "#004000",
    Afro: "#7F0000",
  };

  // Gère la sélection/désélection d’un genre
  const toggleGenre = async (genre) => {
    let newSelected;
    if (selected.includes(genre)) {
      newSelected = selected.filter((g) => g !== genre);
    } else {
      newSelected = [...selected, genre];
    }
    setSelected(newSelected);
    await onUpdateStyles(track.id, newSelected);
  };

  return (
    <div className="genre-selector" style={{ marginTop: "10px" }}>
      {allGenres.map((genre) => {
        const isSelected = selected.includes(genre);
        return (
          <button
            key={genre}
            onClick={() => toggleGenre(genre)}
            style={{
              margin: "0 5px",
              backgroundColor: isSelected
                ? colorMap[genre]
                : darkColorMap[genre],
              color: genre === "Rap" ? "#fff" : "#000",
              border: "none",
              borderRadius: "5px",
              padding: "6px 12px",
              cursor: "pointer",
              fontWeight: "bold",
            }}
          >
            {genre}
          </button>
        );
      })}
    </div>
  );
}

/* -------------------------------------------------------------------------
 * Raison(s) de refus par défaut
 * ------------------------------------------------------------------------- */
const defaultRefusalReasons = [
  {
    keyword: "default",
    label:
      "Désolé, ce morceau n’est pas adapté à la dynamique actuelle de la soirée.",
  },
  {
    keyword: "Déjà Passé",
    label:
      "Malheureusement, ce morceau est déjà passé plus tôt dans la soirée.",
  },
  {
    keyword: "Copyright",
    label:
      "Désolé, notre DJ n’a pas pu se procurer votre morceau pour cause de copyrights...",
  },
];

/* =========================================================================
 * Composant principal : Dashboard
 * ========================================================================= */
function Dashboard() {
  /* -----------------------------------------------------------------------
   * 1. Récupération de eventId via les params
   * ----------------------------------------------------------------------- */
  const { eventId } = useParams();

  /* -----------------------------------------------------------------------
   * 2. États locaux divers (suggestions, user, subscription, etc.)
   * ----------------------------------------------------------------------- */
  const [suggestions, setSuggestions] = useState([]);
  const [userId, setUserId] = useState(null);
  const [subscription, setSubscription] = useState(null);

  // Contrôle de l’affichage des modales et barres
  const [showModal, setShowModal] = useState(false);
  const [showPlayedTracks, setShowPlayedTracks] = useState(false);
  const [showRefusedTracks, setShowRefusedTracks] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);

  // Paramètres divers
  const [cooldownDuration, setCooldownDuration] = useState(300); // 5 min
  const [authenticationEnabled, setAuthenticationEnabled] = useState(true);
  const [refusedText, setRefusedText] = useState(
    "Ce morceau a été refusé car il ne correspond pas aux critères actuels."
  );

  // Message de fin d’événement / page d’attente
  const [endEventMessage, setEndEventMessage] = useState("");
  const [waitingMessage, setWaitingMessage] = useState("");
  const [endEventInstagram, setEndEventInstagram] = useState("");
  const [endEventWebsite, setEndEventWebsite] = useState("");
  const [showEndpageStats, setShowEndpageStats] = useState(false);

  // Statut de l’événement
  const [eventStatus, setEventStatus] = useState("ongoing");
  const { label: statusLabel, color: statusColor } =
    getEventStatusAppearance(eventStatus);

  // Événement : Nom, type, heure de début
  const [eventName, setEventName] = useState("");
  const [eventType, setEventType] = useState("");
  const [eventStartTimeLocal, setEventStartTimeLocal] = useState("");

  // Raisons de refus
  const [refusalReasons, setRefusalReasons] = useState([]);
  const [refusalReasonInputs, setRefusalReasonInputs] = useState([
    { keyword: "", label: "" },
    { keyword: "", label: "" },
    { keyword: "", label: "" },
  ]);

  // Système de refus personnalisé
  const [showRefusalMenu, setShowRefusalMenu] = useState(null);
  const [popoverPosition, setPopoverPosition] = useState({ x: 0, y: 0 });
  const [showCustomRefusal, setShowCustomRefusal] = useState(false);
  const [customReason, setCustomReason] = useState("");
  const [trackIdForCustomReason, setTrackIdForCustomReason] = useState(null);

  // Système de copie (feedback sur clic)
  const [copyMessage, setCopyMessage] = useState({
    visible: false,
    text: "",
    x: 0,
    y: 0,
  });

  // Paramètres & onglets du panneau de réglages
  const [activeTab, setActiveTab] = useState("general");

  // Auto-start
  const [autoStartEnabled, setAutoStartEnabled] = useState(false);

  // Détection de la largeur pour le mode mobile
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isMobile = windowWidth < 768;
  const [mobileActiveTab, setMobileActiveTab] = useState("proposed");

  // Timers & refs
  const longPressTimer = useRef(null);
  const LONG_PRESS_DURATION = 500;
  const customReasonRef = useRef(null);

  /* -----------------------------------------------------------------------
   * 3. Fonctions utilitaires / helpers
   * ----------------------------------------------------------------------- */

  // (A) Copie d’un texte (titre + artiste) dans le presse-papier
  function copyToClipboard(artist, title, event) {
    const formattedText = `${artist} - ${title}`;

    if (
      navigator.clipboard &&
      typeof navigator.clipboard.writeText === "function"
    ) {
      // Méthode moderne
      navigator.clipboard
        .writeText(formattedText)
        .then(() => {
          setCopyMessage({
            visible: true,
            text: "Copié !",
            x: event.clientX,
            y: event.clientY - 20,
          });
          setTimeout(() => {
            setCopyMessage({ visible: false, text: "", x: 0, y: 0 });
          }, 2000);
        })
        .catch((err) => {
          console.error(
            "Erreur lors de la copie dans le presse-papier : ",
            err
          );
        });
    } else {
      // Fallback : document.execCommand
      const scrollX = window.scrollX || window.pageXOffset;
      const scrollY = window.scrollY || window.pageYOffset;

      const textarea = document.createElement("textarea");
      textarea.value = formattedText;
      textarea.style.position = "fixed";
      textarea.style.top = "0";
      textarea.style.left = "-9999px";
      textarea.setAttribute("readonly", "");

      document.body.appendChild(textarea);
      textarea.select();

      try {
        const successful = document.execCommand("copy");
        if (successful) {
          setCopyMessage({
            visible: true,
            text: "Copié !",
            x: event.clientX,
            y: event.clientY - 20,
          });
          setTimeout(() => {
            setCopyMessage({ visible: false, text: "", x: 0, y: 0 });
          }, 2000);
        }
      } catch (err) {
        console.error("Erreur lors de la copie avec execCommand : ", err);
      }

      document.body.removeChild(textarea);
      window.scrollTo(scrollX, scrollY);
    }
  }

  // (B) Gestion de l'appui long sur mobile (Refus)
  const handleTouchStart = (e, trackId) => {
    e.stopPropagation();
    longPressTimer.current = setTimeout(() => {
      setPopoverPosition({ x: e.touches[0].clientX, y: e.touches[0].clientY });
      setShowRefusalMenu(trackId);
      longPressTimer.current = null;
    }, LONG_PRESS_DURATION);
  };

  const handleTouchEnd = (e, trackId) => {
    if (longPressTimer.current) {
      clearTimeout(longPressTimer.current);
      longPressTimer.current = null;

      if (refusalReasons.length > 0) {
        const defaultReason = refusalReasons[0].label;
        changeTrackStatus(trackId, "Refusé", defaultReason);
      } else {
        changeTrackStatus(trackId, "Refusé", "Ce morceau est refusé.");
      }
    }
  };

  // (C) Mise à jour de l’état local des suggestions
  const updateAllSuggestions = useCallback(async () => {
    if (!eventId) {
      console.error(
        "Aucun eventId fourni. Impossible de récupérer les suggestions."
      );
      return;
    }
    const data = await getSuggestions(eventId);
    console.log(`Suggestions récupérées pour l'événement ${eventId} :`, data);
    setSuggestions(data);
  }, [eventId]);

  // (D) Changement de statut d’un morceau
  const changeTrackStatus = async (trackId, newStatus, reason = null) => {
    if (!eventId) {
      console.error(
        "Aucun eventId fourni. Impossible de mettre à jour le statut du morceau."
      );
      return;
    }
    await updateSuggestionStatus(trackId, newStatus, eventId, reason);
    await updateAllSuggestions();
  };

  // (E) Drag & drop "react-beautiful-dnd"
  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;
    if (sourceIndex === destIndex) return;

    const updatedTracks = Array.from(acceptedTracks);
    const [removed] = updatedTracks.splice(sourceIndex, 1);
    updatedTracks.splice(destIndex, 0, removed);

    try {
      await updateTracksOrder(updatedTracks, eventId);
    } catch (error) {
      console.error(
        "Erreur drag & drop react-beautiful-dnd (Dashboard):",
        error
      );
    }
  };

  // (F) Formater l’heure en HH:MM:SS
  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString("fr-FR", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  // (G) Réinitialiser la session
  const handleReset = async () => {
    if (!eventId) {
      console.error(
        "Aucun eventId fourni. Impossible de réinitialiser la session."
      );
      return;
    }
    console.log(
      `Réinitialisation de la session pour l'événement ${eventId}...`
    );
    await clearSuggestions(eventId);
    setSuggestions([]);
    await resetAllCooldowns(eventId);
    setShowModal(false);

    await supabase
      .from("evenements")
      .update({
        cooldown_reset_at: new Date().toISOString(),
        session_reset_at: new Date().toISOString(),
      })
      .eq("id", eventId);

    console.log("Session réinitialisée avec succès.");
  };

  // (H) Convertir date/heure locale en UTC (pour stocker dans Supabase)
  function convertLocalToUTC(localString) {
    const dateObj = new Date(localString);
    return dateObj.toISOString();
  }

  function convertUTCToLocalInput(utcString) {
    if (!utcString) return "";
    const dateObj = new Date(utcString);
    const tzOffset = dateObj.getTimezoneOffset();
    const localTime = new Date(dateObj.getTime() - tzOffset * 60_000);
    return localTime.toISOString().slice(0, 16);
  }

  // (I) Déterminer le "plan level" (abonnement)
  function getPlanLevel(subscription) {
    if (!subscription || subscription.status !== "active") {
      return 0; // pas d'abonnement actif
    }
    switch (subscription.price_id) {
      case "price_1QjSWiGRn8HctkO3lvR7RuyX":
        return 1; // DJ
      case "price_1QhUsjGRn8HctkO3hg5iacZX":
        return 2; // DJ+
      case "price_1QhUu3GRn8HctkO3RQzRx01K":
        return 3; // Établissement
      default:
        return 0;
    }
  }
  const planLevel = getPlanLevel(subscription);

  /* -----------------------------------------------------------------------
   * 4. useEffect : Gestion focus sur zone "motif personnalisé" (customRefusal)
   * ----------------------------------------------------------------------- */
  useEffect(() => {
    if (showCustomRefusal && trackIdForCustomReason !== null) {
      setTimeout(() => {
        if (customReasonRef.current) {
          customReasonRef.current.focus();
        }
      }, 0);
    }
  }, [showCustomRefusal, trackIdForCustomReason]);

  /* -----------------------------------------------------------------------
   * 5. useEffect : Charger les settings de l'événement (refus, etc.)
   * ----------------------------------------------------------------------- */
  useEffect(() => {
    const initializeSettings = async () => {
      if (!eventId) return;
      await initializeEventSettings(eventId);
      const rawJson = await getSetting(eventId, "refused_text_reason");
      if (rawJson) {
        try {
          const parsed = JSON.parse(rawJson);
          setRefusalReasons(parsed);
          setRefusalReasonInputs(parsed);
        } catch (error) {
          console.error("Erreur de parsing des motifs de refus :", error);
        }
      }
    };
    initializeSettings();
  }, [eventId]);

  useEffect(() => {
    const fetchRefusalReasons = async () => {
      if (!eventId) return;
      const rawJson = await getSetting(eventId, "refused_text_reason");
      if (rawJson) {
        try {
          const parsed = JSON.parse(rawJson);
          setRefusalReasons(parsed);
          setRefusalReasonInputs(parsed);
        } catch (error) {
          console.error("Erreur de parsing des motifs de refus :", error);
          setRefusalReasons(defaultRefusalReasons);
          setRefusalReasonInputs(defaultRefusalReasons);
          await updateSetting(
            eventId,
            "refused_text_reason",
            JSON.stringify(defaultRefusalReasons)
          );
        }
      } else {
        // Si aucune donnée, on utilise celles par défaut
        setRefusalReasons(defaultRefusalReasons);
        setRefusalReasonInputs(defaultRefusalReasons);
        await updateSetting(
          eventId,
          "refused_text_reason",
          JSON.stringify(defaultRefusalReasons)
        );
      }
    };
    fetchRefusalReasons();
  }, [eventId]);

  /* -----------------------------------------------------------------------
   * 6. useEffect : Récupération du nom / type de l'événement
   * ----------------------------------------------------------------------- */
  useEffect(() => {
    const fetchEventName = async () => {
      if (!eventId) return;
      try {
        const { data, error } = await supabase
          .from("evenements")
          .select("name, event_type")
          .eq("id", eventId)
          .single();

        if (error) {
          console.error(
            "Erreur lors de la récupération du nom de l'événement :",
            error
          );
        } else {
          setEventName(data.name || "Nom inconnu");
          setEventType(data.event_type || "");
        }
      } catch (err) {
        console.error(
          "Erreur lors de la récupération du nom de l'événement :",
          err
        );
      }
    };
    fetchEventName();
  }, [eventId]);

  /* -----------------------------------------------------------------------
   * 7. useEffect : Charger les suggestions initiales + Realtime
   * ----------------------------------------------------------------------- */
  useEffect(() => {
    if (!eventId) return;
    updateAllSuggestions();

    const channel = supabase
      .channel("dashboard_suggestions")
      .on(
        "postgres_changes",
        { event: "*", schema: "public", table: "suggestions" },
        async (payload) => {
          console.log("Événement Realtime dans Dashboard:", payload);

          // Optimisation : Si seuls les "styles" changent, on ne rafraîchit pas tout
          if (payload.eventType === "UPDATE") {
            const oldRow = payload.old;
            const newRow = payload.new;
            if (oldRow && newRow) {
              const { styles: oldStyles, ...restOld } = oldRow;
              const { styles: newStyles, ...restNew } = newRow;
              if (JSON.stringify(restOld) === JSON.stringify(restNew)) {
                console.log(
                  "[Dashboard] Seuls les styles ont changé => on ignore updateAllSuggestions()"
                );
                return;
              }
            }
          }
          updateAllSuggestions();
        }
      )
      .on(
        "postgres_changes",
        { event: "DELETE", schema: "public", table: "suggestions" },
        (payload) => {
          console.log("Suppression détectée dans Dashboard:", payload);
          updateAllSuggestions();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [eventId, updateAllSuggestions]);

  /* -----------------------------------------------------------------------
   * 8. useEffect : Détecter changements de taille de fenêtre => mode mobile
   * ----------------------------------------------------------------------- */
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  /* -----------------------------------------------------------------------
   * 9. useEffect : Récupération du cooldown
   * ----------------------------------------------------------------------- */
  useEffect(() => {
    if (!eventId) return;
    const fetchCooldown = async () => {
      const cooldown = await getSetting(eventId, "cooldown");
      if (cooldown) {
        setCooldownDuration(Number(cooldown));
      }
    };
    fetchCooldown();
  }, [eventId]);

  // Mise à jour en temps réel de la valeur cooldown (channel)
  useEffect(() => {
    if (!eventId) return;
    const channel = supabase
      .channel(`dashboard_settings_${eventId}`)
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "evenements",
          filter: `id=eq.${eventId}`,
        },
        (payload) => {
          if (payload.new?.key === "cooldown") {
            const newCooldown = parseInt(payload.new.value, 10);
            if (!isNaN(newCooldown) && newCooldown > 0) {
              setCooldownDuration(newCooldown);
              console.log(
                `Mise à jour en temps réel du cooldown : ${newCooldown}s`
              );
            }
          }
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [eventId]);

  /* -----------------------------------------------------------------------
   * 10. useEffect : Récupération du texte de refus
   * ----------------------------------------------------------------------- */
  useEffect(() => {
    if (!eventId) return;
    const fetchRefusedText = async () => {
      const text = await getSetting(eventId, "refused_text");
      if (text) setRefusedText(text);
    };
    fetchRefusedText();
  }, [eventId]);

  /* -----------------------------------------------------------------------
   * 11. useEffect : Récupération du statut (event_status, endpage stats, etc.)
   * ----------------------------------------------------------------------- */
  useEffect(() => {
    if (!eventId) return;

    (async () => {
      try {
        const statusVal = await getSetting(eventId, "event_status");
        if (statusVal) setEventStatus(statusVal);

        const statsVal = await getSetting(eventId, "show_endpage_stats");
        if (statsVal === true || statsVal === "true") setShowEndpageStats(true);
        else setShowEndpageStats(false);

        const asEnabled = await getSetting(eventId, "auto_start_enabled");
        if (asEnabled === true || asEnabled === "true")
          setAutoStartEnabled(true);
        else setAutoStartEnabled(false);
      } catch (err) {
        console.error("Erreur lors de la récupération de event_status :", err);
      }
    })();
  }, [eventId]);

  // Mise à jour en temps réel du statut
  useEffect(() => {
    if (!eventId) return;
    const channel = supabase
      .channel(`event_status_realtime_${eventId}`)
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "evenements",
          filter: `id=eq.${eventId}`,
        },
        (payload) => {
          if (typeof payload.new?.event_status !== "undefined") {
            setEventStatus(payload.new.event_status);
            console.log(
              "[Dashboard] event_status mis à jour en temps réel:",
              payload.new.event_status
            );
          }
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [eventId]);

  /* -----------------------------------------------------------------------
   * 12. useEffect : Récupération du message d'attente
   * ----------------------------------------------------------------------- */
  useEffect(() => {
    if (!eventId) return;
    const fetchWaitingMessage = async () => {
      try {
        const msg = await getSetting(eventId, "waiting_message");
        if (msg) setWaitingMessage(msg);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération du message de la page d'attente:",
          error
        );
      }
    };
    fetchWaitingMessage();
  }, [eventId]);

  /* -----------------------------------------------------------------------
   * 13. useEffect : Récupération de l'heure de début
   * ----------------------------------------------------------------------- */
  useEffect(() => {
    if (!eventId) return;
    const fetchEventStartTime = async () => {
      try {
        const startTime = await getSetting(eventId, "event_start_time");
        if (startTime) {
          const localInputValue = convertUTCToLocalInput(startTime);
          setEventStartTimeLocal(localInputValue);
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération de l'heure de début:",
          error
        );
      }
    };
    fetchEventStartTime();
  }, [eventId]);

  /* -----------------------------------------------------------------------
   * 14. useEffect : Récupération authentication_enabled
   * ----------------------------------------------------------------------- */
  useEffect(() => {
    const fetchAuthenticationSetting = async () => {
      const authEnabled = await getSetting(eventId, "authentication_enabled");
      setAuthenticationEnabled(authEnabled === true);
    };
    fetchAuthenticationSetting();
  }, [eventId]);

  /* -----------------------------------------------------------------------
   * 15. useEffect : Récupérer user + abonnement
   * ----------------------------------------------------------------------- */
  useEffect(() => {
    supabase.auth.getSession().then(({ data, error }) => {
      if (error) {
        console.error("Erreur lors de la récupération de la session:", error);
      }
      if (data?.session?.user) {
        setUserId(data.session.user.id);
      } else {
        setUserId(null);
      }
    });
  }, []);

  useEffect(() => {
    if (!userId) {
      setSubscription(null);
      return;
    }
    const fetchSubscription = async () => {
      try {
        let { data: sub, error } = await supabase
          .from("subscriptions")
          .select("*")
          .eq("user_id", userId)
          .maybeSingle();

        if (error) {
          console.error("Erreur Supabase subscription:", error);
          setSubscription(null);
        } else {
          setSubscription(sub);
        }
      } catch (err) {
        console.error("Exception fetchSubscription:", err);
        setSubscription(null);
      }
    };
    fetchSubscription();
  }, [userId]);

  /* -----------------------------------------------------------------------
   * 16. Séparer les tableaux de morceaux par statut
   * ----------------------------------------------------------------------- */
  const refusedTracks = suggestions.filter(
    (track) => track.status === "Refusé"
  );
  const playedTracks = suggestions.filter((track) => track.status === "Joué");
  const pendingTracks = suggestions
    .filter((track) => track.status === "En attente")
    .sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

  const acceptedTracks = suggestions
    .filter((track) => track.status === "Accepté")
    .sort((a, b) => a.order - b.order);

  // Numéro d’ordre dynamique des morceaux acceptés
  const dynamicProposalOrderMap = acceptedTracks.reduce((map, track, index) => {
    map[track.id] = index + 1;
    return map;
  }, {});

  /* -----------------------------------------------------------------------
   * 17. useEffect (esc key) pour fermer la modal de settings si ouverte
   * ----------------------------------------------------------------------- */
  useEffect(() => {
    if (!showSettingsModal) return;
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        setShowSettingsModal(false);
      }
    };
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [showSettingsModal]);

  /* -----------------------------------------------------------------------
   * 18. Rendu du composant
   * ----------------------------------------------------------------------- */
  return (
    <div className="dashboard-page">
      <Helmet>
        <title>Mixify - Dashboard</title>
        <meta
          name="description"
          content="Gérez les suggestions de morceaux sur le tableau de bord Mixify."
        />
      </Helmet>

      {/* ---------------------------------------------------------------------
         Barre d’en-tête : Retour aux évènements, nom d’événement, user menu
         --------------------------------------------------------------------- */}
      <div
        className="d-flex justify-content-between align-items-center mb-4"
        style={{ flexWrap: "wrap" }}
      >
        {/* Bloc de gauche : Lien retour + nom + statut */}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Link to="/events-manager" className="back-to-events">
            ← Évènements
          </Link>
          <h1
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 700,
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {eventName || "Chargement..."}
            <div className="event-status-display">
              <span
                className="event-status-bullet"
                style={{ backgroundColor: statusColor }}
              />
              <span className="event-status-text">{statusLabel}</span>
            </div>
          </h1>
        </div>

        {/* Bloc de droite : Bouton Page de Proposition + Settings + User Menu */}
        {!isMobile && (
          <div className="dashboard-buttons">
            <Link to={`/search/${eventId}`} className="btn btn-success">
              Accéder à la page de proposition
            </Link>
          </div>
        )}
        <button
          className="btn btn-outline-secondary"
          onClick={() => setShowSettingsModal(true)}
          style={{ display: "flex", alignItems: "center" }}
        >
          ⚙️
        </button>
      
        <div style={{ marginLeft: "20px" }}>
          <UserMenu />
        </div>

        {/* Message de copie (temporaire) */}
        {copyMessage.visible && (
          <div
            className="copy-message"
            style={{
              position: "absolute",
              top: `${copyMessage.y}px`,
              left: `${copyMessage.x}px`,
              pointerEvents: "none",
              zIndex: 10000,
            }}
          >
            {copyMessage.text}
          </div>
        )}
      </div>

      {/* ---------------------------------------------------------------------
         Rendu principal : Vue desktop / Vue mobile
         --------------------------------------------------------------------- */}
      {!isMobile && (
        <>
          {/* Mise en page en 2 colonnes : Proposés / Acceptés */}
          <div className="row dashboard-row">
            {/* Colonne gauche : morceaux en attente */}
            <div className="col-6 dashboard-left-col">
              <div className="dashboard-section-title">Morceaux proposés</div>
              {pendingTracks.length === 0 ? (
                <div className="dashboard-section-status">
                  En attente de suggestions.
                </div>
              ) : (
                pendingTracks.map((track, index) => (
                  <div key={track.id} className="dashboard-card">
                    <div className="dashboard-content">
                      <h5
                        className="dashboard-title"
                        onClick={(e) =>
                          copyToClipboard(
                            track.artists.join(", "),
                            track.name,
                            e
                          )
                        }
                        style={{ cursor: "pointer", position: "relative" }}
                      >
                        {index + 1}. {track.name}
                      </h5>
                      <p
                        className="dashboard-artist"
                        onClick={(e) =>
                          copyToClipboard(
                            track.artists.join(", "),
                            track.name,
                            e
                          )
                        }
                        style={{ cursor: "pointer", position: "relative" }}
                      >
                        {Array.isArray(track.artists)
                          ? track.artists.join(", ")
                          : "Artiste inconnu"}
                      </p>
                    </div>

                    {track.status === "En attente" && (
                      <div
                        className="dashboard-actions"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <button
                          className="btn btn-success btn-common"
                          onClick={(e) => {
                            e.stopPropagation();
                            changeTrackStatus(track.id, "Accepté");
                          }}
                        >
                          Accepter
                        </button>

                        {/* Bouton Refuser + menu contextuel */}
                        <div
                          style={{
                            marginLeft: "10px",
                            display: "flex",
                            position: "relative",
                          }}
                        >
                          <button
                            className="btn btn-danger btn-common"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (refusalReasons.length > 0) {
                                const defaultReason = refusalReasons[0].label;
                                changeTrackStatus(
                                  track.id,
                                  "Refusé",
                                  defaultReason
                                );
                              } else {
                                changeTrackStatus(
                                  track.id,
                                  "Refusé",
                                  "Ce morceau est refusé."
                                );
                              }
                            }}
                            onContextMenu={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setPopoverPosition({
                                x: e.clientX,
                                y: e.clientY,
                              });
                              setShowRefusalMenu(track.id);
                            }}
                            onTouchStart={(e) => handleTouchStart(e, track.id)}
                            onTouchEnd={(e) => handleTouchEnd(e, track.id)}
                            onTouchCancel={(e) => handleTouchEnd(e, track.id)}
                          >
                            Refuser
                          </button>

                          {showRefusalMenu === track.id && (
                            <div
                              className="refusal-popover"
                              style={{
                                position: "fixed",
                                top: popoverPosition.y,
                                left: popoverPosition.x,
                                transform: "translate(-50%, -50%)",
                                zIndex: 9999,
                                backgroundColor: "#333",
                                padding: "10px",
                                borderRadius: "5px",
                              }}
                            >
                              {showCustomRefusal &&
                              trackIdForCustomReason === track.id ? (
                                <>
                                  <textarea
                                    ref={customReasonRef}
                                    rows={3}
                                    value={customReason}
                                    onChange={(e) =>
                                      setCustomReason(e.target.value)
                                    }
                                    style={{
                                      width: "100%",
                                      marginBottom: "10px",
                                    }}
                                    placeholder="Entrez votre motif personnalisé..."
                                  />
                                  <button
                                    className="btn btn-sm btn-secondary"
                                    style={{ marginRight: "10px" }}
                                    onClick={() => {
                                      changeTrackStatus(
                                        track.id,
                                        "Refusé",
                                        customReason
                                      );
                                      setCustomReason("");
                                      setShowCustomRefusal(false);
                                      setTrackIdForCustomReason(null);
                                      setShowRefusalMenu(null);
                                    }}
                                  >
                                    OK
                                  </button>
                                  <button
                                    className="btn btn-sm btn-dark"
                                    onClick={() => {
                                      setCustomReason("");
                                      setShowCustomRefusal(false);
                                      setTrackIdForCustomReason(null);
                                    }}
                                  >
                                    Annuler
                                  </button>
                                </>
                              ) : (
                                <>
                                  <p>Motif du refus :</p>
                                  {refusalReasons
                                    .filter((_, idx) => idx !== 0)
                                    .map((reasonOption, idx) => (
                                      <button
                                        key={idx}
                                        onClick={() => {
                                          changeTrackStatus(
                                            track.id,
                                            "Refusé",
                                            reasonOption.label
                                          );
                                          setShowRefusalMenu(null);
                                        }}
                                        className="btn btn-sm btn-secondary"
                                        style={{
                                          margin: "5px 0",
                                          width: "100%",
                                        }}
                                      >
                                        {reasonOption.keyword}
                                      </button>
                                    ))}
                                  <button
                                    onClick={() => {
                                      setShowCustomRefusal(true);
                                      setTrackIdForCustomReason(track.id);
                                    }}
                                    className="btn btn-sm btn-warning"
                                    style={{ marginTop: "10px", width: "100%" }}
                                  >
                                    Autre...
                                  </button>
                                  <button
                                    onClick={() => setShowRefusalMenu(null)}
                                    className="btn btn-sm btn-dark"
                                    style={{ marginTop: "10px", width: "100%" }}
                                  >
                                    Annuler
                                  </button>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ))
              )}
            </div>

            {/* Colonne droite : morceaux acceptés (DJ SETLIST) */}
            <div className="col-6 dashboard-right-col">
              <div className="dashboard-section-title" disabled={planLevel < 2}>
                DJ SETLIST {planLevel < 2 && <LockedFeatureBadge />}
              </div>

              {acceptedTracks.length === 0 ? (
                <div className="dashboard-section-status">
                  Aucun morceau accepté pour le moment.
                </div>
              ) : (
                <DragDropContext
                  onDragEnd={planLevel >= 2 ? handleDragEnd : () => {}}
                >
                  <Droppable
                    droppableId="dashboard-accepted"
                    direction="vertical"
                  >
                    {(provided) => (
                      <div
                        className="my-droppable-area"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {acceptedTracks.map((track, index) => (
                          <Draggable
                            key={track.id}
                            draggableId={String(track.id)}
                            index={index}
                            isDragDisabled={planLevel < 2}
                          >
                            {(providedDrag) => (
                              <div
                                ref={providedDrag.innerRef}
                                {...providedDrag.draggableProps}
                                {...providedDrag.dragHandleProps}
                                className="dashboard-card accepted"
                              >
                                {/* Ligne haut : Titre/Artiste + boutons Joué/Refuser */}
                                <div className="dashboard-top-row">
                                  <div className="dashboard-title-artist-container">
                                    <h5
                                      className="dashboard-title"
                                      onClick={(e) =>
                                        copyToClipboard(
                                          track.artists.join(", "),
                                          track.name,
                                          e
                                        )
                                      }
                                      style={{
                                        cursor: "pointer",
                                        position: "relative",
                                      }}
                                    >
                                      {`${dynamicProposalOrderMap[track.id]}. ${
                                        track.name
                                      }`}
                                    </h5>
                                    <p
                                      className="dashboard-artist"
                                      onClick={(e) =>
                                        copyToClipboard(
                                          track.artists.join(", "),
                                          track.name,
                                          e
                                        )
                                      }
                                      style={{
                                        cursor: "pointer",
                                        position: "relative",
                                      }}
                                    >
                                      {Array.isArray(track.artists)
                                        ? track.artists.join(", ")
                                        : "Artiste inconnu"}
                                    </p>
                                  </div>

                                  {/* Boutons Joué / Refuser */}
                                  <div
                                    className="dashboard-actions"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <button
                                      className="btn btn-primary btn-common"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        changeTrackStatus(track.id, "Joué");
                                      }}
                                    >
                                      Joué
                                    </button>
                                    <div
                                      style={{
                                        marginLeft: "10px",
                                        display: "flex",
                                        position: "relative",
                                      }}
                                    >
                                      <button
                                        className="btn btn-danger btn-common"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          if (refusalReasons.length > 0) {
                                            const defaultReason =
                                              refusalReasons[0].label;
                                            changeTrackStatus(
                                              track.id,
                                              "Refusé",
                                              defaultReason
                                            );
                                          } else {
                                            changeTrackStatus(
                                              track.id,
                                              "Refusé",
                                              "Ce morceau est refusé."
                                            );
                                          }
                                        }}
                                        onContextMenu={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          setPopoverPosition({
                                            x: e.clientX,
                                            y: e.clientY,
                                          });
                                          setShowRefusalMenu(track.id);
                                        }}
                                        onTouchStart={(e) =>
                                          handleTouchStart(e, track.id)
                                        }
                                        onTouchEnd={(e) =>
                                          handleTouchEnd(e, track.id)
                                        }
                                        onTouchCancel={(e) =>
                                          handleTouchEnd(e, track.id)
                                        }
                                      >
                                        Refuser
                                      </button>
                                    </div>

                                    {showRefusalMenu === track.id && (
                                      <div
                                        className="refusal-popover"
                                        style={{
                                          position: "fixed",
                                          top: popoverPosition.y,
                                          left: popoverPosition.x,
                                          transform: "translate(-50%, -50%)",
                                          zIndex: 9999,
                                          backgroundColor: "#333",
                                          padding: "10px",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        {showCustomRefusal &&
                                        trackIdForCustomReason === track.id ? (
                                          <>
                                            <textarea
                                              ref={customReasonRef}
                                              rows={3}
                                              value={customReason}
                                              onChange={(e) =>
                                                setCustomReason(e.target.value)
                                              }
                                              style={{
                                                width: "100%",
                                                marginBottom: "10px",
                                              }}
                                              placeholder="Entrez votre motif personnalisé..."
                                            />
                                            <button
                                              className="btn btn-sm btn-secondary"
                                              style={{ marginRight: "10px" }}
                                              onClick={() => {
                                                changeTrackStatus(
                                                  track.id,
                                                  "Refusé",
                                                  customReason
                                                );
                                                setCustomReason("");
                                                setShowCustomRefusal(false);
                                                setTrackIdForCustomReason(null);
                                                setShowRefusalMenu(null);
                                              }}
                                            >
                                              OK
                                            </button>
                                            <button
                                              className="btn btn-sm btn-dark"
                                              onClick={() => {
                                                setCustomReason("");
                                                setShowCustomRefusal(false);
                                                setTrackIdForCustomReason(null);
                                              }}
                                            >
                                              Annuler
                                            </button>
                                          </>
                                        ) : (
                                          <>
                                            <p>Motif du refus :</p>
                                            {refusalReasons
                                              .filter((_, idx) => idx !== 0)
                                              .map((reasonOption, idx) => (
                                                <button
                                                  key={idx}
                                                  onClick={() => {
                                                    changeTrackStatus(
                                                      track.id,
                                                      "Refusé",
                                                      reasonOption.label
                                                    );
                                                    setShowRefusalMenu(null);
                                                  }}
                                                  className="btn btn-sm btn-secondary"
                                                  style={{
                                                    margin: "5px 0",
                                                    width: "100%",
                                                  }}
                                                >
                                                  {reasonOption.keyword}
                                                </button>
                                              ))}
                                            <button
                                              onClick={() => {
                                                setShowCustomRefusal(true);
                                                setTrackIdForCustomReason(
                                                  track.id
                                                );
                                              }}
                                              className="btn btn-sm btn-warning"
                                              style={{
                                                marginTop: "10px",
                                                width: "100%",
                                              }}
                                            >
                                              Autre...
                                            </button>
                                            <button
                                              onClick={() =>
                                                setShowRefusalMenu(null)
                                              }
                                              className="btn btn-sm btn-dark"
                                              style={{
                                                marginTop: "10px",
                                                width: "100%",
                                              }}
                                            >
                                              Annuler
                                            </button>
                                          </>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>

                                {/* Sélecteur de genres */}
                                <GenreSelector
                                  track={track}
                                  onUpdateStyles={async (
                                    trackId,
                                    newStyles
                                  ) => {
                                    if (!eventId) {
                                      console.error(
                                        "Aucun eventId fourni. Impossible de mettre à jour les styles."
                                      );
                                      return;
                                    }
                                    await updateTrackStyles(
                                      trackId,
                                      eventId,
                                      newStyles
                                    );
                                    updateAllSuggestions();
                                  }}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
            </div>
          </div>

          {/* Barre dépliante : morceaux refusés */}
          <div
            className={showRefusedTracks ? "refused-bar open" : "refused-bar"}
          >
            <div
              onClick={() => setShowRefusedTracks((prev) => !prev)}
              style={{
                cursor: "pointer",
                padding: "5px",
                backgroundColor: "#333",
              }}
            >
              {showRefusedTracks
                ? `Morceaux refusés (${refusedTracks.length}) (cliquer pour refermer)`
                : `Morceaux refusés (${refusedTracks.length}) (cliquer pour ouvrir)`}
            </div>
            {showRefusedTracks && (
              <div style={{ paddingTop: "5px" }}>
                {refusedTracks.length === 0 ? (
                  <p style={{ textAlign: "center", fontSize: "0.8rem" }}>
                    Aucun morceau refusé.
                  </p>
                ) : (
                  <div className="refused-tracks-container">
                    {refusedTracks
                      .sort(
                        (a, b) =>
                          new Date(b.updated_at) - new Date(a.updated_at)
                      )
                      .map((track, index) => (
                        <div key={index} className="refused-track-row">
                          <div className="refused-track-time">
                            {track.updated_at
                              ? formatTime(track.updated_at)
                              : "--:--:--"}
                          </div>
                          <div className="refused-track-details">
                            <div
                              className="refused-track-title-artist"
                              onClick={(e) =>
                                copyToClipboard(
                                  track.artists.join(", "),
                                  track.name,
                                  e
                                )
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <span className="refused-track-artist">
                                {track.artists.join(", ")}
                              </span>
                              {" - "}
                              <strong className="refused-track-title">
                                {track.name}
                              </strong>
                            </div>
                            <div className="refused-track-reason">
                              {track.refusal_reason
                                ? track.refusal_reason
                                : refusedText}
                            </div>
                          </div>
                          <div className="refused-track-actions">
                            <button
                              className="btn btn-secondary btn-sm"
                              onClick={() =>
                                changeTrackStatus(track.id, "En attente")
                              }
                            >
                              Annuler
                            </button>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            )}
          </div>

          {/* Barre dépliante : morceaux joués */}
          <div className={showPlayedTracks ? "played-bar open" : "played-bar"}>
            <div
              onClick={() => setShowPlayedTracks((prev) => !prev)}
              style={{
                cursor: "pointer",
                padding: "5px",
                backgroundColor: "#444",
              }}
            >
              {showPlayedTracks
                ? `Morceaux joués (${playedTracks.length}) (cliquer pour refermer)`
                : `Morceaux joués (${playedTracks.length}) (cliquer pour ouvrir)`}
            </div>
            {showPlayedTracks && (
              <div style={{ paddingTop: "5px" }}>
                {playedTracks.length === 0 ? (
                  <p style={{ textAlign: "center", fontSize: "0.8rem" }}>
                    Aucun morceau joué.
                  </p>
                ) : (
                  playedTracks
                    .sort(
                      (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
                    )
                    .map((track, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "5px",
                          fontSize: "0.9rem",
                        }}
                      >
                        <span
                          style={{
                            width: "80px",
                            textAlign: "right",
                            marginRight: "10px",
                            color: "#ccc",
                          }}
                        >
                          {track.updated_at
                            ? formatTime(track.updated_at)
                            : "--:--:--"}
                        </span>
                        <div style={{ flexGrow: 1 }}>
                          <span
                            onClick={(e) =>
                              copyToClipboard(
                                track.artists.join(", "),
                                track.name,
                                e
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {track.artists.join(", ")}
                          </span>
                          {" - "}
                          <strong
                            onClick={(e) =>
                              copyToClipboard(
                                track.artists.join(", "),
                                track.name,
                                e
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {track.name}
                          </strong>
                        </div>
                        <button
                          className="btn btn-secondary btn-sm"
                          onClick={(e) => {
                            e.stopPropagation();
                            changeTrackStatus(track.id, "Accepté");
                          }}
                          style={{ marginLeft: "10px" }}
                        >
                          Annuler
                        </button>
                      </div>
                    ))
                )}
              </div>
            )}
          </div>
        </>
      )}

      {/* ---------------------------------------------------------------------
         Vue Mobile : onglets "Morceaux proposés" / "DJ Setlist"
         --------------------------------------------------------------------- */}
      {isMobile && (
        <div className="mobile-tabs-container">
          <div
            className="tabs-switcher"
            style={{ display: "flex", marginBottom: "10px" }}
          >
            <button
              style={{
                flex: 1,
                padding: "10px",
                backgroundColor:
                  mobileActiveTab === "proposed" ? "#444" : "#222",
                color: "#fff",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => setMobileActiveTab("proposed")}
            >
              Morceaux proposés
            </button>
            <button
              style={{
                flex: 1,
                padding: "10px",
                backgroundColor:
                  mobileActiveTab === "djsetlist" ? "#444" : "#222",
                color: "#fff",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => setMobileActiveTab("djsetlist")}
            >
              DJ Setlist
            </button>
          </div>

          {/* Onglet : Morceaux proposés */}
          {mobileActiveTab === "proposed" && (
            <>
              <div className="dashboard-section-title">Morceaux proposés</div>
              {pendingTracks.length === 0 ? (
                <div className="dashboard-section-status">
                  En attente de suggestions.
                </div>
              ) : (
                pendingTracks.map((track, index) => (
                  <div key={track.id} className="dashboard-card">
                    <div className="dashboard-content">
                      <h5
                        className="dashboard-title"
                        onClick={(e) =>
                          copyToClipboard(
                            track.artists.join(", "),
                            track.name,
                            e
                          )
                        }
                        style={{ cursor: "pointer", position: "relative" }}
                      >
                        {index + 1}. {track.name}
                      </h5>
                      <p
                        className="dashboard-artist"
                        onClick={(e) =>
                          copyToClipboard(
                            track.artists.join(", "),
                            track.name,
                            e
                          )
                        }
                        style={{ cursor: "pointer", position: "relative" }}
                      >
                        {Array.isArray(track.artists)
                          ? track.artists.join(", ")
                          : "Artiste inconnu"}
                      </p>
                    </div>

                    {track.status === "En attente" && (
                      <div
                        className="dashboard-actions"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          marginTop: "10px",
                        }}
                      >
                        <button
                          className="btn btn-success btn-common"
                          onClick={(e) => {
                            e.stopPropagation();
                            changeTrackStatus(track.id, "Accepté");
                          }}
                        >
                          Accepter
                        </button>

                        <button
                          className="btn btn-danger btn-common"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (refusalReasons.length > 0) {
                              const defaultReason = refusalReasons[0].label;
                              changeTrackStatus(
                                track.id,
                                "Refusé",
                                defaultReason
                              );
                            } else {
                              changeTrackStatus(
                                track.id,
                                "Refusé",
                                "Ce morceau est refusé."
                              );
                            }
                          }}
                          onContextMenu={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setPopoverPosition({ x: e.clientX, y: e.clientY });
                            setShowRefusalMenu(track.id);
                          }}
                          onTouchStart={(e) => handleTouchStart(e, track.id)}
                          onTouchEnd={(e) => handleTouchEnd(e, track.id)}
                          onTouchCancel={(e) => handleTouchEnd(e, track.id)}
                        >
                          Refuser
                        </button>

                        {showRefusalMenu === track.id && (
                          <div
                            className="refusal-popover"
                            style={{
                              position: "fixed",
                              top: popoverPosition.y,
                              left: popoverPosition.x,
                              transform: "translate(-50%, -50%)",
                              zIndex: 9999,
                              backgroundColor: "#333",
                              padding: "10px",
                              borderRadius: "5px",
                            }}
                          >
                            {showCustomRefusal &&
                            trackIdForCustomReason === track.id ? (
                              <>
                                <textarea
                                  ref={customReasonRef}
                                  rows={3}
                                  value={customReason}
                                  onChange={(e) =>
                                    setCustomReason(e.target.value)
                                  }
                                  style={{
                                    width: "100%",
                                    marginBottom: "10px",
                                  }}
                                  placeholder="Entrez votre motif personnalisé..."
                                />
                                <button
                                  className="btn btn-sm btn-secondary"
                                  style={{ marginRight: "10px" }}
                                  onClick={() => {
                                    changeTrackStatus(
                                      track.id,
                                      "Refusé",
                                      customReason
                                    );
                                    setCustomReason("");
                                    setShowCustomRefusal(false);
                                    setTrackIdForCustomReason(null);
                                    setShowRefusalMenu(null);
                                  }}
                                >
                                  OK
                                </button>
                                <button
                                  className="btn btn-sm btn-dark"
                                  onClick={() => {
                                    setCustomReason("");
                                    setShowCustomRefusal(false);
                                    setTrackIdForCustomReason(null);
                                  }}
                                >
                                  Annuler
                                </button>
                              </>
                            ) : (
                              <>
                                <p>Motif du refus :</p>
                                {refusalReasons
                                  .filter((_, idx) => idx !== 0)
                                  .map((reasonOption, idx) => (
                                    <button
                                      key={idx}
                                      onClick={() => {
                                        changeTrackStatus(
                                          track.id,
                                          "Refusé",
                                          reasonOption.label
                                        );
                                        setShowRefusalMenu(null);
                                      }}
                                      className="btn btn-sm btn-secondary"
                                      style={{ margin: "5px 0", width: "100%" }}
                                    >
                                      {reasonOption.keyword}
                                    </button>
                                  ))}
                                <button
                                  onClick={() => {
                                    setShowCustomRefusal(true);
                                    setTrackIdForCustomReason(track.id);
                                  }}
                                  className="btn btn-sm btn-warning"
                                  style={{ marginTop: "10px", width: "100%" }}
                                >
                                  Autre...
                                </button>
                                <button
                                  onClick={() => setShowRefusalMenu(null)}
                                  className="btn btn-sm btn-dark"
                                  style={{ marginTop: "10px", width: "100%" }}
                                >
                                  Annuler
                                </button>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))
              )}

              {/* Barre dépliante : morceaux refusés */}
              <div
                className={
                  showRefusedTracks ? "refused-bar open" : "refused-bar"
                }
              >
                <div
                  onClick={() => setShowRefusedTracks((prev) => !prev)}
                  style={{
                    cursor: "pointer",
                    padding: "5px",
                    backgroundColor: "#333",
                  }}
                >
                  {showRefusedTracks
                    ? `Morceaux refusés (${refusedTracks.length}) (cliquer pour refermer)`
                    : `Morceaux refusés (${refusedTracks.length}) (cliquer pour ouvrir)`}
                </div>
                {showRefusedTracks && (
                  <div style={{ paddingTop: "5px" }}>
                    {refusedTracks.length === 0 ? (
                      <p style={{ textAlign: "center", fontSize: "0.8rem" }}>
                        Aucun morceau refusé.
                      </p>
                    ) : (
                      <div className="refused-tracks-container">
                        {refusedTracks
                          .sort(
                            (a, b) =>
                              new Date(b.updated_at) - new Date(a.updated_at)
                          )
                          .map((track, index) => (
                            <div key={index} className="refused-track-row">
                              <div className="refused-track-time">
                                {track.updated_at
                                  ? formatTime(track.updated_at)
                                  : "--:--:--"}
                              </div>
                              <div className="refused-track-details">
                                <div
                                  className="refused-track-title-artist"
                                  onClick={(e) =>
                                    copyToClipboard(
                                      track.artists.join(", "),
                                      track.name,
                                      e
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <span className="refused-track-artist">
                                    {track.artists.join(", ")}
                                  </span>
                                  {" - "}
                                  <strong className="refused-track-title">
                                    {track.name}
                                  </strong>
                                </div>
                                <div className="refused-track-reason">
                                  {track.refusal_reason
                                    ? track.refusal_reason
                                    : refusedText}
                                </div>
                              </div>
                              <div className="refused-track-actions">
                                <button
                                  className="btn btn-secondary btn-sm"
                                  onClick={() =>
                                    changeTrackStatus(track.id, "En attente")
                                  }
                                >
                                  Annuler
                                </button>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </>
          )}

          {/* Onglet : DJ Setlist */}
          {mobileActiveTab === "djsetlist" && (
            <>
              <div className="dashboard-section-title" disabled={planLevel < 2}>
                DJ SETLIST {planLevel < 2 && <LockedFeatureBadge />}
              </div>
              {acceptedTracks.length === 0 ? (
                <div className="dashboard-section-status">
                  Aucun morceau accepté pour le moment.
                </div>
              ) : (
                <DragDropContext
                  onDragEnd={planLevel >= 2 ? handleDragEnd : () => {}}
                >
                  <Droppable
                    droppableId="dashboard-accepted"
                    direction="vertical"
                  >
                    {(provided) => (
                      <div
                        className="my-droppable-area"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {acceptedTracks.map((track, index) => (
                          <Draggable
                            key={track.id}
                            draggableId={String(track.id)}
                            index={index}
                            isDragDisabled={planLevel < 2}
                          >
                            {(providedDrag) => (
                              <div
                                ref={providedDrag.innerRef}
                                {...providedDrag.draggableProps}
                                {...providedDrag.dragHandleProps}
                                className="dashboard-card accepted"
                              >
                                <div className="dashboard-content">
                                  <h5
                                    className="dashboard-title"
                                    onClick={(e) =>
                                      copyToClipboard(
                                        track.artists.join(", "),
                                        track.name,
                                        e
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    {`${dynamicProposalOrderMap[track.id]}. ${
                                      track.name
                                    }`}
                                  </h5>
                                  <p
                                    className="dashboard-artist"
                                    onClick={(e) =>
                                      copyToClipboard(
                                        track.artists.join(", "),
                                        track.name,
                                        e
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    {Array.isArray(track.artists)
                                      ? track.artists.join(", ")
                                      : "Artiste inconnu"}
                                  </p>
                                </div>

                                {/* Boutons d’actions (Joué / Refuser) */}
                                <div
                                  className="dashboard-actions"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                    marginTop: "10px",
                                  }}
                                >
                                  <button
                                    className="btn btn-primary btn-common"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      changeTrackStatus(track.id, "Joué");
                                    }}
                                  >
                                    Joué
                                  </button>

                                  <button
                                    className="btn btn-danger btn-common"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (refusalReasons.length > 0) {
                                        const defaultReason =
                                          refusalReasons[0].label;
                                        changeTrackStatus(
                                          track.id,
                                          "Refusé",
                                          defaultReason
                                        );
                                      } else {
                                        changeTrackStatus(
                                          track.id,
                                          "Refusé",
                                          "Ce morceau est refusé."
                                        );
                                      }
                                    }}
                                    onContextMenu={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setPopoverPosition({
                                        x: e.clientX,
                                        y: e.clientY,
                                      });
                                      setShowRefusalMenu(track.id);
                                    }}
                                    onTouchStart={(e) =>
                                      handleTouchStart(e, track.id)
                                    }
                                    onTouchEnd={(e) =>
                                      handleTouchEnd(e, track.id)
                                    }
                                    onTouchCancel={(e) =>
                                      handleTouchEnd(e, track.id)
                                    }
                                  >
                                    Refuser
                                  </button>

                                  {showRefusalMenu === track.id && (
                                    <div
                                      className="refusal-popover"
                                      style={{
                                        position: "fixed",
                                        top: popoverPosition.y,
                                        left: popoverPosition.x,
                                        transform: "translate(-50%, -50%)",
                                        zIndex: 9999,
                                        backgroundColor: "#333",
                                        padding: "10px",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      {showCustomRefusal &&
                                      trackIdForCustomReason === track.id ? (
                                        <>
                                          <textarea
                                            ref={customReasonRef}
                                            rows={3}
                                            value={customReason}
                                            onChange={(e) =>
                                              setCustomReason(e.target.value)
                                            }
                                            style={{
                                              width: "100%",
                                              marginBottom: "10px",
                                            }}
                                            placeholder="Entrez votre motif personnalisé..."
                                          />
                                          <button
                                            className="btn btn-sm btn-secondary"
                                            style={{ marginRight: "10px" }}
                                            onClick={() => {
                                              changeTrackStatus(
                                                track.id,
                                                "Refusé",
                                                customReason
                                              );
                                              setCustomReason("");
                                              setShowCustomRefusal(false);
                                              setTrackIdForCustomReason(null);
                                              setShowRefusalMenu(null);
                                            }}
                                          >
                                            OK
                                          </button>
                                          <button
                                            className="btn btn-sm btn-dark"
                                            onClick={() => {
                                              setCustomReason("");
                                              setShowCustomRefusal(false);
                                              setTrackIdForCustomReason(null);
                                            }}
                                          >
                                            Annuler
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          <p>Motif du refus :</p>
                                          {refusalReasons
                                            .filter((_, idx) => idx !== 0)
                                            .map((reasonOption, idx) => (
                                              <button
                                                key={idx}
                                                onClick={() => {
                                                  changeTrackStatus(
                                                    track.id,
                                                    "Refusé",
                                                    reasonOption.label
                                                  );
                                                  setShowRefusalMenu(null);
                                                }}
                                                className="btn btn-sm btn-secondary"
                                                style={{
                                                  margin: "5px 0",
                                                  width: "100%",
                                                }}
                                              >
                                                {reasonOption.keyword}
                                              </button>
                                            ))}
                                          <button
                                            onClick={() => {
                                              setShowCustomRefusal(true);
                                              setTrackIdForCustomReason(
                                                track.id
                                              );
                                            }}
                                            className="btn btn-sm btn-warning"
                                            style={{
                                              marginTop: "10px",
                                              width: "100%",
                                            }}
                                          >
                                            Autre...
                                          </button>
                                          <button
                                            onClick={() =>
                                              setShowRefusalMenu(null)
                                            }
                                            className="btn btn-sm btn-dark"
                                            style={{
                                              marginTop: "10px",
                                              width: "100%",
                                            }}
                                          >
                                            Annuler
                                          </button>
                                        </>
                                      )}
                                    </div>
                                  )}
                                </div>
                                {/* On n’affiche pas GenreSelector en mobile dans cet exemple */}
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )}

              {/* Barre dépliante : morceaux joués */}
              <div
                className={showPlayedTracks ? "played-bar open" : "played-bar"}
              >
                <div
                  onClick={() => setShowPlayedTracks((prev) => !prev)}
                  style={{
                    cursor: "pointer",
                    padding: "5px",
                    backgroundColor: "#444",
                  }}
                >
                  {showPlayedTracks
                    ? `Morceaux joués (${playedTracks.length}) (cliquer pour refermer)`
                    : `Morceaux joués (${playedTracks.length}) (cliquer pour ouvrir)`}
                </div>
                {showPlayedTracks && (
                  <div style={{ paddingTop: "5px" }}>
                    {playedTracks.length === 0 ? (
                      <p style={{ textAlign: "center", fontSize: "0.8rem" }}>
                        Aucun morceau joué.
                      </p>
                    ) : (
                      playedTracks
                        .sort(
                          (a, b) =>
                            new Date(b.updated_at) - new Date(a.updated_at)
                        )
                        .map((track, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "5px",
                              fontSize: "0.9rem",
                            }}
                          >
                            <span
                              style={{
                                width: "80px",
                                textAlign: "right",
                                marginRight: "10px",
                                color: "#ccc",
                              }}
                            >
                              {track.updated_at
                                ? formatTime(track.updated_at)
                                : "--:--:--"}
                            </span>
                            <div style={{ flexGrow: 1 }}>
                              <span
                                onClick={(e) =>
                                  copyToClipboard(
                                    track.artists.join(", "),
                                    track.name,
                                    e
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {track.artists.join(", ")}
                              </span>
                              {" - "}
                              <strong
                                onClick={(e) =>
                                  copyToClipboard(
                                    track.artists.join(", "),
                                    track.name,
                                    e
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {track.name}
                              </strong>
                            </div>
                            <button
                              className="btn btn-secondary btn-sm"
                              onClick={(e) => {
                                e.stopPropagation();
                                changeTrackStatus(track.id, "Accepté");
                              }}
                              style={{ marginLeft: "10px" }}
                            >
                              Annuler
                            </button>
                          </div>
                        ))
                    )}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      )}

      {/* ---------------------------------------------------------------------
         Modale des paramètres (showSettingsModal)
         --------------------------------------------------------------------- */}
      {showSettingsModal && (
        <SettingsModal
        /* 1) Props liées à la modale "Paramètres" */
        isOpen={showSettingsModal}
        onClose={() => setShowSettingsModal(false)}
        setShowSettingsModal={setShowSettingsModal}
        
        activeTab={activeTab}
        setActiveTab={setActiveTab}

        /* 2) Événement : identifiants, nom, type, statut, etc. */
        eventId={eventId}
        eventName={eventName}
        setEventName={setEventName}
        eventType={eventType}
        setEventType={setEventType}
        eventStatus={eventStatus}
        setEventStatus={setEventStatus}
        planLevel={planLevel}

        /* 3) Divers */
        endEventMessage={endEventMessage}
        setEndEventMessage={setEndEventMessage}
        endEventInstagram={endEventInstagram}
        setEndEventInstagram={setEndEventInstagram}
        endEventWebsite={endEventWebsite}
        setEndEventWebsite={setEndEventWebsite}
        showEndpageStats={showEndpageStats}
        setShowEndpageStats={setShowEndpageStats}
        waitingMessage={waitingMessage}
        setWaitingMessage={setWaitingMessage}

        authenticationEnabled={authenticationEnabled}
        setAuthenticationEnabled={setAuthenticationEnabled}
        autoStartEnabled={autoStartEnabled}
        setAutoStartEnabled={setAutoStartEnabled}
        eventStartTimeLocal={eventStartTimeLocal}
        setEventStartTimeLocal={setEventStartTimeLocal}

        cooldownDuration={cooldownDuration}
        setCooldownDuration={setCooldownDuration}
        refusedText={refusedText}
        setRefusedText={setRefusedText}

        /* 4) Motifs de refus */
        refusalReasons={refusalReasons}
        setRefusalReasons={setRefusalReasons}
        refusalReasonInputs={refusalReasonInputs}
        setRefusalReasonInputs={setRefusalReasonInputs}

        /* 5) Fonctions utilitaires */
        convertLocalToUTC={convertLocalToUTC}

        /* 6) Props pour la modale "Reset" */
        showModal={showModal}
        setShowModal={setShowModal}
        handleReset={handleReset}
      />
      )}
    </div>
  );
}

export default Dashboard;
