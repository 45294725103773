// src/pages/UpdatesPage.js
import React from 'react';
import Layout from '../components/Layout';
import '../styles/UpdatesPage.css'; // Créez ce fichier CSS pour styliser la page si besoin
import { Helmet } from 'react-helmet';
import { updates } from '../data/updatesData';

function UpdatesPage() {
  return (

    <Layout>
            <Helmet>
        <title>Mixify - Nouveautés</title>
        <meta
          name="description"
          content="Découvrez les nouveautés apportées lors de la dernière mise à jour de notre application."
        />
      </Helmet>
      <div className="updates-container" style={containerStyle}>
        
        <h1>Mises à jour de Mixify</h1>
        
        <p>Voici les dernières modifications apportées à Mixify :</p>
        {updates.versionNotes.map((versionData, idx) => (
          <section key={idx}>
            <h2>Version {versionData.version}</h2>
            <ul>
              {versionData.notes.map((note, index) => (
                <li key={index}>{note}</li>
              ))}
            </ul>
          </section>
        ))}
        <section>
          <h2>À venir</h2>
          <ul>
            {updates.upcoming.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </section>
      </div>
    </Layout>
  );
}

// Exemple de style en ligne (vous pouvez transférer ces styles dans UpdatesPage.css)
const containerStyle = {
  padding: '2rem',
};

export default UpdatesPage;