// /src/utils/spotifyPlaylist.js
import axios from "axios";

/**
 * Ajoute un morceau à une playlist Spotify.
 * @param {string} spotifyTrackId - L’ID Spotify du morceau (il faut que ce soit l’ID Spotify réel).
 * @param {string} playlistId - L’ID de la playlist dans laquelle ajouter le morceau.
 * @param {string} accessToken - Le token d’accès de l’utilisateur Spotify.
 * @returns {Promise<Object>} La réponse de l’API Spotify.
 */

/**
 * Récupère les informations de l'utilisateur Spotify authentifié.
 * @param {string} accessToken - Le token d'accès Spotify.
 * @returns {Promise<Object>} Les informations de l'utilisateur, notamment l'id.
 */
export async function getSpotifyUserProfile(accessToken) {
  const response = await axios.get("https://api.spotify.com/v1/me", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.status !== 200) {
    throw new Error("Erreur lors de la récupération du profil Spotify");
  }
  return response.data; // Contient l'id, le display_name, etc.
}

export async function createPlaylist(
  userId,
  playlistName,
  accessToken,
  eventName
) {
  const url = `https://api.spotify.com/v1/users/${userId}/playlists`;
  const body = {
    name: playlistName,
    description: `Playlist générée automatiquement par Mixify pour l'événement : ${eventName}`,
    public: false, // ou true selon vos préférences
  };

  const response = await axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });

  if (response.status !== 201 && response.status !== 200) {
    throw new Error("Erreur lors de la création de la playlist");
  }

  return response.data; // Contient notamment response.data.id
}

export async function addTrackToPlaylist(
  spotifyTrackId,
  playlistId,
  accessToken
) {
  const url = `https://api.spotify.com/v1/playlists/${playlistId}/tracks`;
  const body = {
    uris: [`spotify:track:${spotifyTrackId}`],
  };

  const response = await axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });

  if (response.status !== 201 && response.status !== 200) {
    throw new Error("Erreur lors de l’ajout du morceau à la playlist");
  }

  return response.data;
}

// Met à jour les détails d'une playlist Spotify
export async function updatePlaylistDetails(
  playlistId,
  newName,
  newDescription,
  accessToken
) {
  const url = `https://api.spotify.com/v1/playlists/${playlistId}`;
  const body = {
    name: newName,
    description: newDescription,
  };

  const response = await axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });

  if (response.status !== 200) {
    throw new Error("Erreur lors de la mise à jour de la playlist");
  }
  return response.data;
}

export async function refreshSpotifyAccessToken(refreshToken) {
  const response = await axios.get("/api/spotify-refresh", {
    params: { refresh_token: refreshToken },
  });
  if (response.status !== 200) {
    throw new Error("Erreur lors du rafraîchissement du token");
  }
  return response.data.access_token;
}

/**
 * Récupère la liste de tous les morceaux (et leur ordre) dans une playlist Spotify.
 * @param {string} playlistId - L'ID de la playlist Spotify.
 * @param {string} accessToken - Le token d'accès Spotify (doit inclure playlist-read-private).
 * @returns {Promise<Array>} Un tableau des items (chaque item contient la position et track.id).
 */
export async function getPlaylistTracks(playlistId, accessToken) {
  const url = `https://api.spotify.com/v1/playlists/${playlistId}/tracks`;

  // Pour simplifier, on ne gère pas de pagination ici (limité à 100 tracks max).
  // Si vous avez plus de 100 morceaux, il faudra faire un fetch paginé en boucle.
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      limit: 100,
      offset: 0,
    },
  });

  if (response.status !== 200) {
    throw new Error("Erreur lors de la récupération des pistes de la playlist");
  }

  // response.data.items est un tableau. Chaque item contient un champ "track"
  // qui lui-même contient "id", "name", etc.
  // On renvoie directement ce tableau.
  return response.data.items;
}

/**
 * Récupère le morceau actuellement joué sur le compte Spotify (appareil).
 * @param {string} accessToken - Le token d'accès Spotify (doit inclure user-read-playback-state).
 * @returns {Promise<Object|null>} Les infos du morceau en cours, ou null si rien ne joue.
 */
export async function getCurrentlyPlayingTrack(accessToken) {
  const url = "https://api.spotify.com/v1/me/player/currently-playing";

  // Par défaut, ce endpoint renvoie 204 si rien ne joue.
  const response = await axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .catch((err) => {
      // S'il y a une erreur 204 ou 401, on la gère
      if (err.response && err.response.status === 204) {
        // 204 => pas de contenu => pas de track en lecture
        return { status: 204, data: null };
      }
      throw err;
    });

  if (response.status === 200 && response.data && response.data.item) {
    // Le champ "item" contient le track
    return response.data.item;
    // Par exemple: { id: "3n3Ppam7vgaVa1iaRUc9Lp", name: "track name", ... }
  } else {
    return null;
  }
}

/**
 * Met à jour l'image de couverture d'une playlist Spotify.
 * @param {string} playlistId - L'ID de la playlist.
 * @param {string} imageBase64 - L'image encodée en base64 (sans le préfixe data:image/jpeg;base64,).
 * @param {string} accessToken - Le token d'accès Spotify.
 * @returns {Promise<void>}
 */
export async function updatePlaylistCoverImage(
  playlistId,
  imageBase64,
  accessToken
) {
  const url = `https://api.spotify.com/v1/playlists/${playlistId}/images`;
  const response = await axios.put(url, imageBase64, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "image/jpeg",
    },
  });

  if (response.status !== 202) {
    throw new Error(
      "Erreur lors de la mise à jour de l'image de couverture de la playlist"
    );
  }
}

/**
 * Supprime un morceau d'une playlist Spotify.
 * @param {string} playlistId - L'ID de la playlist.
 * @param {string} trackId - L'ID Spotify du morceau à retirer.
 * @param {string} accessToken - Le token d'accès Spotify.
 * @returns {Promise<Object>} La réponse de l’API Spotify.
 */
export async function removeTrackFromPlaylist(
  playlistId,
  trackId,
  accessToken
) {
  const url = `https://api.spotify.com/v1/playlists/${playlistId}/tracks`;
  const body = {
    tracks: [{ uri: `spotify:track:${trackId}` }],
  };

  const response = await axios({
    method: "DELETE",
    url,
    data: body,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });

  // Selon la documentation, le code de retour attendu peut être 200 ou 202.
  if (response.status !== 200 && response.status !== 202) {
    throw new Error("Erreur lors du retrait du morceau de la playlist");
  }
  return response.data;
}

export async function addTrackToQueue(trackUri, accessToken) {
  const url = `https://api.spotify.com/v1/me/player/queue?uri=${encodeURIComponent(
    trackUri
  )}`;
  const response = await axios.post(url, null, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });
  // Accepter 200, 202 ou 204 comme codes de succès
  if (![200, 202, 204].includes(response.status)) {
    throw new Error("Erreur lors de l’ajout du morceau à la file d’attente");
  }
  return response.data;
}
