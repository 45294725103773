/**
 * Découpe le texte en lignes tenant dans `maxWidth`.
 * Retourne un tableau de lignes (strings).
 */
function getWrappedLines(ctx, text, maxWidth) {
    const words = text.split(' ');
    const lines = [];
    let currentLine = words[0];
  
    for (let i = 1; i < words.length; i++) {
      const word = words[i];
      const testLine = currentLine + ' ' + word;
      const testWidth = ctx.measureText(testLine).width;
  
      if (testWidth > maxWidth) {
        // La ligne serait trop large, on la stocke et on démarre une nouvelle
        lines.push(currentLine);
        currentLine = word;
      } else {
        currentLine = testLine;
      }
    }
    // Pousse la dernière ligne
    lines.push(currentLine);
  
    return lines;
  }
  
  /**
   * Dessine du texte multi-ligne centré (horizontal + vertical) dans le canvas.
   * - ctx : le contexte 2D
   * - text : le texte complet à dessiner
   * - centerX, centerY : le point de centre (souvent canvas.width/2, canvas.height/2)
   * - maxWidth : la largeur max autorisée pour chaque ligne
   * - lineHeight : l'espacement vertical entre chaque ligne
   */
  function drawWrappedTextCentered(ctx, text, centerX, centerY, maxWidth, lineHeight) {
    // 1) On découpe le texte en lignes
    const lines = getWrappedLines(ctx, text, maxWidth);
  
    // 2) Hauteur totale de toutes les lignes
    const totalHeight = lines.length * lineHeight;
  
    // 3) On calcule le Y de départ pour que l'ensemble soit centré verticalement
    let y = centerY - totalHeight / 2 + lineHeight / 2;
  
    // 4) Dessiner chaque ligne, centrée horizontalement
    lines.forEach(line => {
      ctx.fillText(line, centerX, y);
      y += lineHeight;
    });
  }

export async function generateCoverImageWithText(base64Bg, text) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      
      // Remettre le préfixe si nécessaire
      const hasPrefix = base64Bg.startsWith('data:image');
      image.src = hasPrefix ? base64Bg : `data:image/jpeg;base64,${base64Bg}`;
  
      image.onload = () => {
        // Création du canvas
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        const ctx = canvas.getContext('2d');
  
        // Dessiner l'image de fond
        ctx.drawImage(image, 0, 0);
  
        // Configurer la police (ex : noire, épaisse)
        ctx.font = "700 48px 'Poppins', sans-serif";
        ctx.fillStyle = "#000000";
        ctx.textAlign = 'center';
        // On va gérer le centrage vertical "manuellement" via la fonction drawWrappedTextCentered
        ctx.textBaseline = 'alphabetic'; // On peut mettre 'top' si on le souhaite, mais on va calculer le Y nous-mêmes
  
        // Convertir le texte en majuscules
        const textUpper = text.toUpperCase();
  
        // Choisir une largeur max pour le texte
        // Ex : 80% de la largeur du canvas
        const maxTextWidth = canvas.width * 0.8;
        const lineHeight = 60; // Ajustez selon la taille de la police
  
        // Dessiner le texte avec retour à la ligne si besoin
        drawWrappedTextCentered(
          ctx, 
          textUpper, 
          canvas.width / 2,   // centre X 
          canvas.height / 2 + 55,  // centre Y
          maxTextWidth,
          lineHeight
        );
  
        // Récupérer l'image finale en base64
        const newDataUrl = canvas.toDataURL('image/jpeg');
        const newBase64 = newDataUrl.split(',')[1];
        resolve(newBase64);
      };
  
      image.onerror = (err) => reject(err);
    });
  }