// src/pages/ChangePasswordPage.js
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../utils/supabaseClient';
import '../styles/Auth.css'; // Utilise les mêmes styles que les pages d'authentification
import '../styles/ChangePasswordPage.css'; // Assurez-vous de créer ce fichier pour les styles spécifiques
import { Link } from 'react-router-dom';
import UserMenu from '../components/UserMenu';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Logo from '../components/Logo'

function ChangePasswordPage() {
  const navigate = useNavigate();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [isCurrentPasswordValid, setIsCurrentPasswordValid] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordCriteriaMet, setPasswordCriteriaMet] = useState(false);  

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPasswordInput, setShowNewPasswordInput] = useState(false);
  const [showConfirmPasswordInput, setShowConfirmPasswordInput] = useState(false); 

  const validatePassword = (password) => {
    const minLength = 8;
    const uppercase = /[A-Z]/;
    const lowercase = /[a-z]/;
    const number = /[0-9]/;
    const specialChar = /[!@#$%^&*(),.?":{}|<>]/;
  
    if (password.length < minLength) {
      return `Le mot de passe doit contenir au moins ${minLength} caractères.`;
    }
    if (!uppercase.test(password)) {
      return 'Le mot de passe doit contenir au moins une lettre majuscule.';
    }
    if (!lowercase.test(password)) {
      return 'Le mot de passe doit contenir au moins une lettre minuscule.';
    }
    if (!number.test(password)) {
      return 'Le mot de passe doit contenir au moins un chiffre.';
    }
    if (!specialChar.test(password)) {
      return 'Le mot de passe doit contenir au moins un caractère spécial.';
    }
    return '';
  };

  const reAuthenticateUser = async (email, password) => {
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    return error;
  };

  const debounceTimeout = useRef(null);

  useEffect(() => {
    // Réinitialiser les états si le mot de passe actuel est vide
    if (currentPassword === '') {
      setIsCurrentPasswordValid(false);
      setShowNewPassword(false);
      setShowConfirmPassword(false);
      setErrorMessage('');
      return;
    }
  
    // Définir un délai de 500ms après lequel la validation est effectuée
    debounceTimeout.current = setTimeout(() => {
      const validateCurrentPassword = async () => {
        setLoading(true);
        setErrorMessage('');
        try {
          const { data: { session }, error: sessionError } = await supabase.auth.getSession();
  
          if (sessionError) {
            setErrorMessage(sessionError.message);
            setIsCurrentPasswordValid(false);
            setShowNewPassword(false);
            setShowConfirmPassword(false);
            return;
          }
  
          if (!session || !session.user) {
            setErrorMessage('Vous devez être connecté pour changer votre mot de passe.');
            setIsCurrentPasswordValid(false);
            setShowNewPassword(false);
            setShowConfirmPassword(false);
            return;
          }
  
          const signInError = await reAuthenticateUser(session.user.email, currentPassword);
  
          if (signInError) {
            setErrorMessage('Le mot de passe actuel est incorrect.');
            setIsCurrentPasswordValid(false);
            setShowNewPassword(false);
            setShowConfirmPassword(false);
          } else {
            setIsCurrentPasswordValid(true);
            setShowNewPassword(true);
          }
        } catch (err) {
          setErrorMessage(err.message);
          setIsCurrentPasswordValid(false);
          setShowNewPassword(false);
          setShowConfirmPassword(false);
        } finally {
          setLoading(false);
        }
      };
  
      validateCurrentPassword();
    }, 500); // 500ms de délai
  
    // Nettoyage du timeout si currentPassword change avant la fin du délai
    return () => {
      clearTimeout(debounceTimeout.current);
    };
  }, [currentPassword]);


  const handleChangePassword = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');
  
    // Validation progressive des champs
    if (!isCurrentPasswordValid) {
      setErrorMessage('Veuillez entrer un mot de passe actuel valide.');
      return;
    }
  
    if (!newPassword || !confirmNewPassword) {
      setErrorMessage('Veuillez remplir tous les champs.');
      return;
    }
  
    if (newPassword !== confirmNewPassword) {
      setErrorMessage('Les nouveaux mots de passe ne correspondent pas.');
      return;
    }
  
    const passwordError = validatePassword(newPassword);
    if (passwordError) {
      setErrorMessage(passwordError);
      return;
    }
  
    setLoading(true);
  
    try {
      // Mettre à jour le mot de passe
      const { error: updateError } = await supabase.auth.updateUser({
        password: newPassword,
      });
  
      if (updateError) {
        setErrorMessage(updateError.message);
        setLoading(false);
        return;
      }
  
      setSuccessMessage('Votre mot de passe a été mis à jour avec succès.');
      
      // Optionnel : rediriger après un délai
      setTimeout(() => {
        navigate('/profile');
      }, 3000);
  
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setLoading(false);
    }
  };

  

  useEffect(() => {
    const error = validatePassword(newPassword);
    if (!error && newPassword) {
      setPasswordCriteriaMet(true);
      setShowConfirmPassword(true);
    } else {
      setPasswordCriteriaMet(false);
      setShowConfirmPassword(false);
    }
  }, [newPassword]);
  return (
    <div className="auth-page">
      <Logo />
    <div className="auth-container">
        <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
      <UserMenu />
    </div>
      <h2>Changer le mot de passe</h2>
      <form onSubmit={handleChangePassword}>
  {/* Champ Mot de passe actuel */}
  <div className="field-container fade-in">
    <label htmlFor="currentPassword">Mot de passe actuel</label>
    <div className="password-input-container">
      <input
        id="currentPassword"
        type={showCurrentPassword ? 'text' : 'password'}
        placeholder="••••••"
        value={currentPassword}
        onChange={(e) => setCurrentPassword(e.target.value)}
        required
        aria-required="true"
      />
      <span
        className="password-toggle-icon"
        onClick={() => setShowCurrentPassword(!showCurrentPassword)}
        aria-label={showCurrentPassword ? 'Masquer le mot de passe' : 'Afficher le mot de passe'}
        role="button"
        tabIndex={0}
        onKeyPress={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            setShowCurrentPassword(!showCurrentPassword);
          }
        }}
      >
        {showCurrentPassword ? <FaEyeSlash /> : <FaEye />}
      </span>
    </div>
  </div>

  {/* Champ Nouveau mot de passe - affiché conditionnellement */}
  {showNewPassword && (
    <div className="field-container fade-in">
      <label htmlFor="newPassword">Nouveau mot de passe</label>
      <div className="password-input-container">
        <input
          id="newPassword"
          type={showNewPasswordInput ? 'text' : 'password'}
          placeholder="••••••"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
          aria-describedby="passwordHelp"
        />
        <span
          className="password-toggle-icon"
          onClick={() => setShowNewPasswordInput(!showNewPasswordInput)}
          aria-label={showNewPasswordInput ? 'Masquer le mot de passe' : 'Afficher le mot de passe'}
          role="button"
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              setShowNewPasswordInput(!showNewPasswordInput);
            }
          }}
        >
          {showNewPasswordInput ? <FaEyeSlash /> : <FaEye />}
        </span>
      </div>
      {!passwordCriteriaMet && (
        <small id="passwordHelp" className="password-criteria">
          Votre mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial.
        </small>
      )}
    </div>
  )}

  {/* Champ Confirmer le nouveau mot de passe - affiché conditionnellement */}
  {showConfirmPassword && (
    <div className="field-container fade-in">
      <label htmlFor="confirmNewPassword">Confirmer le nouveau mot de passe</label>
      <div className="password-input-container">
        <input
          id="confirmNewPassword"
          type={showConfirmPasswordInput ? 'text' : 'password'}
          placeholder="••••••"
          value={confirmNewPassword}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
          required
          aria-required="true"
        />
        <span
          className="password-toggle-icon"
          onClick={() => setShowConfirmPasswordInput(!showConfirmPasswordInput)}
          aria-label={showConfirmPasswordInput ? 'Masquer le mot de passe' : 'Afficher le mot de passe'}
          role="button"
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              setShowConfirmPasswordInput(!showConfirmPasswordInput);
            }
          }}
        >
          {showConfirmPasswordInput ? <FaEyeSlash /> : <FaEye />}
        </span>
      </div>
    </div>
  )}

  {/* Lien Mot de passe oublié - affiché conditionnellement */}
  {!isCurrentPasswordValid && (
    <p className={`forgot-password ${isCurrentPasswordValid ? 'hidden' : ''}`}>
      Mot de passe oublié ? 
      <span
        className="forgot-password-link"
        onClick={() => {
          window.location.href = '/forgot-password';
        }}
      >
        Cliquez ici
      </span>
    </p>
  )}

  <button type="submit" disabled={loading}>
    {loading ? 'Mise à jour...' : 'Mettre à jour'}
  </button>
</form>

      {/* Messages d'erreur ou de succès */}
      {errorMessage && (
  <div className="auth-message error" role="alert" aria-live="assertive">
    {errorMessage}
  </div>
)}
{successMessage && (
  <div className="auth-message success" role="status" aria-live="polite">
    {successMessage}
  </div>
)}

      <div className="switch-auth-link">
        <p>
        Retourner au <Link to="/profile">profil</Link>
        </p>
      </div>
    </div>
    </div>
  );
}

export default ChangePasswordPage;