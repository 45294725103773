// src/components/AuthMenu.js
import React, { useContext } from 'react';
import { AuthContext } from '../utils/AuthContext';
import { useNavigate } from 'react-router-dom';
import UserMenu from './UserMenu';
import '../styles/ComponentsStyles/AuthMenu.css'; // On va ajouter le CSS squelette ici


function AuthMenu() {
  const { user, loading } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleLoginClick = () => {
    // Comme avant, si l’utilisateur veut se connecter, 
    // on le redirige vers /login
    navigate('/login');
  };

// 1) Si on charge encore l’auth => squelette
if (loading) {
  console.log('[AuthMenu] => loading => skeleton');
  return (
    <div className="auth-menu-skeleton">
      <div className="skeleton-circle" />
      <div className="skeleton-button" />
    </div>
  );
}

// 2) Si pas de user => bouton Connexion
if (!user) {
  console.log('[AuthMenu] => user=null => bouton Connexion/Inscription');
  return (
    <button onClick={handleLoginClick} className="auth-menu-login-btn">
      <span className="auth-menu-text">Connexion / Inscription</span>
      <span className="auth-menu-icon">
        <i className="fas fa-user"></i>
      </span>
    </button>
  );
}

// 3) Sinon => user existe => <UserMenu />
console.log('[AuthMenu] => user OK => <UserMenu />');
return <UserMenu user={user} />;
}

export default AuthMenu;