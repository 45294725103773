// src/pages/ProfilePage.js
import React, { useEffect, useState } from "react";
import { supabase } from "../utils/supabaseClient";
import "../styles/ComponentsStyles/UserMenu.css";
import "../styles/Auth.css";
import "../styles/ProfilePage.css"; // Styles mis à jour pour le layout responsive
import SubscriptionBanner from "../components/SubscriptionBanner";
import { Link } from "react-router-dom";
import Layout from "../components/Layout";
import AvatarUploader from "../components/AvatarUploader";

function LoadingIndicator() {
  return (
    <div className="spinner-container">
      <div className="spinner"></div>
    </div>
  );
}

function ProfilePage() {
  const [profile, setProfile] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);

  // Champs modifiables côté "profiles"
  const [username, setUsername] = useState("");
  // Champs lecture seule
  const [planType, setPlanType] = useState("dj");
  const [planStatus, setPlanStatus] = useState("active");
  // Champs pour update "auth"
  const [newEmail, setNewEmail] = useState("");

  // État pour le changement de mot de passe
  const [changePassword, setChangePassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  // Variable pour la date de fin d'abonnement
  let formattedCancelDate = "";
  if (subscription?.cancel_at) {
    const cancellationDate = new Date(subscription.cancel_at * 1000);
    formattedCancelDate = cancellationDate.toLocaleDateString("fr-FR", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  }

  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    setLoading(true);
    setErrorMessage("");
    try {
      const {
        data: { session },
        error: sessionError,
      } = await supabase.auth.getSession();
      if (sessionError) {
        setErrorMessage(sessionError.message);
        setLoading(false);
        return;
      }
      if (!session || !session.user) {
        setErrorMessage(
          "Vous devez être connecté pour accéder à votre profil."
        );
        setLoading(false);
        return;
      }
      const userId = session.user.id;
      // Récupération dans la table "profiles"
      const { data, error } = await supabase
        .from("profiles")
        .select("*")
        .eq("user_id", userId)
        .single();
      if (error) {
        setErrorMessage(error.message);
        setLoading(false);
        return;
      }
      if (data) {
        setProfile(data);
        setUsername(data.username || "");
        setPlanType(data.plan_type || "dj");
        if (data.avatar_url) {
          setAvatarUrl(data.avatar_url);
        }
      }
      // Récupération dans la table "subscriptions"
      const { data: sub, error: subError } = await supabase
        .from("subscriptions")
        .select("*")
        .eq("user_id", userId)
        .maybeSingle();
      if (subError) {
        setErrorMessage(subError.message);
        setLoading(false);
        return;
      }
      if (sub) {
        setPlanStatus(sub.status);
        setSubscription(sub);
        let determinedPlanType = "";
        switch (sub.price_id) {
          case "price_1QjSWiGRn8HctkO3lvR7RuyX":
            determinedPlanType = "DJ";
            break;
          case "price_1QhUsjGRn8HctkO3hg5iacZX":
            determinedPlanType = "DJ+";
            break;
          case "price_1QhUu3GRn8HctkO3RQzRx01K":
            determinedPlanType = "Établissement";
            break;
          default:
            determinedPlanType = "Abonnement inconnu";
        }
        setPlanType(determinedPlanType);
      } else {
        setPlanStatus("Aucun abonnement");
        setPlanType("");
      }
      if (session.user.email) {
        setNewEmail(session.user.email);
      }
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    if (!profile) return;
    setErrorMessage("");
    setLoading(true);
    try {
      if (changePassword && newPassword) {
        const { data: signInData, error: signInError } =
          await supabase.auth.signInWithPassword({
            email: newEmail,
            password: oldPassword,
          });
        if (signInError || !signInData.session) {
          throw new Error(
            "Mot de passe actuel incorrect. Si vous l'avez oublié, cliquez sur 'Mot de passe oublié ?'."
          );
        }
      }
      const updates = {};
      if (newEmail) updates.email = newEmail.trim();
      if (changePassword && newPassword) updates.password = newPassword;
      if (Object.keys(updates).length > 0) {
        const { error: authError } = await supabase.auth.updateUser(updates);
        if (authError) {
          throw authError;
        }
      }
      const { error: profileError } = await supabase
        .from("profiles")
        .update({ username })
        .eq("user_id", profile.user_id);
      if (profileError) {
        throw profileError;
      }
      await fetchProfile();
      setOldPassword("");
      setNewPassword("");
      setChangePassword(false);
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleManageSubscription = async () => {
    try {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (!session?.user?.id) {
        alert("Utilisateur non connecté.");
        return;
      }
      const userId = session.user.id;
      const response = await fetch("/api/create-billing-portal-session", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userId }),
      });
      const data = await response.json();
      if (data.url) {
        window.location.href = data.url;
        setTimeout(fetchProfile, 3000);
      } else {
        alert(
          data.error ||
            "Impossible de créer la session de gestion d'abonnement."
        );
      }
    } catch (err) {
      console.error(err);
      alert("Erreur interne. Réessayez plus tard.");
    }
  };

  const handleDeleteAccount = async () => {
    const confirmation = window.confirm(
      "Êtes-vous sûr de vouloir supprimer votre compte ? Cette action est irréversible."
    );
    if (!confirmation) return;
    setLoading(true);
    setErrorMessage("");
    try {
      const response = await fetch("/api/delete-account", {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userId: profile.user_id }),
      });
      const data = await response.json();
      if (data.error) throw new Error(data.error);
      await supabase.auth.signOut();
      window.location.href = "/goodbye";
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAvatarUploadSuccess = async (publicUrl) => {
    if (!profile) return;
    setLoading(true);
    setErrorMessage("");
    let oldAvatarFileName = null;
    if (profile.avatar_url) {
      oldAvatarFileName = profile.avatar_url.split("/").pop().split("?")[0];
    }
    const { error: updateError } = await supabase
      .from("profiles")
      .update({ avatar_url: publicUrl })
      .eq("user_id", profile.user_id);
    if (updateError) throw updateError;
    await fetchProfile();
    const newAvatarFileName = publicUrl.split("/").pop().split("?")[0];
    if (oldAvatarFileName && oldAvatarFileName !== newAvatarFileName) {
      const {  error: deleteError } = await supabase.storage
        .from("avatars")
        .remove([oldAvatarFileName]);
      if (deleteError) {
        console.error(
          "Erreur lors de la suppression de l'ancien avatar :",
          deleteError.message
        );
      }
    }
    alert("Photo de profil mise à jour avec succès !");
    setLoading(false);
  };

  // Fonction pour le bouton d'aide : redirige vers un email ou une page de support
  const handleContactSupport = () => {
    window.location.href = "mailto:contact@mixify.fr";
  };

  if (loading) {
    return (
      <div className="auth-container">
        <LoadingIndicator />
      </div>
    );
  }
  if (errorMessage) {
    return <div className="auth-message error">{errorMessage}</div>;
  }
  if (!profile) {
    return <div>Aucun compte trouvé.</div>;
  }

  return (
    <Layout>
      <div className="profile-page-container">
        <h2>Mon compte</h2>
        <div className="profile-sections">
          {/* Section Compte */}
          <section className="profile-section compte">
            <h3>Compte</h3>
            <form onSubmit={handleUpdateProfile} className="profile-form">
              <div className="avatar-input">
                <h4>Photo de profil</h4>
                {avatarUrl ? (
                  <img
                    src={avatarUrl}
                    alt="Mon avatar"
                    className="avatar-preview"
                  />
                ) : (
                  <p>Vous n'avez pas encore de photo de profil.</p>
                )}
                <AvatarUploader
                  onUploadSuccess={handleAvatarUploadSuccess}
                  username={username}
                />
              </div>
              <label>Nom d'utilisateur</label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <label>Adresse email</label>
              <input
                type="email"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
              />
              <button type="submit" className="update-button">
                Mettre à jour
              </button>
            </form>
          </section>

          {/* Section Abonnement */}
          <section className="profile-section abonnement">
            <h3>Abonnement</h3>
            <div className="subscription-details">
              {planStatus === "active" && (
                <div className="subscription-item">
                  <label>Votre abonnement</label>
                  <div className="subscription-info">{planType}</div>
                </div>
              )}
              <div className="subscription-item">
                <label>Statut de l'abonnement</label>
                <div className="subscription-info">{planStatus}</div>
              </div>
              {subscription?.cancel_at_period_end &&
                subscription.status === "active" && (
                  <p className="cancel-date-message">
                    Votre abonnement prendra fin le {formattedCancelDate}
                  </p>
                )}
              <button
                type="button"
                className="manage-subscription-btn"
                onClick={handleManageSubscription}
              >
                Gérer mon abonnement
              </button>
            </div>
          </section>

          {/* Section Sécurité et Confidentialité */}
          <section className="profile-section securite">
            <h3>Sécurité et confidentialité</h3>
            <div className="security-options">
              <Link to="/change-password" className="change-password-button">
                Changer mon mot de passe
              </Link>
              <div className="delete-account-section">
                <button
                  type="button"
                  className="delete-account-button"
                  onClick={handleDeleteAccount}
                >
                  Supprimer mon compte
                </button>
              </div>
            </div>
          </section>

          {/* Section Aide */}
          <section className="profile-section aide">
            <h3>Aide</h3>
            <button
              type="button"
              className="contact-support-button"
              onClick={handleContactSupport}
            >
              Contacter le support
            </button>
          </section>
        </div>
        <SubscriptionBanner />
      </div>
    </Layout>
  );
}

export default ProfilePage;
