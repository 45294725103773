// src/pages/PolitiqueCookies.js
import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/legalPages.css'; // Assurez-vous de créer ce fichier CSS pour le style

function PolitiqueCookies() {
  return (
    <div className='privacy-page'>
    <div className="privacy-container">
      <Helmet>
        <title>Mixify - Politique de Cookies</title>
        <meta name="description" content="Politique de Cookies de Mixify." />
      </Helmet>

      <h1>Politique de Cookies</h1>

      <section>
        <h2>1. Qu'est-ce qu'un Cookie ?</h2>
        <p>
          Un cookie est un petit fichier texte déposé sur votre terminal lors de votre navigation sur un site internet. Il est ensuite lu par le site lors de vos prochaines visites.
        </p>
      </section>

      <section>
        <h2>2. Types de Cookies Utilisés</h2>
        <p>
          Mixify utilise les types de cookies suivants :
          <ul>
            <li>
              <strong>Cookies Essentiels :</strong> Nécessaires au bon fonctionnement du site (ex. gestion de session, sécurité).
            </li>
            <li>
              <strong>Cookies de Performance :</strong> Permettent de mesurer l'audience du site et d'améliorer ses performances (ex. Google Analytics).
            </li>
            <li>
              <strong>Cookies Fonctionnels :</strong> Facilitent l'utilisation du site en mémorisant vos préférences (ex. langue, thème).
            </li>
            <li>
              <strong>Cookies Publicitaires :</strong> Utilisés pour diffuser des publicités ciblées et mesurer leur efficacité.
            </li>
          </ul>
        </p>
      </section>

      <section>
        <h2>3. Gestion des Cookies</h2>
        <p>
          Vous avez la possibilité de configurer votre navigateur pour accepter, refuser ou supprimer les cookies. Veuillez noter que la désactivation des cookies essentiels peut affecter le fonctionnement du site.
        </p>
      </section>

      <section>
        <h2>4. Consentement</h2>
        <p>
          En continuant à utiliser le site Mixify, vous acceptez l'utilisation de cookies conformément à la présente Politique de Cookies.
        </p>
      </section>

      <section>
        <h2>5. Modifications de la Politique de Cookies</h2>
        <p>
          Mixify se réserve le droit de modifier cette Politique de Cookies à tout moment. Les modifications seront publiées sur cette page avec une date de mise à jour.
        </p>
      </section>

      <section>
        <h2>6. Contact</h2>
        <p>
          Pour toute question concernant la Politique de Cookies, veuillez nous contacter à l'adresse suivante : contact@mixify.fr.
        </p>
      </section>
    </div>
    </div>
  );
}

export default PolitiqueCookies;