// src/pages/SubscriptionCancelPage.js
import React from 'react'
import { Link } from 'react-router-dom'
import '../styles/SubscriptionPage.css'

function SubscriptionCancelPage() {
  return (
    <div className="subscription-container">
      <h2 className="subscription-title">Abonnement annulé</h2>

      <p style={{ textAlign: 'center', maxWidth: '600px', margin: '2rem auto' }}>
        Vous avez annulé le paiement ou le processus de souscription.
        <br />
        Aucune somme ne vous a été prélevée et votre abonnement n’a pas été activé.
      </p>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
        <Link
          to="/subscription" 
          style={{
            color: '#fff',
            backgroundColor: 'var(--primary-color)',
            padding: '0.8rem 1.5rem',
            borderRadius: '4px',
            textDecoration: 'none',
            fontWeight: '500',
          }}
        >
          Retour à la page Abonnements
        </Link>
      </div>
    </div>
  )
}

export default SubscriptionCancelPage