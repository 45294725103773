// src/pages/ConfirmationPage.js
import React from 'react';
import Layout from '../components/Layout';

function ConfirmationPage() {
  return (
    <Layout>
      <div style={{ color: 'white', padding: '2rem' }}>
        <h2>Votre compte est confirmé</h2>
        <p>Félicitations, votre compte Mixify est maintenant vérifié !</p>
        <p>Vous pouvez désormais vous connecter et profiter pleinement de nos fonctionnalités.</p>
        <a href="/login" style={{ color: '#1E88E5' }}>Aller à la page de connexion</a>
      </div>
    </Layout>
  );
}

export default ConfirmationPage;