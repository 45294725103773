// src/pages/CompleteProfilePage.js
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { supabase } from '../utils/supabaseClient'
import '../styles/Auth.css'

function CompleteProfilePage() {
  const navigate = useNavigate()

  const [username, setUsername] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [loading, setLoading] = useState(true)

  // Sert à détecter si l'utilisateur a DEJA un profil
  const [alreadyHasProfile, setAlreadyHasProfile] = useState(false)

  useEffect(() => {
    checkExistingProfile()
  }, [])

  const checkExistingProfile = async () => {
    setLoading(true)
    setErrorMessage('')

    // Récupère la session
    const { data, error } = await supabase.auth.getSession()
    if (error) {
      setErrorMessage(error.message)
      setLoading(false)
      return
    }
    if (!data.session) {
      setErrorMessage('Vous devez être connecté pour accéder à cette page.')
      setLoading(false)
      return
    }

    const userId = data.session.user.id
    // On vérifie si un profil existe déjà
    const { data: existingProfile, error: profileError } = await supabase
      .from('profiles')
      .select('*')
      .eq('user_id', userId)
      .maybeSingle()

    if (profileError) {
      setErrorMessage(profileError.message)
      setLoading(false)
      return
    }

    if (existingProfile) {
      // L'utilisateur a déjà un profil => on lui évite de compléter
      setAlreadyHasProfile(true)
    }
    setLoading(false)
  }

  const handleCompleteProfile = async (e) => {
    e.preventDefault()
    setErrorMessage('')
    setSuccessMessage('')

    // Récupérer la session
    const { data, error } = await supabase.auth.getSession()
    if (error) {
      setErrorMessage(error.message)
      return
    }
    if (!data.session) {
      setErrorMessage('Pas de session, impossible de créer le profil.')
      return
    }
    const userId = data.session.user.id
    const userEmail = data.session.user.email

    // Insertion dans 'profiles' => RLS ok car user est loggé
    const { error: insertError } = await supabase
      .from('profiles')
      .insert({
        user_id: userId,
        username: username,
        email: userEmail,
      })

    if (insertError) {
      setErrorMessage(insertError.message)
      return
    }

    setSuccessMessage('Profil créé avec succès !')

    // Après qq secondes => direction /dashboard
    setTimeout(() => {
      navigate('/events-manager')
    }, 1500)
  }

  if (loading) {
    return <div style={{ textAlign: 'center' }}>Chargement...</div>
  }

  if (errorMessage) {
    return <div className="auth-message error">{errorMessage}</div>
  }

  // Si déjà profil => on redirige ou on affiche un message
  if (alreadyHasProfile) {
    return (
      <div className="auth-container">
        <h2>Vous avez déjà un profil</h2>
        <p>Redirection vers le dashboard...</p>
        {setTimeout(() => {
          navigate('/events-manager')
        }, 2000)}
      </div>
    )
  }

  return (
    <div className="auth-container">
      <h2>Compléter mon profil</h2>
      <form onSubmit={handleCompleteProfile}>
        <label>Nom d'utilisateur</label>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />

        <button type="submit">Créer mon profil</button>
      </form>

      {errorMessage && <div className="auth-message error">{errorMessage}</div>}
      {successMessage && <div className="auth-message success">{successMessage}</div>}
    </div>
  )
}

export default CompleteProfilePage