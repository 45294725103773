// /src/pages/DisplayMessagesPage.js
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { supabase } from "../utils/supabaseClient";
import "../styles/DisplayMessagesPage.css";

function DisplayMessagesPage() {
  // [COLONNE DE DROITE] La file des messages à afficher (ceux qui sont "approved" && "displayed=false")
  const [messagesQueue, setMessagesQueue] = useState([]);
  const { eventId } = useParams();
  // [COLONNE DE GAUCHE] La liste de tous les messages existants (optionnel, ou seulement les "approved" ?)
  const [allMessages, setAllMessages] = useState([]);

  // Liste des IDs déjà affichés, pour ne pas réinsérer 100 fois le même
  const [displayedIds, setDisplayedIds] = useState([]);

  // État pour savoir si le message affiché doit lancer l’animation de disparition
  const [isExiting, setIsExiting] = useState(false);

  // État pour savoir si la colonne de gauche est repliée
  const [isCollapsed, setIsCollapsed] = useState(false);

  // === 1) Récupérer toutes les X secondes les messages "non affichés" pour la file (droite) ===
  useEffect(() => {
    const fetchQueue = async () => {
      const { data, error } = await supabase
        .from("messages")
        .select("*")
        .eq("event_id", eventId)
        .eq("approved", true)
        .eq("displayed", false)
        .order("created_at", { ascending: true });

      if (error) {
        console.error("Erreur fetchQueue:", error);
        return;
      }
      if (!data) return;

      setMessagesQueue((oldQueue) => {
        const newQueue = [...oldQueue];
        data.forEach((msg) => {
          const alreadyDisplayed = displayedIds.includes(msg.id);
          const alreadyInQueue = newQueue.some((m) => m.id === msg.id);
          if (!alreadyDisplayed && !alreadyInQueue) {
            newQueue.push(msg);
          }
        });
        return newQueue;
      });
    };

    fetchQueue();
    const intervalId = setInterval(fetchQueue, 5000);
    return () => clearInterval(intervalId);
  }, [displayedIds, eventId]);

  // === 2) Récupérer la liste globale (pour la colonne de gauche). On peut la rafraîchir toutes les X secondes ===
  useEffect(() => {
    const fetchAllMessages = async () => {
      const { data, error } = await supabase
        .from("messages")
        .select("*")
        .eq("event_id", eventId)
        .order("created_at", { ascending: false }); // tri descendant plus récent en premier ?

      if (error) {
        console.error("Erreur fetchAllMessages:", error);
        return;
      }
      if (!data) return;
      setAllMessages(data);
    };

    fetchAllMessages();
    const intervalId = setInterval(fetchAllMessages, 5000);
    return () => clearInterval(intervalId);
  }, [eventId]);

  // On récupère le premier message de la file
  const firstMsg = messagesQueue[0];

  // === 3) Timer de 30s : au bout de 30s, déclencher l’animation de disparition ===
  useEffect(() => {
    if (!firstMsg) return;

    const timer = setTimeout(() => {
      setIsExiting(true);
    }, 30000);

    return () => clearTimeout(timer);
  }, [firstMsg]);

  // === 4) Callback fin de l'animation "sortie" ===
  const handleExitComplete = (msg) => {
    // 1) Retirer le message de la file
    setMessagesQueue((oldQueue) => {
      const newQueue = [...oldQueue];
      newQueue.shift();
      return newQueue;
    });

    // 2) Mettre à jour la liste des IDs affichés (local)
    setDisplayedIds((oldIds) => [...oldIds, msg.id]);

    // 3) Mettre à jour la BDD => displayed = true
    supabase
      .from("messages")
      .update({ displayed: true })
      .eq("id", msg.id)
      .then(({ data, error }) => {
        if (error) {
          console.error("Erreur update displayed:", error);
        } else {
          console.log("Message passé en displayed=true :", data);
        }
      });

    fetch("/api/capture-message-payment", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ messageId: msg.id }),
    })
      .then((r) => r.json())
      .then((resp) => {
        if (resp.error) {
          console.error("Erreur capture payment:", resp.error);
        } else {
          console.log(
            "PaymentIntent capturé avec succès:",
            resp.paymentIntent?.id
          );
        }
      })
      .catch((err) => {
        console.error("Erreur fetch capture payment:", err);
      });

    // 4) Réinitialiser
    setIsExiting(false);
  };

  // === 5) Fonction pour supprimer un message (côté base ET côté front) ===
  const handleDeleteMessage = async (id) => {
    // 1) Supprime en base
    const { error } = await supabase.from("messages").delete().eq("id", id);
    if (error) {
      console.error("Erreur lors de la suppression :", error);
      return;
    }
    // 2) Retire du state "allMessages"
    setAllMessages((old) => old.filter((m) => m.id !== id));
    // 3) Retire aussi du state "messagesQueue" si besoin
    setMessagesQueue((oldQueue) => oldQueue.filter((m) => m.id !== id));
    console.log("Message supprimé avec succès, ID:", id);
  };

  return (
    <>
      {/* Lien en position fixe, en haut à droite */}
      <Link to={`/send-message/${eventId}`} className="fixed-top-right">
        Page Message Clients
      </Link>

      <div className="display-messages-container">
        {/* --- COLONNE DE GAUCHE : liste de tous les messages --- */}
        <div className={`messages-left ${isCollapsed ? "collapsed" : ""}`}>
          <h2 className="messages-title">Liste des Messages</h2>
          {/* Bouton/flèche pour replier ou déplier */}
          <div
            className="collapse-toggle"
            onClick={() => setIsCollapsed((prev) => !prev)}
          >
            {/* Selon l’état, on affiche un chevron différent */}
            {isCollapsed ? "<<" : ">>"}
          </div>
          <div className="messages-list">
            {allMessages.map((msg) => (
              <div key={msg.id} className="message-card-left">
                <div className="message-card-left-sender">
                  {msg.sender_name}
                </div>
                <div className="message-card-left-content">{msg.content}</div>
                <button
                  className="delete-button"
                  onClick={() => handleDeleteMessage(msg.id)}
                >
                  Supprimer
                </button>
              </div>
            ))}
          </div>
        </div>

        {/* --- Conteneur principal pour l'affichage (centré) --- */}
        <div className="messages-right">
          <div className="cropping-guide-container">
            <div className="cropping-guide-square">
              {firstMsg ? (
                <div className="floating-wrapper">
                  <div className="floating-card">
                    <MessageItem
                      message={firstMsg}
                      showExit={isExiting}
                      onExitComplete={handleExitComplete}
                    />
                  </div>
                </div>
              ) : null}
            </div>
            <p className="cropping-guide-text">
              Ce carré délimite la zone d'affichage. Rognez vos médias selon ce
              cadre.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

// On ne modifie pas la fonction MessageItem, sauf si nécessaire
function MessageItem({ message, showExit, onExitComplete }) {
  const MAX_CHARACTERS = 200;
  const truncatedContent =
    message.content.length > MAX_CHARACTERS
      ? message.content.slice(0, MAX_CHARACTERS) + "..."
      : message.content;

  const cardClass = `message-card ${showExit ? "fade-out" : ""}`;

  const handleAnimationEnd = (e) => {
    if (showExit && e.animationName === "fadeOutDown") {
      onExitComplete(message);
    }
  };

  return (
    <div className={cardClass} onAnimationEnd={handleAnimationEnd}>
      <div className="message-card__inner">
        <div className="message-sender">{message.sender_name || "Anonyme"}</div>
        <div className="message-content">{truncatedContent}</div>
      </div>
    </div>
  );
}

export default DisplayMessagesPage;
