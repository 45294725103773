// src/pages/SignupPage.js
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../utils/supabaseClient';
import { AuthContext } from '../utils/AuthContext'; // <-- Import du contexte

import '../styles/SignupPage.css';    // Fichier CSS pour le style
import PasswordCriteria from '../components/PasswordCriteria'; 
import { validatePassword } from '../utils/passwordUtils'; 
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import Input from '../components/Input';
import Layout from '../components/Layout';
import Checkbox from '../components/Checkbox';
import HCaptcha from 'react-hcaptcha';
import GoogleButton from '../components/GoogleButton';

function SignupPage() {
  /* ------------------------------------------------------------------------
   * Hooks & états
   * ------------------------------------------------------------------------ */
  const navigate = useNavigate();
  const { signUpWithEmail, signInWithGoogle } = useContext(AuthContext); 
  // Récupère les fonctions du AuthContext

  // hCaptcha
  const [hcaptchaToken, setHcaptchaToken] = useState('');

  // Champs du formulaire
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // Politique de confidentialité + messages
  const [acceptPolicy, setAcceptPolicy] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Validation de mot de passe
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false
  });
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  // Affichage / masquage du mot de passe
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  /* ------------------------------------------------------------------------
   * Effet : valider le mot de passe à chaque changement
   * ------------------------------------------------------------------------ */
  useEffect(() => {
    const { isValid, criteria } = validatePassword(password);
    setPasswordCriteria(criteria);
    setIsPasswordValid(isValid);
  }, [password]);

  /* ------------------------------------------------------------------------
   * Gestion de l'inscription
   * ------------------------------------------------------------------------ */
  const handleSignup = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    // (0) Vérifier qu'on a bien un token hCaptcha
    if (!hcaptchaToken) {
      setErrorMessage('Veuillez résoudre le Captcha avant de vous inscrire.');
      return;
    }

    // Validation basique des champs
    if (!email || !password) {
      setErrorMessage('Veuillez renseigner votre email et votre mot de passe.');
      return;
    }
    if (password !== confirmPassword) {
      setErrorMessage('Les mots de passe ne correspondent pas.');
      return;
    }
    if (!isPasswordValid) {
      setErrorMessage('Le mot de passe ne répond pas aux critères requis.');
      return;
    }
    if (!acceptPolicy) {
      setErrorMessage('Vous devez accepter les conditions générales pour vous inscrire.');
      return;
    }

    // (1) Vérification email autorisé côté serveur
    try {
      const verifyResponse = await fetch('/api/verify-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
      });
      const verifyData = await verifyResponse.json();
      if (!verifyResponse.ok) {
        setErrorMessage(verifyData.error || "L'email n'est pas autorisé.");
        return;
      }
    } catch (error) {
      console.error('Erreur lors de la vérification de l’email :', error);
      setErrorMessage("Erreur lors de la vérification de l'email.");
      return;
    }

    // (0 bis) Vérification du token hCaptcha côté serveur
    try {
      const verifyCaptchaResponse = await fetch('/api/verify-hcaptcha', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: hcaptchaToken })
      });
      const verifyCaptchaData = await verifyCaptchaResponse.json();
      if (!verifyCaptchaData.success) {
        // La vérification hCaptcha a échoué
        setErrorMessage('La vérification hCaptcha a échoué, veuillez réessayer.');
        return;
      }
    } catch (err) {
      console.error('Erreur de vérification hCaptcha :', err);
      setErrorMessage('Impossible de vérifier le Captcha pour le moment.');
      return;
    }

    // (2) Vérification unicité de l'email dans 'profiles'
    try {
      const { data: existingUser, error: userError } = await supabase
        .from('profiles')
        .select('email')
        .eq('email', email)
        .maybeSingle();

      if (userError) {
        setErrorMessage(userError.message);
        return;
      }
      if (existingUser) {
        setErrorMessage('Cette adresse email est déjà utilisée. Veuillez en choisir une autre.');
        return;
      }
    } catch (err) {
      // Gérer l'erreur liée à la vérification de l'email
      if (err.message.includes('Unique constraint failed')) {
        setErrorMessage('Cette adresse email est déjà utilisée. Veuillez en choisir une autre.');
      } else {
        setErrorMessage(err.message);
      }
      return;
    }

    // (3) Création du compte dans Supabase Auth (via le context)
    try {
      const { data, error: signUpError } = await signUpWithEmail(
        email,
        password,
        { emailRedirectTo: 'https://www.mixify.fr/confirmation' }
      );

      if (signUpError) {
        setErrorMessage(signUpError.message);
        return;
      }

      // data.user peut être null si la confirmation est requise par email
      if (!data.user) {
        setSuccessMessage(
          "Inscription réussie ! Un email de confirmation vous a été envoyé. " +
          "Veuillez confirmer votre adresse pour terminer la création de votre compte."
        );
        setTimeout(() => {
          navigate('/login');
        }, 3000);
        return;
      }

      // (4) Insertion du nouveau profil dans la table 'profiles'
      const newUser = data.user;
      const { error: insertError } = await supabase
        .from('profiles')
        .insert({
          user_id: newUser.id,
          email: newUser.email,
          username
        });

      if (insertError) {
        setErrorMessage(insertError.message);
        return;
      }

      // Afficher le message de succès et rediriger
      setSuccessMessage(
        "Inscription réussie ! Un email de confirmation vous a été envoyé. " +
        "Veuillez confirmer votre adresse pour terminer la création de votre compte."
      );
      setTimeout(() => {
        navigate('/login');
      }, 3000);

      // (5) Notification Admin (appel API) - côté serveur
      try {
        await fetch('/api/notify', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            subject: 'Nouvel utilisateur inscrit',
            userEmail: newUser.email,
            username: username || '',
            planName: '',
            planPrice: ''
          })
        });
      } catch (notifError) {
        console.error('Erreur lors de l’envoi de la notification admin :', notifError);
      }

      // (6) Redirection après quelques secondes
      setTimeout(() => {
        navigate('/login');
      }, 3000);

    } catch (err) {
      // Gérer les erreurs supplémentaires liées à l’insertion en base
      if (err.message.includes('Unique constraint failed')) {
        setErrorMessage('Cette adresse email est déjà utilisée. Veuillez en choisir une autre.');
      } else {
        setErrorMessage(err.message);
      }
    }
  };

  // Fonction de gestion de l'authentification Google (via le context)
  const handleGoogleSignup = async () => {
    try {
      const { error } = await signInWithGoogle('https://www.mixify.fr/');
      if (error) {
        setErrorMessage(error.message);
      }
      // Supabase redirige automatiquement l'utilisateur vers Google
      // puis, après validation, vers l'URL 'redirectTo'.
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  

  /* ------------------------------------------------------------------------
   * Rendu du composant
   * ------------------------------------------------------------------------ */
  return (
    <Layout>
      <div className="auth-page">
        <div className="auth-container">
          <h2>Créer un compte</h2>

          {/* Formulaire d'inscription */}
          <form onSubmit={handleSignup}>
  {/* Bouton S'inscrire avec Google placé en haut */}
  <GoogleButton onClick={handleGoogleSignup} />

  {/* Séparateur visuel avec lignes et texte "ou" */}
  <div className="separator">ou</div>

  {/* Champ Nom d'utilisateur */}
  <Input
    id="username"
    type="text"
    label="Nom d'utilisateur"
    value={username}
    onChange={(e) => setUsername(e.target.value)}
    required
  />

  {/* Champ Email */}
  <Input
    id="email"
    type="email"
    label="Adresse email"
    value={email}
    onChange={(e) => setEmail(e.target.value)}
    required
  />

  {/* Champ Mot de Passe */}
  <div className="password-input-container">
    <Input
      id="password"
      type={showPassword ? 'text' : 'password'}
      label="Mot de passe"
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      required
    />
    <span
      className="password-toggle-icon"
      onClick={() => setShowPassword(!showPassword)}
      aria-label={showPassword ? 'Masquer le mot de passe' : 'Afficher le mot de passe'}
      role="button"
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          setShowPassword(!showPassword);
        }
      }}
    >
      {showPassword ? <FaEyeSlash /> : <FaEye />}
    </span>
  </div>

  {/* Validation du mot de passe */}
  <PasswordCriteria criteria={passwordCriteria} />

  {/* Champ Confirmer le Mot de Passe */}
  <div className="password-input-container">
    <Input
      id="confirmPassword"
      type={showConfirmPassword ? 'text' : 'password'}
      label="Confirmer le mot de passe"
      value={confirmPassword}
      onChange={(e) => setConfirmPassword(e.target.value)}
      required
    />
    <span
      className="password-toggle-icon"
      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
      aria-label={showConfirmPassword ? 'Masquer le mot de passe' : 'Afficher le mot de passe'}
      role="button"
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          setShowConfirmPassword(!showConfirmPassword);
        }
      }}
    >
      {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
    </span>
  </div>

  {/* Checkbox Politique de Confidentialité */}
  <div className="checkbox-wrapper">
    <Checkbox
      id="acceptPolicy"
      checked={acceptPolicy}
      onChange={() => setAcceptPolicy(!acceptPolicy)}
      label={
        <>
          J’accepte la{' '}
          <a
            href="/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
            className="privacy-policy-link"
          >
            politique de confidentialité
          </a>{' '}
          et les{' '}
          <a
            href="/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
            className="privacy-policy-link"
          >
            CGU
          </a>
        </>
      }
    />
  </div>

  {/* hCaptcha */}
  <div style={{ margin: '1rem 0' }}>
    <HCaptcha
      sitekey="6bd0cd2c-b06c-4348-aef6-9922845218f2"
      onVerify={(token) => setHcaptchaToken(token)}
      onExpire={() => setHcaptchaToken('')}
    />
  </div>

  {/* Bouton d'inscription */}
  <button type="submit" disabled={!isPasswordValid || !acceptPolicy || !hcaptchaToken}>
    S'inscrire
  </button>
</form>

          {/* Messages d'erreur ou de succès */}
          {errorMessage && (
            <div className="auth-message error" role="alert">
              {errorMessage}
            </div>
          )}
          {successMessage && (
            <div className="auth-message success fade-in" role="status">
              {successMessage}
            </div>
          )}

          {/* Lien de redirection */}
          <div className="switch-auth-link">
            <p>
              Déjà inscrit ? <a href="/login">Se connecter</a>
            </p>
          </div>

        </div>
      </div>
    </Layout>
  );
}

export default SignupPage;