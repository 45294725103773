// EvenementsManager.js

import React, { useContext, useEffect, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { supabase } from "../utils/supabaseClient";
import { AuthContext } from "../utils/AuthContext";
import jsPDF from "jspdf";
import "jspdf-autotable";

import "../styles/EvenementsManager.css";

import Layout from "../components/Layout";
import SubscriptionBanner from "../components/SubscriptionBanner";
import ConfirmationModal from "../components/ConfirmationModal";
import QRCodeModal from "../components/QRCodeModal";
import EventCreationModal from "../components/EventCreationModal";
import SettingsModal from "../components/SettingsModal";
import LockedFeatureBadge from "../components/LockedFeatureBadge";
import CollaboratorsModal from "../components/CollaboratorsModal";
import { getPlayedSuggestions } from "../utils/suggestions";
/*import {
  createPlaylist,
  addTrackToPlaylist,
  getSpotifyUserProfile,
} from "../utils/spotifyPlaylist"; */

import {
  FaEdit,
  FaCheck,
  FaTimes,
  FaQuestionCircle,
  FaCog,
} from "react-icons/fa";

/* -------------------------------------------------------------------------
 * Helper : Détermine la couleur + le texte (label) en fonction du statut de l’événement
 * ------------------------------------------------------------------------- */
function getEventStatusAppearance(status) {
  switch (status) {
    case "waiting":
      return { label: "En attente", color: "#FFC107" }; // Jaune
    case "ongoing":
      return { label: "En cours", color: "#2196F3" }; // Bleu
    case "ended":
      return { label: "Terminé", color: "#4CAF50" }; // Vert
    default:
      // Si le status n'est pas prévu, label/couleur grise
      return { label: status ?? "Inconnu", color: "#9E9E9E" };
  }
}

/* -------------------------------------------------------------------------
 * Helper : Détermine le plan d’abonnement de l’utilisateur
 * ------------------------------------------------------------------------- */
function getPlanLevel(subscription) {
  if (!subscription || subscription.status !== "active") {
    return 0; // 0 => pas d'abonnement ou abonnement inactif
  }
  switch (subscription.price_id) {
    case "price_1QjSWiGRn8HctkO3lvR7RuyX":
      return 1; // DJ
    case "price_1QhUsjGRn8HctkO3hg5iacZX":
      return 2; // DJ+
    case "price_1QhUu3GRn8HctkO3RQzRx01K":
      return 3; // Établissement
    default:
      return 0; // Inconnu => pas d'accès
  }
}

/* =========================================================================
 * Composant principal : EventsManager
 * ========================================================================= */
function EventsManager() {
  const [events, setEvents] = useState([]);
  const [editingEventId, setEditingEventId] = useState(null);
  const [editedName, setEditedName] = useState("");

  // Contrôle de l’ouverture/fermeture du menu d’options (3 points)
  const [openMenuId, setOpenMenuId] = useState(null);

  const { user, loading } = useContext(AuthContext);

  const [subscription, setSubscription] = useState(null);
  const planLevel = getPlanLevel(subscription);

  // Modale de confirmation pour suppression
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null);

  // Modale d’aide & modale de paramètres
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [eventForSettings, setEventForSettings] = useState(null);

  // Modale de QR Code
  const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [qrCodeEventName, setQrCodeEventName] = useState("");

  // Modale de création
  const [isCreationModalOpen, setIsCreationModalOpen] = useState(false);

  // Modale d'exportation
  const [exportModalEventId, setExportModalEventId] = useState(null);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  // Stocke les morceaux joués (pour affichage éventuel dans la modale)
  const [playedTracks, setPlayedTracks] = useState([]);

  const [showCollaboratorsModal, setShowCollaboratorsModal] = useState(false);
  const [selectedEventForCollab, setSelectedEventForCollab] = useState(null);

  const navigate = useNavigate();

  /* -----------------------------------------------------------------------
   * Gestion de l’édition "inline" du nom d’un événement
   * ----------------------------------------------------------------------- */
  const handleStartEdit = (event) => {
    setEditingEventId(event.id);
    setEditedName(event.name);
  };

  const handleSaveEditInline = useCallback(
    async (eventId) => {
      if (!editedName.trim()) {
        alert("Le nom de l'événement ne peut pas être vide.");
        return;
      }

      try {
        const { error } = await supabase
          .from("evenements")
          .update({ name: editedName })
          .eq("id", eventId);

        if (error) {
          console.error("Erreur lors de la mise à jour du nom :", error);
          alert("Erreur lors de la mise à jour du nom de l'événement.");
        } else {
          console.log("Nom de l'événement mis à jour avec succès.");
          setEvents((prevEvents) =>
            prevEvents.map((ev) =>
              ev.id === eventId ? { ...ev, name: editedName } : ev
            )
          );
          setEditingEventId(null);
          setEditedName("");
        }
      } catch (err) {
        console.error("Erreur lors de la mise à jour du nom :", err);
        alert("Erreur lors de la mise à jour du nom de l'événement.");
      }
    },
    [editedName]
  );

  const handleCancelEdit = () => {
    setEditingEventId(null);
    setEditedName("");
  };

  /* -----------------------------------------------------------------------
   * Chargement des événements pour l’utilisateur connecté
   * ----------------------------------------------------------------------- */
  const loadEvents = useCallback(async () => {
    console.log("Chargement des événements...");

    if (!user) {
      console.log("Pas de user => skip loadEvents");
      return;
    }
    console.log("EvenementsManager => user.id =", user.id);
    // 1) Événements dont je suis le owner
    const { data: eventsOwned, error: errorOwned } = await supabase
      .from("evenements")
      .select("*")
      .eq("owner_id", user.id)
      .order("created_at", { ascending: false });

    if (errorOwned) {
      console.error("Erreur récupération events (owner):", errorOwned);
      return;
    }
    console.log("eventsOwned renvoyés par Supabase :", eventsOwned);

    // 2) Événements dont je suis collaborateur
    //    On joint "event_collaborators" -> "evenements"
    const { data: eventsCollab, error: errorCollab } = await supabase
      .from("event_collaborators")
      .select(
        `
    event_id,
    role,
    evenements (
      id,
      name,
      created_at,
      event_status,
      owner_id
    )
  `
      )
      .eq("collaborator_id", user.id);

    if (errorCollab) {
      console.error("Erreur récupération events (collab):", errorCollab);
    }
    if (!eventsCollab) {
      console.warn("eventsCollab est null, on le remplace par un tableau vide");
      return setEvents(eventsOwned);
    }

    const eventsCollabFlattened = eventsCollab.map((item) => {
      return {
        ...item.evenements, // (les champs de la table "evenements")
        collab_role: item.role,
        isOwner: false,
      };
    });

    // On fait la liste de tous (owner + collab)
    const allEvents = [
      ...eventsOwned.map((ev) => ({
        ...ev,
        collab_role: "owner",
        isOwner: true,
      })),
      ...eventsCollabFlattened,
    ];

    // Tri par date de création (desc)
    allEvents.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    setEvents(allEvents);
  }, [user]);

  /* -----------------------------------------------------------------------
   * ouvrir et fermer la modale d'exportation
   * ----------------------------------------------------------------------- */

  // Quand on clique sur le bouton "Exporter les morceaux joués"
  const handleOpenExportModal = async (event) => {
    // Récupérer la liste des morceaux joués pour cet event
    const played = await getPlayedSuggestions(event.id);
    setPlayedTracks(played);

    setExportModalEventId(event.id);
    setIsExportModalOpen(true);
  };

  const handleCloseExportModal = () => {
    setIsExportModalOpen(false);
    setExportModalEventId(null);
    setPlayedTracks([]);
  };

  /* -----------------------------------------------------------------------
   * (A) Récupération de l’ID utilisateur connecté
   * ----------------------------------------------------------------------- */
  useEffect(() => {
    supabase.auth.getSession().then(({ data, error }) => {
      if (error) {
        console.error("Erreur lors de la récupération de la session :", error);
      }
      if (data?.session?.user) {
      } else {
      }
    });
  }, []);

  /* -----------------------------------------------------------------------
   * (B) Récupération de l’abonnement en fonction de userId
   * ----------------------------------------------------------------------- */

  useEffect(() => {
    if (loading) return;
    if (!user) {
      setSubscription(null);
      return;
    }
    // On a un user => on fetch l’abonnement
    const fetchSubscription = async () => {
      try {
        const { data: sub, error } = await supabase
          .from("subscriptions")
          .select("*")
          .eq("user_id", user.id)
          .maybeSingle();
        if (error) {
          console.error("Erreur Supabase subscription :", error);
          setSubscription(null);
        } else {
          setSubscription(sub);
        }
      } catch (err) {
        console.error("Exception fetchSubscription :", err);
        setSubscription(null);
      }
    };
    fetchSubscription();
  }, [loading, user]);

  /* -----------------------------------------------------------------------
   * Désactiver le scroll du body quand la modale d'aide est ouverte
   * ----------------------------------------------------------------------- */
  useEffect(() => {
    if (showHelpModal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [showHelpModal]);

  /* -----------------------------------------------------------------------
   * Charger les événements au montage
   * ----------------------------------------------------------------------- */
  useEffect(() => {
    if (loading) {
      console.log(
        "EvenementsManager => Auth encore loading => skip pour l’instant."
      );
      return;
    }
    if (!user) {
      console.log("EvenementsManager => Pas de user => skip loadEvents");
      return;
    }
    loadEvents();
  }, [loading, user, loadEvents]);

   /* -----------------------------------------------------------------------
   * Met en place un abonnement aux mises à jour de la table evenements
   * ----------------------------------------------------------------------- */
   useEffect(() => {
    // Création d'un channel pour écouter les changements sur la table "evenements"
    const realtimeChannel = supabase
      .channel("evenements-channel")
      .on(
        "postgres_changes",
        {
          event: "UPDATE",
          schema: "public",  // Assurez-vous que c'est le bon schéma, généralement "public"
          table: "evenements",
        },
        (payload) => {
          console.log("Mise à jour en temps réel détectée :", payload);
          loadEvents(); // Recharger les événements dès qu'une mise à jour est détectée
        }
      )
      .subscribe();
  
    // Nettoyer l'abonnement lors du démontage du composant
    return () => {
      supabase.removeChannel(realtimeChannel);
    };
  }, [loadEvents]);

  /* -----------------------------------------------------------------------
   * Création d’un nouvel événement
   * ----------------------------------------------------------------------- */
  const handleCreate = async (
    newEventName,
    eventType,
    authenticationEnabled
  ) => {
    if (!newEventName.trim() || !eventType.trim()) {
      alert("Veuillez remplir tous les champs.");
      return;
    }

    if (newEventName.trim().length > 41) {
      alert("Le nom de l'événement ne peut pas dépasser 41 caractères.");
      return;
    }

    const {
      data: { session },
      error: sessionError,
    } = await supabase.auth.getSession();

    if (sessionError) {
      console.error("Erreur session :", sessionError);
      return;
    }
    if (!session || !session.user) {
      console.error(
        "Aucun utilisateur connecté, impossible de créer un événement."
      );
      return;
    }

    const { error } = await supabase
      .from("evenements")
      .insert({
        name: newEventName,
        owner_id: session.user.id,
        event_type: eventType,
        authentication_enabled: authenticationEnabled,
      })
      .single();

    if (error) {
      console.error("Erreur lors de la création de l'événement :", error);
      return;
    }

    console.log("Événement créé avec succès");
    setIsCreationModalOpen(false);
    loadEvents();
  };

  /* -----------------------------------------------------------------------
   * Suppression d’un événement (ouverture de la confirmation)
   * ----------------------------------------------------------------------- */
  const handleDelete = useCallback((event) => {
    console.log("Événement sélectionné pour suppression :", event);
    setEventToDelete(event);
    setIsModalOpen(true);
  }, []);

  /* -----------------------------------------------------------------------
   * Confirmation de suppression
   * ----------------------------------------------------------------------- */
  const confirmDelete = useCallback(async () => {
    if (!eventToDelete) {
      console.log("Aucun événement à supprimer.");
      return;
    }

    console.log("Suppression de l'événement :", eventToDelete);

    const { error } = await supabase
      .from("evenements")
      .delete()
      .eq("id", eventToDelete.id);

    if (error) {
      console.error("Erreur lors de la suppression de l'événement :", error);
    } else {
      console.log("Événement supprimé avec succès.");
      setEvents((prev) => prev.filter((ev) => ev.id !== eventToDelete.id));
    }

    setIsModalOpen(false);
    setEventToDelete(null);
  }, [eventToDelete]);

  const cancelDelete = useCallback(() => {
    console.log("Suppression annulée.");
    setIsModalOpen(false);
    setEventToDelete(null);
  }, []);

  /* -----------------------------------------------------------------------
   * Duplication d’un événement
   * ----------------------------------------------------------------------- */
  const handleDuplicate = useCallback(
    async (event) => {
      const newName = prompt(
        "Entrez un nouveau nom pour la copie de l'événement :",
        `${event.name} (copie)`
      );
      if (!newName || !newName.trim()) {
        alert("Nom de copie invalide.");
        return;
      }

      try {
        const { data: oldEvent, error: oldEventError } = await supabase
          .from("evenements")
          .select("*")
          .eq("id", event.id)
          .single();

        if (oldEventError || !oldEvent) {
          console.error(
            "Erreur récupération de l'événement original :",
            oldEventError
          );
          alert("Impossible de récupérer l'événement à dupliquer.");
          return;
        }

        // Exclure les colonnes qu’on ne veut pas recopier (id, created_at, etc.)
        const { id, created_at, updated_at, ...restOfEvent } = oldEvent;

        // Préparer la nouvelle insertion
        const newEventData = {
          ...restOfEvent,
          name: newName,
        };

        const { data: insertedEvent, error: insertError } = await supabase
          .from("evenements")
          .insert(newEventData)
          .select("*")
          .single();

        if (insertError) {
          console.error("Erreur lors de la duplication :", insertError);
          alert("Une erreur est survenue lors de la duplication.");
          return;
        }

        console.log("Nouveau clone :", insertedEvent);
        alert("L'événement a été dupliqué avec succès.");
        loadEvents();
      } catch (err) {
        console.error("Exception lors de la duplication :", err);
      }
    },
    [loadEvents]
  );

  /* -----------------------------------------------------------------------
   * Génération de QR Code : ouvre la modale
   * ----------------------------------------------------------------------- */
  const handleGenerateQRCode = (eventId, eventName) => {
    const url = `${window.location.origin}/search/${eventId}`;
    const displayEventName = planLevel >= 2 ? eventName.toUpperCase() : "";
    setQrCodeUrl(url);
    setQrCodeEventName(displayEventName);
    setIsQRCodeModalOpen(true);
  };

  /* -----------------------------------------------------------------------
   * Copie du lien d’accès
   * ----------------------------------------------------------------------- */
  const handleCopyAccessLink = (eventId) => {
    const url = `${window.location.origin}/search/${eventId}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        alert("Lien d'accès copié !");
      })
      .catch((err) => {
        console.error("Erreur lors de la copie du lien :", err);
        alert(
          `Erreur lors de la copie. Veuillez copier manuellement ce lien : ${url}`
        );
      });
  };

  /* -----------------------------------------------------------------------
   * Génère le contenu du fichier .pdf (ou tout autre format),
   * et lance le téléchargement.
   * ----------------------------------------------------------------------- */
  const handleDownloadPDF = () => {
    if (playedTracks.length === 0) {
      alert("Aucun morceau joué à exporter.");
      return;
    }

    // 1) Créer une instance jsPDF
    const doc = new jsPDF({
      // orientation: 'p' ou 'l' (portrait ou landscape),
      // format: 'a4',
      // etc.
    });

    // 2) Insérer le nom de l'événement (si tu l'as stocké quelque part)
    //    Supposons qu'on a "exportModalEventId" et qu'on veut récupérer
    //    le "name" de cet événement. Soit tu l'as déjà en state, soit tu
    //    fais un fetch, soit tu le stockes dans un autre state.
    //    Pour la démo, imaginons que la variable "currentEventName" contienne
    //    le nom de l'événement.
    //    => À adapter selon ton code :
    const currentEventName =
      events.find((ev) => ev.id === exportModalEventId)?.name ||
      "Nom indisponible";

    // 3) Ajouter un titre / du texte
    doc.setFontSize(16);
    doc.text(`Morceaux joués - Événement : ${currentEventName}`, 10, 10);

    // 4) Lister les morceaux
    //    Position de départ Y = 30 par exemple
    let startY = 30;
    doc.setFontSize(12);

    playedTracks.forEach((track, index) => {
      const artists = Array.isArray(track.artists)
        ? track.artists.join(", ")
        : "Artiste inconnu";
      // On assemble le texte pour cette ligne
      const line = `${index + 1}. ${track.name} - ${artists}`;

      // doc.text(<contenu>, x, y)
      doc.text(line, 10, startY);
      startY += 10; // on descend la ligne suivante de 10px
    });

    // 5) Enregistrer/télécharger le PDF
    doc.save("export_morceaux_joues.pdf");
  };
  /* -----------------------------------------------------------------------
   * Gérer l’exportation vers une nouvelle playlist Spotify
   * ----------------------------------------------------------------------- 
  const handleExportToSpotify = async () => {
    try {
      // 1) Récupérer le token Spotify pour CE eventId
      const { data, error } = await supabase
        .from("spotify_tokens")
        .select("access_token, refresh_token")
        .eq("event_id", exportModalEventId)
        .single();

      if (error || !data) {
        alert(
          "Aucun token Spotify trouvé pour cet événement. Veuillez d'abord vous connecter à Spotify dans le Spotify Dashboard."
        );
        console.error("Erreur supabase:", error);
        return;
      }

      let { access_token } = data;

      // 2) Vérifier la validité du token (optionnel).
      //    S'il a expiré, on le refresh (vous avez déjà un refreshSpotifyAccessToken).
      //    Ici, pour faire simple, on suppose qu'il est valide.

      // 3) Récupérer l'ID utilisateur Spotify
      const userProfile = await getSpotifyUserProfile(access_token);
      const userId = userProfile.id;
      if (!userId) {
        alert(
          "Impossible de récupérer l'utilisateur Spotify. Vérifiez votre connexion."
        );
        return;
      }

      // 4) Créer une nouvelle playlist
      const newPlaylistName = `Export Mixify - ${Date.now()}`;
      // => vous pouvez mettre un nom plus parlant ou inclure le nom de l'event
      const newPlaylist = await createPlaylist(
        userId,
        newPlaylistName,
        access_token,
        "Export des morceaux joués"
      );

      const newPlaylistId = newPlaylist.id;
      console.log("Nouvelle playlist créée:", newPlaylistId);

      // 5) Ajouter chaque track "joué"
      //    On suppose que playedTracks contient des suggestions dont track.id = l'ID Spotify
      if (playedTracks.length === 0) {
        alert("Aucun morceau 'joué' à ajouter.");
        return;
      }

      for (let track of playedTracks) {
        if (!track.id) continue; // par sécurité
        try {
          await addTrackToPlaylist(track.id, newPlaylistId, access_token);
        } catch (errAdd) {
          console.error("Erreur lors de l'ajout du track:", track.id, errAdd);
        }
      }

      alert(
        "Export terminé ! Vous pouvez retrouver cette playlist sur votre compte Spotify."
      );
    } catch (error) {
      console.error("handleExportToSpotify - erreur:", error);
      alert("Une erreur s'est produite lors de l'export vers Spotify.");
    }
  };*/

  /* =========================================================================
   * Rendu du composant
   * ========================================================================= */
  return (
    <Layout>
      <div className="events-manager-page" style={{ position: "relative" }}>
        {/* Bouton d’aide en haut à droite */}
        <button
          className="help-button"
          onClick={() => setShowHelpModal(true)}
          title="Aide - Comment utiliser l'application"
        >
          <FaQuestionCircle size={24} color="#fff" />
        </button>

        <h1 className="events-manager-title">Gestion des Événements</h1>

        {/* Bouton pour créer un nouvel événement */}
        <div className="events-manager-create">
          <button
            onClick={() => setIsCreationModalOpen(true)}
            className="btn btn-primary"
            disabled={planLevel < 2 && events.length >= 3}
          >
            Créer un nouvel évènement
          </button>
          {planLevel < 2 && events.length >= 3 && (
            <p style={{ marginTop: "15px", textAlign: "center" }}>
              3 évènements max {planLevel < 2 && <LockedFeatureBadge />}
            </p>
          )}
        </div>

        {/* Liste des événements */}
        <div className="events-manager-list">
          {events.length === 0 ? (
            <p className="events-manager-status">Aucun événement disponible.</p>
          ) : (
            events.map((ev) => {
              const { label: statusLabel, color: statusColor } =
                getEventStatusAppearance(ev.event_status);

              return (
                <div key={ev.id} className="events-manager-card">
                  <div className="events-manager-card-content">
                    {/* Ligne du haut : Nom d'évènement (modifiable) + statut */}
                    <div className="events-manager-top-row">
                      <div className="events-manager-event-name-container">
                        {editingEventId === ev.id ? (
                          <input
                            type="text"
                            value={editedName}
                            onChange={(e) => setEditedName(e.target.value)}
                            className="events-manager-edit-input"
                            maxLength={41}
                          />
                        ) : (
                          <h3 className="events-manager-event-name no-margin">
                            {ev.name}{" "}
                            {!ev.isOwner && (
                              <span
                                style={{ fontStyle: "italic", color: "#aaa" }}
                              >
                                (Collaborateur)
                              </span>
                            )}
                          </h3>
                        )}

                        {editingEventId === ev.id ? (
                          <span className="events-manager-edit-icons">
                            <FaCheck
                              className="edit-icon confirm-icon"
                              onClick={() => handleSaveEditInline(ev.id)}
                              title="Valider"
                            />
                            <FaTimes
                              className="edit-icon cancel-icon"
                              onClick={handleCancelEdit}
                              title="Annuler"
                            />
                          </span>
                        ) : (
                          <>
                            <FaEdit
                              className="edit-icon"
                              onClick={() => handleStartEdit(ev)}
                              title="Modifier le nom de l'événement"
                            />
                            <FaCog
                              className="edit-icon"
                              onClick={() => {
                                setEventForSettings(ev);
                                setShowSettingsModal(true);
                              }}
                              title="Paramètres de l'évènement"
                              style={{ marginLeft: "8px" }}
                            />
                          </>
                        )}
                      </div>

                      {/* Indicateur de statut */}
                      <div className="event-status-display">
                        <span
                          className="event-status-bullet"
                          style={{ backgroundColor: statusColor }}
                        />
                        <span className="event-status-text">{statusLabel}</span>
                      </div>
                    </div>

                    {/* Bouton d’accès à la page Search (clic gauche), copie du lien (clic droit) */}
                    <button
                      className="btn btn-success btn-access"
                      onClick={() => navigate(`/search/${ev.id}`)}
                      onContextMenu={(e) => {
                        e.preventDefault();
                        handleCopyAccessLink(ev.id);
                      }}
                      title="Cliquez pour accéder à la page Search..."
                    >
                      Page Clients
                    </button>
                  </div>

                  {/* Actions principales (Dashboard, Code Manager, 3 points) */}
                  <div className="events-manager-actions">
                    {ev.event_status === "ended" && (
                      <button
                        onClick={() => handleOpenExportModal(ev)}
                        className="btn btn-warning"
                        style={{ marginLeft: "8px" }}
                      >
                        Exporter les morceaux joués
                      </button>
                    )}
                    <Link to={`/dashboard/${ev.id}`} className="btn btn-info">
                      Tableau de bord
                    </Link>

                    <Link
                      to={`/spotify-dashboard/${ev.id}`}
                      className="btn btn-info"
                    >
                      Spotify Live Playlist
                    </Link>
                    <Link to={`/display/${ev.id}`} className="btn btn-info">
                      Messages clients
                    </Link>

                    {ev.authentication_enabled && (
                      <Link
                        to={`/code-manager/${ev.id}`}
                        className="btn btn-secondary"
                      >
                        Code Manager
                      </Link>
                    )}

                    {/* Bouton 3 points + menu d'options */}
                    <div style={{ position: "relative" }}>
                      <button
                        style={{
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          fontSize: "30px",
                          fontWeight: "1400",
                          color: "white",
                        }}
                        onClick={() => {
                          // Ouvrir ou fermer le menu en fonction de l’ID
                          setOpenMenuId(openMenuId === ev.id ? null : ev.id);
                        }}
                        title="Options"
                      >
                        &#8942;
                      </button>

                      {openMenuId === ev.id && (
                        <div
                          style={{
                            position: "absolute",
                            top: "30px",
                            right: 0,
                            backgroundColor: "#0D1117",
                            border: "1px solid #333",
                            borderRadius: "5px",
                            width: "200px",
                            zIndex: 9999,
                            boxShadow: "0 2px 5px rgba(0,0,0,0.5)",
                          }}
                        >
                          <button
                            className="menu-item"
                            onClick={() => {
                              handleGenerateQRCode(ev.id, ev.name);
                              setOpenMenuId(null);
                            }}
                          >
                            Générer un QR Code
                          </button>
                          <button
                            className="menu-item"
                            onClick={() => {
                              handleOpenExportModal(ev); // <-- la même fonction que ton autre bouton
                              setOpenMenuId(null); // ferme le menu après le clic
                            }}
                          >
                            Exporter les morceaux joués
                          </button>
                          {ev.isOwner && (
                            <button
                              className="menu-item"
                              onClick={() => {
                                setSelectedEventForCollab(ev);
                                setShowCollaboratorsModal(true);
                                setOpenMenuId(null);
                              }}
                            >
                              Gérer les collaborateurs
                            </button>
                          )}
                          <button
                            className="menu-item"
                            onClick={() => {
                              handleStartEdit(ev);
                              setOpenMenuId(null);
                            }}
                          >
                            Renommer l'événement
                          </button>
                          <button
                            className="menu-item"
                            onClick={() => {
                              handleDuplicate(ev);
                              setOpenMenuId(null);
                            }}
                          >
                            Dupliquer l'événement
                          </button>
                          <button
                            className="menu-item"
                            onClick={() => {
                              handleDelete(ev);
                              setOpenMenuId(null);
                            }}
                          >
                            Supprimer l'événement
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>

        {/* Modale de confirmation de suppression */}
        <ConfirmationModal
          isOpen={isModalOpen}
          onConfirm={confirmDelete}
          onCancel={cancelDelete}
          message={`Êtes-vous sûr de vouloir supprimer l'événement "${eventToDelete?.name}" ?`}
        />

        {/* Modale de QR Code */}
        <QRCodeModal
          isOpen={isQRCodeModalOpen}
          onClose={() => setIsQRCodeModalOpen(false)}
          url={qrCodeUrl}
          eventName={planLevel >= 2 ? qrCodeEventName : ""}
          disableCustomization={planLevel < 2}
        />

        <SubscriptionBanner />

        {/* Modale d'aide */}
        {showHelpModal && (
          <div
            className="help-modal-overlay"
            onClick={() => setShowHelpModal(false)}
          >
            <div
              className="help-modal-content"
              onClick={(e) => e.stopPropagation()}
            >
              <button
                className="help-modal-close"
                onClick={() => setShowHelpModal(false)}
                title="Fermer"
              >
                &times;
              </button>
              <h2 style={{ marginBottom: "0.5rem" }}>
                Mode d'emploi "Gestion des évènements" :
              </h2>
              <p style={{ marginBottom: "1.5rem" }}>
                Bienvenue sur la page de gestion des événements ! Ce guide vous
                explique, de manière claire et concise, comment créer, modifier,
                supprimer vos événements et utiliser les outils associés.
              </p>

              <section style={{ marginBottom: "1.5rem" }}>
                <h3 style={{ marginBottom: "0.75rem" }}>
                  1. Créer / gérer un événement
                </h3>
                <p style={{ marginBottom: "0.75rem" }}>
                  <strong>A - Créer un évènement :</strong> Saisissez le nom de
                  votre événement dans le champ dédié en haut de la page, puis
                  cliquez sur le bouton ci-dessous :
                </p>
                <button
                  className="btn btn-primary"
                  disabled
                  style={{
                    cursor: "default",
                    pointerEvents: "none",
                    marginBottom: "1.5rem",
                  }}
                >
                  Créer Événement
                </button>
                <p style={{ marginBottom: "0.75rem" }}>
                  <strong>B - Modifier un événement :</strong> Cliquez sur
                  l'icône{" "}
                  <FaEdit
                    style={{
                      fontSize: "1.2rem",
                      verticalAlign: "middle",
                      color: "#fff",
                    }}
                  />{" "}
                  à côté du nom de l'événement pour passer en mode édition.
                </p>

                <p style={{ marginBottom: "0.75rem" }}>
                  Exemple en mode affichage :
                </p>
                <div
                  style={{
                    maxWidth: "500px",
                    marginBottom: "1.5rem",
                    marginLeft: "0",
                    border: "1px solid #444",
                    borderRadius: "5px",
                    padding: "10px",
                    backgroundColor: "#1e1e1e",
                    color: "#fff",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span>Nom de votre évènement</span>
                  <button
                    disabled
                    style={{
                      background: "transparent",
                      border: "none",
                      cursor: "default",
                      pointerEvents: "none",
                    }}
                    title="Modifier le nom de l'événement"
                  >
                    <FaEdit
                      className="edit-icon"
                      style={{ fontSize: "1.2rem", color: "#fff" }}
                    />
                  </button>
                </div>
                <p style={{ marginBottom: "0.75rem" }}>
                  En mode édition, le nom de l'événement devient modifiable et
                  deux boutons s'affichent pour enregistrer ou annuler la
                  modification :
                </p>
                <div className="example-input-container">
                  <input
                    type="text"
                    value="Nom de votre évènement modifié"
                    disabled
                    className="events-manager-edit-input"
                  />
                  <button disabled title="Valider">
                    <FaCheck className="edit-icon confirm-icon" />
                  </button>
                  <button disabled title="Annuler">
                    <FaTimes className="edit-icon cancel-icon" />
                  </button>
                </div>
                <p style={{ marginBottom: "0.75rem" }}>
                  <strong>C - Supprimer un événement :</strong> Cliquez sur le
                  bouton ci-dessous pour retirer un événement.
                </p>
                <div
                  style={{
                    maxWidth: "500px",
                    marginBottom: "1.5rem",
                    marginLeft: "0",
                  }}
                >
                  <button
                    className="btn btn-danger"
                    disabled
                    style={{ cursor: "default", pointerEvents: "none" }}
                    title="Supprimer l'événement"
                  >
                    Supprimer
                  </button>
                </div>
                <p style={{ marginBottom: "0.75rem" }}>
                  <strong>Note :</strong> Une confirmation vous sera demandée
                  avant la suppression définitive.
                </p>
              </section>

              <section style={{ marginBottom: "1.5rem" }}>
                <h3 style={{ marginBottom: "0.75rem" }}>
                  2. Partager votre évènement
                </h3>
                <p style={{ marginBottom: "0.75rem" }}>
                  Pour partager votre évènement à vos invités, 2 solutions
                  s'offrent à vous :
                </p>
                <p>
                  <strong>A - Générer un QR Code</strong> : Cliquez sur le
                  bouton
                  <strong> "Générer QR Code"</strong> pour obtenir un code que
                  vous pourrez imprimer, diffuser sur vos réseaux sociaux, etc.
                </p>
                <p>
                  <strong>B - Copier le lien de la searchpage</strong> : Cliquez
                  sur le texte
                  <strong> "Copier accès invités"</strong> pour copier le lien
                  d'accès direct à votre searchpage et le partager avec vos
                  invités.
                </p>
              </section>

              <section style={{ marginBottom: "1.5rem" }}>
                <h3 style={{ marginBottom: "0.75rem" }}>
                  3. Navigation entre les fonctionnalités
                </h3>
                <p style={{ marginBottom: "0.75rem" }}>
                  <strong>Action :</strong> Utilisez les boutons situés dans
                  chaque carte d'événement pour accéder aux différents outils :
                </p>
                <ul style={{ marginLeft: "1.5rem", marginBottom: "0.75rem" }}>
                  <li>
                    <strong>Dashboard :</strong> Visualisez et gérez en temps
                    réel les propositions de morceaux.
                  </li>
                  <li>
                    <strong>Page clients :</strong> Recherchez des morceaux à
                    ajouter à votre événement.
                  </li>
                  <li>
                    <strong>Code Manager :</strong> Gérez les codes d'accès et
                    autres paramètres spécifiques.
                  </li>
                </ul>
              </section>
            </div>
          </div>
        )}

        {showCollaboratorsModal && (
          <CollaboratorsModal
            isOpen={showCollaboratorsModal}
            onClose={() => setShowCollaboratorsModal(false)}
            eventData={selectedEventForCollab}
          />
        )}

        {/* Modale de création */}
        {isCreationModalOpen && (
          <EventCreationModal
            isOpen={isCreationModalOpen}
            onClose={() => setIsCreationModalOpen(false)}
            onCreate={handleCreate}
          />
        )}

        {/* Modale de paramètres */}
        {showSettingsModal && (
          <SettingsModal
            isOpen={showSettingsModal}
            onClose={() => setShowSettingsModal(false)}
            eventId={eventForSettings?.id}
            planLevel={planLevel}
            onSaved={() => {
              loadEvents();
              setShowSettingsModal(false);
            }}
          />
        )}

        {/* Modale d'exportation */}
        {isExportModalOpen && (
          <div className="modal-overlay" onClick={handleCloseExportModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <h2>Exporter les morceaux joués</h2>

              {/* Liste des morceaux joués, purement informatif */}
              {playedTracks.length === 0 ? (
                <p>Aucun morceau joué n'a été trouvé.</p>
              ) : (
                <ul>
                  {playedTracks.map((track) => (
                    <li key={track.id}>
                      {track.name} -{" "}
                      {Array.isArray(track.artists)
                        ? track.artists.join(", ")
                        : "Artiste inconnu"}
                    </li>
                  ))}
                </ul>
              )}

              <div style={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
                <button onClick={handleDownloadPDF} className="btn btn-primary">
                  Télécharger en PDF
                </button>
                <button
                  className="btn btn-success"
                  style={{ opacity: 0.6, cursor: "not-allowed" }}
                  disabled
                >
                  Exporter vers Spotify (bientôt disponible)
                </button>
              </div>

              <button
                onClick={handleCloseExportModal}
                className="btn btn-secondary"
                style={{ marginTop: "1rem" }}
              >
                Fermer
              </button>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
}

export default EventsManager;
