import React, { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { getSuggestions, updateSuggestionStatus, updateTracksOrder } from '../utils/suggestions';
import { supabase } from '../utils/supabaseClient';
import '../styles/DJView.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


function DJView() {
  const { eventId } = useParams();
  const [acceptedTracks, setAcceptedTracks] = useState([]);
  const [copyMessage, setCopyMessage] = useState({
    visible: false,
    text: '',
    x: 0,
    y: 0,
  });


  /**
   * Récupère les morceaux acceptés et les trie par ordre.
   */
  const loadAcceptedTracks = useCallback(async () => {
    if (!eventId) {
      console.error("Aucun eventId fourni. Impossible de charger les morceaux acceptés.");
      return;
    }
    const suggestions = await getSuggestions(eventId);
    const accepted = suggestions.filter((track) => track.status === 'Accepté');
    setAcceptedTracks(accepted.sort((a, b) => a.order - b.order));
  }, [eventId]);

   const handleDragEnd = async (result) => {
    if (!eventId) {
      console.error("Aucun eventId fourni. Impossible de mettre à jour l'ordre des morceaux.");
      return;
    }
       if (!result.destination) {
         // Item dropped en dehors du Droppable => on ne fait rien
         return;
       }
    
       const sourceIndex = result.source.index;
       const destIndex   = result.destination.index;
    
       if (sourceIndex === destIndex) {
         return; // L'item n'a pas bougé
       }
    
       // On fait une copie du tableau existant
       const updated = Array.from(acceptedTracks);
    
       // On enlève l'élément "draggué" de sa position initiale
       const [removed] = updated.splice(sourceIndex, 1);
    
       // On l’insère à la position de destination
       updated.splice(destIndex, 0, removed);
    
       // On met à jour l’état local (optionnel, car Realtime peut le refaire)
       setAcceptedTracks(updated);
    
       // On sauvegarde le nouvel ordre en BDD
       try {
        await updateTracksOrder(updated, eventId);
         // => Supabase enverra un event "UPDATE", 
         // => loadAcceptedTracks() se déclenche en Realtime,
         // => acceptedTracks se re-synchronise.
       } catch (err) {
         console.error('Erreur drag & drop react-beautiful-dnd:', err);
       }
     };
  /**
   * Copie l'artiste et le titre dans le presse-papier puis affiche un message de confirmation.
   */
  const copyToClipboard = (artist, title, event) => {
    const formattedText = `${artist} - ${title}`;
    navigator.clipboard
      .writeText(formattedText)
      .then(() => {
        setCopyMessage({
          visible: true,
          text: 'Copié !',
          x: event.clientX,
          y: event.clientY - 20, // Afficher 20px au-dessus du curseur
        });
        // Cache le message après 2 secondes
        setTimeout(() => {
          setCopyMessage({ visible: false, text: '', x: 0, y: 0 });
        }, 2000);
      })
      .catch((err) => {
        console.error('Erreur lors de la copie :', err);
      });
  };

  /**
   * Chargement initial + abonnement aux changements sur Supabase.
   */
  useEffect(() => {
    if (!eventId) {
      console.error("Aucun eventId fourni. Impossible de charger les morceaux acceptés.");
      return;
    }
    loadAcceptedTracks();
  
    // Canal Supabase pour recharger la liste dès qu'il y a un changement
    const channel = supabase
      .channel(`djview_suggestions_${eventId}`) // Canal spécifique à cet événement
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'suggestions' },
        (payload) => {
          console.log('[DJView] postgres_changes:', payload);
  
          if (payload.eventType === 'UPDATE') {
            const oldRow = payload.old;
            const newRow = payload.new;
  
            // Vérifie si seulement les styles ont changé
            if (oldRow && newRow) {
              const { styles: oldStyles, ...restOld } = oldRow;
              const { styles: newStyles, ...restNew } = newRow;
  
              if (JSON.stringify(restOld) === JSON.stringify(restNew)) {
                console.log('[DJView] Seuls les styles ont changé.');
                setAcceptedTracks((prevTracks) =>
                  prevTracks.map((track) =>
                    track.id === newRow.id
                      ? { ...track, styles: JSON.parse(newRow.styles) }
                      : track
                  )
                );
                return; // Évite de recharger toute la liste
              }
            }
          }
  
          // Recharger toute la liste si autre chose a changé
          loadAcceptedTracks();
        }
      )
      .subscribe();
  
    return () => {
      supabase.removeChannel(channel);
    };
  }, [eventId, loadAcceptedTracks]);

  return (
    <div className="dashboard-page">
      <Helmet>
        <title>Mixify - DJ</title>
        <meta name="description" content="Visualisez les suggestions de votre public." />
      </Helmet>

      {/* Message de copie (overlay). Seules les positions top/left restent en inline pour être dynamiques. */}
      {copyMessage.visible && (
        <div
          className="copy-message"
          style={{
            top: `${copyMessage.y}px`,
            left: `${copyMessage.x}px`,
          }}
        >
          {copyMessage.text}
        </div>
      )}

      <h1 className="dashboard-heading">DJ SetList</h1>

      {/* Liste des morceaux acceptés */}
      {acceptedTracks.length === 0 ? (
        <p className="no-accepted-tracks">Aucun morceau accepté pour le moment.</p>
      ) : (
        <DragDropContext onDragEnd={handleDragEnd}>
       <Droppable droppableId="djview-droppable"
       direction="vertical">
         {(provided) => (
           <div
           className="droppable-container"
             {...provided.droppableProps}
             ref={provided.innerRef}
           >
        {acceptedTracks.map((track, index) => {
          // Gestion du nom de l'artiste
          const artistName = Array.isArray(track.artists)
            ? track.artists.join(', ')
            : 'Artiste inconnu';

          return (
            <Draggable
                   key={track.id}
                   draggableId={track.id}
                   index={index}
                 >
                   {(providedDrag) => (
                     <div
                       ref={providedDrag.innerRef}
                       {...providedDrag.draggableProps}
                       {...providedDrag.dragHandleProps}
                       className="dashboard-card"
                     >
                       <div className="dashboard-card-content">
                {/* Titre */}
                <h5
                  className="dashboard-title"
                  onClick={(e) => copyToClipboard(artistName, track.name, e)}
                >
                  {index + 1}. {track.name}
                </h5>

                {/* Artiste */}
                <p
                  className="dashboard-artist"
                  onClick={(e) => copyToClipboard(artistName, track.name, e)}
                >
                  {artistName}
                </p>

                {/* Genres */}
                <div className="track-genres">
                  {track.styles?.map((genre) => {
                    const colorMap = {
                      House: '#1E90FF',
                      Shatta: '#FFA500',
                      Latino: '#FFFF00',
                      Techno: '#8A2BE2',
                      Rap: '#000000',
                      Old: '#008000',
                      Afro: '#FF0000',
                    };
                    const backgroundColor = colorMap[genre] || '#333';
                    const textColor = genre === 'Rap' ? '#fff' : '#000';

                    return (
                      <span
                        key={genre}
                        className="genre-badge"
                        style={{ backgroundColor, color: textColor }}
                      >
                        {genre}
                      </span>
                    );
                  })}
                </div>
              </div>

              {/* Bouton "Joué" */}
              <div className="djview-action-buttons">
   <button
     className="btn-primary"
     onClick={async () => {
       if (!eventId) return;
       await updateSuggestionStatus(track.id, 'Joué', eventId);
       loadAcceptedTracks();
     }}
   >
     Joué
   </button>
 
   <button
     className="btn-danger"
     onClick={async () => {
       if (!eventId) return;
       await updateSuggestionStatus(
         track.id,
         'Refusé',
         eventId,
         "Désolé, nous avons finalement refusé votre suggestion car le DJ n'a pas réussi à trouver un enchainement qui va avec la dynamique de la soirée."
       );
       loadAcceptedTracks();
     }}
   >
     Refuser
   </button>
 </div>
              </div>
              )}
              </Draggable>
          );
        })}
        {provided.placeholder}
        </div>
        )}
        </Droppable>
        </DragDropContext>
        )}
    </div>
  );
}

export default DJView;