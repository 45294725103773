import React, { useState, useEffect, useRef } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import MinimalLogo from './MinimalLogo';
import Logo from './Logo';
import AuthMenu from './AuthMenu'; 
import BurgerIcon from './BurgerIcon';
import '../styles/ComponentsStyles/MainHeader.css';


function MainHeader() {
  const location = useLocation();
  const navRef = useRef(null);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [lineProps, setLineProps] = useState({ left: 0, width: 0 });

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  // Vos liens de navigation
  const navItems = [
    { path: '/about', label: 'À propos' },
    { path: '/features', label: 'Fonctionnalités' },
    { path: '/subscription', label: 'Tarifs' },
    { path: '/charts', label: 'Charts' },
  ];

  // Magic line pour le menu desktop
  useEffect(() => {
    if (!navRef.current) return;
    const activeLink = Array.from(navRef.current.querySelectorAll('a')).find(
      (link) => link.getAttribute('href') === location.pathname
    );
    // Si le lien actif est Charts (on vérifie la classe charts-button), on ne positionne pas la ligne.
    if (activeLink && !activeLink.classList.contains('charts-button')) {
      setLineProps({
        left: activeLink.offsetLeft,
        width: activeLink.offsetWidth,
      });
    } else {
      setLineProps({ left: 0, width: 0 });
    }
  }, [location]);
  

  const handleHover = (e) => {
    setLineProps({
      left: e.currentTarget.offsetLeft,
      width: e.currentTarget.offsetWidth,
    });
  };

  const handleMouseLeave = () => {
    if (!navRef.current) return;
    const activeLink = Array.from(navRef.current.querySelectorAll('a')).find(
      (link) => link.getAttribute('href') === location.pathname
    );
    if (activeLink && !activeLink.classList.contains('charts-button')) {
      setLineProps({
        left: activeLink.offsetLeft,
        width: activeLink.offsetWidth,
      });
    } else {
      setLineProps({ left: 0, width: 0 });
    }
  };

  return (
    // Ajout de la classe "expanded" si drawerOpen est vrai
    <header className={`main-header ${drawerOpen ? "expanded" : ""}`}>
      {/* Hamburger menu (mobile uniquement) */}
      <div className="hamburger-menu">
        <BurgerIcon active={drawerOpen} onClick={toggleDrawer} />
      </div>
  
      {/* Conteneur du logo */}
      <div className="logo-left">
        <div className="desktop-logo">
          <MinimalLogo />
        </div>
        <div className="mobile-logo">
          <Logo />
        </div>
      </div>
  
      {/* Menu horizontal (desktop) */}
      <nav 
  className="nav-links"
  ref={navRef}
  onMouseLeave={handleMouseLeave}
>
  {navItems.map((item) =>
    item.path === '/charts' ? (
      <NavLink
        key={item.path}
        to={item.path}
        // On retire onMouseEnter pour Charts
        className={({ isActive }) => `charts-button ${isActive ? 'active' : ''}`}
      >
        {item.label}
      </NavLink>
    ) : (
      <NavLink
        key={item.path}
        to={item.path}
        onMouseEnter={handleHover}
        className={({ isActive }) => (isActive ? 'active' : '')}
      >
        {item.label}
      </NavLink>
    )
  )}
  <span
    className="magic-line"
    style={{
      left: lineProps.left,
      width: lineProps.width
    }}
  />
</nav>
  
      {/* AuthMenu */}
      <div className="auth-menu-right">
        <AuthMenu />
      </div>
  
      {/* Menu mobile intégré dans le header */}
      {drawerOpen && (
  <div className="mobile-menu">
    <ul>
      {navItems.map((item) => (
        <li key={item.path}>
          <NavLink 
            to={item.path} 
            onClick={toggleDrawer} 
            className={item.path === "/charts" ? "charts-mobile-link" : ""}
          >
            {item.label}
          </NavLink>
        </li>
      ))}
    </ul>
  </div>
)}
    </header>
  );
}

export default MainHeader;