// src/components/MinimalLogo.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/ComponentsStyles/MinimalLogo.css'; 

const MinimalLogo = ({ color = '#fff', className = '' }) => {
  return (
    <Link to="/" className={`minimal-logo-container ${className}`}>
      <h1 className="minimal-logo-title" style={{ color }}>
        Mixify
      </h1>
    </Link>
  );
};

export default MinimalLogo;