// src/pages/SubscriptionSuccessPage.js
import React from 'react'
import { Link } from 'react-router-dom'
import '../styles/SubscriptionPage.css'
// ^ On réutilise le même fichier CSS que ta page Subscription,
//   OU alors on pourra créer un fichier CSS spécifique si besoin.

function SubscriptionSuccessPage() {
  return (
    <div className="subscription-container">
      <h2 className="subscription-title">Merci pour votre abonnement !</h2>

      <p style={{ textAlign: 'center', maxWidth: '600px', margin: '2rem auto' }}>
        Votre paiement a bien été pris en compte et votre abonnement est maintenant actif.
        <br />
        Vous pouvez désormais profiter de toutes vos fonctionnalités !
      </p>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
        <Link
          to="/events-manager"
          style={{
            color: '#fff',
            backgroundColor: 'var(--primary-color)',
            padding: '0.8rem 1.5rem',
            borderRadius: '4px',
            textDecoration: 'none',
            fontWeight: '500',
          }}
        >
          Retourner au Dashboard
        </Link>
      </div>
    </div>
  )
}

export default SubscriptionSuccessPage