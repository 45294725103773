import React, { useState, useEffect, useRef } from 'react';
import Layout from '../components/Layout';
import '../styles/FeaturesPage.css';
import '../styles/HomePage.css'; // Ajout de la CSS pour les sections dynamiques
import FixedEventButton from '../components/FixedEventButton';
import { updates } from '../data/updatesData';
import { NavLink } from 'react-router-dom';

// Import Supabase (nécessaire pour les useEffect dynamiques)
import { supabase } from "../utils/supabaseClient";

// Import des assets (vidéos et logo) utilisés dans les sections
import PhoneMockup from "../assets/videos/phone-mockup.mp4";
import PlaylistVideo1 from "../assets/videos/playlist1.mp4";
import PlaylistVideo2 from "../assets/videos/playlist2.mp4";

function FeaturesPage() {

    // États et références pour les sections dynamiques
    const [topTenSuggestions, setTopTenSuggestions] = useState([]);
    const [isPhoneSectionVisible, setIsPhoneSectionVisible] = useState(false);
    const phoneSectionRef = useRef(null);
    const playlist1Ref = useRef(null);
    const playlist2Ref = useRef(null);
  
    // 1. Récupération de la session et gestion de l'animation de la section phone-feature
    useEffect(() => {
        // Intersection Observer pour déclencher l’animation au scroll
        const observer = new IntersectionObserver(
          (entries) => {
            if (entries[0].isIntersecting) {
              setIsPhoneSectionVisible(true);
            }
          },
          { threshold: 0.2 }
        );
    
        const currentElement = phoneSectionRef.current;
        if (currentElement) {
          observer.observe(currentElement);
        }
    
        return () => {
          if (currentElement) {
            observer.unobserve(currentElement);
          }
        };
      }, []);
    
      useEffect(() => {
        const video2 = playlist2Ref.current;
        const handleEnded = () => {
          if (playlist1Ref.current && video2) {
            // Réinitialiser les vidéos et les relancer
            playlist1Ref.current.currentTime = 0;
            video2.currentTime = 0;
            playlist1Ref.current.play();
            video2.play();
          }
        };
    
        if (video2) {
          video2.addEventListener("ended", handleEnded);
        }
    
        return () => {
          if (video2) {
            video2.removeEventListener("ended", handleEnded);
          }
        };
      }, []);
  
    // 2. Synchronisation des vidéos de la session playlist
    useEffect(() => {
      const video2 = playlist2Ref.current;
      const handleEnded = () => {
        if (playlist1Ref.current && video2) {
          playlist1Ref.current.currentTime = 0;
          video2.currentTime = 0;
          playlist1Ref.current.play();
          video2.play();
        }
      };
  
      if (video2) {
        video2.addEventListener("ended", handleEnded);
      }
  
      return () => {
        if (video2) {
          video2.removeEventListener("ended", handleEnded);
        }
      };
    }, []);
  
    // 3. Récupération et traitement des suggestions pour le Top 10 Charts
    useEffect(() => {
      const now = new Date();
      const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
  
      const fetchTopTen = async () => {
        try {
          let { data, error } = await supabase
            .from("suggestions")
            .select("id, name, artists, created_at")
            .gte("created_at", thirtyDaysAgo.toISOString());
  
          if (error) {
            console.error("Erreur lors de la récupération des suggestions :", error);
            return;
          }
  
          const groupedMap = {};
  
          data.forEach((item) => {
            let artistList;
            if (typeof item.artists === "string") {
              try {
                artistList = JSON.parse(item.artists);
              } catch {
                artistList = ["Artiste inconnu"];
              }
            } else if (Array.isArray(item.artists)) {
              artistList = item.artists;
            } else {
              artistList = ["Artiste inconnu"];
            }
  
            const trackName = item.name || "Titre inconnu";
            const artistsJoined = artistList.join(", ");
            const key = `${trackName}__${artistsJoined}`;
  
            if (!groupedMap[key]) {
              groupedMap[key] = {
                name: trackName,
                artists: artistList,
                count: 0,
              };
            }
            groupedMap[key].count += 1;
          });
  
          const sortedTracks = Object.values(groupedMap).sort((a, b) => b.count - a.count);
          const top10 = sortedTracks.slice(0, 10);
          setTopTenSuggestions(top10);
        } catch (err) {
          console.error("Erreur fetchTopTen : ", err);
        }
      };
  
      fetchTopTen();
    }, []);

  return (
    <Layout>
      <div className="features-container">
  <h1 className="features-title">Fonctionnalités de Mixify</h1>
  
  {/* ================= SECTION IMAGE MOCKUP ================= */}
  <section
    className={`phone-feature-section ${isPhoneSectionVisible ? "phone-visible" : ""}`}
    ref={phoneSectionRef}
  >
    <div className="phone-video-container">
      <video 
        className="phone-mockup-video" 
        autoPlay 
        muted 
        loop 
        playsInline
      >
        <source src={PhoneMockup} type="video/mp4" />
        Votre navigateur ne supporte pas la lecture de vidéo.
      </video>
    </div>
    <div className="phone-feature-text">
      <h3 className="section-title">Recevez les propositions du public</h3>
      <p className="section-text">
        Recevez les propositions du public et offrez-leur la possibilité
        de suivre l’état de leurs propositions en temps réel.
      </p>
    </div>
  </section>
  
  {/* ================= SECTION SESSION PLAYLIST ================= */}
  <section className="playlist-session-section">
    <div className="playlist-session-content">
      <h3 className="section-title">
        Démarrez une session avec nos Playlist
      </h3>
      <p className="section-text">
        créez des playlists avec les propositions du public où intégrez leurs
        propositions à vos playlist.
      </p>
      <div className="playlist-videos-container">
        <div className="playlist-video small">
          <video
            ref={playlist1Ref}
            autoPlay
            muted
            playsInline
          >
            <source src={PlaylistVideo1} type="video/mp4" />
            Votre navigateur ne supporte pas la lecture de vidéo.
          </video>
        </div>
        <div className="playlist-video large">
          <video
            ref={playlist2Ref}
            autoPlay
            muted
            playsInline
          >
            <source src={PlaylistVideo2} type="video/mp4" />
            Votre navigateur ne supporte pas la lecture de vidéo.
          </video>
        </div>
      </div>
    </div>
  </section>
  
  {/* ================= SECTION CHARTS PREVIEW ================= */}
  <section className="charts-preview-section">
    <div className="charts-preview-content">
      <h3 className="section-title">
        Suivez les tendances avec les <span className="mixify-charts-blue">Charts</span>
      </h3>
      <p className="section-text">
        Suivez les tendances des morceaux les plus proposés, les plus joués et filtrez par type d'évènements. <br />(soirées privées, clubs, lounge)
      </p>
      <div className="charts-preview-list">
        {topTenSuggestions.map((track, index) => (
          <div key={index} className="chart-item">
            <div className="chart-item-rank">#{index + 1}</div>
            <div className="chart-item-info">
              <div className="chart-item-name">{track.name}</div>
              <div className="chart-item-artists">
                {track.artists.join(", ")}
              </div>
            </div>
            <div className="chart-item-count">
              {track.count} propositions
            </div>
          </div>
        ))}
      </div>
      <div style={{ marginTop: "20px" }}>
        <NavLink to="/charts" className="go-to-charts-button">
          Voir le top 100
        </NavLink>
      </div>
    </div>
  </section>
  {/* Sections Mises à jour et Notes de patch synchronisées */}

        
  <section className="feature-section patch-notes-section">
          <h2 className="feature-heading">Notes de patch</h2>
          <p className="feature-text">
            Dernière mise à jour : Version {updates.currentVersion}
          </p>
          <ul className="feature-list">
            {updates.versionNotes[0].notes.map((note, index) => (
              <li key={index}>{note}</li>
            ))}
          </ul>
        </section>

        <section className="feature-section updates-section">
          <h2 className="feature-heading">Mises à jour</h2>
          <p className="feature-text">
            Mixify est en constante évolution et reçoit des mises à jour très régulièrement. Prochaines améliorations :
          </p>
          <ul className="feature-list">
            {updates.upcoming.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          <p className="feature-text">
            Rejoignez-nous et contribuez à rendre l’expérience encore plus interactive !
          </p>
        </section>
      </div>
      <FixedEventButton />
    </Layout>
  );
}

export default FeaturesPage;