import axios from "axios";

// Ici, on n’a plus CLIENT_ID, ni CLIENT_SECRET, ni getAccessToken()
// On utilise directement notre API serverless

// Rechercher un morceau
export const searchTracks = async (query, offset = 0, limit = 10) => {
  try {
    // On appelle l'URL serverless : /api/spotify?method=search
    // On passe nos paramètres (query, offset, limit) en query string
    const response = await axios.get("/api/spotify", {
      params: {
        method: "search",
        query,
        offset,
        limit,
      },
    });
    // Notre API renvoie { data: [...] }
    const items = response.data.data;
    console.log("Données reçues de notre API serverless :", items);
    return items;
  } catch (error) {
    console.error("Erreur lors de la recherche (via API serverless) :", error);
    return [];
  }
};

// Obtenir les données analytiques d'un morceau (ex : BPM)
export const getAudioFeatures = async (trackId) => {
  try {
    // On appelle /api/spotify?method=audioFeatures&trackId=XXXX
    const response = await axios.get("/api/spotify", {
      params: {
        method: "audioFeatures",
        trackId,
      },
    });
    const features = response.data.data;
    console.log(
      "Données analytiques reçues de notre API serverless :",
      features
    );
    return features;
  } catch (error) {
    console.error(
      `Erreur lors de la récupération des features (via API serverless) :`,
      error
    );
    return null;
  }
};

// Obtenir les informations d'un artiste (y compris ses genres)
export const getArtistInfo = async (artistId) => {
  try {
    const response = await axios.get("/api/spotify", {
      params: {
        method: "artist",
        artistId,
      },
    });
    const artist = response.data.data;
    console.log("Informations reçues pour l'artiste :", artist);
    return artist;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des informations de l'artiste :",
      error
    );
    return null;
  }
};
