import 'bootstrap/dist/css/bootstrap.min.css';


import React from 'react';
import ReactDOM from 'react-dom/client';


import reportWebVitals from './reportWebVitals';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { supabase } from './utils/supabaseClient';
import { AuthProvider } from './utils/AuthContext'; 
import App from './App';
import './index.css';

supabase.auth.getSession().then(({ data, error }) => {
    if (error) {
      console.error("Erreur getSession initial:", error);
    }
  
    // (2) Ensuite seulement, on render
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
      <AuthProvider>
        <App />
      </AuthProvider>
    );
  });
reportWebVitals();
