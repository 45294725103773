import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/ComponentsStyles/Logo.css'; // Si vous souhaitez appliquer des styles spécifiques

const Logo = ({ color = '#fff', className = '' }) => { // Définir une couleur par défaut
  return (
    <div className='full-container'>
      <div className="line"></div>
      <Link to="/" className={`logo-container ${className}`}>
        <h1 className="logo-title" style={{ color }}>
          Mixify
        </h1>
        <p className="logo-subtitle" style={{ color }}>
          Scannez, proposez, vibrez
        </p>
      </Link>
      <div className="line"></div>
    </div>
  );
};

export default Logo;