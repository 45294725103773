// src/pages/MentionsLegales.js
import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import '../styles/legalPages.css';

function MentionsLegales() {
  return (
    <Layout>
    <div className="privacy-page">
    <div className="privacy-container">
      <Helmet>
        <title>Mixify - Mentions Légales</title>
        <meta name="description" content="Mentions Légales de Mixify." />
      </Helmet>

      <h1>Mentions Légales</h1>

      <section>
        <h2>1. Présentation du Site</h2>
        <p>
          Conformément à la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, il est précisé aux utilisateurs du site Mixify l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi.
        </p>
      </section>

      <section>
        <h2>2. Informations Légales</h2>
        <p>
          <strong>Propriétaire :</strong> Launchmachine Music
          <br />
          <strong>Adresse :</strong> 91 rue de la Saladelle,34400 Lunel Viel, France
          <br />
          <strong>Email :</strong> contact@mixify.fr
          <br />
          <strong>Numéro de SIRET :</strong> 91348607200014
          <br />
          <strong>Directeur de la Publication :</strong> M. Bastien Riviere
        </p>
      </section>

      <section>
        <h2>3. Hébergeur</h2>
        <p>
          <strong>Nom :</strong> Vercel Inc.
          <br />
          <strong>Adresse :</strong> 650 California St, San Francisco, CA 94108, US
          <br />
          <strong>Contact :</strong> security@vercel.com
        </p>
      </section>

      <section>
        <h2>4. Propriété Intellectuelle</h2>
        <p>
          Tous les contenus présents sur le site Mixify, incluant, de façon non limitative, les graphismes, images, textes, vidéos, animations, sons, logos, gifs et icônes ainsi que leur mise en forme sont la propriété exclusive de Mixify à l'exception des marques, logos ou contenus appartenant à d'autres sociétés partenaires ou auteurs.
        </p>
      </section>

      <section>
        <h2>5. Limitations de Responsabilité</h2>
        <p>
          Mixify ne pourra être tenu responsable des dommages directs et indirects causés au matériel de l'utilisateur, lors de l'accès au site Mixify, et résultant soit de l'utilisation d'un matériel ne répondant pas aux spécifications indiquées, soit de l'apparition d'un bug ou d'une incompatibilité.
        </p>
      </section>

      <section>
        <h2>6. Gestion des Données Personnelles</h2>
        <p>
          Pour en savoir plus sur la gestion de vos données personnelles, veuillez consulter notre <a href="/politique-confidentialite">Politique de Confidentialité</a>.
        </p>
      </section>

      <section>
        <h2>7. Cookies</h2>
        <p>
          Le site Mixify utilise des cookies pour améliorer votre expérience utilisateur. Pour en savoir plus, veuillez consulter notre <a href="/politique-cookies">Politique de Cookies</a>.
        </p>
      </section>

      <section>
        <h2>8. Droit Applicable et Attribution de Juridiction</h2>
        <p>
          Tout litige en relation avec l'utilisation du site Mixify est soumis au droit français. Il est fait attribution exclusive de juridiction aux tribunaux compétents de [Votre Ville].
        </p>
      </section>

      <section>
        <h2>9. Contact</h2>
        <p>
          Pour toute question concernant les mentions légales, veuillez nous contacter à l'adresse suivante : contact@mixify.fr.
        </p>
      </section>
    </div>
    </div>
    </ Layout>
  );
}

export default MentionsLegales;