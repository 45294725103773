// LandingPage.js
import React, { useState, useEffect } from 'react'; // Ajout de useEffect
import { supabase } from '../utils/supabaseClient';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { v4 as uuidv4 } from 'uuid';
import { getSetting } from '../utils/settings.js';
import { useParams } from 'react-router-dom';
import '../styles/LandingPage.css'; // Importez le nouveau fichier CSS
import '../styles/spinner.css';

function LoadingIndicator() {
  return (
      <div className="spinner-container">
          <div className="spinner"></div>
      </div>
  );
}

function LandingPage() {
  const [accessCode, setAccessCode] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [authenticationEnabled, setAuthenticationEnabled] = useState(null);

  const { eventId } = useParams();

  useEffect(() => {
    // On récupère 'authEnabled'
    const fetchAuthenticationSetting = async () => {
      const authEnabled = await getSetting(eventId, 'authentication_enabled');
      setAuthenticationEnabled(authEnabled === 'true' || authEnabled === true);
    };
    fetchAuthenticationSetting();
  }, [eventId]);

  const handleAccess = async () => {
    try {
      // 1) On récupère les infos du code (unique) - sans filtrer sur "id_used"
      const { data, error } = await supabase
        .from('access_codes')
        .select('*')
        .eq('code', accessCode)
        .maybeSingle(); // => récupère une seule ligne ou null
  
      // 2) Debug : on affiche ce que Supabase nous renvoie
      console.log('Data from Supabase =', data);
      console.log('Error from Supabase =', error);
  
      if (error) {
        throw new Error('Erreur lors de la vérification en base Supabase');
      }
      if (!data) {
        setError('Code invalide ou déjà utilisé par quelqu’un d’autre.');
        return;
      }
  
      // 3) Génération du userId local si absent
      let userId = localStorage.getItem('userId');
      if (!userId) {
        // tu peux utiliser crypto.randomUUID() si ton navigateur/Node le supporte.
        userId = uuidv4();
        console.log('LandingPage: userId après génération =', userId);
        localStorage.setItem('userId', userId);
      }
  
      // 4) Vérifions si le code est déjà marqué "utilisé"
      if (data.id_used) {
        if (data.used_by_user_id && data.used_by_user_id !== userId) {
          setError('Code déjà utilisé par quelqu’un d’autre.');
          return;
        }
      // si data.used_by_user_id === userId => on laisse la personne poursuivre
      } else {
        // => Le code n’est pas marqué comme utilisé
        if (data.type === 'user') {
          // on le marque "utilisé"
          const { error: updateError } = await supabase
            .from('access_codes')
            .update({
              id_used: true,
              used_by_user_id: userId,
            })
            .eq('id', data.id);
      
          if (updateError) {
            throw new Error('Erreur lors de la mise à jour du code user comme "id_used".');
          }
        }
      }

  
      // 6) Sauvegarder dans le localStorage
      localStorage.setItem('authenticated', 'true');
      localStorage.setItem('accessCode', accessCode);
      
           //Upsert dans la table "users"
           if (userId) {
             const { data: upsertData, error: upsertError } = await supabase
               .from('users')
               .upsert({
                 id: userId,
                 event_id: eventId, // si vous voulez
                 created_at: new Date().toISOString()
               }, {
                 onConflict: 'id'
               });
             if (upsertError) {
               console.error("Erreur upsert user dans LandingPage:", upsertError);
             } else {
               console.log("Upsert user anonyme OK =>", upsertData);
             }
           }
      // 7) Rediriger
      navigate(`/search/${eventId}`);
  
    } catch (err) {
      console.error('handleAccess() - Erreur =', err);
      setError('Une erreur est survenue. Veuillez réessayer.');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleAccess();
    }
  };
if (authenticationEnabled === null) {
    return (
      <div className="landing-page-container">
        <Helmet>
          <title>Mixify</title>
          <meta name="description" content="Scannez, Proposez, Vibrez." />
        </Helmet>
        <LoadingIndicator />;
      </div>
    );
  }
  if (authenticationEnabled) {
    return (
      <div className="landing-page-container">
        <Helmet>
          <title>Mixify</title>
          <meta name="description" content="Scannez, Proposez, Vibrez." />
        </Helmet>
      {/* Section Supérieure : Titre et Sous-Titre */}
      <div className="top-section">
        <h1 className="landing-title">Mixify</h1>
        <h2 className="landing-subtitle">Scannez, Proposez, Vibrez</h2>
      </div>

      {/* Section Centrale : Description, Input et Bouton */}
      <div className="middle-section">
        <p className="landing-description">
          Entrez votre code unique pour accéder à l'application.
        </p>
        {error && <p className="error-message">{error}</p>}
        <div className="input-container">
          <input
            type="text"
            value={accessCode}
            onChange={(e) => setAccessCode(e.target.value)}
            placeholder="952AUTWE"
            className="access-code-input"
            onKeyDown={handleKeyDown}
          />
        </div>
        <button className="access-button" onClick={handleAccess}>
          Proposer un titre
        </button>

        
        <div className="progress-bar"></div>
      </div>
    </div>
  );
}

return null;
}



export default LandingPage;