import React, { useRef, useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import domtoimage from 'dom-to-image-more';
import '../styles/ComponentsStyles/QRCodeModal.css';
import Logo from '../components/Logo.js';

function QRCodeModal({ isOpen, onClose, url, eventName, disableCustomization }) {
  const modalRef = useRef();
  const previewRef = useRef();

  // Personnalisation
  const [backgroundColor, setBackgroundColor] = useState('#F2FAFF');
  const [useTransparentBackground, setUseTransparentBackground] = useState(false);

  // Titre
  const [titleOption, setTitleOption] = useState('logo'); // 'eventName', 'logo', 'customText'
  const [customTitleText, setCustomTitleText] = useState('');

  // Description
  const [showDescription, setShowDescription] = useState(true);
  const [descriptionText, setDescriptionText] = useState('PROPOSEZ VOTRE MUSIQUE');

  // INFOS DJ
  const [showInfos, setShowInfos] = useState(true);
  const [infosText, setInfosText] = useState('NOM DU DJ');

  const maxCustomTitleLength = 28;

  if (!isOpen) return null;

  // Déterminer la couleur de contraste
  const getContrastingColor = (hexColor) => {
    const color = hexColor.startsWith('#') ? hexColor.slice(1) : hexColor;
    let r, g, b;
    if (color.length === 3) {
      r = parseInt(color[0] + color[0], 16);
      g = parseInt(color[1] + color[1], 16);
      b = parseInt(color[2] + color[2], 16);
    } else if (color.length === 6) {
      r = parseInt(color.slice(0, 2), 16);
      g = parseInt(color.slice(2, 4), 16);
      b = parseInt(color.slice(4, 6), 16);
    } else {
      return '#FFFFFF';
    }
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? '#000000' : '#FFFFFF';
  };

  // Si c'est transparent, on considère le contraste sur blanc (ou noir).
  // Sinon, on calcule par rapport à la couleur choisie.
  const contrastColor = useTransparentBackground
    ? '#000000'
    : getContrastingColor(backgroundColor);

  // Téléchargement de l'image
  const handleDownload = async () => {
    if (previewRef.current) {
      try {
        await document.fonts.ready; // Attente du chargement des polices

        domtoimage
          .toPng(previewRef.current, {
            scale: 4,
            width: previewRef.current.offsetWidth,
            height: previewRef.current.offsetHeight,
            quality: 1,
            useCORS: true,
            style: useTransparentBackground
              ? { background: 'transparent' }
              : { backgroundColor: backgroundColor },
          })
          .then((dataUrl) => {
            const link = document.createElement('a');
            link.download = `${eventName}-QRCode.png`;
            link.href = dataUrl;
            link.click();
          })
          .catch((error) => {
            console.error("Erreur lors de la génération de l'image:", error);
          });
      } catch (error) {
        console.error('Erreur de chargement des polices:', error);
      }
    }
  };

  // Rendu dynamique du titre
  const renderTitle = () => {
    switch (titleOption) {
      case 'eventName':
        return (
          <div className="qr-code-event-name">
            <h2 style={{ color: contrastColor }}>{eventName}</h2>
          </div>
        );
      case 'logo':
        return (
          <div className="qr-code-logo">
            <Logo color={contrastColor} className="large-logo" />
          </div>
        );
      case 'customText':
        return (
          <div className="qr-code-custom-title">
            <h2 style={{ color: contrastColor }}>{customTitleText}</h2>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="qr-code-modal-overlay" onClick={onClose}>
      <div
        className="qr-code-modal-content"
        onClick={(e) => e.stopPropagation()}
        ref={modalRef}
        // Pour la modale elle-même, on reste sur la couleur de fond habituelle
        // sauf si on veut aussi la rendre transparente
        // -> On peut décider de laisser la modale (fond global) en "semi-transparent" 
        //    ou un RGBA. Ici on laisse un background "standard" pour la modal.
        style={{ backgroundColor: '#F2FAFF' }}
      >
        {/* Bouton de fermeture (exclu du rendu) */}
        <button
          className="qr-code-modal-close qr-code-modal-exclude"
          onClick={onClose}
        >
          &times;
        </button>

        {/* Aperçu (en haut sur mobile, à gauche ou au centre sur desktop) */}
        <div className="qr-code-preview-container">
          <div
            className="qr-code-preview"
            ref={previewRef}
            style={{
              backgroundColor: useTransparentBackground
                ? 'transparent'
                : backgroundColor,
            }}
          >
            {/* Titre (événement / logo / custom) */}
            {renderTitle()}

            {/* Description facultative (juste au-dessus du QR code) */}
            {showDescription && (
              <p
                className="qr-code-modal-instruction"
                style={{ color: contrastColor }}
              >
                {descriptionText}
              </p>
            )}

            {/* Le QR Code */}
            <div className="QRCode-img">
              <QRCodeSVG
                value={url}
                size={300}
                bgColor="transparent"
                fgColor={contrastColor}
              />
            </div>

            {/* INFOS DJ ou spacer */}
            {showInfos ? (
  <p className="qr-code-modal-infos" style={{ color: contrastColor }}>
    {disableCustomization ? "" : infosText}
  </p>
) : (
  <div className="qr-code-modal-infos-spacer"></div>
)}
          </div>
        </div>

        {/* Personnalisation (en bas sur mobile, à droite sur desktop) */}
        <div className="qr-code-customization-area qr-code-modal-exclude">
          <h3 style={{ color: '#333' }}>Personnalisation</h3>

          {/* Couleur de fond / transparent */}
          <div className="customization-control customization-control-desktop-stack">
            <label htmlFor="background-color-picker">Couleur de Fond : {disableCustomization && <span className="djplus-badge" style={{ marginLeft: '5px' }}>DJ+</span>}</label>
            <input
              type="color"
              id="background-color-picker"
              value={backgroundColor}
              onChange={(e) => {
                setBackgroundColor(e.target.value);
                // Si on choisit une couleur, on désactive le fond transparent
                setUseTransparentBackground(false);
              }}
              disabled={disableCustomization}
            />
            
            {/* Option pour fond transparent */}
            <label>
              <input
                type="checkbox"
                checked={useTransparentBackground}
                onChange={(e) => setUseTransparentBackground(e.target.checked)}
                style={{ marginTop: '-50px',marginRight: '10px' }}
              />
              Transparent
            </label>
          </div>

          {/* Titre */}
          <div className="customization-control">
            <label>TITRE :</label>
            <div className="title-options">
              <label>
                <input
                  type="radio"
                  name="titleOption"
                  value="eventName"
                  checked={titleOption === 'eventName'}
                  onChange={(e) => setTitleOption(e.target.value)}
                  disabled={disableCustomization}
                />
                Nom de l'Événement {disableCustomization && <span className="djplus-badge" style={{ marginLeft: '5px' }}>DJ+</span>}
              </label>
              <label>
                <input
                  type="radio"
                  name="titleOption"
                  value="logo"
                  checked={titleOption === 'logo'}
                  onChange={(e) => setTitleOption(e.target.value)}
                />
                Mixify Logo
              </label>
              <label>
                <input
                  type="radio"
                  name="titleOption"
                  value="customText"
                  checked={titleOption === 'customText'}
                  onChange={(e) => setTitleOption(e.target.value)}
                  disabled={disableCustomization}
                />
                Texte personnalisé {disableCustomization && <span className="djplus-badge" style={{ marginLeft: '5px' }}>DJ+</span>}
              </label>
            </div>
          </div>

          {/* Champ pour texte personnalisé (si "customText") */}
          {titleOption === 'customText' && (
            <div className="customization-control">
              <input
                type="text"
                id="custom-title-text"
                value={customTitleText}
                onChange={(e) => {
                  if (e.target.value.length <= maxCustomTitleLength) {
                    setCustomTitleText(e.target.value);
                  }
                }}
                placeholder="Max 28 caractères"
                maxLength={maxCustomTitleLength}
              />
            </div>
          )}

          {/* DESCRIPTION */}
          <div className="customization-control">
            <label htmlFor="toggle-description">DESCRIPTION :</label>
            <input
              type="checkbox"
              id="toggle-description"
              checked={showDescription}
              onChange={(e) => setShowDescription(e.target.checked)}
            />
          </div>
          {/* Champ si la description est activée */}
          {showDescription && (
            <div className="customization-control">
              <input
                type="text"
                id="description-text"
                value={descriptionText}
                onChange={(e) => setDescriptionText(e.target.value)}
                placeholder="Entrez votre texte ici"
              />
            </div>
          )}

          {/* INFOS */}
          <div className="customization-control">
            <label htmlFor="toggle-infos">INFOS :</label>
            <input
              type="checkbox"
              id="toggle-infos"
              checked={showInfos}
              onChange={(e) => setShowInfos(e.target.checked)}
              disabled={disableCustomization}
            />
            
          </div>
          {/* Champ si les infos sont activées */}
          {showInfos && (
            <div className="customization-control">
              <input
  type="text"
  value={disableCustomization ? "" : infosText}
  onChange={(e) => setInfosText(e.target.value)}
  placeholder="NOM DU DJ"
  disabled={disableCustomization}
/>
              {disableCustomization && <span className="djplus-badge" style={{ marginLeft: '5px' }}>DJ+</span>}
            </div>
          )}

          {/* Bouton de téléchargement */}
          <button onClick={handleDownload} className="btn btn-download-qr">
            Télécharger l'Image
          </button>
        </div>
      </div>
    </div>
  );
}

export default QRCodeModal;