// SearchPage.js
import React, {
  useState,
  useEffect,
  useCallback,
  useRef
} from 'react';
import { getCooldownFromDB, setCooldownInDB } from '../utils/cooldownUtils';
import { Helmet } from 'react-helmet';
import { useLocation, useParams } from 'react-router-dom';
import Confetti from 'react-confetti';

import { searchTracks } from '../utils/spotifyApi';
import { addSuggestion, getSuggestions } from '../utils/suggestions';
import { supabase } from '../utils/supabaseClient';
import { getSetting } from '../utils/settings';

import '../styles/SearchPage.css';
import Logo from '../components/Logo.js';
import Loader from '../components/Loader';
import SkeletonCard from '../components/SkeletonCard';
import '../styles/spinner.css';

function SearchPage() {
  // --------------------------------------------------
  //  STATE
  // --------------------------------------------------
  const [searchQuery, setSearchQuery] = useState('');
  const [results, setResults] = useState([]);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [refusedText, setRefusedText] = useState('');
  const [eventName, setEventName] = useState('');

  const [suggestionsState, setSuggestionsState] = useState([]);
  const [userSuggestions, setUserSuggestions] = useState([]);
  const [playingTracks, setPlayingTracks] = useState([]);
  const { eventId } = useParams();

  // Gestion du cooldown (temps d’attente entre deux propositions)
  const [cooldownRemaining, setCooldownRemaining] = useState(0);
  const [cooldownDuration, setCooldownDuration] = useState(300); // Par défaut : 5 minutes
  const cooldownInterval = useRef(null);

  // Pagination pour la recherche
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);

  // Gestion des modales (accepté / joué)
  const [showAcceptedModal, setShowAcceptedModal] = useState(false);
  const [showPlayedModal, setShowPlayedModal] = useState(false);
  const [acceptedTrackName, setAcceptedTrackName] = useState('');
  const [playedTrackName, setPlayedTrackName] = useState('');
  const playedModalTimeout = useRef(null); // Pour garder une trace du timeout

  const isVibrationSupported = useCallback(() => {
    return 'vibrate' in navigator;
  }, []);

  // Fonction pour déclencher la vibration avec un motif spécifique
  const triggerVibration = useCallback(() => {
    if (isVibrationSupported()) {
      navigator.vibrate([200, 100, 200]); // Vibre 200ms, pause 100ms, vibre 200ms
    } else {
      console.warn('Vibration non supportée sur ce périphérique.');
    }
  }, [isVibrationSupported]);

  // --------------------------------------------------
  //  HOOKS
  // --------------------------------------------------
  const location = useLocation();

  // Adapte le thème en fonction du mode système (dark / light)
  useEffect(() => {
    const userPrefersDark =
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches;
    document.documentElement.setAttribute(
      'data-theme',
      userPrefersDark ? 'dark' : 'light'
    );
  }, []);

  useEffect(() => {
    if (showAcceptedModal) {
      triggerVibration();
    }
  }, [showAcceptedModal, triggerVibration]);

  // Déclencher la vibration lorsque la modale "Joué" s'ouvre
  useEffect(() => {
    if (showPlayedModal) {
      triggerVibration();
    }
  }, [showPlayedModal, triggerVibration]);

  useEffect(() => {
    const storedPlayingTrack = localStorage.getItem('playingTrack');

    if (storedPlayingTrack) {
        const { id, startTime } = JSON.parse(storedPlayingTrack);
        const elapsedTime = Date.now() - startTime;

        // Vérifie si moins d'une minute s'est écoulée
        if (elapsedTime < 60000) {
            setPlayingTracks((prev) => ({
                ...prev,
                [id]: { status: 'Lecture', time: startTime }
            }));

            // Calcule le temps restant et programme un timeout
            const remainingTime = 60000 - elapsedTime;

            playedModalTimeout.current = setTimeout(() => {
                setPlayingTracks((prev) => {
                    const updatedTracks = { ...prev };
                    delete updatedTracks[id];
                    return updatedTracks;
                });

                // Nettoie le localStorage
                localStorage.removeItem('playingTrack');
                setShowPlayedModal(false);
            }, remainingTime);
        } else {
            // Supprime l'entrée si le temps est écoulé
            localStorage.removeItem('playingTrack');
        }
    }
}, []);

  // Vérifie si le cooldown doit être réinitialisé
  const checkCooldownReset = useCallback(() => {
    const storedCooldownEnd = localStorage.getItem(`cooldownEnd_${eventId}`);
    if (!storedCooldownEnd || parseInt(storedCooldownEnd, 10) <= Date.now()) {
      // Si la clé n'existe pas ou si le cooldown est expiré, on le réinitialise
      setCooldownRemaining(0);
      if (cooldownInterval.current) {
        clearInterval(cooldownInterval.current);
      }
      console.log('Cooldown réinitialisé dans SearchPage. Cooldown restant mis à 0.');
    }
  }, [eventId]);

  // Lance l'intervalle qui met à jour la durée restante de cooldown
  const startCooldownInterval = useCallback(() => {
    if (cooldownInterval.current) {
      clearInterval(cooldownInterval.current);
    }

    cooldownInterval.current = setInterval(() => {
      const storedCooldownEnd = localStorage.getItem(`cooldownEnd_${eventId}`);
      if (storedCooldownEnd) {
        const endTime = parseInt(storedCooldownEnd, 10);
        const now = Date.now();
        if (endTime > now) {
          setCooldownRemaining(Math.ceil((endTime - now) / 1000));
        } else {
          setCooldownRemaining(0);
          clearInterval(cooldownInterval.current);
        }
      }
    }, 1000);
  }, [eventId]);

  useEffect(() => {
    const fetchEventName = async () => {
      if (!eventId) {
        console.error('Aucun eventId fourni.');
        return;
      }
      try {
        const { data, error } = await supabase
          .from('evenements')
          .select('name')
          .eq('id', eventId)
          .single();

        if (error) {
          console.error("Erreur lors de la récupération du nom de l'événement :", error);
        } else {
          setEventName(data.name || 'Nom de l\'événement non disponible');
        }
      } catch (err) {
        console.error("Erreur lors de la récupération du nom de l'événement :", err);
      }
    };
    fetchEventName();
  }, [eventId]);

  useEffect(() => {
    const fromLandingPage = localStorage.getItem('fromLandingPage');
    if (fromLandingPage) {
      console.log('Réinitialisation depuis LandingPage...');
      setSearchQuery('');
      setResults([]);
      // Supprimer les clés spécifiques
      localStorage.removeItem(`searchQuery_${eventId}`);
      localStorage.removeItem(`searchResults_${eventId}`);
      localStorage.removeItem('fromLandingPage');
    } else {
      const savedQuery = localStorage.getItem(`searchQuery_${eventId}`);
      const savedResults = localStorage.getItem(`searchResults_${eventId}`);
      if (savedQuery && savedQuery.trim() !== '') {
        setSearchQuery(savedQuery);
      }
      if (savedResults) {
        setResults(JSON.parse(savedResults));
      }
    }
  }, [eventId]);


  // Calcule le temps restant de cooldown (au montage)
  useEffect(() => {
    async function syncCooldownDBvsLocal() {
          try {
            const userId = localStorage.getItem('userId');
            if (!userId) return; // pas de userId => on ne fait rien
      
            // 1) Lire la date en BDD
            const dbEndTime = await getCooldownFromDB(userId, eventId); 
              // => un nombre en millisecondes, ou 0 si pas de record
      
            // 2) Lire la date en localStorage
            const storedCooldownEnd = localStorage.getItem(`cooldownEnd_${eventId}`);
            const localEndTime = storedCooldownEnd ? parseInt(storedCooldownEnd, 10) : 0;
      
            // 3) On prend le max => c'est la date de fin "réelle"
            const realEndTime = Math.max(dbEndTime, localEndTime);
      
            // 4) On met localStorage à jour
            if (realEndTime > 0) {
              localStorage.setItem(`cooldownEnd_${eventId}`, String(realEndTime));
            }
          } catch (err) {
            console.error('Erreur de syncCooldownDBvsLocal:', err);
          }
       }

    const calculateCooldownRemaining = () => {
      const storedCooldownEnd = localStorage.getItem(`cooldownEnd_${eventId}`);
      if (storedCooldownEnd) {
        const endTime = parseInt(storedCooldownEnd, 10);
        const now = Date.now();
        if (endTime > now) {
          return Math.ceil((endTime - now) / 1000);
        }
      }
      return 0;
    };
    
    (async () => {
          // On fait d'abord la synchronisation
          await syncCooldownDBvsLocal();
      
          const initialCooldown = await calculateCooldownRemaining();
          setCooldownRemaining(initialCooldown);
      
          if (initialCooldown > 0) {
            startCooldownInterval();
          }
        })();
      
        return () => {
          if (cooldownInterval.current) {
            clearInterval(cooldownInterval.current);
          }
        };
      }, [startCooldownInterval, eventId]);

  // Récupère la durée de cooldown et le texte depuis la table "settings" de Supabase
useEffect(() => {
  const fetchSettings = async () => {
    try {
      const cooldown = await getSetting(eventId, 'cooldown');
      const refusedText = await getSetting(eventId, 'refused_text');

      if (cooldown) setCooldownDuration(Number(cooldown));
      if (refusedText) setRefusedText(refusedText);

    } catch (error) {
      console.error('Erreur lors de la récupération des paramètres :', error);
    }
  };

  fetchSettings();
}, [eventId]);

  // Écoute en temps réel les changements des settings de la table evenements
  useEffect(() => {
    const channel = supabase
  .channel(`evenements_channel_${eventId}`)
  .on(
    'postgres_changes',
    { event: '*', schema: 'public', table: 'evenements', filter: `id=eq.${eventId}` },
    (payload) => {
      if (payload.new.cooldown) {
        setCooldownDuration(Number(payload.new.cooldown));
      } else if (payload.new.refused_text) {
        setRefusedText(payload.new.refused_text);
      }
      if (payload.new.name !== payload.old?.name) { // Vérifie si le nom a changé
        setEventName(payload.new.name || 'Nom de l\'événement non disponible');
      }
    }
  )
  .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [eventId]);

  // Charge davantage de résultats Spotify à la fin du scroll
  const loadMoreResults = useCallback(async () => {
    if (!searchQuery || isLoading) return;
    setIsLoading(true);
  
    const nextTracks = await searchTracks(searchQuery, currentPage * 10);
    // Filtrer les doublons par rapport aux résultats déjà affichés
    const newUniqueTracks = filterDuplicates([...results, ...nextTracks]).slice(results.length);
  
    setResults((prevResults) => [...prevResults, ...newUniqueTracks]);
    setCurrentPage((prevPage) => prevPage + 1);
  
    setIsLoading(false);
  }, [searchQuery, currentPage, isLoading, results]);

  // Au montage : on ajoute un listener de scroll pour charger plus de résultats
  useEffect(() => {
    const handleScroll = () => {
      const offsetFromBottom =
        document.documentElement.offsetHeight - 200;

      if (
        window.innerHeight + document.documentElement.scrollTop >=
        offsetFromBottom
      ) {
        loadMoreResults();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loadMoreResults]);

  // Met à jour la liste des suggestions depuis Supabase
  const updateSuggestions = useCallback(async () => {
    try {
      if (!navigator.onLine) {
        setConfirmationMessage(
          'Vous êtes hors ligne. Veuillez vérifier votre connexion.'
        );
        setTimeout(() => setConfirmationMessage(''), 5000);
        return;
      }

      const data = await getSuggestions(eventId);
      setSuggestionsState(data);

      // Filtrer les suggestions de l'utilisateur courant
      const userId = localStorage.getItem('userId');
      const userSpecificSuggestions = data.filter(
        (track) => track.user_id === userId
      );
      setUserSuggestions(userSpecificSuggestions);
    } catch (error) {
      console.error('Erreur lors de la récupération des suggestions :', error);
      setConfirmationMessage(
        'Une erreur est survenue lors du chargement des suggestions.'
      );
      setTimeout(() => setConfirmationMessage(''), 5000);
    }
  }, [eventId]);

  // Au montage : mise à jour initiale des suggestions + cooldown
  useEffect(() => {
    if (eventId) {
    updateSuggestions();
  }
    // Récupération des résultats de la dernière recherche (si présents)
    const savedResults = localStorage.getItem('searchResults');
    if (savedResults) {
      try {
        const parsedResults = JSON.parse(savedResults);
        if (Array.isArray(parsedResults)) {
          setResults(parsedResults);
        }
      } catch (err) {
        console.error("Impossible de parser 'searchResults' :", err);
        setConfirmationMessage(
          "Une erreur est survenue. Veuillez effectuer une nouvelle recherche."
        );
      }
    }

    // Gestion du cooldown si "cooldownEnd" existe
    const storedCooldownEnd = localStorage.getItem(`cooldownEnd_${eventId}`);
    if (storedCooldownEnd) {
      const endTime = parseInt(storedCooldownEnd, 10);
      const now = Date.now();
      if (endTime > now) {
        const diffMs = endTime - now;
        const diffSeconds = Math.ceil(diffMs / 1000);
        setCooldownRemaining(diffSeconds);
      } else {
        setCooldownRemaining(0);
      }
    }


    if (!eventId) return;
    // Écoute en temps réel les changements sur la table "suggestions" pour réagir instantanément
    const channel = supabase
    .channel(`searchpage_suggestions_${eventId}`)
    .on('postgres_changes', { event: '*', schema: 'public', table: 'suggestions', filter: `event_id=eq.${eventId}` },
      (payload) => {
          console.log('Realtime event in SearchPage:', payload);
          if (payload.new.event_id !== eventId) {
            return; // Ignorez les changements d'autres événements
          }

          if (payload.old && payload.new) {
            const { styles: oldStyles, ...oldRest } = payload.old;
            const { styles: newStyles, ...newRest } = payload.new;
            const oldRestStr = JSON.stringify(oldRest);
            const newRestStr = JSON.stringify(newRest);
          
            if (oldRestStr === newRestStr) {
              console.log('SearchPage: IGNORE update, only "styles" changed.'
              );
              return;
            }
          }
          // 1) Recharger la liste
          updateSuggestions();

          // 2) Vérifier si c’est pour l’utilisateur courant
          const currentUserId = localStorage.getItem('userId');
          if (!payload.new || payload.new.user_id !== currentUserId) {
            return;
          }

          // 3) Comparer l’ancien statut et le nouveau
          const newStatus = payload.new.status;
          const oldStatus = payload.old?.status;

          // Cas A : Passage de "En attente" à "Accepté"
          if (oldStatus === 'En attente' && newStatus === 'Accepté') {
            setShowAcceptedModal(true);
            setAcceptedTrackName(payload.new.name || 'Votre morceau');
            setTimeout(() => {
              setShowPlayedModal(false);
            }, 30000);
          }

          // Cas B : Passage à "Joué" (depuis "En attente" ou "Accepté")
          if (newStatus === 'Joué') {
            const trackId = payload.new.id;
            const trackName = payload.new.name || 'Votre morceau';
            setShowPlayedModal(true);
            setPlayedTrackName(trackName);
            
            // Stocker dans le localStorage
            const startTime = Date.now();
            localStorage.setItem(
              'playingTrack',
              JSON.stringify({
                id: trackId,
                startTime: startTime,
              })
            );
            // Nettoie l'ancien timeout s'il existe
            if (playedModalTimeout.current) {
                clearTimeout(playedModalTimeout.current); // Annule le précédent
            }
        
            // Affiche temporairement "Lecture"
            setPlayingTracks((prev) => ({
                ...prev,
                [trackId]: { status: 'Lecture', time: startTime }
            }));
        
            // Définit un nouveau timeout
            playedModalTimeout.current = setTimeout(() => {
                setPlayingTracks((prev) => {
                    const updatedTracks = { ...prev };
                    delete updatedTracks[trackId];
                    return updatedTracks;
                });
                localStorage.removeItem('playingTrack');
        
                setShowPlayedModal(false); // Ferme la modale après 60s
            }, 60000);
        }
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [eventId, updateSuggestions]);

  useEffect(() => {
    return () => {
        // Nettoie tous les timeouts en cours lors du démontage
        if (playedModalTimeout.current) {
            clearTimeout(playedModalTimeout.current);
        }
    };
}, []);

  // Surveille les changements de page pour réinitialiser la recherche si on vient d'une autre page
  useEffect(() => {
    const previousPath = localStorage.getItem('previousPath');
    if (previousPath !== '/search') {
      setSearchQuery('');
      setResults([]);
      localStorage.removeItem('searchQuery');
      localStorage.removeItem('searchResults');
    }
    localStorage.setItem('previousPath', location.pathname);
  }, [location]);

  // Surveille (encore) le cooldownReset en localStorage + évènements temps réel depuis Supabase (table "events")
  useEffect(() => {
    const handleStorageChange = () => {
      console.log('Changement détecté dans localStorage.');
      checkCooldownReset();
    };

    window.addEventListener('storage', handleStorageChange);

    const channel = supabase
    .channel(`cooldown_events_${eventId}`)
    .on(
      'postgres_changes',
      { event: '*', schema: 'public', table: 'evenements', filter: `id=eq.${eventId}` },
      (payload) => {
        console.log('Payload received:', payload); // Pour inspecter le payload complet
        if (payload.eventType === 'UPDATE') {
          if (payload.updated_columns && payload.updated_columns.includes('cooldown_reset_at')) {
            if ((payload.new.cooldown_reset_at ?? null) !== (payload.old?.cooldown_reset_at ?? null)) {
              console.log('Cooldown reset detected in SearchPage:', payload);
              localStorage.removeItem(`cooldownEnd_${eventId}`);
              setCooldownRemaining(0);
            }
          }
          
          if (payload.updated_columns && payload.updated_columns.includes('session_reset_at')) {
            if ((payload.new.session_reset_at ?? null) !== (payload.old?.session_reset_at ?? null)) {
              console.log('Session reset detected in SearchPage:', payload);
              updateSuggestions();
            }
          }
        }
      }
    )
      .subscribe((status) => {
        if (status === 'SUBSCRIBED') {
          console.log('Abonnement réussi au canal cooldown_events.');
        } else {
          console.error(
            'Erreur lors de l’abonnement au canal cooldown_events:',
            status
          );
        }
      });

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      supabase.removeChannel(channel);
    };
  }, [eventId, checkCooldownReset, updateSuggestions]);

  // Nettoyage de l'intervalle au démontage
  useEffect(() => {
    return () => {
      if (cooldownInterval.current) {
        clearInterval(cooldownInterval.current);
      }
    };
  }, []);

  // --------------------------------------------------
  //  HANDLERS ET FONCTIONS UTILES
  // --------------------------------------------------

  // Ferme la modale "Accepté"
  function handleCloseAccepted() {
    const contentEl = document.querySelector('.modal-content-accepted');
    if (contentEl) {
      contentEl.classList.add('closing');
      setTimeout(() => {
        setShowAcceptedModal(false);
        contentEl.classList.remove('closing');
      }, 300);
    }
  }

  // Ferme la modale "Joué"
  function handleClosePlayed() {
    const contentEl = document.querySelector('.modal-content-played');
    if (contentEl) {
      contentEl.classList.add('closing');
      setTimeout(() => {
        setShowPlayedModal(false);
        contentEl.classList.remove('closing');
      }, 300);
    }
  }

  // Formatte les heures et minutes en FR
  function formatTime(dateString) {
    if (!dateString) return '';
    const d = new Date(dateString);
    return d.toLocaleTimeString('fr-FR', {
      hour: '2-digit',
      minute: '2-digit'
    });
  }

  // Vide la recherche en cours
  function clearSearch() {
    setSearchQuery('');
    setResults([]);
    localStorage.removeItem(`searchQuery_${eventId}`);
    localStorage.removeItem(`searchResults_${eventId}`);
  }

  // Filtrer les doublons dans un tableau de morceaux
function filterDuplicates(tracks) {
  const seen = new Set();
  return tracks.filter((track) => {
    // Récupère le titre (en minuscule et sans espaces superflus)
    const title = track.name?.trim().toLowerCase() || '';
    // Récupère les noms d'artistes (en minuscule, triés et concaténés)
    const artists =
      Array.isArray(track.artists) && track.artists.length > 0
        ? track.artists
            .map((artist) => artist.name.trim().toLowerCase())
            .sort()
            .join(',')
        : '';
    // Construit une clé unique
    const key = `${title}||${artists}`;
    if (seen.has(key)) {
      return false;
    }
    seen.add(key);
    return true;
  });
}



  // Lance la recherche vers l'API Spotify
  async function handleSearch(query) {
    if (!query) {
      setResults([]);
      return;
    }
  
    setInitialLoading(true);   // <--- Déclare qu’on commence le chargement initial
    setIsLoading(true);        // garde le isLoading si nécessaire pour gérer l’état global
  
    const tracks = await searchTracks(query, 0);
    const uniqueTracks = filterDuplicates(tracks);
  
    setResults(uniqueTracks);
    setSearchQuery(query);
  
    localStorage.setItem(`searchQuery_${eventId}`, query);
    localStorage.setItem(`searchResults_${eventId}`, JSON.stringify(uniqueTracks));
  
    // Fin du premier chargement
    setInitialLoading(false);
    setIsLoading(false);
  }

  // Déclenche la recherche
  function handleSearchSubmit() {
    localStorage.setItem(`searchQuery_${eventId}`, searchQuery);
    handleSearch(searchQuery);
  }

  // Ajoute une proposition de morceau
  function handlePropose(track) {
    // Vérifie le cooldown en local
    if (cooldownRemaining > 0) {
      // On pourrait afficher un message d'erreur
      console.log('Cooldown actif en local. Pas besoin de contacter la BDD.');
      return;
    }
  
    // (1) Vérif en BDD que le cooldown est expiré 
   const userId = localStorage.getItem('userId');
   if (!userId) {
     console.error("Impossible de proposer un morceau: userId introuvable.");
     return;
   }
  
   getCooldownFromDB(userId, eventId).then(async (dbEndTime) => {
     const now = Date.now();
     if (dbEndTime > now) {
       // => le cooldown BDD n'est pas expiré => on met localStorage a jour
       localStorage.setItem(`cooldownEnd_${eventId}`, String(dbEndTime));
       setCooldownRemaining(Math.ceil((dbEndTime - now) / 1000));
       // => on pourrait afficher un toast "Encore X sec..."
       console.log('Cooldown actif en BDD: insertion refusée');
       return;
     }
  
     // => Ici, on sait que BDD + localStorage sont expirés => on peut insérer la suggestion
      // Prépare la structure
      const formattedTrack = {
        id: track.id,
        name: track.name,
        artists: Array.isArray(track.artists) && track.artists.length > 0
          ? track.artists.map((artist) => artist.name)
          : ['Artiste inconnu'],
        status: 'En attente'
      };
  
      // Envoie en BDD
      addSuggestion(formattedTrack, eventId);
  
      // Confirmation
      setConfirmationMessage(`Le morceau "${track.name}" a été proposé avec succès !`);
      setTimeout(() => setConfirmationMessage(''), 5000);
  
      // Met à jour la liste
      updateSuggestions();
  
     // (2) On définit un NOUVEAU cooldown, par ex. "now + cooldownDuration"
     const newEndTime = now + cooldownDuration * 1000;
  
     // => 2a) on l'enregistre en BDD
     await setCooldownInDB(userId, eventId, newEndTime);
  
     // => 2b) on l'enregistre en local
     localStorage.setItem(`cooldownEnd_${eventId}`, String(newEndTime));
     setCooldownRemaining(cooldownDuration);
     startCooldownInterval();
  
      // On vide la searchBar
      setSearchQuery('');
      setResults([]);
   });
  }

  // Affiche le temps de cooldown sous forme "2 m et 30 s"
  function formatCooldown(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const minuteText =
      minutes > 1
        ? `${minutes} m`
        : minutes === 1
        ? `${minutes} m`
        : '';
    const secondText =
      remainingSeconds > 1
        ? `${remainingSeconds} s`
        : remainingSeconds === 1
        ? `${remainingSeconds} s`
        : '';

    if (minuteText && secondText) {
      return `${minuteText} et ${secondText}`;
    }
    return minuteText || secondText;
  }

  // Couleur de fond de la barre de cooldown, évolue durant le temps
  function calculateCooldownBackground() {
    const progress = 1 - cooldownRemaining / cooldownDuration;
    const startColor = [68, 68, 68]; // #444
    const endColor = [34, 34, 34];   // #222
    const color = startColor.map((start, index) =>
      Math.round(start + (endColor[index] - start) * progress)
    );
    return `rgb(${color.join(',')})`;
  }

  // --------------------------------------------------
  //  DÉRIVÉS : LISTES DE PROPOSITIONS
  // --------------------------------------------------
  const pendingTracks = suggestionsState
    .filter((track) => track.status === 'En attente')
    .sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

  const acceptedTracks = suggestionsState
    .filter((track) => track.status === 'Accepté')
    .sort((a, b) => a.order - b.order);


    function getPriority(suggestion, playingTracks) {
      // 1) en cours de lecture
      if (playingTracks[suggestion.id]) {
        return 0;
      }
      // 2) Accepté
      if (suggestion.status === 'Accepté') {
        return 1;
      }
      // 3) En attente
      if (suggestion.status === 'En attente') {
        return 2;
      }
      // 4) Refusé
      if (suggestion.status === 'Refusé') {
        return 4;
      }
      // 5) Joué => si vous voulez le mettre
      if (suggestion.status === 'Joué') {
        return 3; 
        // ou 4, selon comment vous souhaitez l'afficher
      }
      return 99; // fallback
    }
    
    function sortSuggestionsForDisplay(a, b) {
      const priorityA = getPriority(a, playingTracks);
      const priorityB = getPriority(b, playingTracks);
    
      // Compare la priorité (0 = top, 3 ou 4 = bas)
      if (priorityA !== priorityB) {
        return priorityA - priorityB;
      }
    
      // Même priorité => si c'est "Accepté", on compare par "order"
      if (priorityA === 1) {
        return a.order - b.order; 
        // plus l’order est petit => plus on est haut
      }
    
      return 0;
    
    }
  // --------------------------------------------------
  //  RENDER
  // --------------------------------------------------
  return (
    <div className="search-page-container">
      <Helmet>
        <title>Mixify - Proposer</title>
        <meta
          name="description"
          content="Recherchez et proposez vos morceaux préférés."
        />
      </Helmet>

      <Logo />
      <div className="event-name">
        {eventName ? `- ${eventName} -` : ''}
      </div>

      {/* ---------------- Modale "Accepté" ---------------- */}
      {showAcceptedModal && (
        <div className="modal-overlay">
          <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
            recycle={false}
            numberOfPieces={200}
            confettiSource={{
              x: window.innerWidth / 2,
              y: 0,
              w: 0,
              h: 0
            }}
            gravity={0.2}
            wind={0.0}
            style={{
              position: 'absolute',
              zIndex: 1000
            }}
          />
          <div className="modal-content-accepted">
            <h2
              style={{
                fontSize: '1.5rem',
                fontWeight: '600',
                color: '#fff',
                marginBottom: '15px'
              }}
            >
              Félicitations !
            </h2>
            <div className="acceptedtrackname">
              {acceptedTrackName}
            </div>
            <p style={{ marginBottom: '20px' }}>
              a été accepté par notre équipe de DJs !
            </p>
            <div className="modal-button-container">
            <div
              className="classic-button fill-center fill-center-10"
              onClick={handleCloseAccepted}
            >
              Fermer
            </div>
            </div>
          </div>
        </div>
      )}

      {/* ---------------- Modale "Joué" ---------------- */}
      {showPlayedModal && (
        <div className="modal-overlay">
          <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
            recycle={false}
            numberOfPieces={200}
            confettiSource={{
              x: window.innerWidth / 2,
              y: 0,
              w: 0,
              h: 0
            }}
            gravity={0.2}
            wind={0.0}
            style={{
              position: 'absolute',
              zIndex: 1000
            }}
          />
          <div className="modal-content-played">
            <h2
              style={{
                fontSize: '1.5rem',
                fontWeight: '600',
                color: '#fff',
                marginBottom: '15px'
              }}
            >
              C'est le moment !
            </h2>
            <p
              className='acceptedtrackname'
            >
              {playedTrackName}
            </p>
            <p style={{ marginBottom: '20px', fontSize: '0.9rem' }}>
              Est actuellement joué par le DJ. <br />
              Rendez-vous sur la piste pour profiter de votre musique !
            </p>
            <div className='modal-button-container'>
            <div
              className="classic-button fill-center fill-center-120"
              onClick={handleClosePlayed}
              style={{ margin: '0 auto' }}
            >
              C'est noté
            </div>
            </div>
          </div>
        </div>
      )}

      {/* ---------------- Titre principal ---------------- */}
      <h1
        className="text-center"
        style={{
          marginTop: '10px',
          marginBottom: '20px',
          color: '#ffffff',
          fontSize: '1.5rem'
        }}
      >
        Rechercher un morceau :
      </h1>

      {/* ---------------- Message de confirmation ---------------- */}
      {confirmationMessage && (
        <div
          className="alert alert-success"
          style={{
            backgroundColor: '#1E5128',
            color: '#ffffff'
          }}
        >
          {confirmationMessage}
        </div>
      )}

      {/* ---------------- Barre de recherche ---------------- */}
      <div className="input-group" style={{ marginBottom: '-25px', position: 'relative' }}>
  <input
    type="search"
    className="form-control search-input"
    placeholder="Recherchez un morceau ici..."
    enterKeyHint="search"
    autoCorrect="off"
    spellCheck="false"
    value={searchQuery}
    onChange={(e) => setSearchQuery(e.target.value)}
    onKeyDown={(e) => {
      if (e.key === 'Enter') {
        handleSearchSubmit();
      }
    }}
    inputMode="search"
  />
  {isLoading && (
    <div className="loader-inside">
      <Loader />
    </div>
  )}
  {searchQuery && (
    <button
      className="searchBar-cross"
      onClick={clearSearch}
      style={{
        color: '#ffffff',
        backgroundColor: '#333',
        borderColor: '#444',
        padding: '0px 15px',
        fontSize: '1.2rem'
      }}
    >
      ✕
    </button>
  )}
</div>

      {/* ---------------- Cooldown (si actif) ---------------- */}
      {cooldownRemaining > 0 && (
        <div
          style={{
            marginTop: '50px',
            marginBottom: '0',
            textAlign: 'center',
            fontSize: '0.8rem'
          }}
        >
          <p>
            Vous devez attendre encore{' '}
            {formatCooldown(cooldownRemaining)} avant de proposer un
            nouveau morceau.
          </p>

          <div
            style={{
              width: '80%',
              margin: '0 auto',
              height: '8px',
              backgroundColor: calculateCooldownBackground(),
              borderRadius: '5px',
              overflow: 'hidden'
            }}
          >
            <div
              style={{
                width: `${(1 - cooldownRemaining / cooldownDuration) * 100}%`,
                height: '100%',
                backgroundColor: '#1E88E5',
                transition: 'width 1s linear'
              }}
            />
          </div>
        </div>
      )}


     {/* ---------------- Propositions de l'utilisateur ---------------- */}
{!isLoading && (
  <div className="user-suggestions mt-4">
    {results.length > 0 ? null : userSuggestions.length === 0 ? (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
          minHeight: '50vh',
          textAlign: 'center',
          color: '#ffffff',
          fontSize: '1.2rem',
        }}
      >
        <p style={{ marginBottom: '20px' }}>Aucun morceau proposé.</p>
      </div>
         
          
        ) : (
          <div className="user-suggestions mt-4">
            <h2
              style={{
                fontSize: '1.2rem',
                textAlign: 'center',
                marginBottom: '20px',
                marginTop: '0',
                color: '#ffffff'
              }}
            >
              Suivez l'état de vos propositions :
            </h2>
            {userSuggestions.slice().sort(sortSuggestionsForDisplay).map((suggestion) => {
              let statusLabel;

              if (playingTracks[suggestion.id]) {
                // Si le morceau est en lecture
                statusLabel = playingTracks[suggestion.id].status;
            } else if (suggestion.status === 'Joué') {
                const timeString = formatTime(suggestion.updated_at);
                statusLabel = `Joué à ${timeString}`;
            } else {
                statusLabel = suggestion.status;
            }

              // Calcul de la position dans la file + temps estimé
              let queuePosition = 0;
              let estimatedTime = 0;

              if (suggestion.status === 'En attente') {
                const idx = pendingTracks.findIndex((t) => t.id === suggestion.id);
                queuePosition = idx >= 0 ? idx + 1 : 0;
                estimatedTime = queuePosition * 1; // 1 minute / piste (exemple)
              } else if (suggestion.status === 'Accepté') {
                const idx = acceptedTracks.findIndex((t) => t.id === suggestion.id);
                queuePosition = idx >= 0 ? idx + 1 : 0;
                estimatedTime = queuePosition * 2; // 5 minutes / piste (exemple)
              }

              return (
                <div
                  key={suggestion.id}
    className={`card-custom ${
        suggestion.status === 'Accepté'
            ? 'accepted'
            : suggestion.status === 'Refusé'
            ? 'refused'
            : suggestion.status === 'Joué'
            ? 'played'
            : ''
    } ${playingTracks[suggestion.id] ? 'blinking' : ''}`}
                >
                  <div className="container">
                    <div className="text-container">
                      <h5 className="title">{suggestion.name}</h5>
                      <p className="artist">
                        {Array.isArray(suggestion.artists)
                          ? suggestion.artists.join(', ')
                          : 'Artiste inconnu'}
                      </p>
                      
                    </div>
                    <div className="status">
                      <p
                        className={`status-text ${
                          suggestion.status === 'Accepté'
                            ? 'accepted'
                            : suggestion.status === 'Refusé'
                            ? 'refused'
                            : suggestion.status === 'Joué'
                            ? 'played'
                            : ''
                        }`}
                        style={{ margin: 0 }}
                      >
                        {statusLabel}
                      </p>
                    </div>
                    
                  </div>
                  
                  {(suggestion.status === 'En attente' || suggestion.status === 'Accepté') && (
                    <>
                <hr className="divider"/>
                <div className="queue-info">
                <span >
  <strong className="important">
    {queuePosition}e
  </strong>{' '}
  {suggestion.status === 'Accepté'
    ? 'dans la playlist'
    : 'dans la file'}
</span>
<span>
  Patientez {' '}
  <strong className="important">
    {queuePosition} à{' '} {estimatedTime + 2} min
  </strong>
</span>
              </div>
              </>
            )}
                  {/* Texte pour les morceaux refusés */}
                  {suggestion.status === 'Refusé' && (
                    <>
                     <hr className="divider"/>
                     <div className="refused-description">
      {suggestion.refusal_reason 
        ? suggestion.refusal_reason
        : refusedText
      }
    </div>
                    </>
                  )}
                </div>
              );
            })}
            </div>
          )}
        </div>
      )}

     {/* ---------------- Résultats de la recherche Spotify ---------------- */}
<div className="mt-4" style={{ marginTop: '60px', minHeight: '300px' }}>
  {initialLoading ? (
    // Affiche plusieurs cards squelette pendant le chargement initial
    <>
     <SkeletonCard />
    <SkeletonCard />
    <SkeletonCard />
    <SkeletonCard />
    <SkeletonCard />
    <SkeletonCard />
    </>
  ) : (
    results.map((track) => {
      const existingSuggestion = suggestionsState.find(
        (s) => s.id === track.id
      );
      return (
        <div
          key={track.id}
          className={`card-custom ${
            existingSuggestion
              ? existingSuggestion.status === 'Accepté'
                ? 'accepted'
                : existingSuggestion.status === 'Refusé'
                ? 'refused'
                : existingSuggestion.status === 'Joué'
                ? 'played'
                : ''
              : ''
          }`}
        >
          <div className="container">
            <div className="text-container">
              <h5 className="title">{track.name}</h5>
              <p className="artist">
                {Array.isArray(track.artists)
                  ? track.artists.map((a) => a.name).join(', ')
                  : 'Artiste inconnu'}
              </p>
            </div>
            <div className="status">
              {existingSuggestion ? (
                <p
                  className={`status-text ${
                    existingSuggestion.status === 'Accepté'
                      ? 'accepted'
                      : existingSuggestion.status === 'Refusé'
                      ? 'refused'
                      : existingSuggestion.status === 'Joué'
                      ? 'played'
                      : ''
                  }`}
                  style={{ margin: 0 }}
                >
                  {existingSuggestion.status}
                </p>
              ) : cooldownRemaining > 0 ? (
                <button className="cooldown-button" disabled>
                  <div
                    className="cooldown-bar"
                    style={{
                      width: `${
                        ((cooldownDuration - cooldownRemaining) / cooldownDuration) *
                        100
                      }%`
                    }}
                  />
                  <span>{formatCooldown(cooldownRemaining)}</span>
                </button>
              ) : (
                <button
                  className="propose-button"
                  onClick={() => handlePropose(track)}
                >
                  Proposer
                  </button>
                  )}
                  
                  
                </div>
                
              </div>
            </div>
          );
        })
      )}
    </div>
    </div>
  );

}

export default SearchPage;