// src/components/SkeletonCard.js
import React from 'react';
import '../styles/ComponentsStyles/SkeletonCard.css';

function SkeletonCard() {
  return (
    <div className="card-custom skeleton-card">
      <div className="container">
        <div className="text-container">
          <div className="skeleton title"></div>
          <div className="skeleton artist"></div>
        </div>
      </div>
    </div>
  );
}

export default SkeletonCard;