import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import '../styles/legalPages.css';

function PolitiqueConfidentialite() {
  return (
    <Layout>
    <div className="privacy-page">
    <div className="privacy-container">
      <Helmet>
        <title>Mixify - Politique de Confidentialité</title>
        <meta name="description" content="Politique de Confidentialité de Mixify." />
      </Helmet>

      <h1>Politique de Confidentialité</h1>

      <section>
        <h2>1. Introduction</h2>
        <p>
          Mixify s'engage à protéger la vie privée de ses utilisateurs. La présente Politique de Confidentialité décrit comment nous collectons, utilisons, stockons et protégeons vos données personnelles.
        </p>
      </section>

      <section>
        <h2>2. Responsable du Traitement</h2>
        <p>
          Le responsable du traitement des données personnelles est : Launchmachine Music
          <br />
          <strong>Adresse :</strong> 91 rue de la Saladelle,34400 Lunel Viel, France
          <br />
          <strong>Email :</strong> contact@mixify.fr
          <br />
          <strong>Numéro de SIRET :</strong> 91348607200014

        </p>
      </section>

      <section>
        <h2>3. Données Collectées</h2>
        <p>
          Nous collectons les données suivantes :
          <ul>
            <li>Informations d'identification (nom d'utilisateur, email, mot de passe)</li>
            <li>Informations de paiement pour les abonnements</li>
            <li>Données de navigation (adresses IP, type de navigateur, pages visitées)</li>
            <li>Informations relatives aux suggestions de morceaux et aux interactions utilisateurs</li>
          </ul>
        </p>
      </section>

      <section>
        <h2>4. Finalités du Traitement</h2>
        <p>
          Les données collectées sont utilisées pour :
          <ul>
            <li>Gérer les comptes utilisateurs et les abonnements</li>
            <li>Fournir et améliorer les services proposés</li>
            <li>Assurer la sécurité et la maintenance du site</li>
            <li>Communiquer avec les utilisateurs (notifications, mises à jour, support)</li>
          </ul>
        </p>
      </section>

      <section>
        <h2>5. Bases Légales</h2>
        <p>
          Le traitement de vos données personnelles repose sur les bases légales suivantes :
          <ul>
            <li>Exécution d'un contrat (gestion des abonnements)</li>
            <li>Consentement (newsletter, communications marketing)</li>
            <li>Obligations légales (conservation des données pour la comptabilité)</li>
            <li>Intérêts légitimes (sécurité du site, amélioration des services)</li>
          </ul>
        </p>
      </section>

      <section>
        <h2>6. Partage des Données</h2>
        <p>
          Nous ne vendons, n'échangeons ni ne transférons vos données personnelles à des tiers sans votre consentement, sauf dans les cas suivants :
          <ul>
            <li>Fournisseurs de services tiers nécessaires au fonctionnement de Mixify (hébergement, paiement, support technique)</li>
            <li>Conformité avec la loi ou protection des droits de Mixify</li>
          </ul>
        </p>
      </section>

      <section>
        <h2>7. Sécurité des Données</h2>
        <p>
          Nous mettons en œuvre des mesures de sécurité appropriées pour protéger vos données contre tout accès non autorisé, altération, divulgation ou destruction.
        </p>
      </section>

      <section>
        <h2>8. Conservation des Données</h2>
        <p>
          Vos données personnelles sont conservées pendant la durée nécessaire à la réalisation des finalités pour lesquelles elles ont été collectées, conformément aux obligations légales.
        </p>
      </section>

      <section>
        <h2>9. Droits des Utilisateurs</h2>
        <p>
          Conformément au RGPD, vous disposez des droits suivants :
          <ul>
            <li>Droit d'accès : obtenir la confirmation que des données vous concernant sont traitées et en obtenir une copie.</li>
            <li>Droit de rectification : demander la correction de données inexactes ou incomplètes.</li>
            <li>Droit à l'effacement : demander la suppression de vos données dans certaines conditions.</li>
            <li>Droit à la limitation du traitement : restreindre le traitement de vos données dans certaines situations.</li>
            <li>Droit à la portabilité des données : obtenir vos données dans un format structuré et couramment utilisé.</li>
            <li>Droit d'opposition : vous opposer au traitement de vos données pour des raisons légitimes.</li>
          </ul>
          Pour exercer ces droits, veuillez nous contacter à l'adresse email mentionnée ci-dessus.
        </p>
      </section>

      <section>
        <h2>10. Cookies</h2>
        <p>
          Mixify utilise des cookies pour améliorer votre expérience utilisateur, analyser l'utilisation du site et personnaliser les contenus. Pour plus d'informations, veuillez consulter notre <a href="/politique-cookies">Politique de Cookies</a>.
        </p>
      </section>

      <section>
        <h2>11. Modifications de la Politique de Confidentialité</h2>
        <p>
          Mixify se réserve le droit de modifier cette Politique de Confidentialité à tout moment. Les modifications seront publiées sur cette page avec une date de mise à jour.
        </p>
      </section>
    </div>
    </div>
    </Layout>
  );
}

export default PolitiqueConfidentialite;