// FICHIER : /src/pages/PaymentSuccessMessagePage.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function PaymentSuccessMessagePage() {
  const navigate = useNavigate();

  useEffect(() => {
    // Récupère l'eventId stocké dans le localStorage (à adapter selon votre implémentation)
    const eventId = localStorage.getItem('lastEventId');
    if (eventId) {
      // Redirige après 5 secondes vers la page de recherche de l'évènement
      const timer = setTimeout(() => {
        navigate(`/search/${eventId}`);
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [navigate]);

  return (
    <div
      style={{
        padding: '40px',
        textAlign: 'center',
        color: '#fff',
        backgroundColor: '#1b1b1b',
        minHeight: '100vh'
      }}
    >
      <h1>Merci pour votre paiement !</h1>
      <p>Votre message a bien été enregistré et va bientôt apparaître.</p>
      <p>Vous serez redirigé vers la page de recherche de l'évènement sous peu.</p>
      <p>
        Si la redirection ne s'effectue pas automatiquement, cliquez sur le bouton ci-dessous.
      </p>
      <button
        onClick={() => {
          const eventId = localStorage.getItem('lastEventId');
          if (eventId) {
            navigate(`/search/${eventId}`);
          } else {
            // Optionnel : rediriger vers une page par défaut si l'eventId n'est pas disponible
            navigate('/send-message');
          }
        }}
        style={{
          padding: '10px 20px',
          fontSize: '1rem',
          borderRadius: '5px',
          cursor: 'pointer'
        }}
      >
        Retour à la recherche
      </button>
    </div>
  );
}

export default PaymentSuccessMessagePage;