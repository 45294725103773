// src/pages/MentionsLegales.js
import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import '../styles/legalPages.css';

function MentionsLegales() {
  return (
    <Layout>
      <div className="privacy-page">
        <div className="privacy-container">
          <Helmet>
            <title>Mixify - Mentions Légales et Politique d’Utilisation de l’API Spotify</title>
            <meta
              name="description"
              content="Mentions Légales et Politique d’Utilisation de l’API Spotify de Mixify."
            />
          </Helmet>

          <h1>📜 Mentions Légales et Politique d’Utilisation de l’API Spotify</h1>

          <section>
            <h2>1️⃣ Utilisation de l’API Spotify</h2>
            <p>
              Notre site utilise l’API de Spotify for Developers afin de proposer une expérience musicale interactive et personnalisée. Grâce à cette API, nous permettons aux utilisateurs de rechercher des morceaux, proposer des titres pour des playlists et interagir avec la musique en temps réel.
            </p>
            <p>
              Nous respectons strictement les conditions d’utilisation de l’API Spotify et veillons à ne pas stocker, modifier ou manipuler les données fournies au-delà des autorisations accordées par Spotify.
            </p>
          </section>

          <section>
            <h2>2️⃣ Attribution et Propriété des Contenus</h2>
            <p>
              Tous les contenus affichés via l’API Spotify (titres de chansons, artistes, albums, pochettes d’album, extraits audio, etc.) sont la propriété exclusive de Spotify et des ayants droit concernés. Nous n’avons aucun contrôle sur ces contenus et ne revendiquons aucun droit de propriété intellectuelle sur ceux-ci.
            </p>
            <p>
              Spotify est une marque déposée appartenant à Spotify AB. Toute utilisation des données issues de Spotify sur notre site respecte les règles et conditions établies par la plateforme.
            </p>
          </section>

          <section>
            <h2>3️⃣ Protection des Données et Confidentialité</h2>
            <p>
              Lorsque vous interagissez avec notre site et utilisez des fonctionnalités basées sur l’API Spotify, certaines données peuvent être traitées afin de garantir le bon fonctionnement du service. Nous nous engageons à :
            </p>
            <ul>
              <li>Ne collecter aucune donnée personnelle sans consentement explicite.</li>
              <li>Ne pas vendre, partager ou exploiter les informations obtenues auprès des utilisateurs.</li>
              <li>
                Respecter la politique de confidentialité de Spotify et les réglementations en vigueur (RGPD, CCPA, etc.).
              </li>
            </ul>
            <p>
              Vous pouvez consulter la politique de confidentialité de Spotify pour plus d’informations sur la manière dont vos données sont traitées par leur plateforme :{' '}
              <a
                href="https://www.spotify.com/legal/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Spotify Privacy Policy
              </a>.
            </p>
          </section>

          <section>
            <h2>4️⃣ Limitations et Responsabilité</h2>
            <p>
              Notre service fonctionne en connectant l’API Spotify, ce qui signifie que toute modification ou restriction appliquée par Spotify peut affecter la disponibilité des fonctionnalités offertes sur notre site. Nous ne garantissons pas un accès permanent et illimité aux services liés à Spotify.
            </p>
            <p>
              Si vous avez des questions concernant l’utilisation de l’API Spotify sur notre site, vous pouvez nous contacter à l’adresse suivante :{' '}
              <a href="mailto:contact@mixify.fr">contact@mixify.fr</a>.
            </p>
          </section>
        </div>
      </div>
    </Layout>
  );
}

export default MentionsLegales;