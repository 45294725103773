import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { supabase } from "../utils/supabaseClient";
import "../styles/HomePage.css"; // Fichier CSS associé
import MainHeader from "../components/MainHeader";
import Footer from "../components/Footer";
import Mockup1 from "../assets/images/mockup-scannez-left.png";
import BackgroundVideo from "../assets/videos/background.mp4";
import Logo from "../components/Logo";
import PhoneMockup from "../assets/videos/phone-mockup.mp4";
import PlaylistVideo1 from "../assets/videos/playlist1.mp4";
import PlaylistVideo2 from "../assets/videos/playlist2.mp4";


function HomePage() {
  const [session, setSession] = useState(null);
  const [topTenSuggestions, setTopTenSuggestions] = useState([]);

  // État et ref pour l’observer
  const [isPhoneSectionVisible, setIsPhoneSectionVisible] = useState(false);
  const phoneSectionRef = useRef(null);
  const playlist1Ref = useRef(null);
  const playlist2Ref = useRef(null);

  useEffect(() => {
    // Récupération de la session Supabase existante
    const fetchSession = async () => {
      const { data } = await supabase.auth.getSession();
      setSession(data.session);
    };
    fetchSession();

    // Intersection Observer pour déclencher l’animation au scroll
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsPhoneSectionVisible(true);
        }
      },
      { threshold: 0.2 }
    );

    const currentElement = phoneSectionRef.current;
    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, []);

  useEffect(() => {
    const video2 = playlist2Ref.current;
    const handleEnded = () => {
      if (playlist1Ref.current && video2) {
        // Réinitialiser les vidéos et les relancer
        playlist1Ref.current.currentTime = 0;
        video2.currentTime = 0;
        playlist1Ref.current.play();
        video2.play();
      }
    };

    if (video2) {
      video2.addEventListener("ended", handleEnded);
    }

    return () => {
      if (video2) {
        video2.removeEventListener("ended", handleEnded);
      }
    };
  }, []);

  useEffect(() => {
    // On veut récupérer les 30 derniers jours
    const now = new Date();
    const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
  
    // Fonction interne pour fetch
    const fetchTopTen = async () => {
      try {
        // 1) On récupère toutes les suggestions depuis Supabase
        let { data, error } = await supabase
          .from("suggestions")
          .select("id, name, artists, created_at")
          .gte("created_at", thirtyDaysAgo.toISOString());
  
        if (error) {
          console.error("Erreur lors de la récupération des suggestions :", error);
          return;
        }
  
        // 2) On regroupe par (title + artists)
        const groupedMap = {};
  
        data.forEach((item) => {
          let artistList;
          // 'artists' peut être un tableau ou une string JSON => on harmonise
          if (typeof item.artists === "string") {
            try {
              artistList = JSON.parse(item.artists);
            } catch {
              artistList = ["Artiste inconnu"];
            }
          } else if (Array.isArray(item.artists)) {
            artistList = item.artists;
          } else {
            artistList = ["Artiste inconnu"];
          }
  
          const trackName = item.name || "Titre inconnu";
          const artistsJoined = artistList.join(", ");
          const key = `${trackName}__${artistsJoined}`;
  
          if (!groupedMap[key]) {
            groupedMap[key] = {
              name: trackName,
              artists: artistList,
              count: 0,
            };
          }
          groupedMap[key].count += 1;
        });
  
        // 3) On convertit l'objet en tableau et on trie par 'count' décroissant
        const sortedTracks = Object.values(groupedMap).sort((a, b) => b.count - a.count);
  
        // 4) On garde seulement les 10 premiers
        const top10 = sortedTracks.slice(0, 10);
  
        // 5) On met ça dans notre state
        setTopTenSuggestions(top10);
      } catch (err) {
        console.error("Erreur fetchTopTen : ", err);
      }
    };
  
    fetchTopTen();
  }, []);

  return (
    <div className="home-container">
      {/* ================= HEADER ================= */}
      <MainHeader />

      {/* ================= HERO VIDEO SECTION ================= */}
      <section className="hero-video-section">
        <video className="hero-video" autoPlay muted loop playsInline>
          <source src={BackgroundVideo} type="video/mp4" />
          Votre navigateur ne supporte pas la lecture de vidéo.
        </video>
        <div className="hero-overlay">
          <Logo className="hero-logo" />
        </div>
        <div className="hero-text">
          L’application qui transforme vos soirées en expériences
          collaboratives.
        </div>
      </section>

      {/* ================= SECTION IMAGE MOCKUP ================= */}
      <section
  className={`phone-feature-section ${
    isPhoneSectionVisible ? "phone-visible" : ""
  }`}
  ref={phoneSectionRef}
>
  <div className="phone-video-container">
    <video 
      className="phone-mockup-video" 
      autoPlay 
      muted 
      loop 
      playsInline
    >
      <source src={PhoneMockup} type="video/mp4" />
      Votre navigateur ne supporte pas la lecture de vidéo.
    </video>
  </div>

  <div className="phone-feature-text">
    <h3 className="section-title">Recevez les propositions du public</h3>
    <p className="section-text">
      Recevez les propositions du public et offrez-leur la possibilité
      de suivre l’état de leurs propositions en temps réel.
    </p>
  </div>
</section>

 {/* ================= SECTION SESSION PLAYLIST ================= */}
 <section className="playlist-session-section">
        <div className="playlist-session-content">
          <h3 className="section-title">
            Démarrez une session avec nos Playlist
          </h3>
          <p className="section-text">
            créez des playlists avec les propositions du public où intégrez leurs
            propositions à vos playlist.
          </p>
          <div className="playlist-videos-container">
            <div className="playlist-video small">
              <video
                ref={playlist1Ref}
                autoPlay
                muted
                playsInline
                // On retire "loop" pour contrôler manuellement le redémarrage
              >
                <source src={PlaylistVideo1} type="video/mp4" />
                Votre navigateur ne supporte pas la lecture de vidéo.
              </video>
            </div>
            <div className="playlist-video large">
              <video
                ref={playlist2Ref}
                autoPlay
                muted
                playsInline
                // On retire "loop" ici également
              >
                <source src={PlaylistVideo2} type="video/mp4" />
                Votre navigateur ne supporte pas la lecture de vidéo.
              </video>
            </div>
          </div>
        </div>
      </section>

{/* ================= SECTION CHARTS PREVIEW ================= */}
    {/* NOUVEAU CODE : Aperçu du Top 10 */}
    <section className="charts-preview-section">
      <div className="charts-preview-content">
        <h3 className="section-title">
          {/* Choisis l’un des titres suggérés ci-dessus */}
          Suivez les tendances avec les <span className="mixify-charts-blue">Charts</span>
        </h3>

        <p className="section-text">
      Suivez les tendances des morceaux les plus proposés, les plus joués et filtrez par type d'évènements. <br />(soirées privées, clubs, lounge)
    </p>

        {/* On affiche la liste */}
        <div className="charts-preview-list">
          {topTenSuggestions.map((track, index) => (
            <div key={index} className="chart-item">
              <div className="chart-item-rank">#{index + 1}</div>
              <div className="chart-item-info">
                <div className="chart-item-name">{track.name}</div>
                <div className="chart-item-artists">
                  {track.artists.join(", ")}
                </div>
              </div>
              <div className="chart-item-count">
                {track.count} propositions
              </div>
            </div>
          ))}
        </div>

        {/* Bouton vers la page charts */}
        <div style={{ marginTop: "20px" }}>
          <NavLink to="/charts" className="go-to-charts-button">
            Voir le top 100
          </NavLink>
        </div>
      </div>
    </section>

      {/* ================= SECTION : Pour les établissements ================= */}
      <section className="home-section alt-background fullwidth-section">
        <div className="section-inner">
          <h3 className="section-title">Pour les établissements</h3>
          <p className="section-text">
            Que vous soyez propriétaire d’un <strong>bar</strong>, d’une{" "}
            <strong>boîte de nuit</strong> ou d’un <strong>restaurant</strong>
            avec ambiance musicale, Mixify vous apporte :
          </p>
          <ul className="section-list">
            <li>
              <strong>Dynamiser</strong> l’ambiance : impliquer vos clients, qui
              se sentiront acteurs de la soirée.
            </li>
            <li>
              <strong>Fidéliser</strong> votre clientèle : une expérience
              interactive incite vos visiteurs à revenir.
            </li>
            <li>
              <strong>Contrôler</strong> la playlist via la validation DJ, pour
              garder la qualité et le style souhaité.
            </li>
            <li>
              <strong>Facilité</strong> d’installation : un simple QR code et le
              tour est joué !
            </li>
          </ul>
          <p className="section-text">
            Offrez une expérience
            <em>unique</em>, <em>innovante</em> et boostez votre notoriété !
          </p>
        </div>
      </section>

      {/* ================= SECTION : Pour les DJs ================= */}
      <section className="home-section fullwidth-section">
        <div className="section-inner">
          <h3 className="section-title">Pour les DJs</h3>
          <p className="section-text">
            Mixify est aussi un <strong>formidable atout</strong> pour vous :
          </p>
          <ul className="section-list">
            <li>
              <strong>Valoriser</strong> votre prestation et justifier un cachet
              plus élevé (gestion des demandes, animation interactive...).
            </li>
            <li>
              <strong>Élargir</strong> votre bibliothèque musicale grâce aux
              suggestions de clients.
            </li>
            <li>
              <strong>Garder</strong> la main sur la sélection, refus ou
              acceptation en un clic.
            </li>
            <li>
              <strong>Gagner</strong> du temps : l’interface claire permet de
              traiter rapidement les propositions.
            </li>
          </ul>
        </div>
      </section>

      {/* ================= SECTION : Utilisation intuitive ================= */}
      <section className="home-section alt-background fullwidth-section">
        <div className="section-inner">
          <h3 className="section-title">Une utilisation simple</h3>
          <p className="section-text">
            <strong>Établissement</strong> : vous générez un QR code spécifique
            à l’événement, vos clients le scannent, et ont accès à la page de votre évènement.
            <br />
            <img
              src={Mockup1}
              alt="Exemple Interface Mixify"
              className="mockup-image"
            />
            <br />
            
            <strong>DJ</strong> : un tableau de bord présente tous les outils Mixify. 
            Vous pouvez personnaliser chaque outils et rendre vos évènements uniques.
          </p>
          <p className="section-text">
            Mixify est intuitif, rapide et accessible : pas besoin de
            compétences techniques avancées.
          </p>
        </div>
      </section>

      {/* ================= FOOTER ================= */}
      <Footer />
      {/* Bouton fixe visible uniquement lorsque l'utilisateur est connecté */}
      {session && (
        <NavLink to="/events-manager" className="fixed-event-button">
          Accéder à mon espace évènements
        </NavLink>
      )}
    </div>
  );
}

export default HomePage;
