import React, { useEffect, useState } from "react";

// Utils / services
import { supabase } from "../utils/supabaseClient";
import { getSetting, updateSetting } from "../utils/settings";
import { clearSuggestions } from "../utils/suggestions";
import { resetAllCooldowns } from "../utils/cooldownUtils";

// Autres components
import Radio from "./Radio.js";

// Styles
import "./SettingsModal.css";

// ----------------------------------------------------------------------
// Composant : SettingsAndResetModal
// ----------------------------------------------------------------------
function SettingsModal({
  isOpen, // booléen : indique si la modale doit s'afficher
  onClose, // callback : fermer la modale
  eventId, // l'ID de l'événement sur lequel on bosse
  planLevel, // pour savoir si on lock certaines features
  onSaved, // callback appelé après un "Enregistrer"

  // Optionnel : si tu veux la réinitialisation,
  // ou tu peux la retirer si pas nécessaire
}) {
  // Toutes les infos qu'on va charger depuis la DB
  const [eventName, setEventName] = useState("");
  const [eventType, setEventType] = useState("");
  const [eventStatus, setEventStatus] = useState("ongoing");
  const [autoStartEnabled, setAutoStartEnabled] = useState(false);
  const [eventStartTimeLocal, setEventStartTimeLocal] = useState("");
  const [authenticationEnabled, setAuthenticationEnabled] = useState(true);
  const [cooldownDuration, setCooldownDuration] = useState(300);
  const [refusedText, setRefusedText] = useState("");
  const [refusalReasonInputs, setRefusalReasonInputs] = useState([
    { keyword: "", label: "" },
    { keyword: "", label: "" },
    { keyword: "", label: "" },
  ]);
  const [endEventMessage, setEndEventMessage] = useState("");
  const [showEndpageStats, setShowEndpageStats] = useState(false);
  const [endEventInstagram, setEndEventInstagram] = useState("");
  const [endEventWebsite, setEndEventWebsite] = useState("");
  const [waitingMessage, setWaitingMessage] = useState("");
  const [musicStyle, setMusicStyle] = useState("");
  const [activeTab, setActiveTab] = useState("general");
  const [showModal, setShowModal] = useState(false);
  const [setSuggestions] = useState([]);

  // (H) Convertir date/heure locale en UTC (pour stocker dans Supabase)
  function convertLocalToUTC(localString) {
    const dateObj = new Date(localString);
    return dateObj.toISOString();
  }

  const handleReset = async () => {
    if (!eventId) {
      console.error(
        "Aucun eventId fourni. Impossible de réinitialiser la session."
      );
      return;
    }
    console.log(
      `Réinitialisation de la session pour l'événement ${eventId}...`
    );
    await clearSuggestions(eventId);
    setSuggestions([]);
    await resetAllCooldowns(eventId);
    setShowModal(false);

    await supabase
      .from("evenements")
      .update({
        cooldown_reset_at: new Date().toISOString(),
        session_reset_at: new Date().toISOString(),
      })
      .eq("id", eventId);

    console.log("Session réinitialisée avec succès.");
  };

  function convertUTCToLocalInput(utcString) {
    if (!utcString) return "";
    const dateObj = new Date(utcString);
    const tzOffset = dateObj.getTimezoneOffset();
    const localTime = new Date(dateObj.getTime() - tzOffset * 60_000);
    return localTime.toISOString().slice(0, 16);
  }
  // useEffect pour charger l'événement quand la modale s'ouvre
  useEffect(() => {
    console.log(
      "[SettingsModal] useEffect -> isOpen =",
      isOpen,
      ", eventId =",
      eventId
    );
    if (!isOpen || !eventId) return;

    async function fetchEventData() {
      console.log("[SettingsModal] fetchEventData -> pour eventId=", eventId);
      try {
        const { data: eventRow, error: e1 } = await supabase
          .from("evenements")
          .select("*")
          .eq("id", eventId)
          .single();

        if (e1) {
          console.error("[SettingsModal] fetchEventData => ERREUR e1:", e1);
          return;
        }
        console.log("[SettingsModal] fetchEventData => eventRow:", eventRow);

        if (e1 || !eventRow) {
          console.error("Erreur fetchEventData : ", e1);
          return;
        }

        // 2) Mettre à jour les states (exemple)
        setEventName(eventRow.name || "");
        setEventType(eventRow.event_type || "");
        setEventStatus(eventRow.event_status || "waiting");

        const asEnabled = !!eventRow.auto_start_enabled;
        setAutoStartEnabled(asEnabled);

        // 3) Récupérer d'autres settings liés à l’event
        const cd = await getSetting(eventId, "cooldown");
        if (cd) setCooldownDuration(Number(cd));

        const refused = await getSetting(eventId, "refused_text");
        if (refused) setRefusedText(refused);

        const rawReasons = await getSetting(eventId, "refused_text_reason");
        if (rawReasons) {
          setRefusalReasonInputs(JSON.parse(rawReasons));
        } else {
          // Valeurs par défaut
          setRefusalReasonInputs([
            { keyword: "default", label: "Par défaut..." },
            { keyword: "Autre", label: "Une autre raison..." },
          ]);
        }

        const endMsg = await getSetting(eventId, "end_event_message");
        setEndEventMessage(endMsg || "");

        const seeStats = await getSetting(eventId, "show_endpage_stats");
        setShowEndpageStats(!!seeStats);

        const insta = await getSetting(eventId, "end_event_instagram");
        setEndEventInstagram(insta || "");

        const web = await getSetting(eventId, "end_event_website");
        setEndEventWebsite(web || "");

        const waitMsg = await getSetting(eventId, "waiting_message");
        setWaitingMessage(waitMsg || "");

        const ms = await getSetting(eventId, "music_style");
        setMusicStyle(ms || "Global Music");

        const authEnabled = await getSetting(eventId, "authentication_enabled");
        setAuthenticationEnabled(!!authEnabled);

        const startTime = await getSetting(eventId, "event_start_time");
        if (startTime) {
          // Convertir UTC -> local
          const localVal = convertUTCToLocalInput(startTime);
          setEventStartTimeLocal(localVal);
        }
      } catch (err) {
        console.error("[SettingsModal] fetchEventData => Exception:", err);
      }
    }

    fetchEventData();
  }, [isOpen, eventId]);

  // Fermer la modale si on appuie sur Echap
  useEffect(() => {
    if (!isOpen) return;
    const handleEsc = (e) => {
      if (e.key === "Escape") onClose();
    };
    window.addEventListener("keydown", handleEsc);
    return () => window.removeEventListener("keydown", handleEsc);
  }, [isOpen, onClose]);

  // Rendu
  if (!isOpen) return null;

  return (
    <>
      {/* --------------------------------------------------------
          La modale de paramètres
         -------------------------------------------------------- */}
      <div className="modal-settings" onClick={onClose}>
        <div
          className="modal-settings-content"
          onClick={(e) => e.stopPropagation()}
        >
          <h2 className="modal-title">Paramètres</h2>
          <div className="settings-container">
            {/* Barre d’onglets à gauche */}
            <div className="settings-tabs-left">
              <div
                className={`settings-tab ${
                  activeTab === "general" ? "active" : ""
                }`}
                onClick={() => setActiveTab("general")}
              >
                Général
              </div>
              <div
                className={`settings-tab ${
                  activeTab === "refus" ? "active" : ""
                }`}
                onClick={() => setActiveTab("refus")}
              >
                Personnalisation
              </div>
              <div
                className={`settings-tab ${
                  activeTab === "status" ? "active" : ""
                }`}
                onClick={() => setActiveTab("status")}
              >
                Statut
              </div>
            </div>

            {/* Contenu de l’onglet sélectionné */}
            <div className="settings-tabs-right">
              {activeTab === "general" && (
                <>
                  {/* Nom d’évènement */}
                  <div style={{ marginBottom: "20px" }}>
                    <label style={{ fontSize: "0.9rem", color: "#ccc" }}>
                      Nom de l'évènement :
                    </label>
                    <input
                      type="text"
                      value={eventName}
                      onChange={(e) => setEventName(e.target.value)}
                      style={{
                        width: "100%",
                        backgroundColor: "#444",
                        color: "#fff",
                        border: "1px solid #666",
                        padding: "5px",
                        marginTop: "5px",
                      }}
                    />
                  </div>

                  {/* Type d’évènement */}
                  <div style={{ marginBottom: "20px" }}>
                    <label style={{ fontSize: "0.9rem", color: "#ccc" }}>
                      Type d'évènement :
                    </label>
                    <select
                      value={eventType}
                      onChange={(e) => setEventType(e.target.value)}
                      style={{
                        width: "100%",
                        backgroundColor: "#444",
                        color: "#fff",
                        border: "1px solid #666",
                        padding: "5px",
                        marginTop: "5px",
                      }}
                    >
                      <option value="Soirée privée">
                        Soirée privée (mariages, anniversaires, etc)
                      </option>
                      <option value="Clubs, bars dansants">
                        Clubs, bars dansants
                      </option>
                      <option value="restaurant, lounge">
                        restaurant, lounge
                      </option>
                    </select>
                  </div>

                  {/* Choisir le statut */}
                  <div style={{ marginBottom: "20px" }}>
                    <h3>Choisir le statut de l’événement</h3>
                    <Radio
                      options={[
                        {
                          key: "waiting",
                          label: "En attente",
                          color: "#FFC107",
                        },
                        {
                          key: "ongoing",
                          label: "En cours",
                          color: "#2196F3",
                        },
                        {
                          key: "ended",
                          label: "Terminé",
                          color: "#4CAF50",
                        },
                      ]}
                      selected={eventStatus}
                      onChange={(newVal) => {
                        setEventStatus(newVal);
                        console.log("Statut modifié localement à :", newVal);
                      }}
                    />
                  </div>

                  {/* Auto-start */}
                  <div
                    className="settings-toggle"
                    style={{ marginTop: "20px" }}
                  >
                    <label className="settings-label">
                      <input
                        type="checkbox"
                        checked={autoStartEnabled}
                        onChange={() => {
                          const newVal = !autoStartEnabled;
                          setAutoStartEnabled(newVal);
                        }}
                        className="ui-checkbox"
                      />{" "}
                      Activer le démarrage automatique et le compte à rebours
                      {planLevel < 2 && <span> (LOCKED)</span>}
                    </label>
                  </div>

                  {autoStartEnabled && (
                    <div style={{ marginTop: "20px" }}>
                      <label style={{ fontSize: "0.9rem", color: "#ccc" }}>
                        Date/Heure de début :{" "}
                        {planLevel < 2 && <span> (LOCKED)</span>}
                      </label>
                      <input
                        type="datetime-local"
                        value={eventStartTimeLocal}
                        onChange={(e) => setEventStartTimeLocal(e.target.value)}
                        style={{
                          width: "100%",
                          backgroundColor: "#444",
                          color: "#fff",
                          border: "1px solid #666",
                          padding: "5px",
                          marginTop: "5px",
                        }}
                        disabled={planLevel < 2}
                      />
                    </div>
                  )}

                  {/* Réinitialisation */}
                  <p style={{ marginTop: "15px" }}>Réinitialiser la session</p>
                  <button
                    className="btn btn-outline-danger btn-reset-session"
                    onClick={() => setShowModal(true)}
                  >
                    Réinitialiser la session
                  </button>
                  <hr style={{ marginTop: "15px", marginBottom: "20px" }} />

                  {/* Authentification */}
                  <div
                    className="settings-toggle"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                    }}
                  >
                    <label className="settings-label">
                      <input
                        type="checkbox"
                        checked={authenticationEnabled}
                        disabled={planLevel < 2}
                        onChange={async () => {
                          const newAuthEnabled = !authenticationEnabled;
                          try {
                            await updateSetting(
                              eventId,
                              "authentication_enabled",
                              newAuthEnabled
                            );
                            setAuthenticationEnabled(newAuthEnabled);
                          } catch (error) {
                            console.error(
                              "Erreur lors de la mise à jour de l'authentification :",
                              error
                            );
                          }
                        }}
                        className="ui-checkbox"
                      />{" "}
                      Activer l'authentification{" "}
                      {planLevel < 2 && <span> (LOCKED)</span>}
                    </label>
                    {authenticationEnabled && (
                      <button
                        className="btn btn-warning"
                        style={{ marginLeft: "10px", marginBottom: "10px" }}
                        disabled
                      >
                        Code Manager (exemple)
                      </button>
                    )}
                  </div>

                  {/* Cooldown en minutes */}
                  <div style={{ marginBottom: "40px" }}>
                    <label style={{ fontSize: "0.9rem", color: "#ccc" }}>
                      Temps d'attente entre propositions (en minutes) :
                    </label>
                    <input
                      type="number"
                      min="0"
                      value={cooldownDuration / 60}
                      onChange={(e) =>
                        setCooldownDuration(Number(e.target.value) * 60)
                      }
                      style={{
                        marginLeft: "10px",
                        width: "60px",
                        backgroundColor: "#444",
                        color: "#fff",
                        border: "1px solid #666",
                        padding: "5px",
                      }}
                    />
                  </div>
                </>
              )}

              {activeTab === "refus" && (
                <>
                  <div style={{ marginBottom: "20px" }}>
                    <label style={{ fontSize: "0.9rem", color: "#ccc" }}>
                      Motifs de refus :
                    </label>
                    {refusalReasonInputs.map((item, index) => (
                      <div key={index} className="refusal-reason-card">
                        <div className="refusal-reason-title">
                          Motif {index + 1}
                        </div>
                        <div className="refusal-reason-actions">
                          <input
                            type="text"
                            className={`refusal-reason-input ${
                              item.keyword === "default" ? "default" : ""
                            }`}
                            value={item.keyword}
                            onChange={(e) => {
                              if (item.keyword === "default") return;
                              const newArray = [...refusalReasonInputs];
                              newArray[index].keyword = e.target.value;
                              setRefusalReasonInputs(newArray);
                            }}
                            placeholder="Mot clé"
                            disabled={item.keyword === "default"}
                          />
                          {index > 0 && (
                            <button
                              className="refusal-reason-button delete"
                              onClick={() => {
                                const newArray = [...refusalReasonInputs];
                                newArray.splice(index, 1);
                                setRefusalReasonInputs(newArray);
                              }}
                            >
                              Supprimer
                            </button>
                          )}
                        </div>
                        <textarea
                          className="refusal-reason-textarea"
                          value={item.label}
                          onChange={(e) => {
                            const newArray = [...refusalReasonInputs];
                            newArray[index].label = e.target.value;
                            setRefusalReasonInputs(newArray);
                          }}
                          placeholder="Texte complet du motif..."
                        />
                      </div>
                    ))}
                    {refusalReasonInputs.length < 5 && (
                      <button
                        className="refusal-reason-button add"
                        onClick={() => {
                          if (refusalReasonInputs.length < 5) {
                            const newArray = [...refusalReasonInputs];
                            newArray.push({ keyword: "", label: "" });
                            setRefusalReasonInputs(newArray);
                          }
                        }}
                      >
                        Ajouter un motif
                      </button>
                    )}
                  </div>
                </>
              )}

              {activeTab === "status" && (
                <div>
                  <h3>Parametrez les pages de status de l'évènement.</h3>

                  {/* Message de fin de soirée */}
                  <div style={{ marginBottom: "20px" }}>
                    <label style={{ fontSize: "0.9rem", color: "#ccc" }}>
                      Message de fin de soirée{" "}
                      {planLevel < 2 && <span> (LOCKED)</span>}
                    </label>
                    <textarea
                      rows={4}
                      value={endEventMessage}
                      onChange={(e) => setEndEventMessage(e.target.value)}
                      placeholder="Exemple : Merci pour votre contribution..."
                      style={{
                        width: "100%",
                        backgroundColor: "#444",
                        color: "#fff",
                        border: "1px solid #666",
                        padding: "5px",
                        marginTop: "5px",
                      }}
                      disabled={planLevel < 2}
                    />
                  </div>

                  {/* Lien Instagram */}
                  <div style={{ marginBottom: "20px" }}>
                    <label style={{ fontSize: "0.9rem", color: "#ccc" }}>
                      Lien Instagram {planLevel < 2 && <span>(LOCKED)</span>}
                    </label>
                    <input
                      type="text"
                      value={endEventInstagram}
                      onChange={(e) => setEndEventInstagram(e.target.value)}
                      placeholder="Lien vers votre Instagram"
                      style={{
                        width: "100%",
                        backgroundColor: "#444",
                        color: "#fff",
                        border: "1px solid #666",
                        padding: "5px",
                        marginTop: "5px",
                      }}
                      disabled={planLevel < 2}
                    />
                  </div>

                  {/* Lien Site Web */}
                  <div style={{ marginBottom: "20px" }}>
                    <label style={{ fontSize: "0.9rem", color: "#ccc" }}>
                      Lien Site Web {planLevel < 2 && <span>(LOCKED)</span>}
                    </label>
                    <input
                      type="text"
                      value={endEventWebsite}
                      onChange={(e) => setEndEventWebsite(e.target.value)}
                      placeholder="Lien vers votre site"
                      style={{
                        width: "100%",
                        backgroundColor: "#444",
                        color: "#fff",
                        border: "1px solid #666",
                        padding: "5px",
                        marginTop: "5px",
                      }}
                      disabled={planLevel < 2}
                    />
                  </div>

                  {/* Message de la page d’attente */}
                  <div style={{ marginTop: "20px" }}>
                    <label style={{ fontSize: "0.9rem", color: "#ccc" }}>
                      Message de la page d’attente{" "}
                      {planLevel < 2 && <span>(LOCKED)</span>}
                    </label>
                    <textarea
                      rows={4}
                      value={waitingMessage}
                      onChange={(e) => setWaitingMessage(e.target.value)}
                      placeholder="Message affiché sur la page d’attente"
                      style={{
                        width: "100%",
                        backgroundColor: "#444",
                        color: "#fff",
                        border: "1px solid #666",
                        padding: "5px",
                        marginTop: "5px",
                      }}
                      disabled={planLevel < 2}
                    />
                  </div>

                  <div style={{ marginBottom: "20px" }}>
                    <label style={{ fontSize: "0.9rem", color: "#ccc" }}>
                      Style musical affiché sur la page de propositions{" "}
                      {planLevel < 2 && <span>(LOCKED)</span>}
                    </label>
                    <input
                      type="text"
                      value={musicStyle}
                      onChange={(e) => setMusicStyle(e.target.value)}
                      placeholder="Ex : Lounge - Global Music"
                      style={{
                        width: "100%",
                        backgroundColor: "#444",
                        color: "#fff",
                        border: "1px solid #666",
                        padding: "5px",
                        marginTop: "5px",
                      }}
                      disabled={planLevel < 2}
                    />
                  </div>

                  {/* Toggle affichage stats en fin de soirée */}
                  <div style={{ marginTop: "20px" }}>
                    <label style={{ fontSize: "0.9rem", color: "#ccc" }}>
                      <input
                        type="checkbox"
                        checked={showEndpageStats}
                        onChange={() => setShowEndpageStats(!showEndpageStats)}
                        style={{ marginRight: "8px" }}
                      />
                      Afficher les statistiques de l’utilisateur (fin de soirée)
                    </label>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Boutons Enregistrer / Annuler */}
          <div className="modal-buttons">
            <div className="classic-button-non" onClick={onClose}>
              Annuler
            </div>
            <div
              className="classic-button"
              onClick={async () => {
                // --- SAUVEGARDE DES PARAMÈTRES ---

                // Exemples de mise à jour dans la BDD Supabase
                // Vous pouvez adapter selon vos besoins

                // Nom de l’évènement
                await updateSetting(eventId, "name", eventName);

                // Type d'évènement
                await updateSetting(eventId, "event_type", eventType);

                // Statut
                await updateSetting(eventId, "event_status", eventStatus);

                // Auto-start
                await updateSetting(
                  eventId,
                  "auto_start_enabled",
                  autoStartEnabled
                );

                if (autoStartEnabled && eventStartTimeLocal) {
                  const isoString = convertLocalToUTC(eventStartTimeLocal);
                  await updateSetting(eventId, "event_start_time", isoString);
                }

                // Cooldown
                const validatedCooldown = Math.max(
                  0,
                  parseInt(cooldownDuration, 10)
                );
                if (!isNaN(validatedCooldown)) {
                  await updateSetting(
                    eventId,
                    "cooldown",
                    String(validatedCooldown)
                  );
                }

                // Texte refusé
                await updateSetting(eventId, "refused_text", refusedText);

                // Motifs de refus (tableau JSON)
                const serializedReasons = JSON.stringify(refusalReasonInputs);
                await updateSetting(
                  eventId,
                  "refused_text_reason",
                  serializedReasons
                );
                // MàJ localement
                // setRefusalReasons(refusalReasonInputs); // si besoin

                // Fin de soirée
                await updateSetting(
                  eventId,
                  "end_event_message",
                  endEventMessage
                );
                await updateSetting(
                  eventId,
                  "show_endpage_stats",
                  showEndpageStats
                );
                await updateSetting(
                  eventId,
                  "end_event_instagram",
                  endEventInstagram
                );
                await updateSetting(
                  eventId,
                  "end_event_website",
                  endEventWebsite
                );
                onSaved();
                // Page d’attente
                await updateSetting(eventId, "waiting_message", waitingMessage);
                await updateSetting(eventId, "music_style", musicStyle);
                // Fermer la modale
                onClose();
              }}
            >
              Enregistrer
            </div>
          </div>
        </div>
      </div>

      {/* --------------------------------------------------------
          Petite modale de confirmation "réinitialisation session"
         -------------------------------------------------------- */}
      {showModal && (
        <div className="modal-reinit">
          <div className="modal-reinit-content">
            <div className="modal-reinit-text">
              Voulez-vous vraiment réinitialiser la session ?
            </div>
          </div>
          <div className="modal-reinit-content-buttons">
            <div
              className="classic-button-non"
              onClick={() => setShowModal(false)}
            >
              Annuler
            </div>
            <div className="classic-button" onClick={handleReset}>
              Oui
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SettingsModal;
