// src/data/updatesData.js
export const updates = {
  currentVersion: "1.1.8",
  versionNotes: [
    {
      version: "1.1.8",
      notes: [
        "Nouvelle fonctionnalité : Spotify Playlist est disponible ! Intégrez les demandes des clients directement dans une playlist Spotify !",
        "Nouvelle fonctionnalité : Envoi et diffusion de messages personnalisés pour les clients d'un évènement",
        "Nouvelle fonctionnalité : Téléchargement de la liste des morceaux joués lors d'un évènement",
        "Refonte visuelle partielle de la page Profil, du gestionnaire d’évènements et de la page d'attente clients pour une meilleure ergonomie.",
        "Correction du bug d'affichage de l’avatar et du menu utilisateur bloqué après connexion via Google.",
        "Optimisations générales : meilleure fluidité et stabilité de l'application.",
      ],
    },
    {
      version: "1.1.7",
      notes: [
        "Ajout de l'authentification via Google.",
        "Ajout du tableau de bord de Playlists qui permet la gestion de suggestions via Spotify uniquement, suivi des propositions en temps réél.",
        "Ajout des photos de profils.",
        "Améliorations globales des interfaces utilisateurs sur mobile et desktop.",
        "Ajout de protections supplémentaires pour les données utilisateurs.",
        "Correction de bugs pour améliorer la fluidité de l'application.",
      ],
    },
    {
      version: "1.1.6",
      notes: [
        "Correction de bugs pour améliorer la fluidité de l'application.",
        "Améliorations de l'interface utilisateur pour une meilleure expérience.",
        "Ajout d'une fonctionnalité permettant la programmation du lancement automatique d'un évènement dans les paramètres du Tableau de Bord.",
        "Modification de l'interface de la page de gestion des évènements. La modification, le renommage, la duplication (nouvelle fonctionnalité) et la génération du QR Code sont maintenant centralisés dans un menu déroulant situé tout à droite de la case d'un évènement.",
        "Création de compte plus sécurisée.",
      ],
    },
  ],
  upcoming: [
    "Amélioration des interfaces des différents tableaux de bord et des outils du gestionnaire d'évènements.",
    "Permettre une personnalisation plus complète des pages clients de propositions de morceaux.",
    "Implémentation d'un système d'invitation pour permettre à d'autres DJs / organisateurs / établissements de consulter et de participer à la gestion de vos évènements",
    "Optimisation des pages sur mobile.",
  ],
};
