// src/pages/AboutPage.js
import React from 'react';
import Layout from '../components/Layout';
import '../styles/AboutPage.css';
import FixedEventButton from '../components/FixedEventButton';

function AboutPage() {
  return (
    <Layout>
    <div className="about-page">
      <div className="about-container">
        <h1 className="about-title">À propos de Mixify</h1>

        <section className="about-section">
          <h2 className="about-subtitle">Notre mission</h2>
          <p className="about-text">
            Mixify est une application web dédiée à rendre vos soirées plus
            <strong> interactives</strong> et <strong>participatives</strong>. 
            Nous permettons à vos clients de proposer des morceaux
            musicaux, de laisser des messages payants pour être diffusés à l'écran, 
            tout en vous laissant un contrôle total (approbation, refus, ordre de passage, etc.).
          </p>
        </section>

        <section className="about-section">
          <h2 className="about-subtitle">Notre histoire</h2>
          <div className="about-text">
            L’idée est née d’un besoin simple&nbsp;: dynamiser l’ambiance 
            et offrir une expérience unique dans les bars, clubs et soirées DJ.
            Au fil du temps, Mixify a grandi, en intégrant des fonctionnalités 
            plus avancées : 
            <ul>
              <li>La gestion des messages payants,</li>
              <li>La capture de paiement (via Stripe) uniquement si le message est réellement diffusé,</li>
              <li>La personnalisation des motifs de refus pour les propositions,</li>
              <li>Le grouping de morceaux, la gestion d’événements, etc.</li>
            </ul>
          </div>
        </section>

        <section className="about-section">
          <h2 className="about-subtitle">Notre vision</h2>
          <p className="about-text">
            Nous croyons en un monde où chaque soirée devient un moment de
            <em> partage</em>, de <em>créativité</em> et d’<em>interactivité</em>.
            Avec Mixify, tout le monde participe, collabore — et l’organisateur 
            conserve la main sur ce qui passe durant l’événement. 
          </p>
          <p className="about-text">
            Que vous soyez un DJ expérimenté, un gérant de bar/club, 
            ou un simple utilisateur souhaitant déposer un message sur l’écran,
            Mixify est là pour faciliter ces interactions tout en offrant une 
            fluidité de paiement et un confort technique.
          </p>
        </section>

        <section className="about-section emphasis">
          <h2 className="about-subtitle">En résumé</h2>
          <p className="about-text">
            Mixify, c’est la certitude d’animer et de monétiser vos soirées, 
            d’engager votre public et de proposer de nouvelles façons de 
            communiquer. Rejoignez-nous pour bâtir des expériences événementielles 
            encore plus riches et originales&nbsp;!
          </p>
        </section>
        </div>
        
    </div>
    <FixedEventButton />
    </Layout>
  );
}


export default AboutPage;